import { ColorFamily } from '@graphql-types@';
import { EventColorMap } from 'types/color';
import { colorMap } from './factory';

export const EVENT_COLOR_MAP: EventColorMap = {
  [ColorFamily.Gray]: colorMap[ColorFamily.Gray],
  [ColorFamily.Red]: colorMap[ColorFamily.Red],
  [ColorFamily.Orange]: colorMap[ColorFamily.Orange],
  [ColorFamily.Yellow]: colorMap[ColorFamily.Yellow],
  [ColorFamily.Green]: colorMap[ColorFamily.Green],
  [ColorFamily.Blue]: colorMap[ColorFamily.Blue],
  [ColorFamily.Purple]: colorMap[ColorFamily.Purple],
  [ColorFamily.Pink]: colorMap[ColorFamily.Pink],
};

export const KEYUP_CODE_COLOR_MAP: {
  [k: string]: ColorFamily;
} = {
  '1': ColorFamily.Gray,
  '2': ColorFamily.Red,
  '3': ColorFamily.Orange,
  '4': ColorFamily.Yellow,
  '5': ColorFamily.Green,
  '6': ColorFamily.Blue,
  '7': ColorFamily.Purple,
  '8': ColorFamily.Pink,
};

/** @deprecated
 * The only thing this function does is fallbacks to Gray
 * You should fallback to default event color from user preferences instead
 *
 * Instead `useDefaultEventColor` hook should be used.
 */
export const colorOrDefault = (color?: ColorFamily | null): ColorFamily => {
  return color || ColorFamily.Gray;
};
