import { useUser, useUserId } from 'contexts/auth';
import { useInsertPreferencesMutation } from 'graphql/mutations/InsertPreferences.graphql';
import { useUpdateUserPreferencesMutation } from 'graphql/mutations/UpdateUserPreferences.graphql';
import { useUserQuery } from 'graphql/queries/user.graphql';
import { DateTime } from 'luxon';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { EventName } from 'types/analytics';
import { PreferenceName, PreferenceNameValues } from 'types/preference';
import { trackEvent } from 'utils/analytics';
import { TWENTY_FOUR_HOUR_FORMAT, withPreferenceDefaults } from 'utils/format';
import {
  defaultEventColorAtom,
  hideDeclinedEventsAtom,
  preferencesAtom,
  ui24HourClockAtom,
  userThemeAtom,
} from './preferences/preferencesAtoms';
import { ColorFamily, Theme_Enum } from '@graphql-types@';

export function usePreferences() {
  return useAtomValue(preferencesAtom);
}

export function useUpdatePreference() {
  const userId = useUserId();
  const setPreferences = useUpdateAtom(preferencesAtom);
  const [, updateUserPreferencesMutation] = useUpdateUserPreferencesMutation();
  const [, insertUserPreferencesMutation] = useInsertPreferencesMutation();

  return useCallback(
    async (
      preference: PreferenceNameValues,
      value: string | boolean | DateTime
    ) => {
      if (!userId) return;
      trackEvent(EventName.UpdatedPreference);

      const newValue = mapValue(preference, value);
      setPreferences((prev) => ({ ...prev, [preference]: value }));
      const response = await updateUserPreferencesMutation({
        userId: userId,
        set: { [preference]: newValue },
      });

      if (response.data?.update_userPreferences?.returning.length === 0) {
        insertUserPreferencesMutation({
          object: {
            userId,
            [preference]: newValue,
          },
        });
      }
    },
    [
      insertUserPreferencesMutation,
      setPreferences,
      updateUserPreferencesMutation,
      userId,
    ]
  );
}

export function SyncUserPreferences() {
  const user = useUser();

  const [userQueryRes] = useUserQuery({
    pause: Boolean(user?.didAuth) === false,
    requestPolicy: 'cache-and-network',
  });
  const setPreferences = useUpdateAtom(preferencesAtom);
  const queryPreferences = useMemo(
    () => userQueryRes.data?.new_user[0]?.preferences,
    [userQueryRes.data?.new_user]
  );

  useEffect(() => {
    if (!queryPreferences) return;
    setPreferences(withPreferenceDefaults(queryPreferences));
  }, [setPreferences, queryPreferences]);
  return null;
}

export function useUi24HourClock(): boolean {
  return useAtomValue(ui24HourClockAtom);
}

export function useHideDeclinedEvents(): boolean {
  return useAtomValue(hideDeclinedEventsAtom);
}

export function useDefaultEventColor(): ColorFamily {
  return useAtomValue(defaultEventColorAtom);
}

export function useUserTheme(): Theme_Enum | undefined {
  return useAtomValue(userThemeAtom);
}

function mapValue(
  key: PreferenceNameValues,
  value: string | boolean | DateTime
) {
  switch (key) {
    case PreferenceName.DefaultEventColor:
      return (value as string).toUpperCase();
    case PreferenceName.Theme:
      return value ? value : null;
    case PreferenceName.WorkHoursStartTime:
    case PreferenceName.WorkHoursEndTime:
      return value && value instanceof DateTime
        ? value.toFormat(TWENTY_FOUR_HOUR_FORMAT.short)
        : null;
    default:
      return value;
  }
}
