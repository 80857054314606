/* 94c48d6a1bb694de2e5e9ca4b7b6eaa0c74cd0bb
 * This file is automatically generated by graphql-let. */

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  DateTimeRFC3339: any;
  date: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export enum AccessRole {
  None = 'none',
  Owner = 'owner',
  Reader = 'reader',
  Writer = 'writer'
}

export type AttendeeAvailability = {
  __typename?: 'AttendeeAvailability';
  availability: Availability;
  emailAddress: Scalars['String'];
};

export enum Availability {
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
  Unknown = 'UNKNOWN'
}

export type Bestie = {
  __typename?: 'Bestie';
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  eventsAttended: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
};

export type Birthday = {
  __typename?: 'Birthday';
  avatar?: Maybe<Scalars['String']>;
  birthdayDate: Scalars['String'];
  contactId: Scalars['ID'];
  daysLeft: Scalars['Int'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type BirthdaysViewInput = {
  from: Scalars['DateTimeRFC3339'];
  limit: Scalars['Int'];
};

export type BirthdaysViewOutput = {
  __typename?: 'BirthdaysViewOutput';
  birthdays: Array<Birthday>;
};

export type BookCalendarSlotInput = {
  endAt: Scalars['DateTimeRFC3339'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  shareLinkId: Scalars['String'];
  startAt: Scalars['DateTimeRFC3339'];
};

export type BookCalendarSlotOutput = {
  __typename?: 'BookCalendarSlotOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CalendarOwner = {
  __typename?: 'CalendarOwner';
  contactId?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type CalendarRole = {
  __typename?: 'CalendarRole';
  accessRole: AccessRole;
  id: Scalars['String'];
};

export type CalendarSlot = {
  __typename?: 'CalendarSlot';
  endAt: Scalars['String'];
  recurrenceRules?: Maybe<Array<Scalars['String']>>;
  startAt: Scalars['String'];
};

export type CalendarSlotInput = {
  endAt: Scalars['DateTimeRFC3339'];
  id: Scalars['ID'];
  recurrenceRules?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTimeRFC3339'];
};

export type CategoryUpsertInput = {
  after?: InputMaybe<Scalars['String']>;
  archivedAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  colorFamily?: InputMaybe<ColorFamily>;
  createdAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  deletedAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  expanded?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  index?: InputMaybe<Scalars['Int']>;
  lastClientUpdate: Scalars['DateTimeRFC3339'];
  name?: InputMaybe<Scalars['String']>;
};

export type CheckAliasAvailabilityOutput = {
  __typename?: 'CheckAliasAvailabilityOutput';
  alias: Scalars['String'];
  isAvailable: Scalars['Boolean'];
};

export type ClearEventsCacheInput = {
  calendarId: Scalars['String'];
};

export type ClearEventsCacheOutput = {
  __typename?: 'ClearEventsCacheOutput';
  calendarId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum ColorFamily {
  Blue = 'blue',
  Gray = 'gray',
  Green = 'green',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  Yellow = 'yellow'
}

export type ContactReminderOutput = {
  __typename?: 'ContactReminderOutput';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  recurrenceRules: Array<Scalars['String']>;
  reminderEventId?: Maybe<Scalars['String']>;
};

export type CreateCalendarLinkInput = {
  alias?: InputMaybe<Scalars['String']>;
  bufferDurationMinutes?: InputMaybe<Scalars['Int']>;
  calendarId?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventMaxDurationMinutes?: InputMaybe<Scalars['Int']>;
  eventMinDurationMinutes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  slots: Array<CalendarSlotInput>;
  title: Scalars['String'];
};

export type CreateCalendarLinkOutput = {
  __typename?: 'CreateCalendarLinkOutput';
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type CreateContactsInput = {
  emails: Array<Scalars['String']>;
};

export type CreateContactsOutput = {
  __typename?: 'CreateContactsOutput';
  success: Scalars['Boolean'];
};

export type CreateEventInput = {
  addVideoConference?: InputMaybe<NewVideoConferenceProvider>;
  attendeesEmailAddresses: Array<Scalars['String']>;
  calendarId?: InputMaybe<Scalars['String']>;
  colorFamily?: InputMaybe<ColorFamily>;
  description?: InputMaybe<Scalars['String']>;
  doneAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  endAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  id?: InputMaybe<Scalars['String']>;
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  notifyGuests?: InputMaybe<Scalars['Boolean']>;
  recurrenceRules?: InputMaybe<Array<Scalars['String']>>;
  startAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  title: Scalars['String'];
  videoConferenceLink?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<NewEventVisibilityEnum>;
};

export type CreateEventOutput = {
  __typename?: 'CreateEventOutput';
  event?: Maybe<NewEvent>;
  success: Scalars['Boolean'];
  willNotify: Scalars['Boolean'];
};

export type DeleteEventFilter = {
  applyToFutureEvents?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
  notifyMessage?: InputMaybe<Scalars['String']>;
};

export type DeleteEventOutput = {
  __typename?: 'DeleteEventOutput';
  eventId?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
  willNotify: Scalars['Boolean'];
};

export type DisplayOptions = {
  __typename?: 'DisplayOptions';
  backgroundColor: Scalars['String'];
  foregroundColor: Scalars['String'];
};

export type EventCreator = {
  __typename?: 'EventCreator';
  avatar?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  self?: Maybe<Scalars['Boolean']>;
};

export type EventsResponse = {
  __typename?: 'EventsResponse';
  calendars: Array<CalendarRole>;
  events: Array<NewEvent>;
};

export type Feedback = {
  __typename?: 'Feedback';
  content: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['String'];
};

export type FindNextAvailableSlotArgs = {
  attendeesEmailAddresses: Array<Scalars['String']>;
};

export type FindNextAvailableSlotResponse = {
  __typename?: 'FindNextAvailableSlotResponse';
  attendeeAvailabilities: Array<AttendeeAvailability>;
  slot?: Maybe<Slot>;
};

export type GMapsPlace = {
  __typename?: 'GMapsPlace';
  description: Scalars['String'];
  id: Scalars['String'];
  mainText?: Maybe<Scalars['String']>;
  secondaryText?: Maybe<Scalars['String']>;
};

export type GetGMapsPlacesInput = {
  term: Scalars['String'];
};

export type GetGMapsPlacesOutput = {
  __typename?: 'GetGMapsPlacesOutput';
  places: Array<GMapsPlace>;
};

export type HideCalendarInput = {
  calendarId: Scalars['ID'];
  calendarIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type HideCalendarOutput = {
  __typename?: 'HideCalendarOutput';
  success: Scalars['Boolean'];
};

export type InCalendarsFilter = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  isPrimaryOnly?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LightweightEventsFilter = {
  endAt: Scalars['DateTimeRFC3339'];
  searchTerm?: InputMaybe<Scalars['String']>;
  startAt: Scalars['DateTimeRFC3339'];
  uniqueTitles?: InputMaybe<Scalars['Boolean']>;
};

export type MinimalCalendarInfo = {
  __typename?: 'MinimalCalendarInfo';
  colorFamily: ColorFamily;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type NewCalendar = {
  __typename?: 'NewCalendar';
  colorFamily?: Maybe<ColorFamily>;
  displayOptions: DisplayOptions;
  events?: Maybe<EventsResponse>;
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  name: Scalars['String'];
  owner?: Maybe<CalendarOwner>;
};


export type NewCalendarEventsArgs = {
  where?: InputMaybe<NewEventFilter>;
};

export type NewCalendarFilter = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  primaryOnly?: InputMaybe<Scalars['Boolean']>;
};

export type NewEvent = {
  __typename?: 'NewEvent';
  attendees: Array<NewEventAttendee>;
  belongsToUserCalendar: Scalars['Boolean'];
  calendar?: Maybe<NewCalendar>;
  calendarId: Scalars['String'];
  canEdit: Scalars['Boolean'];
  colorFamily?: Maybe<ColorFamily>;
  createdAt: Scalars['String'];
  creator?: Maybe<EventCreator>;
  description?: Maybe<Scalars['String']>;
  doneAt?: Maybe<Scalars['String']>;
  doneBy?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTimeRFC3339'];
  eventType?: Maybe<Scalars['String']>;
  guestsCanInviteOthers?: Maybe<Scalars['Boolean']>;
  guestsCanModify?: Maybe<Scalars['Boolean']>;
  guestsCanSeeOtherGuests?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isAllDay: Scalars['Boolean'];
  isOwnEvent: Scalars['Boolean'];
  isSelfAsAttendee: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  prevEndAt: Scalars['DateTimeRFC3339'];
  prevStartAt: Scalars['DateTimeRFC3339'];
  recurrenceRules?: Maybe<Array<Scalars['String']>>;
  recurringEventId?: Maybe<Scalars['String']>;
  rsvp: NewEventRsvpEnum;
  startAt: Scalars['DateTimeRFC3339'];
  status: Scalars['String'];
  title: Scalars['String'];
  updated: Scalars['DateTimeRFC3339'];
  videoConferences: Array<NewEventVideoConference>;
  visibility: NewEventVisibilityEnum;
};

export type NewEventAttendee = {
  __typename?: 'NewEventAttendee';
  RSVP: NewEventRsvpEnum;
  avatar?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  organizer: Scalars['Boolean'];
  responseStatus: NewEventAttendeeResponseStatusEnum;
};

export enum NewEventAttendeeResponseStatusEnum {
  Accepted = 'accepted',
  Declined = 'declined',
  NeedsAction = 'needsAction',
  Tentative = 'tentative'
}

export type NewEventFilter = {
  endAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  inCalendars?: InputMaybe<InCalendarsFilter>;
  includeCancelledEvents?: InputMaybe<Scalars['Boolean']>;
  isUserEventCreator?: InputMaybe<Scalars['Boolean']>;
  startAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  updatedMin?: InputMaybe<Scalars['DateTimeRFC3339']>;
};

export enum NewEventRsvpEnum {
  No = 'NO',
  NotInvited = 'NOT_INVITED',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export type NewEventVideoConference = {
  __typename?: 'NewEventVideoConference';
  link: Scalars['String'];
  provider: NewVideoConferenceProvider;
};

export enum NewEventVisibilityEnum {
  Default = 'DEFAULT',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum NewVideoConferenceProvider {
  Around = 'AROUND',
  GoogleMeet = 'GOOGLE_MEET',
  Jitsi = 'JITSI',
  MsTeams = 'MS_TEAMS',
  None = 'NONE',
  Whereby = 'WHEREBY',
  Zoom = 'ZOOM'
}

export type ProfileMeetInfoInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type ProfileMeetInfoOutput = {
  __typename?: 'ProfileMeetInfoOutput';
  lastSeen?: Maybe<Scalars['DateTimeRFC3339']>;
  lastSeenSource?: Maybe<Scalars['String']>;
  numberOfTimesMeet: Scalars['Int'];
};

export type ProfileViewOutput = {
  __typename?: 'ProfileViewOutput';
  birthday?: Maybe<Scalars['DateTimeRFC3339']>;
  contactId: Scalars['String'];
  email: Scalars['String'];
  favoriteSince?: Maybe<Scalars['DateTimeRFC3339']>;
  firstName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOwnProfile: Scalars['Boolean'];
  lastLocation?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  reminderEvent?: Maybe<NewEvent>;
  twitter?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['DateTimeRFC3339']>;
};

export type RecentlyPlayedTrack = {
  __typename?: 'RecentlyPlayedTrack';
  album?: Maybe<Scalars['String']>;
  artist: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  playedAt: Scalars['DateTimeRFC3339'];
  title: Scalars['String'];
  uri: Scalars['String'];
};

export type RefreshActivityFeedOutput = {
  __typename?: 'RefreshActivityFeedOutput';
  success: Scalars['Boolean'];
};

export type RetrieveSharedCalendarLinkInput = {
  fromDate: Scalars['DateTime'];
  id: Scalars['ID'];
  toDate: Scalars['DateTime'];
};

export type RetrieveSharedCalendarLinkOutput = {
  __typename?: 'RetrieveSharedCalendarLinkOutput';
  alias?: Maybe<Scalars['String']>;
  bookedCount: Scalars['Int'];
  bufferDurationMinutes: Scalars['Int'];
  calendarId: Scalars['String'];
  coverImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventMaxDurationMinutes: Scalars['Int'];
  eventMinDurationMinutes: Scalars['Int'];
  expiresAt: Scalars['String'];
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  ownerAvatar: Scalars['String'];
  ownerName: Scalars['String'];
  slots: Array<CalendarSlot>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type SendFeedbackInput = {
  content: Scalars['String'];
  metadata: Scalars['String'];
};

export type SendFeedbackOutput = {
  __typename?: 'SendFeedbackOutput';
  feedback?: Maybe<Feedback>;
  success: Scalars['Boolean'];
};

export type SetBirthdayReminderInput = {
  birthday?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
};

export type SetBirthdayReminderOutput = {
  __typename?: 'SetBirthdayReminderOutput';
  event?: Maybe<NewEvent>;
};

export type SetEventRecurrenceInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
  notifyGuests?: InputMaybe<Scalars['Boolean']>;
  notifyMessage?: InputMaybe<Scalars['String']>;
  recurrenceRules: Array<Scalars['String']>;
};

export type SetEventRecurrenceOutput = {
  __typename?: 'SetEventRecurrenceOutput';
  event?: Maybe<NewEvent>;
  success: Scalars['Boolean'];
};

export type SetReminderInput = {
  contactEmail?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  recurrenceRules: Array<Scalars['String']>;
};

export type SetReminderOutput = {
  __typename?: 'SetReminderOutput';
  event?: Maybe<NewEvent>;
};

export type ShowCalendarInput = {
  calendarId: Scalars['ID'];
  calendarIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ShowCalendarOutput = {
  __typename?: 'ShowCalendarOutput';
  success: Scalars['Boolean'];
};

export type SignInUsingFirebaseAuthenticationInput = {
  firebaseIdToken: Scalars['String'];
  googleRefreshToken?: InputMaybe<Scalars['String']>;
};

export type SignInUsingFirebaseAuthenticationOutput = {
  __typename?: 'SignInUsingFirebaseAuthenticationOutput';
  error?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SignInUsingGoogleAuthorizationCodeInput = {
  firebaseIdToken: Scalars['String'];
  googleAuthorizationCode: Scalars['String'];
};

export type SignInUsingGoogleAuthorizationCodeOutput = {
  __typename?: 'SignInUsingGoogleAuthorizationCodeOutput';
  error?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SingleEventInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  eventId: Scalars['String'];
};

export type Slot = {
  __typename?: 'Slot';
  endAt: Scalars['DateTimeRFC3339'];
  startAt: Scalars['DateTimeRFC3339'];
};

export type SpotifyPauseTrack = {
  __typename?: 'SpotifyPauseTrack';
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SpotifyPlayTrack = {
  __typename?: 'SpotifyPlayTrack';
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SpotifyQueue = {
  __typename?: 'SpotifyQueue';
  tracks: Array<RecentlyPlayedTrack>;
};

export type SpotifyTrack = {
  __typename?: 'SpotifyTrack';
  album: Scalars['String'];
  artist: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['String'];
  isCurrentlyPlaying: Scalars['Boolean'];
  photo: Scalars['String'];
  title: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SyncContactsInput = {
  isFullSync?: InputMaybe<Scalars['Boolean']>;
};

export type SyncContactsOutput = {
  __typename?: 'SyncContactsOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type TodoCategory = {
  __typename?: 'TodoCategory';
  archivedAt?: Maybe<Scalars['DateTimeRFC3339']>;
  colorFamily?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeRFC3339']>;
  deletedAt?: Maybe<Scalars['DateTimeRFC3339']>;
  expanded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['DateTimeRFC3339']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeRFC3339'];
  userId?: Maybe<Scalars['String']>;
};

export type TodoItem = {
  __typename?: 'TodoItem';
  archivedAt?: Maybe<Scalars['DateTimeRFC3339']>;
  categoryId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeRFC3339']>;
  deletedAt?: Maybe<Scalars['DateTimeRFC3339']>;
  doneAt?: Maybe<Scalars['DateTimeRFC3339']>;
  id?: Maybe<Scalars['ID']>;
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['DateTimeRFC3339']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeRFC3339'];
};

export type TodoUpsertInput = {
  after?: InputMaybe<Scalars['String']>;
  archivedAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  deletedAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  doneAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  id: Scalars['ID'];
  index?: InputMaybe<Scalars['Int']>;
  lastClientUpdate: Scalars['DateTimeRFC3339'];
  name?: InputMaybe<Scalars['String']>;
};

export type ToggleEventDoneFilter = {
  calendarId?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
};

export type ToggleEventDoneOutput = {
  __typename?: 'ToggleEventDoneOutput';
  event?: Maybe<NewEvent>;
  success: Scalars['Boolean'];
};

export type UpdateCalendarInput = {
  colorFamily?: InputMaybe<ColorFamily>;
  emoji?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCalendarLinkInput = {
  alias?: InputMaybe<Scalars['String']>;
  bufferDurationMinutes?: InputMaybe<Scalars['Int']>;
  calendarId?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventMaxDurationMinutes?: InputMaybe<Scalars['Int']>;
  eventMinDurationMinutes?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  location?: InputMaybe<Scalars['String']>;
  slots?: InputMaybe<Array<CalendarSlotInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCalendarLinkOutput = {
  __typename?: 'UpdateCalendarLinkOutput';
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateEventInput = {
  addVideoConference?: InputMaybe<NewVideoConferenceProvider>;
  attendeesEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  calendarId: Scalars['String'];
  colorFamily?: InputMaybe<ColorFamily>;
  description?: InputMaybe<Scalars['String']>;
  doneAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  endAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  eventId: Scalars['ID'];
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  notifyGuests?: InputMaybe<Scalars['Boolean']>;
  notifyMessage?: InputMaybe<Scalars['String']>;
  prevCalendarId?: InputMaybe<Scalars['String']>;
  recurrenceRules?: InputMaybe<Array<Scalars['String']>>;
  recurringEventId?: InputMaybe<Scalars['String']>;
  rsvp?: InputMaybe<NewEventRsvpEnum>;
  startAt?: InputMaybe<Scalars['DateTimeRFC3339']>;
  title?: InputMaybe<Scalars['String']>;
  videoConferenceLink?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<NewEventVisibilityEnum>;
};

export type UpdateEventOutput = {
  __typename?: 'UpdateEventOutput';
  event?: Maybe<NewEvent>;
  success: Scalars['Boolean'];
  willNotify: Scalars['Boolean'];
};

export type UpdateEventRsvpInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
  rsvp?: InputMaybe<NewEventRsvpEnum>;
};

export type UpdateEventRsvpOutput = {
  __typename?: 'UpdateEventRSVPOutput';
  event?: Maybe<NewEvent>;
  success: Scalars['Boolean'];
  willNotify: Scalars['Boolean'];
};

export type UpdateTodosInput = {
  categories?: InputMaybe<Array<CategoryUpsertInput>>;
  todos?: InputMaybe<Array<TodoUpsertInput>>;
};

export type UpdateTodosOutput = {
  __typename?: 'UpdateTodosOutput';
  categories: Array<TodoCategory>;
  todos: Array<TodoItem>;
};

/** columns and relationships of "activityFeed" */
export type ActivityFeed = {
  __typename?: 'activityFeed';
  /** An object relationship */
  activityFeedTypeEnum: ActivityFeedTypeEnum;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  owner: Scalars['jsonb'];
  ownerId: Scalars['String'];
  payload: Scalars['jsonb'];
  type: ActivityFeedTypeEnum_Enum;
  uniqHash?: Maybe<Scalars['String']>;
  /** An array relationship */
  userActivityFeeds: Array<UserActivityFeed>;
  /** An aggregate relationship */
  userActivityFeeds_aggregate: UserActivityFeed_Aggregate;
};


/** columns and relationships of "activityFeed" */
export type ActivityFeedOwnerArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "activityFeed" */
export type ActivityFeedPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "activityFeed" */
export type ActivityFeedUserActivityFeedsArgs = {
  distinct_on?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserActivityFeed_Order_By>>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};


/** columns and relationships of "activityFeed" */
export type ActivityFeedUserActivityFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserActivityFeed_Order_By>>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};

/** columns and relationships of "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum = {
  __typename?: 'activityFeedTypeEnum';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Aggregate = {
  __typename?: 'activityFeedTypeEnum_aggregate';
  aggregate?: Maybe<ActivityFeedTypeEnum_Aggregate_Fields>;
  nodes: Array<ActivityFeedTypeEnum>;
};

/** aggregate fields of "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Aggregate_Fields = {
  __typename?: 'activityFeedTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ActivityFeedTypeEnum_Max_Fields>;
  min?: Maybe<ActivityFeedTypeEnum_Min_Fields>;
};


/** aggregate fields of "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityFeedTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "activityFeedTypeEnum". All fields are combined with a logical 'AND'. */
export type ActivityFeedTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ActivityFeedTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityFeedTypeEnum_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "activityFeedTypeEnum" */
export enum ActivityFeedTypeEnum_Constraint {
  /** unique or primary key constraint */
  ActivityFeedTypeEnumPkey = 'activityFeedTypeEnum_pkey'
}

export enum ActivityFeedTypeEnum_Enum {
  /** Triggered when creating an event */
  EventCreationActivity = 'EventCreationActivity',
  /** Triggered when updating an event */
  EventUpdateActivity = 'EventUpdateActivity'
}

/** Boolean expression to compare columns of type "activityFeedTypeEnum_enum". All fields are combined with logical 'AND'. */
export type ActivityFeedTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ActivityFeedTypeEnum_Enum>;
  _in?: InputMaybe<Array<ActivityFeedTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ActivityFeedTypeEnum_Enum>;
  _nin?: InputMaybe<Array<ActivityFeedTypeEnum_Enum>>;
};

/** input type for inserting data into table "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ActivityFeedTypeEnum_Max_Fields = {
  __typename?: 'activityFeedTypeEnum_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ActivityFeedTypeEnum_Min_Fields = {
  __typename?: 'activityFeedTypeEnum_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Mutation_Response = {
  __typename?: 'activityFeedTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityFeedTypeEnum>;
};

/** input type for inserting object relation for remote table "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Obj_Rel_Insert_Input = {
  data: ActivityFeedTypeEnum_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<ActivityFeedTypeEnum_On_Conflict>;
};

/** on conflict condition type for table "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_On_Conflict = {
  constraint: ActivityFeedTypeEnum_Constraint;
  update_columns?: Array<ActivityFeedTypeEnum_Update_Column>;
  where?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "activityFeedTypeEnum". */
export type ActivityFeedTypeEnum_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: activityFeedTypeEnum */
export type ActivityFeedTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "activityFeedTypeEnum" */
export enum ActivityFeedTypeEnum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "activityFeedTypeEnum" */
export type ActivityFeedTypeEnum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "activityFeedTypeEnum" */
export enum ActivityFeedTypeEnum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** aggregated selection of "activityFeed" */
export type ActivityFeed_Aggregate = {
  __typename?: 'activityFeed_aggregate';
  aggregate?: Maybe<ActivityFeed_Aggregate_Fields>;
  nodes: Array<ActivityFeed>;
};

/** aggregate fields of "activityFeed" */
export type ActivityFeed_Aggregate_Fields = {
  __typename?: 'activityFeed_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ActivityFeed_Max_Fields>;
  min?: Maybe<ActivityFeed_Min_Fields>;
};


/** aggregate fields of "activityFeed" */
export type ActivityFeed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityFeed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ActivityFeed_Append_Input = {
  owner?: InputMaybe<Scalars['jsonb']>;
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "activityFeed". All fields are combined with a logical 'AND'. */
export type ActivityFeed_Bool_Exp = {
  _and?: InputMaybe<Array<ActivityFeed_Bool_Exp>>;
  _not?: InputMaybe<ActivityFeed_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityFeed_Bool_Exp>>;
  activityFeedTypeEnum?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Jsonb_Comparison_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<ActivityFeedTypeEnum_Enum_Comparison_Exp>;
  uniqHash?: InputMaybe<String_Comparison_Exp>;
  userActivityFeeds?: InputMaybe<UserActivityFeed_Bool_Exp>;
};

/** unique or primary key constraints on table "activityFeed" */
export enum ActivityFeed_Constraint {
  /** unique or primary key constraint */
  ActivityFeedPkey = 'activityFeed_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ActivityFeed_Delete_At_Path_Input = {
  owner?: InputMaybe<Array<Scalars['String']>>;
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ActivityFeed_Delete_Elem_Input = {
  owner?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ActivityFeed_Delete_Key_Input = {
  owner?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "activityFeed" */
export type ActivityFeed_Insert_Input = {
  activityFeedTypeEnum?: InputMaybe<ActivityFeedTypeEnum_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner?: InputMaybe<Scalars['jsonb']>;
  ownerId?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ActivityFeedTypeEnum_Enum>;
  uniqHash?: InputMaybe<Scalars['String']>;
  userActivityFeeds?: InputMaybe<UserActivityFeed_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ActivityFeed_Max_Fields = {
  __typename?: 'activityFeed_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ownerId?: Maybe<Scalars['String']>;
  uniqHash?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ActivityFeed_Min_Fields = {
  __typename?: 'activityFeed_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ownerId?: Maybe<Scalars['String']>;
  uniqHash?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "activityFeed" */
export type ActivityFeed_Mutation_Response = {
  __typename?: 'activityFeed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityFeed>;
};

/** input type for inserting object relation for remote table "activityFeed" */
export type ActivityFeed_Obj_Rel_Insert_Input = {
  data: ActivityFeed_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<ActivityFeed_On_Conflict>;
};

/** on conflict condition type for table "activityFeed" */
export type ActivityFeed_On_Conflict = {
  constraint: ActivityFeed_Constraint;
  update_columns?: Array<ActivityFeed_Update_Column>;
  where?: InputMaybe<ActivityFeed_Bool_Exp>;
};

/** Ordering options when selecting data from "activityFeed". */
export type ActivityFeed_Order_By = {
  activityFeedTypeEnum?: InputMaybe<ActivityFeedTypeEnum_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  uniqHash?: InputMaybe<Order_By>;
  userActivityFeeds_aggregate?: InputMaybe<UserActivityFeed_Aggregate_Order_By>;
};

/** primary key columns input for table: activityFeed */
export type ActivityFeed_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ActivityFeed_Prepend_Input = {
  owner?: InputMaybe<Scalars['jsonb']>;
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "activityFeed" */
export enum ActivityFeed_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UniqHash = 'uniqHash'
}

/** input type for updating data in table "activityFeed" */
export type ActivityFeed_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner?: InputMaybe<Scalars['jsonb']>;
  ownerId?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ActivityFeedTypeEnum_Enum>;
  uniqHash?: InputMaybe<Scalars['String']>;
};

/** update columns of table "activityFeed" */
export enum ActivityFeed_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UniqHash = 'uniqHash'
}

/** columns and relationships of "analytics_event" */
export type Analytics_Event = {
  __typename?: 'analytics_event';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  labels: Scalars['jsonb'];
  name: Scalars['String'];
  received_at: Scalars['timestamptz'];
  sent_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "analytics_event" */
export type Analytics_EventLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "analytics_event" */
export type Analytics_Event_Aggregate = {
  __typename?: 'analytics_event_aggregate';
  aggregate?: Maybe<Analytics_Event_Aggregate_Fields>;
  nodes: Array<Analytics_Event>;
};

/** aggregate fields of "analytics_event" */
export type Analytics_Event_Aggregate_Fields = {
  __typename?: 'analytics_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Analytics_Event_Max_Fields>;
  min?: Maybe<Analytics_Event_Min_Fields>;
};


/** aggregate fields of "analytics_event" */
export type Analytics_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Analytics_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Analytics_Event_Append_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "analytics_event". All fields are combined with a logical 'AND'. */
export type Analytics_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Analytics_Event_Bool_Exp>>;
  _not?: InputMaybe<Analytics_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Analytics_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  received_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "analytics_event" */
export enum Analytics_Event_Constraint {
  /** unique or primary key constraint */
  AnalyticsEventPkey = 'analytics_event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Analytics_Event_Delete_At_Path_Input = {
  labels?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Analytics_Event_Delete_Elem_Input = {
  labels?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Analytics_Event_Delete_Key_Input = {
  labels?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "analytics_event" */
export type Analytics_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Analytics_Event_Max_Fields = {
  __typename?: 'analytics_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Analytics_Event_Min_Fields = {
  __typename?: 'analytics_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "analytics_event" */
export type Analytics_Event_Mutation_Response = {
  __typename?: 'analytics_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_Event>;
};

/** on conflict condition type for table "analytics_event" */
export type Analytics_Event_On_Conflict = {
  constraint: Analytics_Event_Constraint;
  update_columns?: Array<Analytics_Event_Update_Column>;
  where?: InputMaybe<Analytics_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "analytics_event". */
export type Analytics_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  received_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: analytics_event */
export type Analytics_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Analytics_Event_Prepend_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "analytics_event" */
export enum Analytics_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  Name = 'name',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "analytics_event" */
export type Analytics_Event_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "analytics_event" */
export enum Analytics_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  Name = 'name',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "calendarHistory" */
export type CalendarHistory = {
  __typename?: 'calendarHistory';
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "calendarHistory" */
export type CalendarHistory_Aggregate = {
  __typename?: 'calendarHistory_aggregate';
  aggregate?: Maybe<CalendarHistory_Aggregate_Fields>;
  nodes: Array<CalendarHistory>;
};

/** aggregate fields of "calendarHistory" */
export type CalendarHistory_Aggregate_Fields = {
  __typename?: 'calendarHistory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CalendarHistory_Max_Fields>;
  min?: Maybe<CalendarHistory_Min_Fields>;
};


/** aggregate fields of "calendarHistory" */
export type CalendarHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalendarHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "calendarHistory". All fields are combined with a logical 'AND'. */
export type CalendarHistory_Bool_Exp = {
  _and?: InputMaybe<Array<CalendarHistory_Bool_Exp>>;
  _not?: InputMaybe<CalendarHistory_Bool_Exp>;
  _or?: InputMaybe<Array<CalendarHistory_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendarHistory" */
export enum CalendarHistory_Constraint {
  /** unique or primary key constraint */
  CalendarHistoryPkey = 'calendarHistory_pkey'
}

/** input type for inserting data into table "calendarHistory" */
export type CalendarHistory_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CalendarHistory_Max_Fields = {
  __typename?: 'calendarHistory_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CalendarHistory_Min_Fields = {
  __typename?: 'calendarHistory_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "calendarHistory" */
export type CalendarHistory_Mutation_Response = {
  __typename?: 'calendarHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalendarHistory>;
};

/** on conflict condition type for table "calendarHistory" */
export type CalendarHistory_On_Conflict = {
  constraint: CalendarHistory_Constraint;
  update_columns?: Array<CalendarHistory_Update_Column>;
  where?: InputMaybe<CalendarHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "calendarHistory". */
export type CalendarHistory_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendarHistory */
export type CalendarHistory_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "calendarHistory" */
export enum CalendarHistory_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "calendarHistory" */
export type CalendarHistory_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "calendarHistory" */
export enum CalendarHistory_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "calendarPreference" */
export type CalendarPreference = {
  __typename?: 'calendarPreference';
  createdAt: Scalars['timestamptz'];
  googleCalendarId: Scalars['String'];
  googleWatchChannelExpiresAt?: Maybe<Scalars['timestamptz']>;
  googleWatchChannelId?: Maybe<Scalars['String']>;
  googleWatchChannelResourceId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isVisible?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: New_User;
  userId: Scalars['String'];
};

/** aggregated selection of "calendarPreference" */
export type CalendarPreference_Aggregate = {
  __typename?: 'calendarPreference_aggregate';
  aggregate?: Maybe<CalendarPreference_Aggregate_Fields>;
  nodes: Array<CalendarPreference>;
};

/** aggregate fields of "calendarPreference" */
export type CalendarPreference_Aggregate_Fields = {
  __typename?: 'calendarPreference_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CalendarPreference_Max_Fields>;
  min?: Maybe<CalendarPreference_Min_Fields>;
};


/** aggregate fields of "calendarPreference" */
export type CalendarPreference_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalendarPreference_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "calendarPreference". All fields are combined with a logical 'AND'. */
export type CalendarPreference_Bool_Exp = {
  _and?: InputMaybe<Array<CalendarPreference_Bool_Exp>>;
  _not?: InputMaybe<CalendarPreference_Bool_Exp>;
  _or?: InputMaybe<Array<CalendarPreference_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  googleCalendarId?: InputMaybe<String_Comparison_Exp>;
  googleWatchChannelExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  googleWatchChannelId?: InputMaybe<String_Comparison_Exp>;
  googleWatchChannelResourceId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isVisible?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendarPreference" */
export enum CalendarPreference_Constraint {
  /** unique or primary key constraint */
  CalendarPreferenceGoogleCalendarIdUserIdKey = 'calendarPreference_googleCalendarId_userId_key',
  /** unique or primary key constraint */
  CalendarPreferencePkey = 'calendarPreference_pkey'
}

/** input type for inserting data into table "calendarPreference" */
export type CalendarPreference_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  googleWatchChannelExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  googleWatchChannelId?: InputMaybe<Scalars['String']>;
  googleWatchChannelResourceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CalendarPreference_Max_Fields = {
  __typename?: 'calendarPreference_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleWatchChannelExpiresAt?: Maybe<Scalars['timestamptz']>;
  googleWatchChannelId?: Maybe<Scalars['String']>;
  googleWatchChannelResourceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CalendarPreference_Min_Fields = {
  __typename?: 'calendarPreference_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleWatchChannelExpiresAt?: Maybe<Scalars['timestamptz']>;
  googleWatchChannelId?: Maybe<Scalars['String']>;
  googleWatchChannelResourceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "calendarPreference" */
export type CalendarPreference_Mutation_Response = {
  __typename?: 'calendarPreference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalendarPreference>;
};

/** input type for inserting object relation for remote table "calendarPreference" */
export type CalendarPreference_Obj_Rel_Insert_Input = {
  data: CalendarPreference_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<CalendarPreference_On_Conflict>;
};

/** on conflict condition type for table "calendarPreference" */
export type CalendarPreference_On_Conflict = {
  constraint: CalendarPreference_Constraint;
  update_columns?: Array<CalendarPreference_Update_Column>;
  where?: InputMaybe<CalendarPreference_Bool_Exp>;
};

/** Ordering options when selecting data from "calendarPreference". */
export type CalendarPreference_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  googleCalendarId?: InputMaybe<Order_By>;
  googleWatchChannelExpiresAt?: InputMaybe<Order_By>;
  googleWatchChannelId?: InputMaybe<Order_By>;
  googleWatchChannelResourceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isVisible?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendarPreference */
export type CalendarPreference_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendarPreference" */
export enum CalendarPreference_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GoogleCalendarId = 'googleCalendarId',
  /** column name */
  GoogleWatchChannelExpiresAt = 'googleWatchChannelExpiresAt',
  /** column name */
  GoogleWatchChannelId = 'googleWatchChannelId',
  /** column name */
  GoogleWatchChannelResourceId = 'googleWatchChannelResourceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "calendarPreference" */
export type CalendarPreference_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  googleWatchChannelExpiresAt?: InputMaybe<Scalars['timestamptz']>;
  googleWatchChannelId?: InputMaybe<Scalars['String']>;
  googleWatchChannelResourceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "calendarPreference" */
export enum CalendarPreference_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GoogleCalendarId = 'googleCalendarId',
  /** column name */
  GoogleWatchChannelExpiresAt = 'googleWatchChannelExpiresAt',
  /** column name */
  GoogleWatchChannelId = 'googleWatchChannelId',
  /** column name */
  GoogleWatchChannelResourceId = 'googleWatchChannelResourceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "calendarStartsOn" */
export type CalendarStartsOn = {
  __typename?: 'calendarStartsOn';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "calendarStartsOn" */
export type CalendarStartsOn_Aggregate = {
  __typename?: 'calendarStartsOn_aggregate';
  aggregate?: Maybe<CalendarStartsOn_Aggregate_Fields>;
  nodes: Array<CalendarStartsOn>;
};

/** aggregate fields of "calendarStartsOn" */
export type CalendarStartsOn_Aggregate_Fields = {
  __typename?: 'calendarStartsOn_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CalendarStartsOn_Max_Fields>;
  min?: Maybe<CalendarStartsOn_Min_Fields>;
};


/** aggregate fields of "calendarStartsOn" */
export type CalendarStartsOn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalendarStartsOn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "calendarStartsOn". All fields are combined with a logical 'AND'. */
export type CalendarStartsOn_Bool_Exp = {
  _and?: InputMaybe<Array<CalendarStartsOn_Bool_Exp>>;
  _not?: InputMaybe<CalendarStartsOn_Bool_Exp>;
  _or?: InputMaybe<Array<CalendarStartsOn_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendarStartsOn" */
export enum CalendarStartsOn_Constraint {
  /** unique or primary key constraint */
  CalendarStartsOnPkey = 'calendarStartsOn_pkey'
}

export enum CalendarStartsOn_Enum {
  /** Monday */
  Monday = 'MONDAY',
  /** Today */
  Rolling = 'ROLLING',
  /** Sunday */
  Sunday = 'SUNDAY'
}

/** Boolean expression to compare columns of type "calendarStartsOn_enum". All fields are combined with logical 'AND'. */
export type CalendarStartsOn_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CalendarStartsOn_Enum>;
  _in?: InputMaybe<Array<CalendarStartsOn_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CalendarStartsOn_Enum>;
  _nin?: InputMaybe<Array<CalendarStartsOn_Enum>>;
};

/** input type for inserting data into table "calendarStartsOn" */
export type CalendarStartsOn_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CalendarStartsOn_Max_Fields = {
  __typename?: 'calendarStartsOn_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CalendarStartsOn_Min_Fields = {
  __typename?: 'calendarStartsOn_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "calendarStartsOn" */
export type CalendarStartsOn_Mutation_Response = {
  __typename?: 'calendarStartsOn_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalendarStartsOn>;
};

/** on conflict condition type for table "calendarStartsOn" */
export type CalendarStartsOn_On_Conflict = {
  constraint: CalendarStartsOn_Constraint;
  update_columns?: Array<CalendarStartsOn_Update_Column>;
  where?: InputMaybe<CalendarStartsOn_Bool_Exp>;
};

/** Ordering options when selecting data from "calendarStartsOn". */
export type CalendarStartsOn_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendarStartsOn */
export type CalendarStartsOn_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "calendarStartsOn" */
export enum CalendarStartsOn_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "calendarStartsOn" */
export type CalendarStartsOn_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "calendarStartsOn" */
export enum CalendarStartsOn_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "category" */
export type Category = {
  __typename?: 'category';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  colorFamily?: Maybe<ColorFamilyEnum_Enum>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expanded: Scalars['Boolean'];
  id: Scalars['uuid'];
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  /** An array relationship */
  todos: Array<Todo>;
  /** An aggregate relationship */
  todos_aggregate: Todo_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<New_User>;
  userId: Scalars['String'];
};


/** columns and relationships of "category" */
export type CategoryTodosArgs = {
  distinct_on?: InputMaybe<Array<Todo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Order_By>>;
  where?: InputMaybe<Todo_Bool_Exp>;
};


/** columns and relationships of "category" */
export type CategoryTodos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Order_By>>;
  where?: InputMaybe<Todo_Bool_Exp>;
};

/** aggregated selection of "category" */
export type Category_Aggregate = {
  __typename?: 'category_aggregate';
  aggregate?: Maybe<Category_Aggregate_Fields>;
  nodes: Array<Category>;
};

/** aggregate fields of "category" */
export type Category_Aggregate_Fields = {
  __typename?: 'category_aggregate_fields';
  avg?: Maybe<Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Max_Fields>;
  min?: Maybe<Category_Min_Fields>;
  stddev?: Maybe<Category_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Sum_Fields>;
  var_pop?: Maybe<Category_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Var_Samp_Fields>;
  variance?: Maybe<Category_Variance_Fields>;
};


/** aggregate fields of "category" */
export type Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Avg_Fields = {
  __typename?: 'category_avg_fields';
  index?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "category". All fields are combined with a logical 'AND'. */
export type Category_Bool_Exp = {
  _and?: InputMaybe<Array<Category_Bool_Exp>>;
  _not?: InputMaybe<Category_Bool_Exp>;
  _or?: InputMaybe<Array<Category_Bool_Exp>>;
  archivedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  colorFamily?: InputMaybe<ColorFamilyEnum_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expanded?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  lastClientUpdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<String_Comparison_Exp>;
  todos?: InputMaybe<Todo_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "category" */
export enum Category_Constraint {
  /** unique or primary key constraint */
  CategoryPkey = 'category_pkey'
}

/** input type for incrementing numeric columns in table "category" */
export type Category_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "category" */
export type Category_Insert_Input = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  colorFamily?: InputMaybe<ColorFamilyEnum_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expanded?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  lastClientUpdate?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  todos?: InputMaybe<Todo_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Category_Max_Fields = {
  __typename?: 'category_max_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Min_Fields = {
  __typename?: 'category_min_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "category" */
export type Category_Mutation_Response = {
  __typename?: 'category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category>;
};

/** input type for inserting object relation for remote table "category" */
export type Category_Obj_Rel_Insert_Input = {
  data: Category_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Category_On_Conflict>;
};

/** on conflict condition type for table "category" */
export type Category_On_Conflict = {
  constraint: Category_Constraint;
  update_columns?: Array<Category_Update_Column>;
  where?: InputMaybe<Category_Bool_Exp>;
};

/** Ordering options when selecting data from "category". */
export type Category_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  colorFamily?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  expanded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lastClientUpdate?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  todos_aggregate?: InputMaybe<Todo_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: category */
export type Category_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "category" */
export enum Category_Select_Column {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  ColorFamily = 'colorFamily',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Expanded = 'expanded',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LastClientUpdate = 'lastClientUpdate',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "category" */
export type Category_Set_Input = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  colorFamily?: InputMaybe<ColorFamilyEnum_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expanded?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  lastClientUpdate?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Stddev_Fields = {
  __typename?: 'category_stddev_fields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Stddev_Pop_Fields = {
  __typename?: 'category_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Stddev_Samp_Fields = {
  __typename?: 'category_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Sum_Fields = {
  __typename?: 'category_sum_fields';
  index?: Maybe<Scalars['Int']>;
};

/** update columns of table "category" */
export enum Category_Update_Column {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  ColorFamily = 'colorFamily',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Expanded = 'expanded',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LastClientUpdate = 'lastClientUpdate',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Category_Var_Pop_Fields = {
  __typename?: 'category_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Var_Samp_Fields = {
  __typename?: 'category_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Variance_Fields = {
  __typename?: 'category_variance_fields';
  index?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "colorFamilyEnum" */
export type ColorFamilyEnum = {
  __typename?: 'colorFamilyEnum';
  value: Scalars['String'];
};

/** aggregated selection of "colorFamilyEnum" */
export type ColorFamilyEnum_Aggregate = {
  __typename?: 'colorFamilyEnum_aggregate';
  aggregate?: Maybe<ColorFamilyEnum_Aggregate_Fields>;
  nodes: Array<ColorFamilyEnum>;
};

/** aggregate fields of "colorFamilyEnum" */
export type ColorFamilyEnum_Aggregate_Fields = {
  __typename?: 'colorFamilyEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ColorFamilyEnum_Max_Fields>;
  min?: Maybe<ColorFamilyEnum_Min_Fields>;
};


/** aggregate fields of "colorFamilyEnum" */
export type ColorFamilyEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ColorFamilyEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "colorFamilyEnum". All fields are combined with a logical 'AND'. */
export type ColorFamilyEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ColorFamilyEnum_Bool_Exp>>;
  _not?: InputMaybe<ColorFamilyEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ColorFamilyEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "colorFamilyEnum" */
export enum ColorFamilyEnum_Constraint {
  /** unique or primary key constraint */
  ColorFamilyEnumPkey = 'colorFamilyEnum_pkey'
}

export enum ColorFamilyEnum_Enum {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

/** Boolean expression to compare columns of type "colorFamilyEnum_enum". All fields are combined with logical 'AND'. */
export type ColorFamilyEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ColorFamilyEnum_Enum>;
  _in?: InputMaybe<Array<ColorFamilyEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ColorFamilyEnum_Enum>;
  _nin?: InputMaybe<Array<ColorFamilyEnum_Enum>>;
};

/** input type for inserting data into table "colorFamilyEnum" */
export type ColorFamilyEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ColorFamilyEnum_Max_Fields = {
  __typename?: 'colorFamilyEnum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ColorFamilyEnum_Min_Fields = {
  __typename?: 'colorFamilyEnum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "colorFamilyEnum" */
export type ColorFamilyEnum_Mutation_Response = {
  __typename?: 'colorFamilyEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ColorFamilyEnum>;
};

/** on conflict condition type for table "colorFamilyEnum" */
export type ColorFamilyEnum_On_Conflict = {
  constraint: ColorFamilyEnum_Constraint;
  update_columns?: Array<ColorFamilyEnum_Update_Column>;
  where?: InputMaybe<ColorFamilyEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "colorFamilyEnum". */
export type ColorFamilyEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: colorFamilyEnum */
export type ColorFamilyEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "colorFamilyEnum" */
export enum ColorFamilyEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "colorFamilyEnum" */
export type ColorFamilyEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "colorFamilyEnum" */
export enum ColorFamilyEnum_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "contact_sync" */
export type Contact_Sync = {
  __typename?: 'contact_sync';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sync_started_at?: Maybe<Scalars['timestamptz']>;
  sync_token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

/** aggregated selection of "contact_sync" */
export type Contact_Sync_Aggregate = {
  __typename?: 'contact_sync_aggregate';
  aggregate?: Maybe<Contact_Sync_Aggregate_Fields>;
  nodes: Array<Contact_Sync>;
};

/** aggregate fields of "contact_sync" */
export type Contact_Sync_Aggregate_Fields = {
  __typename?: 'contact_sync_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contact_Sync_Max_Fields>;
  min?: Maybe<Contact_Sync_Min_Fields>;
};


/** aggregate fields of "contact_sync" */
export type Contact_Sync_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Sync_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contact_sync". All fields are combined with a logical 'AND'. */
export type Contact_Sync_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Sync_Bool_Exp>>;
  _not?: InputMaybe<Contact_Sync_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Sync_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sync_started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sync_token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_sync" */
export enum Contact_Sync_Constraint {
  /** unique or primary key constraint */
  ContactSyncPkey = 'contact_sync_pkey',
  /** unique or primary key constraint */
  ContactSyncUserIdKey = 'contact_sync_user_id_key'
}

/** input type for inserting data into table "contact_sync" */
export type Contact_Sync_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sync_started_at?: InputMaybe<Scalars['timestamptz']>;
  sync_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contact_Sync_Max_Fields = {
  __typename?: 'contact_sync_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sync_started_at?: Maybe<Scalars['timestamptz']>;
  sync_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contact_Sync_Min_Fields = {
  __typename?: 'contact_sync_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sync_started_at?: Maybe<Scalars['timestamptz']>;
  sync_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contact_sync" */
export type Contact_Sync_Mutation_Response = {
  __typename?: 'contact_sync_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Sync>;
};

/** on conflict condition type for table "contact_sync" */
export type Contact_Sync_On_Conflict = {
  constraint: Contact_Sync_Constraint;
  update_columns?: Array<Contact_Sync_Update_Column>;
  where?: InputMaybe<Contact_Sync_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_sync". */
export type Contact_Sync_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sync_started_at?: InputMaybe<Order_By>;
  sync_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_sync */
export type Contact_Sync_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contact_sync" */
export enum Contact_Sync_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SyncStartedAt = 'sync_started_at',
  /** column name */
  SyncToken = 'sync_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "contact_sync" */
export type Contact_Sync_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sync_started_at?: InputMaybe<Scalars['timestamptz']>;
  sync_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "contact_sync" */
export enum Contact_Sync_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SyncStartedAt = 'sync_started_at',
  /** column name */
  SyncToken = 'sync_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "databasechangelog" */
export type Databasechangelog = {
  __typename?: 'databasechangelog';
  author: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted: Scalars['timestamp'];
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['String'];
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted: Scalars['Int'];
  tag?: Maybe<Scalars['String']>;
};

/** aggregated selection of "databasechangelog" */
export type Databasechangelog_Aggregate = {
  __typename?: 'databasechangelog_aggregate';
  aggregate?: Maybe<Databasechangelog_Aggregate_Fields>;
  nodes: Array<Databasechangelog>;
};

/** aggregate fields of "databasechangelog" */
export type Databasechangelog_Aggregate_Fields = {
  __typename?: 'databasechangelog_aggregate_fields';
  avg?: Maybe<Databasechangelog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Databasechangelog_Max_Fields>;
  min?: Maybe<Databasechangelog_Min_Fields>;
  stddev?: Maybe<Databasechangelog_Stddev_Fields>;
  stddev_pop?: Maybe<Databasechangelog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Databasechangelog_Stddev_Samp_Fields>;
  sum?: Maybe<Databasechangelog_Sum_Fields>;
  var_pop?: Maybe<Databasechangelog_Var_Pop_Fields>;
  var_samp?: Maybe<Databasechangelog_Var_Samp_Fields>;
  variance?: Maybe<Databasechangelog_Variance_Fields>;
};


/** aggregate fields of "databasechangelog" */
export type Databasechangelog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Databasechangelog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Databasechangelog_Avg_Fields = {
  __typename?: 'databasechangelog_avg_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "databasechangelog". All fields are combined with a logical 'AND'. */
export type Databasechangelog_Bool_Exp = {
  _and?: InputMaybe<Array<Databasechangelog_Bool_Exp>>;
  _not?: InputMaybe<Databasechangelog_Bool_Exp>;
  _or?: InputMaybe<Array<Databasechangelog_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  contexts?: InputMaybe<String_Comparison_Exp>;
  dateexecuted?: InputMaybe<Timestamp_Comparison_Exp>;
  deployment_id?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  exectype?: InputMaybe<String_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  labels?: InputMaybe<String_Comparison_Exp>;
  liquibase?: InputMaybe<String_Comparison_Exp>;
  md5sum?: InputMaybe<String_Comparison_Exp>;
  orderexecuted?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "databasechangelog" */
export type Databasechangelog_Inc_Input = {
  orderexecuted?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "databasechangelog" */
export type Databasechangelog_Insert_Input = {
  author?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  contexts?: InputMaybe<Scalars['String']>;
  dateexecuted?: InputMaybe<Scalars['timestamp']>;
  deployment_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exectype?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['String']>;
  liquibase?: InputMaybe<Scalars['String']>;
  md5sum?: InputMaybe<Scalars['String']>;
  orderexecuted?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Databasechangelog_Max_Fields = {
  __typename?: 'databasechangelog_max_fields';
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Databasechangelog_Min_Fields = {
  __typename?: 'databasechangelog_min_fields';
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "databasechangelog" */
export type Databasechangelog_Mutation_Response = {
  __typename?: 'databasechangelog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Databasechangelog>;
};

/** Ordering options when selecting data from "databasechangelog". */
export type Databasechangelog_Order_By = {
  author?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  contexts?: InputMaybe<Order_By>;
  dateexecuted?: InputMaybe<Order_By>;
  deployment_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  exectype?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  liquibase?: InputMaybe<Order_By>;
  md5sum?: InputMaybe<Order_By>;
  orderexecuted?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** select columns of table "databasechangelog" */
export enum Databasechangelog_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Comments = 'comments',
  /** column name */
  Contexts = 'contexts',
  /** column name */
  Dateexecuted = 'dateexecuted',
  /** column name */
  DeploymentId = 'deployment_id',
  /** column name */
  Description = 'description',
  /** column name */
  Exectype = 'exectype',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  Liquibase = 'liquibase',
  /** column name */
  Md5sum = 'md5sum',
  /** column name */
  Orderexecuted = 'orderexecuted',
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "databasechangelog" */
export type Databasechangelog_Set_Input = {
  author?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  contexts?: InputMaybe<Scalars['String']>;
  dateexecuted?: InputMaybe<Scalars['timestamp']>;
  deployment_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exectype?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['String']>;
  liquibase?: InputMaybe<Scalars['String']>;
  md5sum?: InputMaybe<Scalars['String']>;
  orderexecuted?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Databasechangelog_Stddev_Fields = {
  __typename?: 'databasechangelog_stddev_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Databasechangelog_Stddev_Pop_Fields = {
  __typename?: 'databasechangelog_stddev_pop_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Databasechangelog_Stddev_Samp_Fields = {
  __typename?: 'databasechangelog_stddev_samp_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Databasechangelog_Sum_Fields = {
  __typename?: 'databasechangelog_sum_fields';
  orderexecuted?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Databasechangelog_Var_Pop_Fields = {
  __typename?: 'databasechangelog_var_pop_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Databasechangelog_Var_Samp_Fields = {
  __typename?: 'databasechangelog_var_samp_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Databasechangelog_Variance_Fields = {
  __typename?: 'databasechangelog_variance_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "databasechangeloglock" */
export type Databasechangeloglock = {
  __typename?: 'databasechangeloglock';
  id: Scalars['Int'];
  locked: Scalars['Boolean'];
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate = {
  __typename?: 'databasechangeloglock_aggregate';
  aggregate?: Maybe<Databasechangeloglock_Aggregate_Fields>;
  nodes: Array<Databasechangeloglock>;
};

/** aggregate fields of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate_Fields = {
  __typename?: 'databasechangeloglock_aggregate_fields';
  avg?: Maybe<Databasechangeloglock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Databasechangeloglock_Max_Fields>;
  min?: Maybe<Databasechangeloglock_Min_Fields>;
  stddev?: Maybe<Databasechangeloglock_Stddev_Fields>;
  stddev_pop?: Maybe<Databasechangeloglock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Databasechangeloglock_Stddev_Samp_Fields>;
  sum?: Maybe<Databasechangeloglock_Sum_Fields>;
  var_pop?: Maybe<Databasechangeloglock_Var_Pop_Fields>;
  var_samp?: Maybe<Databasechangeloglock_Var_Samp_Fields>;
  variance?: Maybe<Databasechangeloglock_Variance_Fields>;
};


/** aggregate fields of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Databasechangeloglock_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Databasechangeloglock_Avg_Fields = {
  __typename?: 'databasechangeloglock_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "databasechangeloglock". All fields are combined with a logical 'AND'. */
export type Databasechangeloglock_Bool_Exp = {
  _and?: InputMaybe<Array<Databasechangeloglock_Bool_Exp>>;
  _not?: InputMaybe<Databasechangeloglock_Bool_Exp>;
  _or?: InputMaybe<Array<Databasechangeloglock_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  locked?: InputMaybe<Boolean_Comparison_Exp>;
  lockedby?: InputMaybe<String_Comparison_Exp>;
  lockgranted?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "databasechangeloglock" */
export enum Databasechangeloglock_Constraint {
  /** unique or primary key constraint */
  DatabasechangeloglockPkey = 'databasechangeloglock_pkey'
}

/** input type for incrementing numeric columns in table "databasechangeloglock" */
export type Databasechangeloglock_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "databasechangeloglock" */
export type Databasechangeloglock_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  lockedby?: InputMaybe<Scalars['String']>;
  lockgranted?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Databasechangeloglock_Max_Fields = {
  __typename?: 'databasechangeloglock_max_fields';
  id?: Maybe<Scalars['Int']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Databasechangeloglock_Min_Fields = {
  __typename?: 'databasechangeloglock_min_fields';
  id?: Maybe<Scalars['Int']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "databasechangeloglock" */
export type Databasechangeloglock_Mutation_Response = {
  __typename?: 'databasechangeloglock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Databasechangeloglock>;
};

/** on conflict condition type for table "databasechangeloglock" */
export type Databasechangeloglock_On_Conflict = {
  constraint: Databasechangeloglock_Constraint;
  update_columns?: Array<Databasechangeloglock_Update_Column>;
  where?: InputMaybe<Databasechangeloglock_Bool_Exp>;
};

/** Ordering options when selecting data from "databasechangeloglock". */
export type Databasechangeloglock_Order_By = {
  id?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  lockedby?: InputMaybe<Order_By>;
  lockgranted?: InputMaybe<Order_By>;
};

/** primary key columns input for table: databasechangeloglock */
export type Databasechangeloglock_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "databasechangeloglock" */
export enum Databasechangeloglock_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Lockedby = 'lockedby',
  /** column name */
  Lockgranted = 'lockgranted'
}

/** input type for updating data in table "databasechangeloglock" */
export type Databasechangeloglock_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  lockedby?: InputMaybe<Scalars['String']>;
  lockgranted?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Databasechangeloglock_Stddev_Fields = {
  __typename?: 'databasechangeloglock_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Databasechangeloglock_Stddev_Pop_Fields = {
  __typename?: 'databasechangeloglock_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Databasechangeloglock_Stddev_Samp_Fields = {
  __typename?: 'databasechangeloglock_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Databasechangeloglock_Sum_Fields = {
  __typename?: 'databasechangeloglock_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "databasechangeloglock" */
export enum Databasechangeloglock_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Lockedby = 'lockedby',
  /** column name */
  Lockgranted = 'lockgranted'
}

/** aggregate var_pop on columns */
export type Databasechangeloglock_Var_Pop_Fields = {
  __typename?: 'databasechangeloglock_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Databasechangeloglock_Var_Samp_Fields = {
  __typename?: 'databasechangeloglock_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Databasechangeloglock_Variance_Fields = {
  __typename?: 'databasechangeloglock_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dateFormat" */
export type DateFormat = {
  __typename?: 'dateFormat';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "dateFormat" */
export type DateFormat_Aggregate = {
  __typename?: 'dateFormat_aggregate';
  aggregate?: Maybe<DateFormat_Aggregate_Fields>;
  nodes: Array<DateFormat>;
};

/** aggregate fields of "dateFormat" */
export type DateFormat_Aggregate_Fields = {
  __typename?: 'dateFormat_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DateFormat_Max_Fields>;
  min?: Maybe<DateFormat_Min_Fields>;
};


/** aggregate fields of "dateFormat" */
export type DateFormat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DateFormat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dateFormat". All fields are combined with a logical 'AND'. */
export type DateFormat_Bool_Exp = {
  _and?: InputMaybe<Array<DateFormat_Bool_Exp>>;
  _not?: InputMaybe<DateFormat_Bool_Exp>;
  _or?: InputMaybe<Array<DateFormat_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "dateFormat" */
export enum DateFormat_Constraint {
  /** unique or primary key constraint */
  DateFormatPkey = 'dateFormat_pkey'
}

export enum DateFormat_Enum {
  /** dd/mm */
  DayMonth = 'DAY_MONTH',
  /** mm/dd */
  MonthDay = 'MONTH_DAY'
}

/** Boolean expression to compare columns of type "dateFormat_enum". All fields are combined with logical 'AND'. */
export type DateFormat_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DateFormat_Enum>;
  _in?: InputMaybe<Array<DateFormat_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DateFormat_Enum>;
  _nin?: InputMaybe<Array<DateFormat_Enum>>;
};

/** input type for inserting data into table "dateFormat" */
export type DateFormat_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DateFormat_Max_Fields = {
  __typename?: 'dateFormat_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DateFormat_Min_Fields = {
  __typename?: 'dateFormat_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dateFormat" */
export type DateFormat_Mutation_Response = {
  __typename?: 'dateFormat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DateFormat>;
};

/** on conflict condition type for table "dateFormat" */
export type DateFormat_On_Conflict = {
  constraint: DateFormat_Constraint;
  update_columns?: Array<DateFormat_Update_Column>;
  where?: InputMaybe<DateFormat_Bool_Exp>;
};

/** Ordering options when selecting data from "dateFormat". */
export type DateFormat_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dateFormat */
export type DateFormat_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "dateFormat" */
export enum DateFormat_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "dateFormat" */
export type DateFormat_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "dateFormat" */
export enum DateFormat_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "google_credential" */
export type Google_Credential = {
  __typename?: 'google_credential';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  refresh_token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: New_User;
  user_id: Scalars['String'];
};

/** aggregated selection of "google_credential" */
export type Google_Credential_Aggregate = {
  __typename?: 'google_credential_aggregate';
  aggregate?: Maybe<Google_Credential_Aggregate_Fields>;
  nodes: Array<Google_Credential>;
};

/** aggregate fields of "google_credential" */
export type Google_Credential_Aggregate_Fields = {
  __typename?: 'google_credential_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Google_Credential_Max_Fields>;
  min?: Maybe<Google_Credential_Min_Fields>;
};


/** aggregate fields of "google_credential" */
export type Google_Credential_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Google_Credential_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "google_credential". All fields are combined with a logical 'AND'. */
export type Google_Credential_Bool_Exp = {
  _and?: InputMaybe<Array<Google_Credential_Bool_Exp>>;
  _not?: InputMaybe<Google_Credential_Bool_Exp>;
  _or?: InputMaybe<Array<Google_Credential_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "google_credential" */
export enum Google_Credential_Constraint {
  /** unique or primary key constraint */
  GoogleCredentialPkey = 'google_credential_pkey',
  /** unique or primary key constraint */
  GoogleCredentialUserIdKey = 'google_credential_user_id_key'
}

/** input type for inserting data into table "google_credential" */
export type Google_Credential_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Google_Credential_Max_Fields = {
  __typename?: 'google_credential_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Google_Credential_Min_Fields = {
  __typename?: 'google_credential_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "google_credential" */
export type Google_Credential_Mutation_Response = {
  __typename?: 'google_credential_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Google_Credential>;
};

/** input type for inserting object relation for remote table "google_credential" */
export type Google_Credential_Obj_Rel_Insert_Input = {
  data: Google_Credential_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Google_Credential_On_Conflict>;
};

/** on conflict condition type for table "google_credential" */
export type Google_Credential_On_Conflict = {
  constraint: Google_Credential_Constraint;
  update_columns?: Array<Google_Credential_Update_Column>;
  where?: InputMaybe<Google_Credential_Bool_Exp>;
};

/** Ordering options when selecting data from "google_credential". */
export type Google_Credential_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: google_credential */
export type Google_Credential_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "google_credential" */
export enum Google_Credential_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "google_credential" */
export type Google_Credential_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "google_credential" */
export enum Google_Credential_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "linear_issue" */
export type Linear_Issue = {
  __typename?: 'linear_issue';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  linear_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "linear_issue" */
export type Linear_Issue_Aggregate = {
  __typename?: 'linear_issue_aggregate';
  aggregate?: Maybe<Linear_Issue_Aggregate_Fields>;
  nodes: Array<Linear_Issue>;
};

/** aggregate fields of "linear_issue" */
export type Linear_Issue_Aggregate_Fields = {
  __typename?: 'linear_issue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Linear_Issue_Max_Fields>;
  min?: Maybe<Linear_Issue_Min_Fields>;
};


/** aggregate fields of "linear_issue" */
export type Linear_Issue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Linear_Issue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "linear_issue". All fields are combined with a logical 'AND'. */
export type Linear_Issue_Bool_Exp = {
  _and?: InputMaybe<Array<Linear_Issue_Bool_Exp>>;
  _not?: InputMaybe<Linear_Issue_Bool_Exp>;
  _or?: InputMaybe<Array<Linear_Issue_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  linear_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "linear_issue" */
export enum Linear_Issue_Constraint {
  /** unique or primary key constraint */
  LinearIssuePkey = 'linear_issue_pkey',
  /** unique or primary key constraint */
  LinearIssueUniqueLinearIdUserId = 'linear_issue_unique_linear_id_user_id'
}

/** input type for inserting data into table "linear_issue" */
export type Linear_Issue_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  linear_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Linear_Issue_Max_Fields = {
  __typename?: 'linear_issue_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linear_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Linear_Issue_Min_Fields = {
  __typename?: 'linear_issue_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linear_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "linear_issue" */
export type Linear_Issue_Mutation_Response = {
  __typename?: 'linear_issue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Linear_Issue>;
};

/** on conflict condition type for table "linear_issue" */
export type Linear_Issue_On_Conflict = {
  constraint: Linear_Issue_Constraint;
  update_columns?: Array<Linear_Issue_Update_Column>;
  where?: InputMaybe<Linear_Issue_Bool_Exp>;
};

/** Ordering options when selecting data from "linear_issue". */
export type Linear_Issue_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  linear_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: linear_issue */
export type Linear_Issue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "linear_issue" */
export enum Linear_Issue_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LinearId = 'linear_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "linear_issue" */
export type Linear_Issue_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  linear_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "linear_issue" */
export enum Linear_Issue_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LinearId = 'linear_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  bookCalendarSlot: BookCalendarSlotOutput;
  clearEventsCache: ClearEventsCacheOutput;
  createCalendarLink: CreateCalendarLinkOutput;
  createContacts: CreateContactsOutput;
  createEvent: CreateEventOutput;
  deleteEvent: DeleteEventOutput;
  /** delete data from the table: "activityFeed" */
  delete_activityFeed?: Maybe<ActivityFeed_Mutation_Response>;
  /** delete data from the table: "activityFeedTypeEnum" */
  delete_activityFeedTypeEnum?: Maybe<ActivityFeedTypeEnum_Mutation_Response>;
  /** delete single row from the table: "activityFeedTypeEnum" */
  delete_activityFeedTypeEnum_by_pk?: Maybe<ActivityFeedTypeEnum>;
  /** delete single row from the table: "activityFeed" */
  delete_activityFeed_by_pk?: Maybe<ActivityFeed>;
  /** delete data from the table: "analytics_event" */
  delete_analytics_event?: Maybe<Analytics_Event_Mutation_Response>;
  /** delete single row from the table: "analytics_event" */
  delete_analytics_event_by_pk?: Maybe<Analytics_Event>;
  /** delete data from the table: "calendarHistory" */
  delete_calendarHistory?: Maybe<CalendarHistory_Mutation_Response>;
  /** delete single row from the table: "calendarHistory" */
  delete_calendarHistory_by_pk?: Maybe<CalendarHistory>;
  /** delete data from the table: "calendarPreference" */
  delete_calendarPreference?: Maybe<CalendarPreference_Mutation_Response>;
  /** delete single row from the table: "calendarPreference" */
  delete_calendarPreference_by_pk?: Maybe<CalendarPreference>;
  /** delete data from the table: "calendarStartsOn" */
  delete_calendarStartsOn?: Maybe<CalendarStartsOn_Mutation_Response>;
  /** delete single row from the table: "calendarStartsOn" */
  delete_calendarStartsOn_by_pk?: Maybe<CalendarStartsOn>;
  /** delete data from the table: "category" */
  delete_category?: Maybe<Category_Mutation_Response>;
  /** delete single row from the table: "category" */
  delete_category_by_pk?: Maybe<Category>;
  /** delete data from the table: "colorFamilyEnum" */
  delete_colorFamilyEnum?: Maybe<ColorFamilyEnum_Mutation_Response>;
  /** delete single row from the table: "colorFamilyEnum" */
  delete_colorFamilyEnum_by_pk?: Maybe<ColorFamilyEnum>;
  /** delete data from the table: "contact_sync" */
  delete_contact_sync?: Maybe<Contact_Sync_Mutation_Response>;
  /** delete single row from the table: "contact_sync" */
  delete_contact_sync_by_pk?: Maybe<Contact_Sync>;
  /** delete data from the table: "databasechangelog" */
  delete_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** delete data from the table: "databasechangeloglock" */
  delete_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** delete single row from the table: "databasechangeloglock" */
  delete_databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** delete data from the table: "dateFormat" */
  delete_dateFormat?: Maybe<DateFormat_Mutation_Response>;
  /** delete single row from the table: "dateFormat" */
  delete_dateFormat_by_pk?: Maybe<DateFormat>;
  /** delete data from the table: "google_credential" */
  delete_google_credential?: Maybe<Google_Credential_Mutation_Response>;
  /** delete single row from the table: "google_credential" */
  delete_google_credential_by_pk?: Maybe<Google_Credential>;
  /** delete data from the table: "linear_issue" */
  delete_linear_issue?: Maybe<Linear_Issue_Mutation_Response>;
  /** delete single row from the table: "linear_issue" */
  delete_linear_issue_by_pk?: Maybe<Linear_Issue>;
  /** delete data from the table: "contact" */
  delete_new_contact?: Maybe<New_Contact_Mutation_Response>;
  /** delete single row from the table: "contact" */
  delete_new_contact_by_pk?: Maybe<New_Contact>;
  /** delete data from the table: "feedback" */
  delete_new_feedback?: Maybe<New_Feedback_Mutation_Response>;
  /** delete single row from the table: "feedback" */
  delete_new_feedback_by_pk?: Maybe<New_Feedback>;
  /** delete data from the table: "user" */
  delete_new_user?: Maybe<New_User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_new_user_by_pk?: Maybe<New_User>;
  /** delete data from the table: "profile" */
  delete_profile?: Maybe<Profile_Mutation_Response>;
  /** delete single row from the table: "profile" */
  delete_profile_by_pk?: Maybe<Profile>;
  /** delete data from the table: "shareCalendarLink" */
  delete_shareCalendarLink?: Maybe<ShareCalendarLink_Mutation_Response>;
  /** delete data from the table: "shareCalendarLinkTypeEnum" */
  delete_shareCalendarLinkTypeEnum?: Maybe<ShareCalendarLinkTypeEnum_Mutation_Response>;
  /** delete single row from the table: "shareCalendarLinkTypeEnum" */
  delete_shareCalendarLinkTypeEnum_by_pk?: Maybe<ShareCalendarLinkTypeEnum>;
  /** delete single row from the table: "shareCalendarLink" */
  delete_shareCalendarLink_by_pk?: Maybe<ShareCalendarLink>;
  /** delete data from the table: "theme" */
  delete_theme?: Maybe<Theme_Mutation_Response>;
  /** delete single row from the table: "theme" */
  delete_theme_by_pk?: Maybe<Theme>;
  /** delete data from the table: "todo" */
  delete_todo?: Maybe<Todo_Mutation_Response>;
  /** delete data from the table: "todoTypeEnum" */
  delete_todoTypeEnum?: Maybe<TodoTypeEnum_Mutation_Response>;
  /** delete single row from the table: "todoTypeEnum" */
  delete_todoTypeEnum_by_pk?: Maybe<TodoTypeEnum>;
  /** delete single row from the table: "todo" */
  delete_todo_by_pk?: Maybe<Todo>;
  /** delete data from the table: "userActivityFeed" */
  delete_userActivityFeed?: Maybe<UserActivityFeed_Mutation_Response>;
  /** delete single row from the table: "userActivityFeed" */
  delete_userActivityFeed_by_pk?: Maybe<UserActivityFeed>;
  /** delete data from the table: "userPreferences" */
  delete_userPreferences?: Maybe<UserPreferences_Mutation_Response>;
  /** delete single row from the table: "userPreferences" */
  delete_userPreferences_by_pk?: Maybe<UserPreferences>;
  /** delete data from the table: "userStatus" */
  delete_userStatus?: Maybe<UserStatus_Mutation_Response>;
  /** delete single row from the table: "userStatus" */
  delete_userStatus_by_pk?: Maybe<UserStatus>;
  /** delete data from the table: "user_allowlist" */
  delete_user_allowlist?: Maybe<User_Allowlist_Mutation_Response>;
  /** delete single row from the table: "user_allowlist" */
  delete_user_allowlist_by_pk?: Maybe<User_Allowlist>;
  /** delete data from the table: "videoProvider" */
  delete_videoProvider?: Maybe<VideoProvider_Mutation_Response>;
  /** delete single row from the table: "videoProvider" */
  delete_videoProvider_by_pk?: Maybe<VideoProvider>;
  hideCalendar: HideCalendarOutput;
  /** insert data into the table: "activityFeed" */
  insert_activityFeed?: Maybe<ActivityFeed_Mutation_Response>;
  /** insert data into the table: "activityFeedTypeEnum" */
  insert_activityFeedTypeEnum?: Maybe<ActivityFeedTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "activityFeedTypeEnum" */
  insert_activityFeedTypeEnum_one?: Maybe<ActivityFeedTypeEnum>;
  /** insert a single row into the table: "activityFeed" */
  insert_activityFeed_one?: Maybe<ActivityFeed>;
  /** insert data into the table: "analytics_event" */
  insert_analytics_event?: Maybe<Analytics_Event_Mutation_Response>;
  /** insert a single row into the table: "analytics_event" */
  insert_analytics_event_one?: Maybe<Analytics_Event>;
  /** insert data into the table: "calendarHistory" */
  insert_calendarHistory?: Maybe<CalendarHistory_Mutation_Response>;
  /** insert a single row into the table: "calendarHistory" */
  insert_calendarHistory_one?: Maybe<CalendarHistory>;
  /** insert data into the table: "calendarPreference" */
  insert_calendarPreference?: Maybe<CalendarPreference_Mutation_Response>;
  /** insert a single row into the table: "calendarPreference" */
  insert_calendarPreference_one?: Maybe<CalendarPreference>;
  /** insert data into the table: "calendarStartsOn" */
  insert_calendarStartsOn?: Maybe<CalendarStartsOn_Mutation_Response>;
  /** insert a single row into the table: "calendarStartsOn" */
  insert_calendarStartsOn_one?: Maybe<CalendarStartsOn>;
  /** insert data into the table: "category" */
  insert_category?: Maybe<Category_Mutation_Response>;
  /** insert a single row into the table: "category" */
  insert_category_one?: Maybe<Category>;
  /** insert data into the table: "colorFamilyEnum" */
  insert_colorFamilyEnum?: Maybe<ColorFamilyEnum_Mutation_Response>;
  /** insert a single row into the table: "colorFamilyEnum" */
  insert_colorFamilyEnum_one?: Maybe<ColorFamilyEnum>;
  /** insert data into the table: "contact_sync" */
  insert_contact_sync?: Maybe<Contact_Sync_Mutation_Response>;
  /** insert a single row into the table: "contact_sync" */
  insert_contact_sync_one?: Maybe<Contact_Sync>;
  /** insert data into the table: "databasechangelog" */
  insert_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** insert a single row into the table: "databasechangelog" */
  insert_databasechangelog_one?: Maybe<Databasechangelog>;
  /** insert data into the table: "databasechangeloglock" */
  insert_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** insert a single row into the table: "databasechangeloglock" */
  insert_databasechangeloglock_one?: Maybe<Databasechangeloglock>;
  /** insert data into the table: "dateFormat" */
  insert_dateFormat?: Maybe<DateFormat_Mutation_Response>;
  /** insert a single row into the table: "dateFormat" */
  insert_dateFormat_one?: Maybe<DateFormat>;
  /** insert data into the table: "google_credential" */
  insert_google_credential?: Maybe<Google_Credential_Mutation_Response>;
  /** insert a single row into the table: "google_credential" */
  insert_google_credential_one?: Maybe<Google_Credential>;
  /** insert data into the table: "linear_issue" */
  insert_linear_issue?: Maybe<Linear_Issue_Mutation_Response>;
  /** insert a single row into the table: "linear_issue" */
  insert_linear_issue_one?: Maybe<Linear_Issue>;
  /** insert data into the table: "contact" */
  insert_new_contact?: Maybe<New_Contact_Mutation_Response>;
  /** insert a single row into the table: "contact" */
  insert_new_contact_one?: Maybe<New_Contact>;
  /** insert data into the table: "feedback" */
  insert_new_feedback?: Maybe<New_Feedback_Mutation_Response>;
  /** insert a single row into the table: "feedback" */
  insert_new_feedback_one?: Maybe<New_Feedback>;
  /** insert data into the table: "user" */
  insert_new_user?: Maybe<New_User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_new_user_one?: Maybe<New_User>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "shareCalendarLink" */
  insert_shareCalendarLink?: Maybe<ShareCalendarLink_Mutation_Response>;
  /** insert data into the table: "shareCalendarLinkTypeEnum" */
  insert_shareCalendarLinkTypeEnum?: Maybe<ShareCalendarLinkTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "shareCalendarLinkTypeEnum" */
  insert_shareCalendarLinkTypeEnum_one?: Maybe<ShareCalendarLinkTypeEnum>;
  /** insert a single row into the table: "shareCalendarLink" */
  insert_shareCalendarLink_one?: Maybe<ShareCalendarLink>;
  /** insert data into the table: "theme" */
  insert_theme?: Maybe<Theme_Mutation_Response>;
  /** insert a single row into the table: "theme" */
  insert_theme_one?: Maybe<Theme>;
  /** insert data into the table: "todo" */
  insert_todo?: Maybe<Todo_Mutation_Response>;
  /** insert data into the table: "todoTypeEnum" */
  insert_todoTypeEnum?: Maybe<TodoTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "todoTypeEnum" */
  insert_todoTypeEnum_one?: Maybe<TodoTypeEnum>;
  /** insert a single row into the table: "todo" */
  insert_todo_one?: Maybe<Todo>;
  /** insert data into the table: "userActivityFeed" */
  insert_userActivityFeed?: Maybe<UserActivityFeed_Mutation_Response>;
  /** insert a single row into the table: "userActivityFeed" */
  insert_userActivityFeed_one?: Maybe<UserActivityFeed>;
  /** insert data into the table: "userPreferences" */
  insert_userPreferences?: Maybe<UserPreferences_Mutation_Response>;
  /** insert a single row into the table: "userPreferences" */
  insert_userPreferences_one?: Maybe<UserPreferences>;
  /** insert data into the table: "userStatus" */
  insert_userStatus?: Maybe<UserStatus_Mutation_Response>;
  /** insert a single row into the table: "userStatus" */
  insert_userStatus_one?: Maybe<UserStatus>;
  /** insert data into the table: "user_allowlist" */
  insert_user_allowlist?: Maybe<User_Allowlist_Mutation_Response>;
  /** insert a single row into the table: "user_allowlist" */
  insert_user_allowlist_one?: Maybe<User_Allowlist>;
  /** insert data into the table: "videoProvider" */
  insert_videoProvider?: Maybe<VideoProvider_Mutation_Response>;
  /** insert a single row into the table: "videoProvider" */
  insert_videoProvider_one?: Maybe<VideoProvider>;
  refreshActivityFeed: RefreshActivityFeedOutput;
  refreshUserStatus: RefreshUserStatusOutput;
  sendFeedback: SendFeedbackOutput;
  setBirthdayReminder: SetBirthdayReminderOutput;
  setEventRecurrence: SetEventRecurrenceOutput;
  setReminder: SetReminderOutput;
  showCalendar: ShowCalendarOutput;
  signInUsingFirebaseAuthentication?: Maybe<SignInUsingFirebaseAuthenticationOutput>;
  signInUsingGoogleAuthorizationCode?: Maybe<SignInUsingGoogleAuthorizationCodeOutput>;
  spotifyPauseSong?: Maybe<SpotifyPauseTrack>;
  spotifyPlaySong?: Maybe<SpotifyPlayTrack>;
  syncContacts: SyncContactsOutput;
  toggleEventDone: ToggleEventDoneOutput;
  updateCalendar: MinimalCalendarInfo;
  updateCalendarLink: UpdateCalendarLinkOutput;
  updateEvent: UpdateEventOutput;
  updateEventRSVP: UpdateEventRsvpOutput;
  updateTodos: UpdateTodosOutput;
  /** update data of the table: "activityFeed" */
  update_activityFeed?: Maybe<ActivityFeed_Mutation_Response>;
  /** update data of the table: "activityFeedTypeEnum" */
  update_activityFeedTypeEnum?: Maybe<ActivityFeedTypeEnum_Mutation_Response>;
  /** update single row of the table: "activityFeedTypeEnum" */
  update_activityFeedTypeEnum_by_pk?: Maybe<ActivityFeedTypeEnum>;
  /** update single row of the table: "activityFeed" */
  update_activityFeed_by_pk?: Maybe<ActivityFeed>;
  /** update data of the table: "analytics_event" */
  update_analytics_event?: Maybe<Analytics_Event_Mutation_Response>;
  /** update single row of the table: "analytics_event" */
  update_analytics_event_by_pk?: Maybe<Analytics_Event>;
  /** update data of the table: "calendarHistory" */
  update_calendarHistory?: Maybe<CalendarHistory_Mutation_Response>;
  /** update single row of the table: "calendarHistory" */
  update_calendarHistory_by_pk?: Maybe<CalendarHistory>;
  /** update data of the table: "calendarPreference" */
  update_calendarPreference?: Maybe<CalendarPreference_Mutation_Response>;
  /** update single row of the table: "calendarPreference" */
  update_calendarPreference_by_pk?: Maybe<CalendarPreference>;
  /** update data of the table: "calendarStartsOn" */
  update_calendarStartsOn?: Maybe<CalendarStartsOn_Mutation_Response>;
  /** update single row of the table: "calendarStartsOn" */
  update_calendarStartsOn_by_pk?: Maybe<CalendarStartsOn>;
  /** update data of the table: "category" */
  update_category?: Maybe<Category_Mutation_Response>;
  /** update single row of the table: "category" */
  update_category_by_pk?: Maybe<Category>;
  /** update data of the table: "colorFamilyEnum" */
  update_colorFamilyEnum?: Maybe<ColorFamilyEnum_Mutation_Response>;
  /** update single row of the table: "colorFamilyEnum" */
  update_colorFamilyEnum_by_pk?: Maybe<ColorFamilyEnum>;
  /** update data of the table: "contact_sync" */
  update_contact_sync?: Maybe<Contact_Sync_Mutation_Response>;
  /** update single row of the table: "contact_sync" */
  update_contact_sync_by_pk?: Maybe<Contact_Sync>;
  /** update data of the table: "databasechangelog" */
  update_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** update data of the table: "databasechangeloglock" */
  update_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** update single row of the table: "databasechangeloglock" */
  update_databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** update data of the table: "dateFormat" */
  update_dateFormat?: Maybe<DateFormat_Mutation_Response>;
  /** update single row of the table: "dateFormat" */
  update_dateFormat_by_pk?: Maybe<DateFormat>;
  /** update data of the table: "google_credential" */
  update_google_credential?: Maybe<Google_Credential_Mutation_Response>;
  /** update single row of the table: "google_credential" */
  update_google_credential_by_pk?: Maybe<Google_Credential>;
  /** update data of the table: "linear_issue" */
  update_linear_issue?: Maybe<Linear_Issue_Mutation_Response>;
  /** update single row of the table: "linear_issue" */
  update_linear_issue_by_pk?: Maybe<Linear_Issue>;
  /** update data of the table: "contact" */
  update_new_contact?: Maybe<New_Contact_Mutation_Response>;
  /** update single row of the table: "contact" */
  update_new_contact_by_pk?: Maybe<New_Contact>;
  /** update data of the table: "feedback" */
  update_new_feedback?: Maybe<New_Feedback_Mutation_Response>;
  /** update single row of the table: "feedback" */
  update_new_feedback_by_pk?: Maybe<New_Feedback>;
  /** update data of the table: "user" */
  update_new_user?: Maybe<New_User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_new_user_by_pk?: Maybe<New_User>;
  /** update data of the table: "profile" */
  update_profile?: Maybe<Profile_Mutation_Response>;
  /** update single row of the table: "profile" */
  update_profile_by_pk?: Maybe<Profile>;
  /** update data of the table: "shareCalendarLink" */
  update_shareCalendarLink?: Maybe<ShareCalendarLink_Mutation_Response>;
  /** update data of the table: "shareCalendarLinkTypeEnum" */
  update_shareCalendarLinkTypeEnum?: Maybe<ShareCalendarLinkTypeEnum_Mutation_Response>;
  /** update single row of the table: "shareCalendarLinkTypeEnum" */
  update_shareCalendarLinkTypeEnum_by_pk?: Maybe<ShareCalendarLinkTypeEnum>;
  /** update single row of the table: "shareCalendarLink" */
  update_shareCalendarLink_by_pk?: Maybe<ShareCalendarLink>;
  /** update data of the table: "theme" */
  update_theme?: Maybe<Theme_Mutation_Response>;
  /** update single row of the table: "theme" */
  update_theme_by_pk?: Maybe<Theme>;
  /** update data of the table: "todo" */
  update_todo?: Maybe<Todo_Mutation_Response>;
  /** update data of the table: "todoTypeEnum" */
  update_todoTypeEnum?: Maybe<TodoTypeEnum_Mutation_Response>;
  /** update single row of the table: "todoTypeEnum" */
  update_todoTypeEnum_by_pk?: Maybe<TodoTypeEnum>;
  /** update single row of the table: "todo" */
  update_todo_by_pk?: Maybe<Todo>;
  /** update data of the table: "userActivityFeed" */
  update_userActivityFeed?: Maybe<UserActivityFeed_Mutation_Response>;
  /** update single row of the table: "userActivityFeed" */
  update_userActivityFeed_by_pk?: Maybe<UserActivityFeed>;
  /** update data of the table: "userPreferences" */
  update_userPreferences?: Maybe<UserPreferences_Mutation_Response>;
  /** update single row of the table: "userPreferences" */
  update_userPreferences_by_pk?: Maybe<UserPreferences>;
  /** update data of the table: "userStatus" */
  update_userStatus?: Maybe<UserStatus_Mutation_Response>;
  /** update single row of the table: "userStatus" */
  update_userStatus_by_pk?: Maybe<UserStatus>;
  /** update data of the table: "user_allowlist" */
  update_user_allowlist?: Maybe<User_Allowlist_Mutation_Response>;
  /** update single row of the table: "user_allowlist" */
  update_user_allowlist_by_pk?: Maybe<User_Allowlist>;
  /** update data of the table: "videoProvider" */
  update_videoProvider?: Maybe<VideoProvider_Mutation_Response>;
  /** update single row of the table: "videoProvider" */
  update_videoProvider_by_pk?: Maybe<VideoProvider>;
};


/** mutation root */
export type Mutation_RootBookCalendarSlotArgs = {
  objects: BookCalendarSlotInput;
};


/** mutation root */
export type Mutation_RootClearEventsCacheArgs = {
  where: ClearEventsCacheInput;
};


/** mutation root */
export type Mutation_RootCreateCalendarLinkArgs = {
  objects: CreateCalendarLinkInput;
};


/** mutation root */
export type Mutation_RootCreateContactsArgs = {
  objects: CreateContactsInput;
};


/** mutation root */
export type Mutation_RootCreateEventArgs = {
  objects: CreateEventInput;
};


/** mutation root */
export type Mutation_RootDeleteEventArgs = {
  notifyGuests?: InputMaybe<Scalars['Boolean']>;
  where: DeleteEventFilter;
};


/** mutation root */
export type Mutation_RootDelete_ActivityFeedArgs = {
  where: ActivityFeed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivityFeedTypeEnumArgs = {
  where: ActivityFeedTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivityFeedTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ActivityFeed_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_EventArgs = {
  where: Analytics_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CalendarHistoryArgs = {
  where: CalendarHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalendarHistory_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CalendarPreferenceArgs = {
  where: CalendarPreference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalendarPreference_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CalendarStartsOnArgs = {
  where: CalendarStartsOn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalendarStartsOn_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CategoryArgs = {
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ColorFamilyEnumArgs = {
  where: ColorFamilyEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ColorFamilyEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_SyncArgs = {
  where: Contact_Sync_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Sync_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DatabasechangelogArgs = {
  where: Databasechangelog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DatabasechangeloglockArgs = {
  where: Databasechangeloglock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Databasechangeloglock_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DateFormatArgs = {
  where: DateFormat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DateFormat_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Google_CredentialArgs = {
  where: Google_Credential_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Google_Credential_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Linear_IssueArgs = {
  where: Linear_Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Linear_Issue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_New_ContactArgs = {
  where: New_Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_New_FeedbackArgs = {
  where: New_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_New_UserArgs = {
  where: New_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_User_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ShareCalendarLinkArgs = {
  where: ShareCalendarLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ShareCalendarLinkTypeEnumArgs = {
  where: ShareCalendarLinkTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ShareCalendarLinkTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ShareCalendarLink_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ThemeArgs = {
  where: Theme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Theme_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TodoArgs = {
  where: Todo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TodoTypeEnumArgs = {
  where: TodoTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TodoTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Todo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserActivityFeedArgs = {
  where: UserActivityFeed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserActivityFeed_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserPreferencesArgs = {
  where: UserPreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserPreferences_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserStatusArgs = {
  where: UserStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserStatus_By_PkArgs = {
  userEmail: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_AllowlistArgs = {
  where: User_Allowlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Allowlist_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VideoProviderArgs = {
  where: VideoProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VideoProvider_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootHideCalendarArgs = {
  input: HideCalendarInput;
};


/** mutation root */
export type Mutation_RootInsert_ActivityFeedArgs = {
  objects: Array<ActivityFeed_Insert_Input>;
  on_conflict?: InputMaybe<ActivityFeed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityFeedTypeEnumArgs = {
  objects: Array<ActivityFeedTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<ActivityFeedTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityFeedTypeEnum_OneArgs = {
  object: ActivityFeedTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<ActivityFeedTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityFeed_OneArgs = {
  object: ActivityFeed_Insert_Input;
  on_conflict?: InputMaybe<ActivityFeed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_EventArgs = {
  objects: Array<Analytics_Event_Insert_Input>;
  on_conflict?: InputMaybe<Analytics_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Event_OneArgs = {
  object: Analytics_Event_Insert_Input;
  on_conflict?: InputMaybe<Analytics_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarHistoryArgs = {
  objects: Array<CalendarHistory_Insert_Input>;
  on_conflict?: InputMaybe<CalendarHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarHistory_OneArgs = {
  object: CalendarHistory_Insert_Input;
  on_conflict?: InputMaybe<CalendarHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarPreferenceArgs = {
  objects: Array<CalendarPreference_Insert_Input>;
  on_conflict?: InputMaybe<CalendarPreference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarPreference_OneArgs = {
  object: CalendarPreference_Insert_Input;
  on_conflict?: InputMaybe<CalendarPreference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarStartsOnArgs = {
  objects: Array<CalendarStartsOn_Insert_Input>;
  on_conflict?: InputMaybe<CalendarStartsOn_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarStartsOn_OneArgs = {
  object: CalendarStartsOn_Insert_Input;
  on_conflict?: InputMaybe<CalendarStartsOn_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoryArgs = {
  objects: Array<Category_Insert_Input>;
  on_conflict?: InputMaybe<Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_OneArgs = {
  object: Category_Insert_Input;
  on_conflict?: InputMaybe<Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ColorFamilyEnumArgs = {
  objects: Array<ColorFamilyEnum_Insert_Input>;
  on_conflict?: InputMaybe<ColorFamilyEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ColorFamilyEnum_OneArgs = {
  object: ColorFamilyEnum_Insert_Input;
  on_conflict?: InputMaybe<ColorFamilyEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_SyncArgs = {
  objects: Array<Contact_Sync_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Sync_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Sync_OneArgs = {
  object: Contact_Sync_Insert_Input;
  on_conflict?: InputMaybe<Contact_Sync_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DatabasechangelogArgs = {
  objects: Array<Databasechangelog_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Databasechangelog_OneArgs = {
  object: Databasechangelog_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_DatabasechangeloglockArgs = {
  objects: Array<Databasechangeloglock_Insert_Input>;
  on_conflict?: InputMaybe<Databasechangeloglock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Databasechangeloglock_OneArgs = {
  object: Databasechangeloglock_Insert_Input;
  on_conflict?: InputMaybe<Databasechangeloglock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DateFormatArgs = {
  objects: Array<DateFormat_Insert_Input>;
  on_conflict?: InputMaybe<DateFormat_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DateFormat_OneArgs = {
  object: DateFormat_Insert_Input;
  on_conflict?: InputMaybe<DateFormat_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Google_CredentialArgs = {
  objects: Array<Google_Credential_Insert_Input>;
  on_conflict?: InputMaybe<Google_Credential_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Google_Credential_OneArgs = {
  object: Google_Credential_Insert_Input;
  on_conflict?: InputMaybe<Google_Credential_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Linear_IssueArgs = {
  objects: Array<Linear_Issue_Insert_Input>;
  on_conflict?: InputMaybe<Linear_Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Linear_Issue_OneArgs = {
  object: Linear_Issue_Insert_Input;
  on_conflict?: InputMaybe<Linear_Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_ContactArgs = {
  objects: Array<New_Contact_Insert_Input>;
  on_conflict?: InputMaybe<New_Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Contact_OneArgs = {
  object: New_Contact_Insert_Input;
  on_conflict?: InputMaybe<New_Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_FeedbackArgs = {
  objects: Array<New_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<New_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Feedback_OneArgs = {
  object: New_Feedback_Insert_Input;
  on_conflict?: InputMaybe<New_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_UserArgs = {
  objects: Array<New_User_Insert_Input>;
  on_conflict?: InputMaybe<New_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_User_OneArgs = {
  object: New_User_Insert_Input;
  on_conflict?: InputMaybe<New_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShareCalendarLinkArgs = {
  objects: Array<ShareCalendarLink_Insert_Input>;
  on_conflict?: InputMaybe<ShareCalendarLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShareCalendarLinkTypeEnumArgs = {
  objects: Array<ShareCalendarLinkTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<ShareCalendarLinkTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShareCalendarLinkTypeEnum_OneArgs = {
  object: ShareCalendarLinkTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<ShareCalendarLinkTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShareCalendarLink_OneArgs = {
  object: ShareCalendarLink_Insert_Input;
  on_conflict?: InputMaybe<ShareCalendarLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ThemeArgs = {
  objects: Array<Theme_Insert_Input>;
  on_conflict?: InputMaybe<Theme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Theme_OneArgs = {
  object: Theme_Insert_Input;
  on_conflict?: InputMaybe<Theme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TodoArgs = {
  objects: Array<Todo_Insert_Input>;
  on_conflict?: InputMaybe<Todo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TodoTypeEnumArgs = {
  objects: Array<TodoTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<TodoTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TodoTypeEnum_OneArgs = {
  object: TodoTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<TodoTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Todo_OneArgs = {
  object: Todo_Insert_Input;
  on_conflict?: InputMaybe<Todo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserActivityFeedArgs = {
  objects: Array<UserActivityFeed_Insert_Input>;
  on_conflict?: InputMaybe<UserActivityFeed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserActivityFeed_OneArgs = {
  object: UserActivityFeed_Insert_Input;
  on_conflict?: InputMaybe<UserActivityFeed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserPreferencesArgs = {
  objects: Array<UserPreferences_Insert_Input>;
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserPreferences_OneArgs = {
  object: UserPreferences_Insert_Input;
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserStatusArgs = {
  objects: Array<UserStatus_Insert_Input>;
  on_conflict?: InputMaybe<UserStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserStatus_OneArgs = {
  object: UserStatus_Insert_Input;
  on_conflict?: InputMaybe<UserStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AllowlistArgs = {
  objects: Array<User_Allowlist_Insert_Input>;
  on_conflict?: InputMaybe<User_Allowlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Allowlist_OneArgs = {
  object: User_Allowlist_Insert_Input;
  on_conflict?: InputMaybe<User_Allowlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VideoProviderArgs = {
  objects: Array<VideoProvider_Insert_Input>;
  on_conflict?: InputMaybe<VideoProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VideoProvider_OneArgs = {
  object: VideoProvider_Insert_Input;
  on_conflict?: InputMaybe<VideoProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRefreshUserStatusArgs = {
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendFeedbackArgs = {
  input: SendFeedbackInput;
};


/** mutation root */
export type Mutation_RootSetBirthdayReminderArgs = {
  objects: SetBirthdayReminderInput;
};


/** mutation root */
export type Mutation_RootSetEventRecurrenceArgs = {
  objects: SetEventRecurrenceInput;
};


/** mutation root */
export type Mutation_RootSetReminderArgs = {
  objects: SetReminderInput;
};


/** mutation root */
export type Mutation_RootShowCalendarArgs = {
  input: ShowCalendarInput;
};


/** mutation root */
export type Mutation_RootSignInUsingFirebaseAuthenticationArgs = {
  input: SignInUsingFirebaseAuthenticationInput;
};


/** mutation root */
export type Mutation_RootSignInUsingGoogleAuthorizationCodeArgs = {
  input: SignInUsingGoogleAuthorizationCodeInput;
};


/** mutation root */
export type Mutation_RootSpotifyPauseSongArgs = {
  source: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSpotifyPlaySongArgs = {
  source: Scalars['String'];
  uri: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSyncContactsArgs = {
  objects?: InputMaybe<SyncContactsInput>;
};


/** mutation root */
export type Mutation_RootToggleEventDoneArgs = {
  state: Scalars['Boolean'];
  where: ToggleEventDoneFilter;
};


/** mutation root */
export type Mutation_RootUpdateCalendarArgs = {
  input: UpdateCalendarInput;
};


/** mutation root */
export type Mutation_RootUpdateCalendarLinkArgs = {
  objects: UpdateCalendarLinkInput;
};


/** mutation root */
export type Mutation_RootUpdateEventArgs = {
  objects: UpdateEventInput;
};


/** mutation root */
export type Mutation_RootUpdateEventRsvpArgs = {
  objects: UpdateEventRsvpInput;
};


/** mutation root */
export type Mutation_RootUpdateTodosArgs = {
  objects: UpdateTodosInput;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityFeedArgs = {
  _append?: InputMaybe<ActivityFeed_Append_Input>;
  _delete_at_path?: InputMaybe<ActivityFeed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ActivityFeed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ActivityFeed_Delete_Key_Input>;
  _prepend?: InputMaybe<ActivityFeed_Prepend_Input>;
  _set?: InputMaybe<ActivityFeed_Set_Input>;
  where: ActivityFeed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityFeedTypeEnumArgs = {
  _set?: InputMaybe<ActivityFeedTypeEnum_Set_Input>;
  where: ActivityFeedTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityFeedTypeEnum_By_PkArgs = {
  _set?: InputMaybe<ActivityFeedTypeEnum_Set_Input>;
  pk_columns: ActivityFeedTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityFeed_By_PkArgs = {
  _append?: InputMaybe<ActivityFeed_Append_Input>;
  _delete_at_path?: InputMaybe<ActivityFeed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ActivityFeed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ActivityFeed_Delete_Key_Input>;
  _prepend?: InputMaybe<ActivityFeed_Prepend_Input>;
  _set?: InputMaybe<ActivityFeed_Set_Input>;
  pk_columns: ActivityFeed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_EventArgs = {
  _append?: InputMaybe<Analytics_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Analytics_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Analytics_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Analytics_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Analytics_Event_Prepend_Input>;
  _set?: InputMaybe<Analytics_Event_Set_Input>;
  where: Analytics_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Event_By_PkArgs = {
  _append?: InputMaybe<Analytics_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Analytics_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Analytics_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Analytics_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Analytics_Event_Prepend_Input>;
  _set?: InputMaybe<Analytics_Event_Set_Input>;
  pk_columns: Analytics_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarHistoryArgs = {
  _set?: InputMaybe<CalendarHistory_Set_Input>;
  where: CalendarHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarHistory_By_PkArgs = {
  _set?: InputMaybe<CalendarHistory_Set_Input>;
  pk_columns: CalendarHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarPreferenceArgs = {
  _set?: InputMaybe<CalendarPreference_Set_Input>;
  where: CalendarPreference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarPreference_By_PkArgs = {
  _set?: InputMaybe<CalendarPreference_Set_Input>;
  pk_columns: CalendarPreference_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarStartsOnArgs = {
  _set?: InputMaybe<CalendarStartsOn_Set_Input>;
  where: CalendarStartsOn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarStartsOn_By_PkArgs = {
  _set?: InputMaybe<CalendarStartsOn_Set_Input>;
  pk_columns: CalendarStartsOn_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoryArgs = {
  _inc?: InputMaybe<Category_Inc_Input>;
  _set?: InputMaybe<Category_Set_Input>;
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_By_PkArgs = {
  _inc?: InputMaybe<Category_Inc_Input>;
  _set?: InputMaybe<Category_Set_Input>;
  pk_columns: Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ColorFamilyEnumArgs = {
  _set?: InputMaybe<ColorFamilyEnum_Set_Input>;
  where: ColorFamilyEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ColorFamilyEnum_By_PkArgs = {
  _set?: InputMaybe<ColorFamilyEnum_Set_Input>;
  pk_columns: ColorFamilyEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_SyncArgs = {
  _set?: InputMaybe<Contact_Sync_Set_Input>;
  where: Contact_Sync_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Sync_By_PkArgs = {
  _set?: InputMaybe<Contact_Sync_Set_Input>;
  pk_columns: Contact_Sync_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DatabasechangelogArgs = {
  _inc?: InputMaybe<Databasechangelog_Inc_Input>;
  _set?: InputMaybe<Databasechangelog_Set_Input>;
  where: Databasechangelog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DatabasechangeloglockArgs = {
  _inc?: InputMaybe<Databasechangeloglock_Inc_Input>;
  _set?: InputMaybe<Databasechangeloglock_Set_Input>;
  where: Databasechangeloglock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Databasechangeloglock_By_PkArgs = {
  _inc?: InputMaybe<Databasechangeloglock_Inc_Input>;
  _set?: InputMaybe<Databasechangeloglock_Set_Input>;
  pk_columns: Databasechangeloglock_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DateFormatArgs = {
  _set?: InputMaybe<DateFormat_Set_Input>;
  where: DateFormat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DateFormat_By_PkArgs = {
  _set?: InputMaybe<DateFormat_Set_Input>;
  pk_columns: DateFormat_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Google_CredentialArgs = {
  _set?: InputMaybe<Google_Credential_Set_Input>;
  where: Google_Credential_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Google_Credential_By_PkArgs = {
  _set?: InputMaybe<Google_Credential_Set_Input>;
  pk_columns: Google_Credential_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Linear_IssueArgs = {
  _set?: InputMaybe<Linear_Issue_Set_Input>;
  where: Linear_Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Linear_Issue_By_PkArgs = {
  _set?: InputMaybe<Linear_Issue_Set_Input>;
  pk_columns: Linear_Issue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_ContactArgs = {
  _append?: InputMaybe<New_Contact_Append_Input>;
  _delete_at_path?: InputMaybe<New_Contact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<New_Contact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<New_Contact_Delete_Key_Input>;
  _prepend?: InputMaybe<New_Contact_Prepend_Input>;
  _set?: InputMaybe<New_Contact_Set_Input>;
  where: New_Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Contact_By_PkArgs = {
  _append?: InputMaybe<New_Contact_Append_Input>;
  _delete_at_path?: InputMaybe<New_Contact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<New_Contact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<New_Contact_Delete_Key_Input>;
  _prepend?: InputMaybe<New_Contact_Prepend_Input>;
  _set?: InputMaybe<New_Contact_Set_Input>;
  pk_columns: New_Contact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_FeedbackArgs = {
  _set?: InputMaybe<New_Feedback_Set_Input>;
  where: New_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Feedback_By_PkArgs = {
  _set?: InputMaybe<New_Feedback_Set_Input>;
  pk_columns: New_Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_UserArgs = {
  _set?: InputMaybe<New_User_Set_Input>;
  where: New_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_User_By_PkArgs = {
  _set?: InputMaybe<New_User_Set_Input>;
  pk_columns: New_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _set?: InputMaybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_By_PkArgs = {
  _set?: InputMaybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ShareCalendarLinkArgs = {
  _append?: InputMaybe<ShareCalendarLink_Append_Input>;
  _delete_at_path?: InputMaybe<ShareCalendarLink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ShareCalendarLink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ShareCalendarLink_Delete_Key_Input>;
  _inc?: InputMaybe<ShareCalendarLink_Inc_Input>;
  _prepend?: InputMaybe<ShareCalendarLink_Prepend_Input>;
  _set?: InputMaybe<ShareCalendarLink_Set_Input>;
  where: ShareCalendarLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ShareCalendarLinkTypeEnumArgs = {
  _set?: InputMaybe<ShareCalendarLinkTypeEnum_Set_Input>;
  where: ShareCalendarLinkTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ShareCalendarLinkTypeEnum_By_PkArgs = {
  _set?: InputMaybe<ShareCalendarLinkTypeEnum_Set_Input>;
  pk_columns: ShareCalendarLinkTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ShareCalendarLink_By_PkArgs = {
  _append?: InputMaybe<ShareCalendarLink_Append_Input>;
  _delete_at_path?: InputMaybe<ShareCalendarLink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ShareCalendarLink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ShareCalendarLink_Delete_Key_Input>;
  _inc?: InputMaybe<ShareCalendarLink_Inc_Input>;
  _prepend?: InputMaybe<ShareCalendarLink_Prepend_Input>;
  _set?: InputMaybe<ShareCalendarLink_Set_Input>;
  pk_columns: ShareCalendarLink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ThemeArgs = {
  _set?: InputMaybe<Theme_Set_Input>;
  where: Theme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Theme_By_PkArgs = {
  _set?: InputMaybe<Theme_Set_Input>;
  pk_columns: Theme_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TodoArgs = {
  _inc?: InputMaybe<Todo_Inc_Input>;
  _set?: InputMaybe<Todo_Set_Input>;
  where: Todo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TodoTypeEnumArgs = {
  _set?: InputMaybe<TodoTypeEnum_Set_Input>;
  where: TodoTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TodoTypeEnum_By_PkArgs = {
  _set?: InputMaybe<TodoTypeEnum_Set_Input>;
  pk_columns: TodoTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Todo_By_PkArgs = {
  _inc?: InputMaybe<Todo_Inc_Input>;
  _set?: InputMaybe<Todo_Set_Input>;
  pk_columns: Todo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserActivityFeedArgs = {
  _set?: InputMaybe<UserActivityFeed_Set_Input>;
  where: UserActivityFeed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserActivityFeed_By_PkArgs = {
  _set?: InputMaybe<UserActivityFeed_Set_Input>;
  pk_columns: UserActivityFeed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserPreferencesArgs = {
  _inc?: InputMaybe<UserPreferences_Inc_Input>;
  _set?: InputMaybe<UserPreferences_Set_Input>;
  where: UserPreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserPreferences_By_PkArgs = {
  _inc?: InputMaybe<UserPreferences_Inc_Input>;
  _set?: InputMaybe<UserPreferences_Set_Input>;
  pk_columns: UserPreferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserStatusArgs = {
  _set?: InputMaybe<UserStatus_Set_Input>;
  where: UserStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserStatus_By_PkArgs = {
  _set?: InputMaybe<UserStatus_Set_Input>;
  pk_columns: UserStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_AllowlistArgs = {
  _set?: InputMaybe<User_Allowlist_Set_Input>;
  where: User_Allowlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Allowlist_By_PkArgs = {
  _set?: InputMaybe<User_Allowlist_Set_Input>;
  pk_columns: User_Allowlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VideoProviderArgs = {
  _set?: InputMaybe<VideoProvider_Set_Input>;
  where: VideoProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VideoProvider_By_PkArgs = {
  _set?: InputMaybe<VideoProvider_Set_Input>;
  pk_columns: VideoProvider_Pk_Columns_Input;
};

/** columns and relationships of "contact" */
export type New_Contact = {
  __typename?: 'new_contact';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  birthdayReminderEventId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  displayName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  favoriteAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  googlePersonId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  labels: Scalars['jsonb'];
  lastInteractedAt: Scalars['timestamptz'];
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reminderEventId?: Maybe<Scalars['String']>;
  /** An object relationship */
  status?: Maybe<UserStatus>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: New_User;
  userId: Scalars['String'];
};


/** columns and relationships of "contact" */
export type New_ContactLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "contact" */
export type New_Contact_Aggregate = {
  __typename?: 'new_contact_aggregate';
  aggregate?: Maybe<New_Contact_Aggregate_Fields>;
  nodes: Array<New_Contact>;
};

/** aggregate fields of "contact" */
export type New_Contact_Aggregate_Fields = {
  __typename?: 'new_contact_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<New_Contact_Max_Fields>;
  min?: Maybe<New_Contact_Min_Fields>;
};


/** aggregate fields of "contact" */
export type New_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type New_Contact_Append_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type New_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<New_Contact_Bool_Exp>>;
  _not?: InputMaybe<New_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<New_Contact_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  birthdayReminderEventId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  emailAddress?: InputMaybe<String_Comparison_Exp>;
  favoriteAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  googlePersonId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  lastInteractedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  reminderEventId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<UserStatus_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact" */
export enum New_Contact_Constraint {
  /** unique or primary key constraint */
  ContactEmailAddressUserIdKey = 'contact_email_address_user_id_key',
  /** unique or primary key constraint */
  ContactPkey = 'contact_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type New_Contact_Delete_At_Path_Input = {
  labels?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type New_Contact_Delete_Elem_Input = {
  labels?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type New_Contact_Delete_Key_Input = {
  labels?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "contact" */
export type New_Contact_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['date']>;
  birthdayReminderEventId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  favoriteAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  googlePersonId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  lastInteractedAt?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  reminderEventId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type New_Contact_Max_Fields = {
  __typename?: 'new_contact_max_fields';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  birthdayReminderEventId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  favoriteAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  googlePersonId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastInteractedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reminderEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type New_Contact_Min_Fields = {
  __typename?: 'new_contact_min_fields';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  birthdayReminderEventId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  favoriteAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  googlePersonId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastInteractedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reminderEventId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contact" */
export type New_Contact_Mutation_Response = {
  __typename?: 'new_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Contact>;
};

/** on conflict condition type for table "contact" */
export type New_Contact_On_Conflict = {
  constraint: New_Contact_Constraint;
  update_columns?: Array<New_Contact_Update_Column>;
  where?: InputMaybe<New_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "contact". */
export type New_Contact_Order_By = {
  avatar?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  birthdayReminderEventId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  emailAddress?: InputMaybe<Order_By>;
  favoriteAt?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  googlePersonId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  lastInteractedAt?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  reminderEventId?: InputMaybe<Order_By>;
  status?: InputMaybe<UserStatus_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: new_contact */
export type New_Contact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type New_Contact_Prepend_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "contact" */
export enum New_Contact_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  BirthdayReminderEventId = 'birthdayReminderEventId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  FavoriteAt = 'favoriteAt',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GooglePersonId = 'googlePersonId',
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  LastInteractedAt = 'lastInteractedAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReminderEventId = 'reminderEventId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "contact" */
export type New_Contact_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['date']>;
  birthdayReminderEventId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  favoriteAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  googlePersonId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  lastInteractedAt?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  reminderEventId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "contact" */
export enum New_Contact_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  BirthdayReminderEventId = 'birthdayReminderEventId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  FavoriteAt = 'favoriteAt',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GooglePersonId = 'googlePersonId',
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  LastInteractedAt = 'lastInteractedAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReminderEventId = 'reminderEventId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "feedback" */
export type New_Feedback = {
  __typename?: 'new_feedback';
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  metadata: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: New_User;
  userId: Scalars['String'];
};

/** aggregated selection of "feedback" */
export type New_Feedback_Aggregate = {
  __typename?: 'new_feedback_aggregate';
  aggregate?: Maybe<New_Feedback_Aggregate_Fields>;
  nodes: Array<New_Feedback>;
};

/** aggregate fields of "feedback" */
export type New_Feedback_Aggregate_Fields = {
  __typename?: 'new_feedback_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<New_Feedback_Max_Fields>;
  min?: Maybe<New_Feedback_Min_Fields>;
};


/** aggregate fields of "feedback" */
export type New_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type New_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<New_Feedback_Bool_Exp>>;
  _not?: InputMaybe<New_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<New_Feedback_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum New_Feedback_Constraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'feedback_pkey'
}

/** input type for inserting data into table "feedback" */
export type New_Feedback_Insert_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type New_Feedback_Max_Fields = {
  __typename?: 'new_feedback_max_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type New_Feedback_Min_Fields = {
  __typename?: 'new_feedback_min_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feedback" */
export type New_Feedback_Mutation_Response = {
  __typename?: 'new_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Feedback>;
};

/** on conflict condition type for table "feedback" */
export type New_Feedback_On_Conflict = {
  constraint: New_Feedback_Constraint;
  update_columns?: Array<New_Feedback_Update_Column>;
  where?: InputMaybe<New_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback". */
export type New_Feedback_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: new_feedback */
export type New_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "feedback" */
export enum New_Feedback_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "feedback" */
export type New_Feedback_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "feedback" */
export enum New_Feedback_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "user" */
export type New_User = {
  __typename?: 'new_user';
  /** An object relationship */
  calendarPreference?: Maybe<CalendarPreference>;
  created_at: Scalars['timestamptz'];
  display_name: Scalars['String'];
  email: Scalars['String'];
  googleContactsSyncToken?: Maybe<Scalars['String']>;
  googleContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  googleCredential?: Maybe<Google_Credential>;
  googleDirectoryContactsSyncToken?: Maybe<Scalars['String']>;
  googleDirectoryContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  googleOtherContactsSyncToken?: Maybe<Scalars['String']>;
  googleOtherContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  photo_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  preferences?: Maybe<UserPreferences>;
  preferencesId?: Maybe<Scalars['uuid']>;
  spotifyRefreshToken?: Maybe<Scalars['String']>;
  updatedMinRefreshActivityFeed?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  zoomRefreshToken?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type New_User_Aggregate = {
  __typename?: 'new_user_aggregate';
  aggregate?: Maybe<New_User_Aggregate_Fields>;
  nodes: Array<New_User>;
};

/** aggregate fields of "user" */
export type New_User_Aggregate_Fields = {
  __typename?: 'new_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<New_User_Max_Fields>;
  min?: Maybe<New_User_Min_Fields>;
};


/** aggregate fields of "user" */
export type New_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type New_User_Bool_Exp = {
  _and?: InputMaybe<Array<New_User_Bool_Exp>>;
  _not?: InputMaybe<New_User_Bool_Exp>;
  _or?: InputMaybe<Array<New_User_Bool_Exp>>;
  calendarPreference?: InputMaybe<CalendarPreference_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  googleContactsSyncToken?: InputMaybe<String_Comparison_Exp>;
  googleContactsSyncTokenIat?: InputMaybe<Timestamptz_Comparison_Exp>;
  googleCredential?: InputMaybe<Google_Credential_Bool_Exp>;
  googleDirectoryContactsSyncToken?: InputMaybe<String_Comparison_Exp>;
  googleDirectoryContactsSyncTokenIat?: InputMaybe<Timestamptz_Comparison_Exp>;
  googleOtherContactsSyncToken?: InputMaybe<String_Comparison_Exp>;
  googleOtherContactsSyncTokenIat?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  photo_url?: InputMaybe<String_Comparison_Exp>;
  preferences?: InputMaybe<UserPreferences_Bool_Exp>;
  preferencesId?: InputMaybe<Uuid_Comparison_Exp>;
  spotifyRefreshToken?: InputMaybe<String_Comparison_Exp>;
  updatedMinRefreshActivityFeed?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomRefreshToken?: InputMaybe<String_Comparison_Exp>;
  zoomUserId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum New_User_Constraint {
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type New_User_Insert_Input = {
  calendarPreference?: InputMaybe<CalendarPreference_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  googleContactsSyncToken?: InputMaybe<Scalars['String']>;
  googleContactsSyncTokenIat?: InputMaybe<Scalars['timestamptz']>;
  googleCredential?: InputMaybe<Google_Credential_Obj_Rel_Insert_Input>;
  googleDirectoryContactsSyncToken?: InputMaybe<Scalars['String']>;
  googleDirectoryContactsSyncTokenIat?: InputMaybe<Scalars['timestamptz']>;
  googleOtherContactsSyncToken?: InputMaybe<Scalars['String']>;
  googleOtherContactsSyncTokenIat?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  photo_url?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<UserPreferences_Obj_Rel_Insert_Input>;
  preferencesId?: InputMaybe<Scalars['uuid']>;
  spotifyRefreshToken?: InputMaybe<Scalars['String']>;
  updatedMinRefreshActivityFeed?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zoomRefreshToken?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type New_User_Max_Fields = {
  __typename?: 'new_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  googleContactsSyncToken?: Maybe<Scalars['String']>;
  googleContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  googleDirectoryContactsSyncToken?: Maybe<Scalars['String']>;
  googleDirectoryContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  googleOtherContactsSyncToken?: Maybe<Scalars['String']>;
  googleOtherContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  preferencesId?: Maybe<Scalars['uuid']>;
  spotifyRefreshToken?: Maybe<Scalars['String']>;
  updatedMinRefreshActivityFeed?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type New_User_Min_Fields = {
  __typename?: 'new_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  googleContactsSyncToken?: Maybe<Scalars['String']>;
  googleContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  googleDirectoryContactsSyncToken?: Maybe<Scalars['String']>;
  googleDirectoryContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  googleOtherContactsSyncToken?: Maybe<Scalars['String']>;
  googleOtherContactsSyncTokenIat?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  preferencesId?: Maybe<Scalars['uuid']>;
  spotifyRefreshToken?: Maybe<Scalars['String']>;
  updatedMinRefreshActivityFeed?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type New_User_Mutation_Response = {
  __typename?: 'new_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_User>;
};

/** input type for inserting object relation for remote table "user" */
export type New_User_Obj_Rel_Insert_Input = {
  data: New_User_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<New_User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type New_User_On_Conflict = {
  constraint: New_User_Constraint;
  update_columns?: Array<New_User_Update_Column>;
  where?: InputMaybe<New_User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type New_User_Order_By = {
  calendarPreference?: InputMaybe<CalendarPreference_Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  googleContactsSyncToken?: InputMaybe<Order_By>;
  googleContactsSyncTokenIat?: InputMaybe<Order_By>;
  googleCredential?: InputMaybe<Google_Credential_Order_By>;
  googleDirectoryContactsSyncToken?: InputMaybe<Order_By>;
  googleDirectoryContactsSyncTokenIat?: InputMaybe<Order_By>;
  googleOtherContactsSyncToken?: InputMaybe<Order_By>;
  googleOtherContactsSyncTokenIat?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photo_url?: InputMaybe<Order_By>;
  preferences?: InputMaybe<UserPreferences_Order_By>;
  preferencesId?: InputMaybe<Order_By>;
  spotifyRefreshToken?: InputMaybe<Order_By>;
  updatedMinRefreshActivityFeed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zoomRefreshToken?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: new_user */
export type New_User_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "user" */
export enum New_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleContactsSyncToken = 'googleContactsSyncToken',
  /** column name */
  GoogleContactsSyncTokenIat = 'googleContactsSyncTokenIat',
  /** column name */
  GoogleDirectoryContactsSyncToken = 'googleDirectoryContactsSyncToken',
  /** column name */
  GoogleDirectoryContactsSyncTokenIat = 'googleDirectoryContactsSyncTokenIat',
  /** column name */
  GoogleOtherContactsSyncToken = 'googleOtherContactsSyncToken',
  /** column name */
  GoogleOtherContactsSyncTokenIat = 'googleOtherContactsSyncTokenIat',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  PreferencesId = 'preferencesId',
  /** column name */
  SpotifyRefreshToken = 'spotifyRefreshToken',
  /** column name */
  UpdatedMinRefreshActivityFeed = 'updatedMinRefreshActivityFeed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZoomRefreshToken = 'zoomRefreshToken',
  /** column name */
  ZoomUserId = 'zoomUserId'
}

/** input type for updating data in table "user" */
export type New_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  googleContactsSyncToken?: InputMaybe<Scalars['String']>;
  googleContactsSyncTokenIat?: InputMaybe<Scalars['timestamptz']>;
  googleDirectoryContactsSyncToken?: InputMaybe<Scalars['String']>;
  googleDirectoryContactsSyncTokenIat?: InputMaybe<Scalars['timestamptz']>;
  googleOtherContactsSyncToken?: InputMaybe<Scalars['String']>;
  googleOtherContactsSyncTokenIat?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  photo_url?: InputMaybe<Scalars['String']>;
  preferencesId?: InputMaybe<Scalars['uuid']>;
  spotifyRefreshToken?: InputMaybe<Scalars['String']>;
  updatedMinRefreshActivityFeed?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zoomRefreshToken?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum New_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleContactsSyncToken = 'googleContactsSyncToken',
  /** column name */
  GoogleContactsSyncTokenIat = 'googleContactsSyncTokenIat',
  /** column name */
  GoogleDirectoryContactsSyncToken = 'googleDirectoryContactsSyncToken',
  /** column name */
  GoogleDirectoryContactsSyncTokenIat = 'googleDirectoryContactsSyncTokenIat',
  /** column name */
  GoogleOtherContactsSyncToken = 'googleOtherContactsSyncToken',
  /** column name */
  GoogleOtherContactsSyncTokenIat = 'googleOtherContactsSyncTokenIat',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  PreferencesId = 'preferencesId',
  /** column name */
  SpotifyRefreshToken = 'spotifyRefreshToken',
  /** column name */
  UpdatedMinRefreshActivityFeed = 'updatedMinRefreshActivityFeed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZoomRefreshToken = 'zoomRefreshToken',
  /** column name */
  ZoomUserId = 'zoomUserId'
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  birthday?: Maybe<Scalars['date']>;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastLocation?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<New_User>;
  userId?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Bool_Exp>>;
  _not?: InputMaybe<Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Bool_Exp>>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  handle?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastLocation?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  verifiedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint */
  ProfileEmailKey = 'profile_email_key',
  /** unique or primary key constraint */
  ProfilePkey = 'profile_pkey',
  /** unique or primary key constraint */
  ProfileUserIdKey = 'profile_userId_key'
}

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  birthday?: InputMaybe<Scalars['date']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastLocation?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
  verifiedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  birthday?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastLocation?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  birthday?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastLocation?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** on conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns?: Array<Profile_Update_Column>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "profile". */
export type Profile_Order_By = {
  birthday?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLocation?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
  verifiedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile */
export type Profile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  LastLocation = 'lastLocation',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VerifiedAt = 'verifiedAt'
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  birthday?: InputMaybe<Scalars['date']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastLocation?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
  verifiedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "profile" */
export enum Profile_Update_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  LastLocation = 'lastLocation',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Phone = 'phone',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VerifiedAt = 'verifiedAt'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "activityFeed" */
  activityFeed: Array<ActivityFeed>;
  /** fetch data from the table: "activityFeedTypeEnum" */
  activityFeedTypeEnum: Array<ActivityFeedTypeEnum>;
  /** fetch aggregated fields from the table: "activityFeedTypeEnum" */
  activityFeedTypeEnum_aggregate: ActivityFeedTypeEnum_Aggregate;
  /** fetch data from the table: "activityFeedTypeEnum" using primary key columns */
  activityFeedTypeEnum_by_pk?: Maybe<ActivityFeedTypeEnum>;
  /** fetch aggregated fields from the table: "activityFeed" */
  activityFeed_aggregate: ActivityFeed_Aggregate;
  /** fetch data from the table: "activityFeed" using primary key columns */
  activityFeed_by_pk?: Maybe<ActivityFeed>;
  /** fetch data from the table: "analytics_event" */
  analytics_event: Array<Analytics_Event>;
  /** fetch aggregated fields from the table: "analytics_event" */
  analytics_event_aggregate: Analytics_Event_Aggregate;
  /** fetch data from the table: "analytics_event" using primary key columns */
  analytics_event_by_pk?: Maybe<Analytics_Event>;
  bestie: Array<Bestie>;
  birthdaysView?: Maybe<BirthdaysViewOutput>;
  calendar: Array<NewCalendar>;
  /** fetch data from the table: "calendarHistory" */
  calendarHistory: Array<CalendarHistory>;
  /** fetch aggregated fields from the table: "calendarHistory" */
  calendarHistory_aggregate: CalendarHistory_Aggregate;
  /** fetch data from the table: "calendarHistory" using primary key columns */
  calendarHistory_by_pk?: Maybe<CalendarHistory>;
  /** fetch data from the table: "calendarPreference" */
  calendarPreference: Array<CalendarPreference>;
  /** fetch aggregated fields from the table: "calendarPreference" */
  calendarPreference_aggregate: CalendarPreference_Aggregate;
  /** fetch data from the table: "calendarPreference" using primary key columns */
  calendarPreference_by_pk?: Maybe<CalendarPreference>;
  /** fetch data from the table: "calendarStartsOn" */
  calendarStartsOn: Array<CalendarStartsOn>;
  /** fetch aggregated fields from the table: "calendarStartsOn" */
  calendarStartsOn_aggregate: CalendarStartsOn_Aggregate;
  /** fetch data from the table: "calendarStartsOn" using primary key columns */
  calendarStartsOn_by_pk?: Maybe<CalendarStartsOn>;
  /** fetch data from the table: "category" */
  category: Array<Category>;
  /** fetch aggregated fields from the table: "category" */
  category_aggregate: Category_Aggregate;
  /** fetch data from the table: "category" using primary key columns */
  category_by_pk?: Maybe<Category>;
  checkAliasAvailability: CheckAliasAvailabilityOutput;
  /** fetch data from the table: "colorFamilyEnum" */
  colorFamilyEnum: Array<ColorFamilyEnum>;
  /** fetch aggregated fields from the table: "colorFamilyEnum" */
  colorFamilyEnum_aggregate: ColorFamilyEnum_Aggregate;
  /** fetch data from the table: "colorFamilyEnum" using primary key columns */
  colorFamilyEnum_by_pk?: Maybe<ColorFamilyEnum>;
  contactReminder?: Maybe<ContactReminderOutput>;
  /** fetch data from the table: "contact_sync" */
  contact_sync: Array<Contact_Sync>;
  /** fetch aggregated fields from the table: "contact_sync" */
  contact_sync_aggregate: Contact_Sync_Aggregate;
  /** fetch data from the table: "contact_sync" using primary key columns */
  contact_sync_by_pk?: Maybe<Contact_Sync>;
  /** fetch data from the table: "databasechangelog" */
  databasechangelog: Array<Databasechangelog>;
  /** fetch aggregated fields from the table: "databasechangelog" */
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  /** fetch data from the table: "databasechangeloglock" */
  databasechangeloglock: Array<Databasechangeloglock>;
  /** fetch aggregated fields from the table: "databasechangeloglock" */
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  /** fetch data from the table: "databasechangeloglock" using primary key columns */
  databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** fetch data from the table: "dateFormat" */
  dateFormat: Array<DateFormat>;
  /** fetch aggregated fields from the table: "dateFormat" */
  dateFormat_aggregate: DateFormat_Aggregate;
  /** fetch data from the table: "dateFormat" using primary key columns */
  dateFormat_by_pk?: Maybe<DateFormat>;
  event: EventsResponse;
  findNextAvailableSlot: FindNextAvailableSlotResponse;
  getGMapsPlaces: GetGMapsPlacesOutput;
  /** fetch data from the table: "google_credential" */
  google_credential: Array<Google_Credential>;
  /** fetch aggregated fields from the table: "google_credential" */
  google_credential_aggregate: Google_Credential_Aggregate;
  /** fetch data from the table: "google_credential" using primary key columns */
  google_credential_by_pk?: Maybe<Google_Credential>;
  lightweightEvents: Array<Maybe<NewEvent>>;
  /** fetch data from the table: "linear_issue" */
  linear_issue: Array<Linear_Issue>;
  /** fetch aggregated fields from the table: "linear_issue" */
  linear_issue_aggregate: Linear_Issue_Aggregate;
  /** fetch data from the table: "linear_issue" using primary key columns */
  linear_issue_by_pk?: Maybe<Linear_Issue>;
  /** fetch data from the table: "contact" */
  new_contact: Array<New_Contact>;
  /** fetch aggregated fields from the table: "contact" */
  new_contact_aggregate: New_Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  new_contact_by_pk?: Maybe<New_Contact>;
  /** fetch data from the table: "feedback" */
  new_feedback: Array<New_Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  new_feedback_aggregate: New_Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  new_feedback_by_pk?: Maybe<New_Feedback>;
  /** fetch data from the table: "user" */
  new_user: Array<New_User>;
  /** fetch aggregated fields from the table: "user" */
  new_user_aggregate: New_User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  new_user_by_pk?: Maybe<New_User>;
  ownCalendarList: Array<MinimalCalendarInfo>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  profileMeetInfo?: Maybe<ProfileMeetInfoOutput>;
  profileView?: Maybe<ProfileViewOutput>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  retrieveSharedCalendarLink: RetrieveSharedCalendarLinkOutput;
  /** fetch data from the table: "shareCalendarLink" */
  shareCalendarLink: Array<ShareCalendarLink>;
  /** fetch data from the table: "shareCalendarLinkTypeEnum" */
  shareCalendarLinkTypeEnum: Array<ShareCalendarLinkTypeEnum>;
  /** fetch aggregated fields from the table: "shareCalendarLinkTypeEnum" */
  shareCalendarLinkTypeEnum_aggregate: ShareCalendarLinkTypeEnum_Aggregate;
  /** fetch data from the table: "shareCalendarLinkTypeEnum" using primary key columns */
  shareCalendarLinkTypeEnum_by_pk?: Maybe<ShareCalendarLinkTypeEnum>;
  /** fetch aggregated fields from the table: "shareCalendarLink" */
  shareCalendarLink_aggregate: ShareCalendarLink_Aggregate;
  /** fetch data from the table: "shareCalendarLink" using primary key columns */
  shareCalendarLink_by_pk?: Maybe<ShareCalendarLink>;
  singleEvent?: Maybe<NewEvent>;
  spotifyCurrentlyPlaying?: Maybe<SpotifyTrack>;
  spotifyRecentlyPlayed?: Maybe<SpotifyQueue>;
  /** fetch data from the table: "theme" */
  theme: Array<Theme>;
  /** fetch aggregated fields from the table: "theme" */
  theme_aggregate: Theme_Aggregate;
  /** fetch data from the table: "theme" using primary key columns */
  theme_by_pk?: Maybe<Theme>;
  /** fetch data from the table: "todo" */
  todo: Array<Todo>;
  /** fetch data from the table: "todoTypeEnum" */
  todoTypeEnum: Array<TodoTypeEnum>;
  /** fetch aggregated fields from the table: "todoTypeEnum" */
  todoTypeEnum_aggregate: TodoTypeEnum_Aggregate;
  /** fetch data from the table: "todoTypeEnum" using primary key columns */
  todoTypeEnum_by_pk?: Maybe<TodoTypeEnum>;
  /** fetch aggregated fields from the table: "todo" */
  todo_aggregate: Todo_Aggregate;
  /** fetch data from the table: "todo" using primary key columns */
  todo_by_pk?: Maybe<Todo>;
  /** fetch data from the table: "userActivityFeed" */
  userActivityFeed: Array<UserActivityFeed>;
  /** fetch aggregated fields from the table: "userActivityFeed" */
  userActivityFeed_aggregate: UserActivityFeed_Aggregate;
  /** fetch data from the table: "userActivityFeed" using primary key columns */
  userActivityFeed_by_pk?: Maybe<UserActivityFeed>;
  /** fetch data from the table: "userPreferences" */
  userPreferences: Array<UserPreferences>;
  /** fetch aggregated fields from the table: "userPreferences" */
  userPreferences_aggregate: UserPreferences_Aggregate;
  /** fetch data from the table: "userPreferences" using primary key columns */
  userPreferences_by_pk?: Maybe<UserPreferences>;
  /** fetch data from the table: "userStatus" */
  userStatus: Array<UserStatus>;
  /** fetch aggregated fields from the table: "userStatus" */
  userStatus_aggregate: UserStatus_Aggregate;
  /** fetch data from the table: "userStatus" using primary key columns */
  userStatus_by_pk?: Maybe<UserStatus>;
  /** fetch data from the table: "user_allowlist" */
  user_allowlist: Array<User_Allowlist>;
  /** fetch aggregated fields from the table: "user_allowlist" */
  user_allowlist_aggregate: User_Allowlist_Aggregate;
  /** fetch data from the table: "user_allowlist" using primary key columns */
  user_allowlist_by_pk?: Maybe<User_Allowlist>;
  /** fetch data from the table: "videoProvider" */
  videoProvider: Array<VideoProvider>;
  /** fetch aggregated fields from the table: "videoProvider" */
  videoProvider_aggregate: VideoProvider_Aggregate;
  /** fetch data from the table: "videoProvider" using primary key columns */
  videoProvider_by_pk?: Maybe<VideoProvider>;
};


export type Query_RootActivityFeedArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeed_Order_By>>;
  where?: InputMaybe<ActivityFeed_Bool_Exp>;
};


export type Query_RootActivityFeedTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeedTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeedTypeEnum_Order_By>>;
  where?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
};


export type Query_RootActivityFeedTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeedTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeedTypeEnum_Order_By>>;
  where?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
};


export type Query_RootActivityFeedTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootActivityFeed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeed_Order_By>>;
  where?: InputMaybe<ActivityFeed_Bool_Exp>;
};


export type Query_RootActivityFeed_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAnalytics_EventArgs = {
  distinct_on?: InputMaybe<Array<Analytics_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_Event_Order_By>>;
  where?: InputMaybe<Analytics_Event_Bool_Exp>;
};


export type Query_RootAnalytics_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Analytics_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_Event_Order_By>>;
  where?: InputMaybe<Analytics_Event_Bool_Exp>;
};


export type Query_RootAnalytics_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBestieArgs = {
  domainEndsWith?: InputMaybe<Scalars['String']>;
};


export type Query_RootBirthdaysViewArgs = {
  input: BirthdaysViewInput;
};


export type Query_RootCalendarArgs = {
  where?: InputMaybe<NewCalendarFilter>;
};


export type Query_RootCalendarHistoryArgs = {
  distinct_on?: InputMaybe<Array<CalendarHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarHistory_Order_By>>;
  where?: InputMaybe<CalendarHistory_Bool_Exp>;
};


export type Query_RootCalendarHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalendarHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarHistory_Order_By>>;
  where?: InputMaybe<CalendarHistory_Bool_Exp>;
};


export type Query_RootCalendarHistory_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCalendarPreferenceArgs = {
  distinct_on?: InputMaybe<Array<CalendarPreference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarPreference_Order_By>>;
  where?: InputMaybe<CalendarPreference_Bool_Exp>;
};


export type Query_RootCalendarPreference_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalendarPreference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarPreference_Order_By>>;
  where?: InputMaybe<CalendarPreference_Bool_Exp>;
};


export type Query_RootCalendarPreference_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCalendarStartsOnArgs = {
  distinct_on?: InputMaybe<Array<CalendarStartsOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarStartsOn_Order_By>>;
  where?: InputMaybe<CalendarStartsOn_Bool_Exp>;
};


export type Query_RootCalendarStartsOn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalendarStartsOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarStartsOn_Order_By>>;
  where?: InputMaybe<CalendarStartsOn_Bool_Exp>;
};


export type Query_RootCalendarStartsOn_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCategoryArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Query_RootCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Query_RootCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCheckAliasAvailabilityArgs = {
  alias: Scalars['String'];
};


export type Query_RootColorFamilyEnumArgs = {
  distinct_on?: InputMaybe<Array<ColorFamilyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ColorFamilyEnum_Order_By>>;
  where?: InputMaybe<ColorFamilyEnum_Bool_Exp>;
};


export type Query_RootColorFamilyEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ColorFamilyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ColorFamilyEnum_Order_By>>;
  where?: InputMaybe<ColorFamilyEnum_Bool_Exp>;
};


export type Query_RootColorFamilyEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootContactReminderArgs = {
  id: Scalars['ID'];
};


export type Query_RootContact_SyncArgs = {
  distinct_on?: InputMaybe<Array<Contact_Sync_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Sync_Order_By>>;
  where?: InputMaybe<Contact_Sync_Bool_Exp>;
};


export type Query_RootContact_Sync_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Sync_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Sync_Order_By>>;
  where?: InputMaybe<Contact_Sync_Bool_Exp>;
};


export type Query_RootContact_Sync_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDatabasechangelogArgs = {
  distinct_on?: InputMaybe<Array<Databasechangelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangelog_Order_By>>;
  where?: InputMaybe<Databasechangelog_Bool_Exp>;
};


export type Query_RootDatabasechangelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Databasechangelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangelog_Order_By>>;
  where?: InputMaybe<Databasechangelog_Bool_Exp>;
};


export type Query_RootDatabasechangeloglockArgs = {
  distinct_on?: InputMaybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangeloglock_Order_By>>;
  where?: InputMaybe<Databasechangeloglock_Bool_Exp>;
};


export type Query_RootDatabasechangeloglock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangeloglock_Order_By>>;
  where?: InputMaybe<Databasechangeloglock_Bool_Exp>;
};


export type Query_RootDatabasechangeloglock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDateFormatArgs = {
  distinct_on?: InputMaybe<Array<DateFormat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DateFormat_Order_By>>;
  where?: InputMaybe<DateFormat_Bool_Exp>;
};


export type Query_RootDateFormat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DateFormat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DateFormat_Order_By>>;
  where?: InputMaybe<DateFormat_Bool_Exp>;
};


export type Query_RootDateFormat_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEventArgs = {
  where?: InputMaybe<NewEventFilter>;
};


export type Query_RootFindNextAvailableSlotArgs = {
  input: FindNextAvailableSlotArgs;
};


export type Query_RootGetGMapsPlacesArgs = {
  where: GetGMapsPlacesInput;
};


export type Query_RootGoogle_CredentialArgs = {
  distinct_on?: InputMaybe<Array<Google_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Credential_Order_By>>;
  where?: InputMaybe<Google_Credential_Bool_Exp>;
};


export type Query_RootGoogle_Credential_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Google_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Credential_Order_By>>;
  where?: InputMaybe<Google_Credential_Bool_Exp>;
};


export type Query_RootGoogle_Credential_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLightweightEventsArgs = {
  where: LightweightEventsFilter;
};


export type Query_RootLinear_IssueArgs = {
  distinct_on?: InputMaybe<Array<Linear_Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linear_Issue_Order_By>>;
  where?: InputMaybe<Linear_Issue_Bool_Exp>;
};


export type Query_RootLinear_Issue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Linear_Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linear_Issue_Order_By>>;
  where?: InputMaybe<Linear_Issue_Bool_Exp>;
};


export type Query_RootLinear_Issue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNew_ContactArgs = {
  distinct_on?: InputMaybe<Array<New_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Contact_Order_By>>;
  where?: InputMaybe<New_Contact_Bool_Exp>;
};


export type Query_RootNew_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Contact_Order_By>>;
  where?: InputMaybe<New_Contact_Bool_Exp>;
};


export type Query_RootNew_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNew_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<New_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Feedback_Order_By>>;
  where?: InputMaybe<New_Feedback_Bool_Exp>;
};


export type Query_RootNew_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Feedback_Order_By>>;
  where?: InputMaybe<New_Feedback_Bool_Exp>;
};


export type Query_RootNew_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNew_UserArgs = {
  distinct_on?: InputMaybe<Array<New_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_User_Order_By>>;
  where?: InputMaybe<New_User_Bool_Exp>;
};


export type Query_RootNew_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_User_Order_By>>;
  where?: InputMaybe<New_User_Bool_Exp>;
};


export type Query_RootNew_User_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfileMeetInfoArgs = {
  objects: ProfileMeetInfoInput;
};


export type Query_RootProfileViewArgs = {
  email: Scalars['String'];
};


export type Query_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRetrieveSharedCalendarLinkArgs = {
  objects: RetrieveSharedCalendarLinkInput;
};


export type Query_RootShareCalendarLinkArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLink_Order_By>>;
  where?: InputMaybe<ShareCalendarLink_Bool_Exp>;
};


export type Query_RootShareCalendarLinkTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Order_By>>;
  where?: InputMaybe<ShareCalendarLinkTypeEnum_Bool_Exp>;
};


export type Query_RootShareCalendarLinkTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Order_By>>;
  where?: InputMaybe<ShareCalendarLinkTypeEnum_Bool_Exp>;
};


export type Query_RootShareCalendarLinkTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootShareCalendarLink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLink_Order_By>>;
  where?: InputMaybe<ShareCalendarLink_Bool_Exp>;
};


export type Query_RootShareCalendarLink_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSingleEventArgs = {
  where: SingleEventInput;
};


export type Query_RootSpotifyCurrentlyPlayingArgs = {
  targetUser: Scalars['String'];
};


export type Query_RootSpotifyRecentlyPlayedArgs = {
  before: Scalars['DateTimeRFC3339'];
};


export type Query_RootThemeArgs = {
  distinct_on?: InputMaybe<Array<Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Theme_Order_By>>;
  where?: InputMaybe<Theme_Bool_Exp>;
};


export type Query_RootTheme_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Theme_Order_By>>;
  where?: InputMaybe<Theme_Bool_Exp>;
};


export type Query_RootTheme_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTodoArgs = {
  distinct_on?: InputMaybe<Array<Todo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Order_By>>;
  where?: InputMaybe<Todo_Bool_Exp>;
};


export type Query_RootTodoTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<TodoTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TodoTypeEnum_Order_By>>;
  where?: InputMaybe<TodoTypeEnum_Bool_Exp>;
};


export type Query_RootTodoTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TodoTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TodoTypeEnum_Order_By>>;
  where?: InputMaybe<TodoTypeEnum_Bool_Exp>;
};


export type Query_RootTodoTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTodo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Order_By>>;
  where?: InputMaybe<Todo_Bool_Exp>;
};


export type Query_RootTodo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserActivityFeedArgs = {
  distinct_on?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserActivityFeed_Order_By>>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};


export type Query_RootUserActivityFeed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserActivityFeed_Order_By>>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};


export type Query_RootUserActivityFeed_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Query_RootUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Query_RootUserPreferences_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserStatusArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};


export type Query_RootUserStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};


export type Query_RootUserStatus_By_PkArgs = {
  userEmail: Scalars['String'];
};


export type Query_RootUser_AllowlistArgs = {
  distinct_on?: InputMaybe<Array<User_Allowlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Allowlist_Order_By>>;
  where?: InputMaybe<User_Allowlist_Bool_Exp>;
};


export type Query_RootUser_Allowlist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Allowlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Allowlist_Order_By>>;
  where?: InputMaybe<User_Allowlist_Bool_Exp>;
};


export type Query_RootUser_Allowlist_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideoProviderArgs = {
  distinct_on?: InputMaybe<Array<VideoProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VideoProvider_Order_By>>;
  where?: InputMaybe<VideoProvider_Bool_Exp>;
};


export type Query_RootVideoProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VideoProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VideoProvider_Order_By>>;
  where?: InputMaybe<VideoProvider_Bool_Exp>;
};


export type Query_RootVideoProvider_By_PkArgs = {
  value: Scalars['String'];
};

export type RefreshUserStatusOutput = {
  __typename?: 'refreshUserStatusOutput';
  success: Scalars['Boolean'];
};

/** columns and relationships of "shareCalendarLink" */
export type ShareCalendarLink = {
  __typename?: 'shareCalendarLink';
  alias?: Maybe<Scalars['String']>;
  bookedCount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  payload?: Maybe<Scalars['jsonb']>;
  type: ShareCalendarLinkTypeEnum_Enum;
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['String'];
};


/** columns and relationships of "shareCalendarLink" */
export type ShareCalendarLinkPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum = {
  __typename?: 'shareCalendarLinkTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_Aggregate = {
  __typename?: 'shareCalendarLinkTypeEnum_aggregate';
  aggregate?: Maybe<ShareCalendarLinkTypeEnum_Aggregate_Fields>;
  nodes: Array<ShareCalendarLinkTypeEnum>;
};

/** aggregate fields of "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_Aggregate_Fields = {
  __typename?: 'shareCalendarLinkTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ShareCalendarLinkTypeEnum_Max_Fields>;
  min?: Maybe<ShareCalendarLinkTypeEnum_Min_Fields>;
};


/** aggregate fields of "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "shareCalendarLinkTypeEnum". All fields are combined with a logical 'AND'. */
export type ShareCalendarLinkTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<ShareCalendarLinkTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shareCalendarLinkTypeEnum" */
export enum ShareCalendarLinkTypeEnum_Constraint {
  /** unique or primary key constraint */
  ShareCalendarLinkTypeEnumPkey = 'shareCalendarLinkTypeEnum_pkey'
}

export enum ShareCalendarLinkTypeEnum_Enum {
  OneOff = 'one_off',
  Recurring = 'recurring'
}

/** Boolean expression to compare columns of type "shareCalendarLinkTypeEnum_enum". All fields are combined with logical 'AND'. */
export type ShareCalendarLinkTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ShareCalendarLinkTypeEnum_Enum>;
  _in?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ShareCalendarLinkTypeEnum_Enum>;
  _nin?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Enum>>;
};

/** input type for inserting data into table "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ShareCalendarLinkTypeEnum_Max_Fields = {
  __typename?: 'shareCalendarLinkTypeEnum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ShareCalendarLinkTypeEnum_Min_Fields = {
  __typename?: 'shareCalendarLinkTypeEnum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_Mutation_Response = {
  __typename?: 'shareCalendarLinkTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShareCalendarLinkTypeEnum>;
};

/** on conflict condition type for table "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_On_Conflict = {
  constraint: ShareCalendarLinkTypeEnum_Constraint;
  update_columns?: Array<ShareCalendarLinkTypeEnum_Update_Column>;
  where?: InputMaybe<ShareCalendarLinkTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "shareCalendarLinkTypeEnum". */
export type ShareCalendarLinkTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shareCalendarLinkTypeEnum */
export type ShareCalendarLinkTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "shareCalendarLinkTypeEnum" */
export enum ShareCalendarLinkTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "shareCalendarLinkTypeEnum" */
export type ShareCalendarLinkTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "shareCalendarLinkTypeEnum" */
export enum ShareCalendarLinkTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "shareCalendarLink" */
export type ShareCalendarLink_Aggregate = {
  __typename?: 'shareCalendarLink_aggregate';
  aggregate?: Maybe<ShareCalendarLink_Aggregate_Fields>;
  nodes: Array<ShareCalendarLink>;
};

/** aggregate fields of "shareCalendarLink" */
export type ShareCalendarLink_Aggregate_Fields = {
  __typename?: 'shareCalendarLink_aggregate_fields';
  avg?: Maybe<ShareCalendarLink_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ShareCalendarLink_Max_Fields>;
  min?: Maybe<ShareCalendarLink_Min_Fields>;
  stddev?: Maybe<ShareCalendarLink_Stddev_Fields>;
  stddev_pop?: Maybe<ShareCalendarLink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ShareCalendarLink_Stddev_Samp_Fields>;
  sum?: Maybe<ShareCalendarLink_Sum_Fields>;
  var_pop?: Maybe<ShareCalendarLink_Var_Pop_Fields>;
  var_samp?: Maybe<ShareCalendarLink_Var_Samp_Fields>;
  variance?: Maybe<ShareCalendarLink_Variance_Fields>;
};


/** aggregate fields of "shareCalendarLink" */
export type ShareCalendarLink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ShareCalendarLink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ShareCalendarLink_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ShareCalendarLink_Avg_Fields = {
  __typename?: 'shareCalendarLink_avg_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "shareCalendarLink". All fields are combined with a logical 'AND'. */
export type ShareCalendarLink_Bool_Exp = {
  _and?: InputMaybe<Array<ShareCalendarLink_Bool_Exp>>;
  _not?: InputMaybe<ShareCalendarLink_Bool_Exp>;
  _or?: InputMaybe<Array<ShareCalendarLink_Bool_Exp>>;
  alias?: InputMaybe<String_Comparison_Exp>;
  bookedCount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<ShareCalendarLinkTypeEnum_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shareCalendarLink" */
export enum ShareCalendarLink_Constraint {
  /** unique or primary key constraint */
  ShareCalendarLinkAliasKey = 'shareCalendarLink_alias_key',
  /** unique or primary key constraint */
  ShareCalendarLinkPkey = 'shareCalendarLink_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ShareCalendarLink_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ShareCalendarLink_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ShareCalendarLink_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "shareCalendarLink" */
export type ShareCalendarLink_Inc_Input = {
  bookedCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shareCalendarLink" */
export type ShareCalendarLink_Insert_Input = {
  alias?: InputMaybe<Scalars['String']>;
  bookedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ShareCalendarLinkTypeEnum_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ShareCalendarLink_Max_Fields = {
  __typename?: 'shareCalendarLink_max_fields';
  alias?: Maybe<Scalars['String']>;
  bookedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ShareCalendarLink_Min_Fields = {
  __typename?: 'shareCalendarLink_min_fields';
  alias?: Maybe<Scalars['String']>;
  bookedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "shareCalendarLink" */
export type ShareCalendarLink_Mutation_Response = {
  __typename?: 'shareCalendarLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShareCalendarLink>;
};

/** on conflict condition type for table "shareCalendarLink" */
export type ShareCalendarLink_On_Conflict = {
  constraint: ShareCalendarLink_Constraint;
  update_columns?: Array<ShareCalendarLink_Update_Column>;
  where?: InputMaybe<ShareCalendarLink_Bool_Exp>;
};

/** Ordering options when selecting data from "shareCalendarLink". */
export type ShareCalendarLink_Order_By = {
  alias?: InputMaybe<Order_By>;
  bookedCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shareCalendarLink */
export type ShareCalendarLink_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ShareCalendarLink_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "shareCalendarLink" */
export enum ShareCalendarLink_Select_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  BookedCount = 'bookedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "shareCalendarLink" */
export type ShareCalendarLink_Set_Input = {
  alias?: InputMaybe<Scalars['String']>;
  bookedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ShareCalendarLinkTypeEnum_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ShareCalendarLink_Stddev_Fields = {
  __typename?: 'shareCalendarLink_stddev_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ShareCalendarLink_Stddev_Pop_Fields = {
  __typename?: 'shareCalendarLink_stddev_pop_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ShareCalendarLink_Stddev_Samp_Fields = {
  __typename?: 'shareCalendarLink_stddev_samp_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ShareCalendarLink_Sum_Fields = {
  __typename?: 'shareCalendarLink_sum_fields';
  bookedCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "shareCalendarLink" */
export enum ShareCalendarLink_Update_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  BookedCount = 'bookedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type ShareCalendarLink_Var_Pop_Fields = {
  __typename?: 'shareCalendarLink_var_pop_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ShareCalendarLink_Var_Samp_Fields = {
  __typename?: 'shareCalendarLink_var_samp_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ShareCalendarLink_Variance_Fields = {
  __typename?: 'shareCalendarLink_variance_fields';
  bookedCount?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "activityFeed" */
  activityFeed: Array<ActivityFeed>;
  /** fetch data from the table: "activityFeedTypeEnum" */
  activityFeedTypeEnum: Array<ActivityFeedTypeEnum>;
  /** fetch aggregated fields from the table: "activityFeedTypeEnum" */
  activityFeedTypeEnum_aggregate: ActivityFeedTypeEnum_Aggregate;
  /** fetch data from the table: "activityFeedTypeEnum" using primary key columns */
  activityFeedTypeEnum_by_pk?: Maybe<ActivityFeedTypeEnum>;
  /** fetch aggregated fields from the table: "activityFeed" */
  activityFeed_aggregate: ActivityFeed_Aggregate;
  /** fetch data from the table: "activityFeed" using primary key columns */
  activityFeed_by_pk?: Maybe<ActivityFeed>;
  /** fetch data from the table: "analytics_event" */
  analytics_event: Array<Analytics_Event>;
  /** fetch aggregated fields from the table: "analytics_event" */
  analytics_event_aggregate: Analytics_Event_Aggregate;
  /** fetch data from the table: "analytics_event" using primary key columns */
  analytics_event_by_pk?: Maybe<Analytics_Event>;
  /** fetch data from the table: "calendarHistory" */
  calendarHistory: Array<CalendarHistory>;
  /** fetch aggregated fields from the table: "calendarHistory" */
  calendarHistory_aggregate: CalendarHistory_Aggregate;
  /** fetch data from the table: "calendarHistory" using primary key columns */
  calendarHistory_by_pk?: Maybe<CalendarHistory>;
  /** fetch data from the table: "calendarPreference" */
  calendarPreference: Array<CalendarPreference>;
  /** fetch aggregated fields from the table: "calendarPreference" */
  calendarPreference_aggregate: CalendarPreference_Aggregate;
  /** fetch data from the table: "calendarPreference" using primary key columns */
  calendarPreference_by_pk?: Maybe<CalendarPreference>;
  /** fetch data from the table: "calendarStartsOn" */
  calendarStartsOn: Array<CalendarStartsOn>;
  /** fetch aggregated fields from the table: "calendarStartsOn" */
  calendarStartsOn_aggregate: CalendarStartsOn_Aggregate;
  /** fetch data from the table: "calendarStartsOn" using primary key columns */
  calendarStartsOn_by_pk?: Maybe<CalendarStartsOn>;
  /** fetch data from the table: "category" */
  category: Array<Category>;
  /** fetch aggregated fields from the table: "category" */
  category_aggregate: Category_Aggregate;
  /** fetch data from the table: "category" using primary key columns */
  category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "colorFamilyEnum" */
  colorFamilyEnum: Array<ColorFamilyEnum>;
  /** fetch aggregated fields from the table: "colorFamilyEnum" */
  colorFamilyEnum_aggregate: ColorFamilyEnum_Aggregate;
  /** fetch data from the table: "colorFamilyEnum" using primary key columns */
  colorFamilyEnum_by_pk?: Maybe<ColorFamilyEnum>;
  /** fetch data from the table: "contact_sync" */
  contact_sync: Array<Contact_Sync>;
  /** fetch aggregated fields from the table: "contact_sync" */
  contact_sync_aggregate: Contact_Sync_Aggregate;
  /** fetch data from the table: "contact_sync" using primary key columns */
  contact_sync_by_pk?: Maybe<Contact_Sync>;
  /** fetch data from the table: "databasechangelog" */
  databasechangelog: Array<Databasechangelog>;
  /** fetch aggregated fields from the table: "databasechangelog" */
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  /** fetch data from the table: "databasechangeloglock" */
  databasechangeloglock: Array<Databasechangeloglock>;
  /** fetch aggregated fields from the table: "databasechangeloglock" */
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  /** fetch data from the table: "databasechangeloglock" using primary key columns */
  databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** fetch data from the table: "dateFormat" */
  dateFormat: Array<DateFormat>;
  /** fetch aggregated fields from the table: "dateFormat" */
  dateFormat_aggregate: DateFormat_Aggregate;
  /** fetch data from the table: "dateFormat" using primary key columns */
  dateFormat_by_pk?: Maybe<DateFormat>;
  /** fetch data from the table: "google_credential" */
  google_credential: Array<Google_Credential>;
  /** fetch aggregated fields from the table: "google_credential" */
  google_credential_aggregate: Google_Credential_Aggregate;
  /** fetch data from the table: "google_credential" using primary key columns */
  google_credential_by_pk?: Maybe<Google_Credential>;
  /** fetch data from the table: "linear_issue" */
  linear_issue: Array<Linear_Issue>;
  /** fetch aggregated fields from the table: "linear_issue" */
  linear_issue_aggregate: Linear_Issue_Aggregate;
  /** fetch data from the table: "linear_issue" using primary key columns */
  linear_issue_by_pk?: Maybe<Linear_Issue>;
  /** fetch data from the table: "contact" */
  new_contact: Array<New_Contact>;
  /** fetch aggregated fields from the table: "contact" */
  new_contact_aggregate: New_Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  new_contact_by_pk?: Maybe<New_Contact>;
  /** fetch data from the table: "feedback" */
  new_feedback: Array<New_Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  new_feedback_aggregate: New_Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  new_feedback_by_pk?: Maybe<New_Feedback>;
  /** fetch data from the table: "user" */
  new_user: Array<New_User>;
  /** fetch aggregated fields from the table: "user" */
  new_user_aggregate: New_User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  new_user_by_pk?: Maybe<New_User>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "shareCalendarLink" */
  shareCalendarLink: Array<ShareCalendarLink>;
  /** fetch data from the table: "shareCalendarLinkTypeEnum" */
  shareCalendarLinkTypeEnum: Array<ShareCalendarLinkTypeEnum>;
  /** fetch aggregated fields from the table: "shareCalendarLinkTypeEnum" */
  shareCalendarLinkTypeEnum_aggregate: ShareCalendarLinkTypeEnum_Aggregate;
  /** fetch data from the table: "shareCalendarLinkTypeEnum" using primary key columns */
  shareCalendarLinkTypeEnum_by_pk?: Maybe<ShareCalendarLinkTypeEnum>;
  /** fetch aggregated fields from the table: "shareCalendarLink" */
  shareCalendarLink_aggregate: ShareCalendarLink_Aggregate;
  /** fetch data from the table: "shareCalendarLink" using primary key columns */
  shareCalendarLink_by_pk?: Maybe<ShareCalendarLink>;
  /** fetch data from the table: "theme" */
  theme: Array<Theme>;
  /** fetch aggregated fields from the table: "theme" */
  theme_aggregate: Theme_Aggregate;
  /** fetch data from the table: "theme" using primary key columns */
  theme_by_pk?: Maybe<Theme>;
  /** fetch data from the table: "todo" */
  todo: Array<Todo>;
  /** fetch data from the table: "todoTypeEnum" */
  todoTypeEnum: Array<TodoTypeEnum>;
  /** fetch aggregated fields from the table: "todoTypeEnum" */
  todoTypeEnum_aggregate: TodoTypeEnum_Aggregate;
  /** fetch data from the table: "todoTypeEnum" using primary key columns */
  todoTypeEnum_by_pk?: Maybe<TodoTypeEnum>;
  /** fetch aggregated fields from the table: "todo" */
  todo_aggregate: Todo_Aggregate;
  /** fetch data from the table: "todo" using primary key columns */
  todo_by_pk?: Maybe<Todo>;
  /** fetch data from the table: "userActivityFeed" */
  userActivityFeed: Array<UserActivityFeed>;
  /** fetch aggregated fields from the table: "userActivityFeed" */
  userActivityFeed_aggregate: UserActivityFeed_Aggregate;
  /** fetch data from the table: "userActivityFeed" using primary key columns */
  userActivityFeed_by_pk?: Maybe<UserActivityFeed>;
  /** fetch data from the table: "userPreferences" */
  userPreferences: Array<UserPreferences>;
  /** fetch aggregated fields from the table: "userPreferences" */
  userPreferences_aggregate: UserPreferences_Aggregate;
  /** fetch data from the table: "userPreferences" using primary key columns */
  userPreferences_by_pk?: Maybe<UserPreferences>;
  /** fetch data from the table: "userStatus" */
  userStatus: Array<UserStatus>;
  /** fetch aggregated fields from the table: "userStatus" */
  userStatus_aggregate: UserStatus_Aggregate;
  /** fetch data from the table: "userStatus" using primary key columns */
  userStatus_by_pk?: Maybe<UserStatus>;
  /** fetch data from the table: "user_allowlist" */
  user_allowlist: Array<User_Allowlist>;
  /** fetch aggregated fields from the table: "user_allowlist" */
  user_allowlist_aggregate: User_Allowlist_Aggregate;
  /** fetch data from the table: "user_allowlist" using primary key columns */
  user_allowlist_by_pk?: Maybe<User_Allowlist>;
  /** fetch data from the table: "videoProvider" */
  videoProvider: Array<VideoProvider>;
  /** fetch aggregated fields from the table: "videoProvider" */
  videoProvider_aggregate: VideoProvider_Aggregate;
  /** fetch data from the table: "videoProvider" using primary key columns */
  videoProvider_by_pk?: Maybe<VideoProvider>;
};


export type Subscription_RootActivityFeedArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeed_Order_By>>;
  where?: InputMaybe<ActivityFeed_Bool_Exp>;
};


export type Subscription_RootActivityFeedTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeedTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeedTypeEnum_Order_By>>;
  where?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
};


export type Subscription_RootActivityFeedTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeedTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeedTypeEnum_Order_By>>;
  where?: InputMaybe<ActivityFeedTypeEnum_Bool_Exp>;
};


export type Subscription_RootActivityFeedTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootActivityFeed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityFeed_Order_By>>;
  where?: InputMaybe<ActivityFeed_Bool_Exp>;
};


export type Subscription_RootActivityFeed_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAnalytics_EventArgs = {
  distinct_on?: InputMaybe<Array<Analytics_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_Event_Order_By>>;
  where?: InputMaybe<Analytics_Event_Bool_Exp>;
};


export type Subscription_RootAnalytics_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Analytics_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_Event_Order_By>>;
  where?: InputMaybe<Analytics_Event_Bool_Exp>;
};


export type Subscription_RootAnalytics_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCalendarHistoryArgs = {
  distinct_on?: InputMaybe<Array<CalendarHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarHistory_Order_By>>;
  where?: InputMaybe<CalendarHistory_Bool_Exp>;
};


export type Subscription_RootCalendarHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalendarHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarHistory_Order_By>>;
  where?: InputMaybe<CalendarHistory_Bool_Exp>;
};


export type Subscription_RootCalendarHistory_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCalendarPreferenceArgs = {
  distinct_on?: InputMaybe<Array<CalendarPreference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarPreference_Order_By>>;
  where?: InputMaybe<CalendarPreference_Bool_Exp>;
};


export type Subscription_RootCalendarPreference_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalendarPreference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarPreference_Order_By>>;
  where?: InputMaybe<CalendarPreference_Bool_Exp>;
};


export type Subscription_RootCalendarPreference_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCalendarStartsOnArgs = {
  distinct_on?: InputMaybe<Array<CalendarStartsOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarStartsOn_Order_By>>;
  where?: InputMaybe<CalendarStartsOn_Bool_Exp>;
};


export type Subscription_RootCalendarStartsOn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalendarStartsOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalendarStartsOn_Order_By>>;
  where?: InputMaybe<CalendarStartsOn_Bool_Exp>;
};


export type Subscription_RootCalendarStartsOn_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCategoryArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Subscription_RootCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Subscription_RootCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootColorFamilyEnumArgs = {
  distinct_on?: InputMaybe<Array<ColorFamilyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ColorFamilyEnum_Order_By>>;
  where?: InputMaybe<ColorFamilyEnum_Bool_Exp>;
};


export type Subscription_RootColorFamilyEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ColorFamilyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ColorFamilyEnum_Order_By>>;
  where?: InputMaybe<ColorFamilyEnum_Bool_Exp>;
};


export type Subscription_RootColorFamilyEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootContact_SyncArgs = {
  distinct_on?: InputMaybe<Array<Contact_Sync_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Sync_Order_By>>;
  where?: InputMaybe<Contact_Sync_Bool_Exp>;
};


export type Subscription_RootContact_Sync_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Sync_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Sync_Order_By>>;
  where?: InputMaybe<Contact_Sync_Bool_Exp>;
};


export type Subscription_RootContact_Sync_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDatabasechangelogArgs = {
  distinct_on?: InputMaybe<Array<Databasechangelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangelog_Order_By>>;
  where?: InputMaybe<Databasechangelog_Bool_Exp>;
};


export type Subscription_RootDatabasechangelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Databasechangelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangelog_Order_By>>;
  where?: InputMaybe<Databasechangelog_Bool_Exp>;
};


export type Subscription_RootDatabasechangeloglockArgs = {
  distinct_on?: InputMaybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangeloglock_Order_By>>;
  where?: InputMaybe<Databasechangeloglock_Bool_Exp>;
};


export type Subscription_RootDatabasechangeloglock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Databasechangeloglock_Order_By>>;
  where?: InputMaybe<Databasechangeloglock_Bool_Exp>;
};


export type Subscription_RootDatabasechangeloglock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDateFormatArgs = {
  distinct_on?: InputMaybe<Array<DateFormat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DateFormat_Order_By>>;
  where?: InputMaybe<DateFormat_Bool_Exp>;
};


export type Subscription_RootDateFormat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DateFormat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DateFormat_Order_By>>;
  where?: InputMaybe<DateFormat_Bool_Exp>;
};


export type Subscription_RootDateFormat_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGoogle_CredentialArgs = {
  distinct_on?: InputMaybe<Array<Google_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Credential_Order_By>>;
  where?: InputMaybe<Google_Credential_Bool_Exp>;
};


export type Subscription_RootGoogle_Credential_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Google_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Credential_Order_By>>;
  where?: InputMaybe<Google_Credential_Bool_Exp>;
};


export type Subscription_RootGoogle_Credential_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLinear_IssueArgs = {
  distinct_on?: InputMaybe<Array<Linear_Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linear_Issue_Order_By>>;
  where?: InputMaybe<Linear_Issue_Bool_Exp>;
};


export type Subscription_RootLinear_Issue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Linear_Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linear_Issue_Order_By>>;
  where?: InputMaybe<Linear_Issue_Bool_Exp>;
};


export type Subscription_RootLinear_Issue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNew_ContactArgs = {
  distinct_on?: InputMaybe<Array<New_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Contact_Order_By>>;
  where?: InputMaybe<New_Contact_Bool_Exp>;
};


export type Subscription_RootNew_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Contact_Order_By>>;
  where?: InputMaybe<New_Contact_Bool_Exp>;
};


export type Subscription_RootNew_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNew_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<New_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Feedback_Order_By>>;
  where?: InputMaybe<New_Feedback_Bool_Exp>;
};


export type Subscription_RootNew_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Feedback_Order_By>>;
  where?: InputMaybe<New_Feedback_Bool_Exp>;
};


export type Subscription_RootNew_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNew_UserArgs = {
  distinct_on?: InputMaybe<Array<New_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_User_Order_By>>;
  where?: InputMaybe<New_User_Bool_Exp>;
};


export type Subscription_RootNew_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_User_Order_By>>;
  where?: InputMaybe<New_User_Bool_Exp>;
};


export type Subscription_RootNew_User_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootShareCalendarLinkArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLink_Order_By>>;
  where?: InputMaybe<ShareCalendarLink_Bool_Exp>;
};


export type Subscription_RootShareCalendarLinkTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Order_By>>;
  where?: InputMaybe<ShareCalendarLinkTypeEnum_Bool_Exp>;
};


export type Subscription_RootShareCalendarLinkTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLinkTypeEnum_Order_By>>;
  where?: InputMaybe<ShareCalendarLinkTypeEnum_Bool_Exp>;
};


export type Subscription_RootShareCalendarLinkTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootShareCalendarLink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ShareCalendarLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShareCalendarLink_Order_By>>;
  where?: InputMaybe<ShareCalendarLink_Bool_Exp>;
};


export type Subscription_RootShareCalendarLink_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootThemeArgs = {
  distinct_on?: InputMaybe<Array<Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Theme_Order_By>>;
  where?: InputMaybe<Theme_Bool_Exp>;
};


export type Subscription_RootTheme_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Theme_Order_By>>;
  where?: InputMaybe<Theme_Bool_Exp>;
};


export type Subscription_RootTheme_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTodoArgs = {
  distinct_on?: InputMaybe<Array<Todo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Order_By>>;
  where?: InputMaybe<Todo_Bool_Exp>;
};


export type Subscription_RootTodoTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<TodoTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TodoTypeEnum_Order_By>>;
  where?: InputMaybe<TodoTypeEnum_Bool_Exp>;
};


export type Subscription_RootTodoTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TodoTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TodoTypeEnum_Order_By>>;
  where?: InputMaybe<TodoTypeEnum_Bool_Exp>;
};


export type Subscription_RootTodoTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTodo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Todo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Todo_Order_By>>;
  where?: InputMaybe<Todo_Bool_Exp>;
};


export type Subscription_RootTodo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserActivityFeedArgs = {
  distinct_on?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserActivityFeed_Order_By>>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};


export type Subscription_RootUserActivityFeed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserActivityFeed_Order_By>>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};


export type Subscription_RootUserActivityFeed_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Subscription_RootUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Subscription_RootUserPreferences_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserStatusArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};


export type Subscription_RootUserStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};


export type Subscription_RootUserStatus_By_PkArgs = {
  userEmail: Scalars['String'];
};


export type Subscription_RootUser_AllowlistArgs = {
  distinct_on?: InputMaybe<Array<User_Allowlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Allowlist_Order_By>>;
  where?: InputMaybe<User_Allowlist_Bool_Exp>;
};


export type Subscription_RootUser_Allowlist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Allowlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Allowlist_Order_By>>;
  where?: InputMaybe<User_Allowlist_Bool_Exp>;
};


export type Subscription_RootUser_Allowlist_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideoProviderArgs = {
  distinct_on?: InputMaybe<Array<VideoProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VideoProvider_Order_By>>;
  where?: InputMaybe<VideoProvider_Bool_Exp>;
};


export type Subscription_RootVideoProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VideoProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VideoProvider_Order_By>>;
  where?: InputMaybe<VideoProvider_Bool_Exp>;
};


export type Subscription_RootVideoProvider_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "theme" */
export type Theme = {
  __typename?: 'theme';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "theme" */
export type Theme_Aggregate = {
  __typename?: 'theme_aggregate';
  aggregate?: Maybe<Theme_Aggregate_Fields>;
  nodes: Array<Theme>;
};

/** aggregate fields of "theme" */
export type Theme_Aggregate_Fields = {
  __typename?: 'theme_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Theme_Max_Fields>;
  min?: Maybe<Theme_Min_Fields>;
};


/** aggregate fields of "theme" */
export type Theme_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Theme_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "theme". All fields are combined with a logical 'AND'. */
export type Theme_Bool_Exp = {
  _and?: InputMaybe<Array<Theme_Bool_Exp>>;
  _not?: InputMaybe<Theme_Bool_Exp>;
  _or?: InputMaybe<Array<Theme_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "theme" */
export enum Theme_Constraint {
  /** unique or primary key constraint */
  ThemePkey = 'theme_pkey'
}

export enum Theme_Enum {
  /** Dark */
  Dark = 'DARK',
  /** Light */
  Light = 'LIGHT'
}

/** Boolean expression to compare columns of type "theme_enum". All fields are combined with logical 'AND'. */
export type Theme_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Theme_Enum>;
  _in?: InputMaybe<Array<Theme_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Theme_Enum>;
  _nin?: InputMaybe<Array<Theme_Enum>>;
};

/** input type for inserting data into table "theme" */
export type Theme_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Theme_Max_Fields = {
  __typename?: 'theme_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Theme_Min_Fields = {
  __typename?: 'theme_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "theme" */
export type Theme_Mutation_Response = {
  __typename?: 'theme_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Theme>;
};

/** on conflict condition type for table "theme" */
export type Theme_On_Conflict = {
  constraint: Theme_Constraint;
  update_columns?: Array<Theme_Update_Column>;
  where?: InputMaybe<Theme_Bool_Exp>;
};

/** Ordering options when selecting data from "theme". */
export type Theme_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: theme */
export type Theme_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "theme" */
export enum Theme_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "theme" */
export type Theme_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "theme" */
export enum Theme_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "todo" */
export type Todo = {
  __typename?: 'todo';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  doneAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  type?: Maybe<TodoTypeEnum_Enum>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<New_User>;
  userId: Scalars['String'];
};

/** columns and relationships of "todoTypeEnum" */
export type TodoTypeEnum = {
  __typename?: 'todoTypeEnum';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "todoTypeEnum" */
export type TodoTypeEnum_Aggregate = {
  __typename?: 'todoTypeEnum_aggregate';
  aggregate?: Maybe<TodoTypeEnum_Aggregate_Fields>;
  nodes: Array<TodoTypeEnum>;
};

/** aggregate fields of "todoTypeEnum" */
export type TodoTypeEnum_Aggregate_Fields = {
  __typename?: 'todoTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TodoTypeEnum_Max_Fields>;
  min?: Maybe<TodoTypeEnum_Min_Fields>;
};


/** aggregate fields of "todoTypeEnum" */
export type TodoTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TodoTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "todoTypeEnum". All fields are combined with a logical 'AND'. */
export type TodoTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<TodoTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<TodoTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<TodoTypeEnum_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "todoTypeEnum" */
export enum TodoTypeEnum_Constraint {
  /** unique or primary key constraint */
  TodoTypeEnumPkey = 'todoTypeEnum_pkey'
}

export enum TodoTypeEnum_Enum {
  /** Separator */
  Separator = 'SEPARATOR',
  /** Spacer */
  Spacer = 'SPACER',
  /** Todo */
  Todo = 'TODO'
}

/** Boolean expression to compare columns of type "todoTypeEnum_enum". All fields are combined with logical 'AND'. */
export type TodoTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<TodoTypeEnum_Enum>;
  _in?: InputMaybe<Array<TodoTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TodoTypeEnum_Enum>;
  _nin?: InputMaybe<Array<TodoTypeEnum_Enum>>;
};

/** input type for inserting data into table "todoTypeEnum" */
export type TodoTypeEnum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TodoTypeEnum_Max_Fields = {
  __typename?: 'todoTypeEnum_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TodoTypeEnum_Min_Fields = {
  __typename?: 'todoTypeEnum_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "todoTypeEnum" */
export type TodoTypeEnum_Mutation_Response = {
  __typename?: 'todoTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TodoTypeEnum>;
};

/** on conflict condition type for table "todoTypeEnum" */
export type TodoTypeEnum_On_Conflict = {
  constraint: TodoTypeEnum_Constraint;
  update_columns?: Array<TodoTypeEnum_Update_Column>;
  where?: InputMaybe<TodoTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "todoTypeEnum". */
export type TodoTypeEnum_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: todoTypeEnum */
export type TodoTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "todoTypeEnum" */
export enum TodoTypeEnum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "todoTypeEnum" */
export type TodoTypeEnum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "todoTypeEnum" */
export enum TodoTypeEnum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** aggregated selection of "todo" */
export type Todo_Aggregate = {
  __typename?: 'todo_aggregate';
  aggregate?: Maybe<Todo_Aggregate_Fields>;
  nodes: Array<Todo>;
};

/** aggregate fields of "todo" */
export type Todo_Aggregate_Fields = {
  __typename?: 'todo_aggregate_fields';
  avg?: Maybe<Todo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Todo_Max_Fields>;
  min?: Maybe<Todo_Min_Fields>;
  stddev?: Maybe<Todo_Stddev_Fields>;
  stddev_pop?: Maybe<Todo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Todo_Stddev_Samp_Fields>;
  sum?: Maybe<Todo_Sum_Fields>;
  var_pop?: Maybe<Todo_Var_Pop_Fields>;
  var_samp?: Maybe<Todo_Var_Samp_Fields>;
  variance?: Maybe<Todo_Variance_Fields>;
};


/** aggregate fields of "todo" */
export type Todo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Todo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "todo" */
export type Todo_Aggregate_Order_By = {
  avg?: InputMaybe<Todo_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Todo_Max_Order_By>;
  min?: InputMaybe<Todo_Min_Order_By>;
  stddev?: InputMaybe<Todo_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Todo_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Todo_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Todo_Sum_Order_By>;
  var_pop?: InputMaybe<Todo_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Todo_Var_Samp_Order_By>;
  variance?: InputMaybe<Todo_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "todo" */
export type Todo_Arr_Rel_Insert_Input = {
  data: Array<Todo_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Todo_On_Conflict>;
};

/** aggregate avg on columns */
export type Todo_Avg_Fields = {
  __typename?: 'todo_avg_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "todo" */
export type Todo_Avg_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "todo". All fields are combined with a logical 'AND'. */
export type Todo_Bool_Exp = {
  _and?: InputMaybe<Array<Todo_Bool_Exp>>;
  _not?: InputMaybe<Todo_Bool_Exp>;
  _or?: InputMaybe<Array<Todo_Bool_Exp>>;
  archivedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  category?: InputMaybe<Category_Bool_Exp>;
  categoryId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  doneAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  lastClientUpdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<TodoTypeEnum_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "todo" */
export enum Todo_Constraint {
  /** unique or primary key constraint */
  TodoPkey = 'todo_pkey'
}

/** input type for incrementing numeric columns in table "todo" */
export type Todo_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "todo" */
export type Todo_Insert_Input = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  category?: InputMaybe<Category_Obj_Rel_Insert_Input>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  doneAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  lastClientUpdate?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<TodoTypeEnum_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Todo_Max_Fields = {
  __typename?: 'todo_max_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  categoryId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  doneAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "todo" */
export type Todo_Max_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  doneAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lastClientUpdate?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Todo_Min_Fields = {
  __typename?: 'todo_min_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  categoryId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  doneAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  lastClientUpdate?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "todo" */
export type Todo_Min_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  doneAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lastClientUpdate?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "todo" */
export type Todo_Mutation_Response = {
  __typename?: 'todo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Todo>;
};

/** on conflict condition type for table "todo" */
export type Todo_On_Conflict = {
  constraint: Todo_Constraint;
  update_columns?: Array<Todo_Update_Column>;
  where?: InputMaybe<Todo_Bool_Exp>;
};

/** Ordering options when selecting data from "todo". */
export type Todo_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  category?: InputMaybe<Category_Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  doneAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lastClientUpdate?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: todo */
export type Todo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "todo" */
export enum Todo_Select_Column {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DoneAt = 'doneAt',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LastClientUpdate = 'lastClientUpdate',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "todo" */
export type Todo_Set_Input = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  doneAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  lastClientUpdate?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<TodoTypeEnum_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Todo_Stddev_Fields = {
  __typename?: 'todo_stddev_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "todo" */
export type Todo_Stddev_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Todo_Stddev_Pop_Fields = {
  __typename?: 'todo_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "todo" */
export type Todo_Stddev_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Todo_Stddev_Samp_Fields = {
  __typename?: 'todo_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "todo" */
export type Todo_Stddev_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Todo_Sum_Fields = {
  __typename?: 'todo_sum_fields';
  index?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "todo" */
export type Todo_Sum_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** update columns of table "todo" */
export enum Todo_Update_Column {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  DoneAt = 'doneAt',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LastClientUpdate = 'lastClientUpdate',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Todo_Var_Pop_Fields = {
  __typename?: 'todo_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "todo" */
export type Todo_Var_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Todo_Var_Samp_Fields = {
  __typename?: 'todo_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "todo" */
export type Todo_Var_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Todo_Variance_Fields = {
  __typename?: 'todo_variance_fields';
  index?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "todo" */
export type Todo_Variance_Order_By = {
  index?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "userActivityFeed" */
export type UserActivityFeed = {
  __typename?: 'userActivityFeed';
  /** An object relationship */
  activityFeed: ActivityFeed;
  activityFeedId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: New_User;
  userId: Scalars['String'];
};

/** aggregated selection of "userActivityFeed" */
export type UserActivityFeed_Aggregate = {
  __typename?: 'userActivityFeed_aggregate';
  aggregate?: Maybe<UserActivityFeed_Aggregate_Fields>;
  nodes: Array<UserActivityFeed>;
};

/** aggregate fields of "userActivityFeed" */
export type UserActivityFeed_Aggregate_Fields = {
  __typename?: 'userActivityFeed_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserActivityFeed_Max_Fields>;
  min?: Maybe<UserActivityFeed_Min_Fields>;
};


/** aggregate fields of "userActivityFeed" */
export type UserActivityFeed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserActivityFeed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userActivityFeed" */
export type UserActivityFeed_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserActivityFeed_Max_Order_By>;
  min?: InputMaybe<UserActivityFeed_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userActivityFeed" */
export type UserActivityFeed_Arr_Rel_Insert_Input = {
  data: Array<UserActivityFeed_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<UserActivityFeed_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userActivityFeed". All fields are combined with a logical 'AND'. */
export type UserActivityFeed_Bool_Exp = {
  _and?: InputMaybe<Array<UserActivityFeed_Bool_Exp>>;
  _not?: InputMaybe<UserActivityFeed_Bool_Exp>;
  _or?: InputMaybe<Array<UserActivityFeed_Bool_Exp>>;
  activityFeed?: InputMaybe<ActivityFeed_Bool_Exp>;
  activityFeedId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "userActivityFeed" */
export enum UserActivityFeed_Constraint {
  /** unique or primary key constraint */
  UserActivityFeedPkey = 'userActivityFeed_pkey'
}

/** input type for inserting data into table "userActivityFeed" */
export type UserActivityFeed_Insert_Input = {
  activityFeed?: InputMaybe<ActivityFeed_Obj_Rel_Insert_Input>;
  activityFeedId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserActivityFeed_Max_Fields = {
  __typename?: 'userActivityFeed_max_fields';
  activityFeedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userActivityFeed" */
export type UserActivityFeed_Max_Order_By = {
  activityFeedId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserActivityFeed_Min_Fields = {
  __typename?: 'userActivityFeed_min_fields';
  activityFeedId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userActivityFeed" */
export type UserActivityFeed_Min_Order_By = {
  activityFeedId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "userActivityFeed" */
export type UserActivityFeed_Mutation_Response = {
  __typename?: 'userActivityFeed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserActivityFeed>;
};

/** on conflict condition type for table "userActivityFeed" */
export type UserActivityFeed_On_Conflict = {
  constraint: UserActivityFeed_Constraint;
  update_columns?: Array<UserActivityFeed_Update_Column>;
  where?: InputMaybe<UserActivityFeed_Bool_Exp>;
};

/** Ordering options when selecting data from "userActivityFeed". */
export type UserActivityFeed_Order_By = {
  activityFeed?: InputMaybe<ActivityFeed_Order_By>;
  activityFeedId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userActivityFeed */
export type UserActivityFeed_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userActivityFeed" */
export enum UserActivityFeed_Select_Column {
  /** column name */
  ActivityFeedId = 'activityFeedId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userActivityFeed" */
export type UserActivityFeed_Set_Input = {
  activityFeedId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "userActivityFeed" */
export enum UserActivityFeed_Update_Column {
  /** column name */
  ActivityFeedId = 'activityFeedId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "userPreferences" */
export type UserPreferences = {
  __typename?: 'userPreferences';
  aroundLink?: Maybe<Scalars['String']>;
  calendarStartsOn?: Maybe<CalendarStartsOn_Enum>;
  createdAt: Scalars['timestamptz'];
  dateFormat?: Maybe<DateFormat_Enum>;
  defaultDuration: Scalars['Int'];
  defaultEventColor: ColorFamilyEnum_Enum;
  hasOnboarded: Scalars['Boolean'];
  hideDeclinedEvents?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  showArchivedLists: Scalars['Boolean'];
  showDoneTodos: Scalars['Boolean'];
  theme?: Maybe<Theme_Enum>;
  timezone?: Maybe<Scalars['String']>;
  todoPrivacy: Scalars['Boolean'];
  ui24HourClock: Scalars['Boolean'];
  uiCalendarTargetDate?: Maybe<Scalars['String']>;
  uiHideWeekend?: Maybe<Scalars['Boolean']>;
  uiVisibleCalendarsId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<New_User>;
  userId: Scalars['String'];
  videoProvider?: Maybe<VideoProvider_Enum>;
  workHoursEnabled: Scalars['Boolean'];
  workHoursEndTime?: Maybe<Scalars['String']>;
  workHoursStartTime?: Maybe<Scalars['String']>;
  zoomLink?: Maybe<Scalars['String']>;
};

/** aggregated selection of "userPreferences" */
export type UserPreferences_Aggregate = {
  __typename?: 'userPreferences_aggregate';
  aggregate?: Maybe<UserPreferences_Aggregate_Fields>;
  nodes: Array<UserPreferences>;
};

/** aggregate fields of "userPreferences" */
export type UserPreferences_Aggregate_Fields = {
  __typename?: 'userPreferences_aggregate_fields';
  avg?: Maybe<UserPreferences_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserPreferences_Max_Fields>;
  min?: Maybe<UserPreferences_Min_Fields>;
  stddev?: Maybe<UserPreferences_Stddev_Fields>;
  stddev_pop?: Maybe<UserPreferences_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserPreferences_Stddev_Samp_Fields>;
  sum?: Maybe<UserPreferences_Sum_Fields>;
  var_pop?: Maybe<UserPreferences_Var_Pop_Fields>;
  var_samp?: Maybe<UserPreferences_Var_Samp_Fields>;
  variance?: Maybe<UserPreferences_Variance_Fields>;
};


/** aggregate fields of "userPreferences" */
export type UserPreferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserPreferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserPreferences_Avg_Fields = {
  __typename?: 'userPreferences_avg_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "userPreferences". All fields are combined with a logical 'AND'. */
export type UserPreferences_Bool_Exp = {
  _and?: InputMaybe<Array<UserPreferences_Bool_Exp>>;
  _not?: InputMaybe<UserPreferences_Bool_Exp>;
  _or?: InputMaybe<Array<UserPreferences_Bool_Exp>>;
  aroundLink?: InputMaybe<String_Comparison_Exp>;
  calendarStartsOn?: InputMaybe<CalendarStartsOn_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dateFormat?: InputMaybe<DateFormat_Enum_Comparison_Exp>;
  defaultDuration?: InputMaybe<Int_Comparison_Exp>;
  defaultEventColor?: InputMaybe<ColorFamilyEnum_Enum_Comparison_Exp>;
  hasOnboarded?: InputMaybe<Boolean_Comparison_Exp>;
  hideDeclinedEvents?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  showArchivedLists?: InputMaybe<Boolean_Comparison_Exp>;
  showDoneTodos?: InputMaybe<Boolean_Comparison_Exp>;
  theme?: InputMaybe<Theme_Enum_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  todoPrivacy?: InputMaybe<Boolean_Comparison_Exp>;
  ui24HourClock?: InputMaybe<Boolean_Comparison_Exp>;
  uiCalendarTargetDate?: InputMaybe<String_Comparison_Exp>;
  uiHideWeekend?: InputMaybe<Boolean_Comparison_Exp>;
  uiVisibleCalendarsId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<New_User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  videoProvider?: InputMaybe<VideoProvider_Enum_Comparison_Exp>;
  workHoursEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  workHoursEndTime?: InputMaybe<String_Comparison_Exp>;
  workHoursStartTime?: InputMaybe<String_Comparison_Exp>;
  zoomLink?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "userPreferences" */
export enum UserPreferences_Constraint {
  /** unique or primary key constraint */
  UserPreferencesUserIdKey = 'userPreferences_userId_key',
  /** unique or primary key constraint */
  UserPreferencePkey = 'user_preference_pkey'
}

/** input type for incrementing numeric columns in table "userPreferences" */
export type UserPreferences_Inc_Input = {
  defaultDuration?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "userPreferences" */
export type UserPreferences_Insert_Input = {
  aroundLink?: InputMaybe<Scalars['String']>;
  calendarStartsOn?: InputMaybe<CalendarStartsOn_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateFormat?: InputMaybe<DateFormat_Enum>;
  defaultDuration?: InputMaybe<Scalars['Int']>;
  defaultEventColor?: InputMaybe<ColorFamilyEnum_Enum>;
  hasOnboarded?: InputMaybe<Scalars['Boolean']>;
  hideDeclinedEvents?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  showArchivedLists?: InputMaybe<Scalars['Boolean']>;
  showDoneTodos?: InputMaybe<Scalars['Boolean']>;
  theme?: InputMaybe<Theme_Enum>;
  timezone?: InputMaybe<Scalars['String']>;
  todoPrivacy?: InputMaybe<Scalars['Boolean']>;
  ui24HourClock?: InputMaybe<Scalars['Boolean']>;
  uiCalendarTargetDate?: InputMaybe<Scalars['String']>;
  uiHideWeekend?: InputMaybe<Scalars['Boolean']>;
  uiVisibleCalendarsId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<New_User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']>;
  videoProvider?: InputMaybe<VideoProvider_Enum>;
  workHoursEnabled?: InputMaybe<Scalars['Boolean']>;
  workHoursEndTime?: InputMaybe<Scalars['String']>;
  workHoursStartTime?: InputMaybe<Scalars['String']>;
  zoomLink?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserPreferences_Max_Fields = {
  __typename?: 'userPreferences_max_fields';
  aroundLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultDuration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  timezone?: Maybe<Scalars['String']>;
  uiCalendarTargetDate?: Maybe<Scalars['String']>;
  uiVisibleCalendarsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  workHoursEndTime?: Maybe<Scalars['String']>;
  workHoursStartTime?: Maybe<Scalars['String']>;
  zoomLink?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserPreferences_Min_Fields = {
  __typename?: 'userPreferences_min_fields';
  aroundLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultDuration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  timezone?: Maybe<Scalars['String']>;
  uiCalendarTargetDate?: Maybe<Scalars['String']>;
  uiVisibleCalendarsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  workHoursEndTime?: Maybe<Scalars['String']>;
  workHoursStartTime?: Maybe<Scalars['String']>;
  zoomLink?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "userPreferences" */
export type UserPreferences_Mutation_Response = {
  __typename?: 'userPreferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserPreferences>;
};

/** input type for inserting object relation for remote table "userPreferences" */
export type UserPreferences_Obj_Rel_Insert_Input = {
  data: UserPreferences_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};

/** on conflict condition type for table "userPreferences" */
export type UserPreferences_On_Conflict = {
  constraint: UserPreferences_Constraint;
  update_columns?: Array<UserPreferences_Update_Column>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};

/** Ordering options when selecting data from "userPreferences". */
export type UserPreferences_Order_By = {
  aroundLink?: InputMaybe<Order_By>;
  calendarStartsOn?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateFormat?: InputMaybe<Order_By>;
  defaultDuration?: InputMaybe<Order_By>;
  defaultEventColor?: InputMaybe<Order_By>;
  hasOnboarded?: InputMaybe<Order_By>;
  hideDeclinedEvents?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  showArchivedLists?: InputMaybe<Order_By>;
  showDoneTodos?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  todoPrivacy?: InputMaybe<Order_By>;
  ui24HourClock?: InputMaybe<Order_By>;
  uiCalendarTargetDate?: InputMaybe<Order_By>;
  uiHideWeekend?: InputMaybe<Order_By>;
  uiVisibleCalendarsId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<New_User_Order_By>;
  userId?: InputMaybe<Order_By>;
  videoProvider?: InputMaybe<Order_By>;
  workHoursEnabled?: InputMaybe<Order_By>;
  workHoursEndTime?: InputMaybe<Order_By>;
  workHoursStartTime?: InputMaybe<Order_By>;
  zoomLink?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userPreferences */
export type UserPreferences_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userPreferences" */
export enum UserPreferences_Select_Column {
  /** column name */
  AroundLink = 'aroundLink',
  /** column name */
  CalendarStartsOn = 'calendarStartsOn',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateFormat = 'dateFormat',
  /** column name */
  DefaultDuration = 'defaultDuration',
  /** column name */
  DefaultEventColor = 'defaultEventColor',
  /** column name */
  HasOnboarded = 'hasOnboarded',
  /** column name */
  HideDeclinedEvents = 'hideDeclinedEvents',
  /** column name */
  Id = 'id',
  /** column name */
  ShowArchivedLists = 'showArchivedLists',
  /** column name */
  ShowDoneTodos = 'showDoneTodos',
  /** column name */
  Theme = 'theme',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TodoPrivacy = 'todoPrivacy',
  /** column name */
  Ui24HourClock = 'ui24HourClock',
  /** column name */
  UiCalendarTargetDate = 'uiCalendarTargetDate',
  /** column name */
  UiHideWeekend = 'uiHideWeekend',
  /** column name */
  UiVisibleCalendarsId = 'uiVisibleCalendarsId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoProvider = 'videoProvider',
  /** column name */
  WorkHoursEnabled = 'workHoursEnabled',
  /** column name */
  WorkHoursEndTime = 'workHoursEndTime',
  /** column name */
  WorkHoursStartTime = 'workHoursStartTime',
  /** column name */
  ZoomLink = 'zoomLink'
}

/** input type for updating data in table "userPreferences" */
export type UserPreferences_Set_Input = {
  aroundLink?: InputMaybe<Scalars['String']>;
  calendarStartsOn?: InputMaybe<CalendarStartsOn_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateFormat?: InputMaybe<DateFormat_Enum>;
  defaultDuration?: InputMaybe<Scalars['Int']>;
  defaultEventColor?: InputMaybe<ColorFamilyEnum_Enum>;
  hasOnboarded?: InputMaybe<Scalars['Boolean']>;
  hideDeclinedEvents?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  showArchivedLists?: InputMaybe<Scalars['Boolean']>;
  showDoneTodos?: InputMaybe<Scalars['Boolean']>;
  theme?: InputMaybe<Theme_Enum>;
  timezone?: InputMaybe<Scalars['String']>;
  todoPrivacy?: InputMaybe<Scalars['Boolean']>;
  ui24HourClock?: InputMaybe<Scalars['Boolean']>;
  uiCalendarTargetDate?: InputMaybe<Scalars['String']>;
  uiHideWeekend?: InputMaybe<Scalars['Boolean']>;
  uiVisibleCalendarsId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
  videoProvider?: InputMaybe<VideoProvider_Enum>;
  workHoursEnabled?: InputMaybe<Scalars['Boolean']>;
  workHoursEndTime?: InputMaybe<Scalars['String']>;
  workHoursStartTime?: InputMaybe<Scalars['String']>;
  zoomLink?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserPreferences_Stddev_Fields = {
  __typename?: 'userPreferences_stddev_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserPreferences_Stddev_Pop_Fields = {
  __typename?: 'userPreferences_stddev_pop_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserPreferences_Stddev_Samp_Fields = {
  __typename?: 'userPreferences_stddev_samp_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UserPreferences_Sum_Fields = {
  __typename?: 'userPreferences_sum_fields';
  defaultDuration?: Maybe<Scalars['Int']>;
};

/** update columns of table "userPreferences" */
export enum UserPreferences_Update_Column {
  /** column name */
  AroundLink = 'aroundLink',
  /** column name */
  CalendarStartsOn = 'calendarStartsOn',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateFormat = 'dateFormat',
  /** column name */
  DefaultDuration = 'defaultDuration',
  /** column name */
  DefaultEventColor = 'defaultEventColor',
  /** column name */
  HasOnboarded = 'hasOnboarded',
  /** column name */
  HideDeclinedEvents = 'hideDeclinedEvents',
  /** column name */
  Id = 'id',
  /** column name */
  ShowArchivedLists = 'showArchivedLists',
  /** column name */
  ShowDoneTodos = 'showDoneTodos',
  /** column name */
  Theme = 'theme',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TodoPrivacy = 'todoPrivacy',
  /** column name */
  Ui24HourClock = 'ui24HourClock',
  /** column name */
  UiCalendarTargetDate = 'uiCalendarTargetDate',
  /** column name */
  UiHideWeekend = 'uiHideWeekend',
  /** column name */
  UiVisibleCalendarsId = 'uiVisibleCalendarsId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VideoProvider = 'videoProvider',
  /** column name */
  WorkHoursEnabled = 'workHoursEnabled',
  /** column name */
  WorkHoursEndTime = 'workHoursEndTime',
  /** column name */
  WorkHoursStartTime = 'workHoursStartTime',
  /** column name */
  ZoomLink = 'zoomLink'
}

/** aggregate var_pop on columns */
export type UserPreferences_Var_Pop_Fields = {
  __typename?: 'userPreferences_var_pop_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserPreferences_Var_Samp_Fields = {
  __typename?: 'userPreferences_var_samp_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserPreferences_Variance_Fields = {
  __typename?: 'userPreferences_variance_fields';
  defaultDuration?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "userStatus" */
export type UserStatus = {
  __typename?: 'userStatus';
  clearStatusCronJobId?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieEndAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieEventId?: Maybe<Scalars['String']>;
  integrationAmieStartAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieTitle?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  userEmail: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "userStatus" */
export type UserStatus_Aggregate = {
  __typename?: 'userStatus_aggregate';
  aggregate?: Maybe<UserStatus_Aggregate_Fields>;
  nodes: Array<UserStatus>;
};

/** aggregate fields of "userStatus" */
export type UserStatus_Aggregate_Fields = {
  __typename?: 'userStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserStatus_Max_Fields>;
  min?: Maybe<UserStatus_Min_Fields>;
};


/** aggregate fields of "userStatus" */
export type UserStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "userStatus". All fields are combined with a logical 'AND'. */
export type UserStatus_Bool_Exp = {
  _and?: InputMaybe<Array<UserStatus_Bool_Exp>>;
  _not?: InputMaybe<UserStatus_Bool_Exp>;
  _or?: InputMaybe<Array<UserStatus_Bool_Exp>>;
  clearStatusCronJobId?: InputMaybe<String_Comparison_Exp>;
  emoji?: InputMaybe<String_Comparison_Exp>;
  endAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  integrationAmieEndAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  integrationAmieEventId?: InputMaybe<String_Comparison_Exp>;
  integrationAmieStartAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  integrationAmieTitle?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  startAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  userEmail?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "userStatus" */
export enum UserStatus_Constraint {
  /** unique or primary key constraint */
  UserStatusPkey = 'userStatus_pkey',
  /** unique or primary key constraint */
  UserStatusUserIdKey = 'userStatus_userId_key'
}

/** input type for inserting data into table "userStatus" */
export type UserStatus_Insert_Input = {
  clearStatusCronJobId?: InputMaybe<Scalars['String']>;
  emoji?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['timestamptz']>;
  integrationAmieEndAt?: InputMaybe<Scalars['timestamptz']>;
  integrationAmieEventId?: InputMaybe<Scalars['String']>;
  integrationAmieStartAt?: InputMaybe<Scalars['timestamptz']>;
  integrationAmieTitle?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserStatus_Max_Fields = {
  __typename?: 'userStatus_max_fields';
  clearStatusCronJobId?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieEndAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieEventId?: Maybe<Scalars['String']>;
  integrationAmieStartAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieTitle?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserStatus_Min_Fields = {
  __typename?: 'userStatus_min_fields';
  clearStatusCronJobId?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieEndAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieEventId?: Maybe<Scalars['String']>;
  integrationAmieStartAt?: Maybe<Scalars['timestamptz']>;
  integrationAmieTitle?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "userStatus" */
export type UserStatus_Mutation_Response = {
  __typename?: 'userStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserStatus>;
};

/** input type for inserting object relation for remote table "userStatus" */
export type UserStatus_Obj_Rel_Insert_Input = {
  data: UserStatus_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<UserStatus_On_Conflict>;
};

/** on conflict condition type for table "userStatus" */
export type UserStatus_On_Conflict = {
  constraint: UserStatus_Constraint;
  update_columns?: Array<UserStatus_Update_Column>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "userStatus". */
export type UserStatus_Order_By = {
  clearStatusCronJobId?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  endAt?: InputMaybe<Order_By>;
  integrationAmieEndAt?: InputMaybe<Order_By>;
  integrationAmieEventId?: InputMaybe<Order_By>;
  integrationAmieStartAt?: InputMaybe<Order_By>;
  integrationAmieTitle?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  startAt?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userEmail?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userStatus */
export type UserStatus_Pk_Columns_Input = {
  userEmail: Scalars['String'];
};

/** select columns of table "userStatus" */
export enum UserStatus_Select_Column {
  /** column name */
  ClearStatusCronJobId = 'clearStatusCronJobId',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  EndAt = 'endAt',
  /** column name */
  IntegrationAmieEndAt = 'integrationAmieEndAt',
  /** column name */
  IntegrationAmieEventId = 'integrationAmieEventId',
  /** column name */
  IntegrationAmieStartAt = 'integrationAmieStartAt',
  /** column name */
  IntegrationAmieTitle = 'integrationAmieTitle',
  /** column name */
  Message = 'message',
  /** column name */
  StartAt = 'startAt',
  /** column name */
  Status = 'status',
  /** column name */
  UserEmail = 'userEmail',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userStatus" */
export type UserStatus_Set_Input = {
  clearStatusCronJobId?: InputMaybe<Scalars['String']>;
  emoji?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['timestamptz']>;
  integrationAmieEndAt?: InputMaybe<Scalars['timestamptz']>;
  integrationAmieEventId?: InputMaybe<Scalars['String']>;
  integrationAmieStartAt?: InputMaybe<Scalars['timestamptz']>;
  integrationAmieTitle?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "userStatus" */
export enum UserStatus_Update_Column {
  /** column name */
  ClearStatusCronJobId = 'clearStatusCronJobId',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  EndAt = 'endAt',
  /** column name */
  IntegrationAmieEndAt = 'integrationAmieEndAt',
  /** column name */
  IntegrationAmieEventId = 'integrationAmieEventId',
  /** column name */
  IntegrationAmieStartAt = 'integrationAmieStartAt',
  /** column name */
  IntegrationAmieTitle = 'integrationAmieTitle',
  /** column name */
  Message = 'message',
  /** column name */
  StartAt = 'startAt',
  /** column name */
  Status = 'status',
  /** column name */
  UserEmail = 'userEmail',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "user_allowlist" */
export type User_Allowlist = {
  __typename?: 'user_allowlist';
  created_at: Scalars['timestamptz'];
  email_address: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user_allowlist" */
export type User_Allowlist_Aggregate = {
  __typename?: 'user_allowlist_aggregate';
  aggregate?: Maybe<User_Allowlist_Aggregate_Fields>;
  nodes: Array<User_Allowlist>;
};

/** aggregate fields of "user_allowlist" */
export type User_Allowlist_Aggregate_Fields = {
  __typename?: 'user_allowlist_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Allowlist_Max_Fields>;
  min?: Maybe<User_Allowlist_Min_Fields>;
};


/** aggregate fields of "user_allowlist" */
export type User_Allowlist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Allowlist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_allowlist". All fields are combined with a logical 'AND'. */
export type User_Allowlist_Bool_Exp = {
  _and?: InputMaybe<Array<User_Allowlist_Bool_Exp>>;
  _not?: InputMaybe<User_Allowlist_Bool_Exp>;
  _or?: InputMaybe<Array<User_Allowlist_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_allowlist" */
export enum User_Allowlist_Constraint {
  /** unique or primary key constraint */
  UserAllowlistEmailAddressKey = 'user_allowlist_email_address_key',
  /** unique or primary key constraint */
  UserAllowlistPkey = 'user_allowlist_pkey'
}

/** input type for inserting data into table "user_allowlist" */
export type User_Allowlist_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Allowlist_Max_Fields = {
  __typename?: 'user_allowlist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Allowlist_Min_Fields = {
  __typename?: 'user_allowlist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user_allowlist" */
export type User_Allowlist_Mutation_Response = {
  __typename?: 'user_allowlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Allowlist>;
};

/** on conflict condition type for table "user_allowlist" */
export type User_Allowlist_On_Conflict = {
  constraint: User_Allowlist_Constraint;
  update_columns?: Array<User_Allowlist_Update_Column>;
  where?: InputMaybe<User_Allowlist_Bool_Exp>;
};

/** Ordering options when selecting data from "user_allowlist". */
export type User_Allowlist_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_allowlist */
export type User_Allowlist_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_allowlist" */
export enum User_Allowlist_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_allowlist" */
export type User_Allowlist_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user_allowlist" */
export enum User_Allowlist_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "videoProvider" */
export type VideoProvider = {
  __typename?: 'videoProvider';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "videoProvider" */
export type VideoProvider_Aggregate = {
  __typename?: 'videoProvider_aggregate';
  aggregate?: Maybe<VideoProvider_Aggregate_Fields>;
  nodes: Array<VideoProvider>;
};

/** aggregate fields of "videoProvider" */
export type VideoProvider_Aggregate_Fields = {
  __typename?: 'videoProvider_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VideoProvider_Max_Fields>;
  min?: Maybe<VideoProvider_Min_Fields>;
};


/** aggregate fields of "videoProvider" */
export type VideoProvider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VideoProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "videoProvider". All fields are combined with a logical 'AND'. */
export type VideoProvider_Bool_Exp = {
  _and?: InputMaybe<Array<VideoProvider_Bool_Exp>>;
  _not?: InputMaybe<VideoProvider_Bool_Exp>;
  _or?: InputMaybe<Array<VideoProvider_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "videoProvider" */
export enum VideoProvider_Constraint {
  /** unique or primary key constraint */
  VideoProviderPkey = 'videoProvider_pkey'
}

export enum VideoProvider_Enum {
  /** Around */
  Around = 'AROUND',
  /** Meet */
  Meet = 'MEET',
  /** None */
  None = 'NONE',
  /** Teams */
  Teams = 'TEAMS',
  /** Whereby */
  Whereby = 'WHEREBY',
  /** Zoom */
  Zoom = 'ZOOM'
}

/** Boolean expression to compare columns of type "videoProvider_enum". All fields are combined with logical 'AND'. */
export type VideoProvider_Enum_Comparison_Exp = {
  _eq?: InputMaybe<VideoProvider_Enum>;
  _in?: InputMaybe<Array<VideoProvider_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<VideoProvider_Enum>;
  _nin?: InputMaybe<Array<VideoProvider_Enum>>;
};

/** input type for inserting data into table "videoProvider" */
export type VideoProvider_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type VideoProvider_Max_Fields = {
  __typename?: 'videoProvider_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type VideoProvider_Min_Fields = {
  __typename?: 'videoProvider_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "videoProvider" */
export type VideoProvider_Mutation_Response = {
  __typename?: 'videoProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VideoProvider>;
};

/** on conflict condition type for table "videoProvider" */
export type VideoProvider_On_Conflict = {
  constraint: VideoProvider_Constraint;
  update_columns?: Array<VideoProvider_Update_Column>;
  where?: InputMaybe<VideoProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "videoProvider". */
export type VideoProvider_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: videoProvider */
export type VideoProvider_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "videoProvider" */
export enum VideoProvider_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "videoProvider" */
export type VideoProvider_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "videoProvider" */
export enum VideoProvider_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccessRole: AccessRole;
  AttendeeAvailability: ResolverTypeWrapper<AttendeeAvailability>;
  Availability: Availability;
  Bestie: ResolverTypeWrapper<Bestie>;
  Birthday: ResolverTypeWrapper<Birthday>;
  BirthdaysViewInput: BirthdaysViewInput;
  BirthdaysViewOutput: ResolverTypeWrapper<BirthdaysViewOutput>;
  BookCalendarSlotInput: BookCalendarSlotInput;
  BookCalendarSlotOutput: ResolverTypeWrapper<BookCalendarSlotOutput>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  CalendarOwner: ResolverTypeWrapper<CalendarOwner>;
  CalendarRole: ResolverTypeWrapper<CalendarRole>;
  CalendarSlot: ResolverTypeWrapper<CalendarSlot>;
  CalendarSlotInput: CalendarSlotInput;
  CategoryUpsertInput: CategoryUpsertInput;
  CheckAliasAvailabilityOutput: ResolverTypeWrapper<CheckAliasAvailabilityOutput>;
  ClearEventsCacheInput: ClearEventsCacheInput;
  ClearEventsCacheOutput: ResolverTypeWrapper<ClearEventsCacheOutput>;
  ColorFamily: ColorFamily;
  ContactReminderOutput: ResolverTypeWrapper<ContactReminderOutput>;
  CreateCalendarLinkInput: CreateCalendarLinkInput;
  CreateCalendarLinkOutput: ResolverTypeWrapper<CreateCalendarLinkOutput>;
  CreateContactsInput: CreateContactsInput;
  CreateContactsOutput: ResolverTypeWrapper<CreateContactsOutput>;
  CreateEventInput: CreateEventInput;
  CreateEventOutput: ResolverTypeWrapper<CreateEventOutput>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DateTimeRFC3339: ResolverTypeWrapper<Scalars['DateTimeRFC3339']>;
  DeleteEventFilter: DeleteEventFilter;
  DeleteEventOutput: ResolverTypeWrapper<DeleteEventOutput>;
  DisplayOptions: ResolverTypeWrapper<DisplayOptions>;
  EventCreator: ResolverTypeWrapper<EventCreator>;
  EventsResponse: ResolverTypeWrapper<EventsResponse>;
  Feedback: ResolverTypeWrapper<Feedback>;
  FindNextAvailableSlotArgs: FindNextAvailableSlotArgs;
  FindNextAvailableSlotResponse: ResolverTypeWrapper<FindNextAvailableSlotResponse>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GMapsPlace: ResolverTypeWrapper<GMapsPlace>;
  GetGMapsPlacesInput: GetGMapsPlacesInput;
  GetGMapsPlacesOutput: ResolverTypeWrapper<GetGMapsPlacesOutput>;
  HideCalendarInput: HideCalendarInput;
  HideCalendarOutput: ResolverTypeWrapper<HideCalendarOutput>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  InCalendarsFilter: InCalendarsFilter;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int_comparison_exp: Int_Comparison_Exp;
  LightweightEventsFilter: LightweightEventsFilter;
  MinimalCalendarInfo: ResolverTypeWrapper<MinimalCalendarInfo>;
  NewCalendar: ResolverTypeWrapper<NewCalendar>;
  NewCalendarFilter: NewCalendarFilter;
  NewEvent: ResolverTypeWrapper<NewEvent>;
  NewEventAttendee: ResolverTypeWrapper<NewEventAttendee>;
  NewEventAttendeeResponseStatusEnum: NewEventAttendeeResponseStatusEnum;
  NewEventFilter: NewEventFilter;
  NewEventRSVPEnum: NewEventRsvpEnum;
  NewEventVideoConference: ResolverTypeWrapper<NewEventVideoConference>;
  NewEventVisibilityEnum: NewEventVisibilityEnum;
  NewVideoConferenceProvider: NewVideoConferenceProvider;
  ProfileMeetInfoInput: ProfileMeetInfoInput;
  ProfileMeetInfoOutput: ResolverTypeWrapper<ProfileMeetInfoOutput>;
  ProfileViewOutput: ResolverTypeWrapper<ProfileViewOutput>;
  RecentlyPlayedTrack: ResolverTypeWrapper<RecentlyPlayedTrack>;
  RefreshActivityFeedOutput: ResolverTypeWrapper<RefreshActivityFeedOutput>;
  RetrieveSharedCalendarLinkInput: RetrieveSharedCalendarLinkInput;
  RetrieveSharedCalendarLinkOutput: ResolverTypeWrapper<RetrieveSharedCalendarLinkOutput>;
  SendFeedbackInput: SendFeedbackInput;
  SendFeedbackOutput: ResolverTypeWrapper<SendFeedbackOutput>;
  SetBirthdayReminderInput: SetBirthdayReminderInput;
  SetBirthdayReminderOutput: ResolverTypeWrapper<SetBirthdayReminderOutput>;
  SetEventRecurrenceInput: SetEventRecurrenceInput;
  SetEventRecurrenceOutput: ResolverTypeWrapper<SetEventRecurrenceOutput>;
  SetReminderInput: SetReminderInput;
  SetReminderOutput: ResolverTypeWrapper<SetReminderOutput>;
  ShowCalendarInput: ShowCalendarInput;
  ShowCalendarOutput: ResolverTypeWrapper<ShowCalendarOutput>;
  SignInUsingFirebaseAuthenticationInput: SignInUsingFirebaseAuthenticationInput;
  SignInUsingFirebaseAuthenticationOutput: ResolverTypeWrapper<SignInUsingFirebaseAuthenticationOutput>;
  SignInUsingGoogleAuthorizationCodeInput: SignInUsingGoogleAuthorizationCodeInput;
  SignInUsingGoogleAuthorizationCodeOutput: ResolverTypeWrapper<SignInUsingGoogleAuthorizationCodeOutput>;
  SingleEventInput: SingleEventInput;
  Slot: ResolverTypeWrapper<Slot>;
  SpotifyPauseTrack: ResolverTypeWrapper<SpotifyPauseTrack>;
  SpotifyPlayTrack: ResolverTypeWrapper<SpotifyPlayTrack>;
  SpotifyQueue: ResolverTypeWrapper<SpotifyQueue>;
  SpotifyTrack: ResolverTypeWrapper<SpotifyTrack>;
  String: ResolverTypeWrapper<Scalars['String']>;
  String_comparison_exp: String_Comparison_Exp;
  SyncContactsInput: SyncContactsInput;
  SyncContactsOutput: ResolverTypeWrapper<SyncContactsOutput>;
  TodoCategory: ResolverTypeWrapper<TodoCategory>;
  TodoItem: ResolverTypeWrapper<TodoItem>;
  TodoUpsertInput: TodoUpsertInput;
  ToggleEventDoneFilter: ToggleEventDoneFilter;
  ToggleEventDoneOutput: ResolverTypeWrapper<ToggleEventDoneOutput>;
  UpdateCalendarInput: UpdateCalendarInput;
  UpdateCalendarLinkInput: UpdateCalendarLinkInput;
  UpdateCalendarLinkOutput: ResolverTypeWrapper<UpdateCalendarLinkOutput>;
  UpdateEventInput: UpdateEventInput;
  UpdateEventOutput: ResolverTypeWrapper<UpdateEventOutput>;
  UpdateEventRSVPInput: UpdateEventRsvpInput;
  UpdateEventRSVPOutput: ResolverTypeWrapper<UpdateEventRsvpOutput>;
  UpdateTodosInput: UpdateTodosInput;
  UpdateTodosOutput: ResolverTypeWrapper<UpdateTodosOutput>;
  activityFeed: ResolverTypeWrapper<ActivityFeed>;
  activityFeedTypeEnum: ResolverTypeWrapper<ActivityFeedTypeEnum>;
  activityFeedTypeEnum_aggregate: ResolverTypeWrapper<ActivityFeedTypeEnum_Aggregate>;
  activityFeedTypeEnum_aggregate_fields: ResolverTypeWrapper<ActivityFeedTypeEnum_Aggregate_Fields>;
  activityFeedTypeEnum_bool_exp: ActivityFeedTypeEnum_Bool_Exp;
  activityFeedTypeEnum_constraint: ActivityFeedTypeEnum_Constraint;
  activityFeedTypeEnum_enum: ActivityFeedTypeEnum_Enum;
  activityFeedTypeEnum_enum_comparison_exp: ActivityFeedTypeEnum_Enum_Comparison_Exp;
  activityFeedTypeEnum_insert_input: ActivityFeedTypeEnum_Insert_Input;
  activityFeedTypeEnum_max_fields: ResolverTypeWrapper<ActivityFeedTypeEnum_Max_Fields>;
  activityFeedTypeEnum_min_fields: ResolverTypeWrapper<ActivityFeedTypeEnum_Min_Fields>;
  activityFeedTypeEnum_mutation_response: ResolverTypeWrapper<ActivityFeedTypeEnum_Mutation_Response>;
  activityFeedTypeEnum_obj_rel_insert_input: ActivityFeedTypeEnum_Obj_Rel_Insert_Input;
  activityFeedTypeEnum_on_conflict: ActivityFeedTypeEnum_On_Conflict;
  activityFeedTypeEnum_order_by: ActivityFeedTypeEnum_Order_By;
  activityFeedTypeEnum_pk_columns_input: ActivityFeedTypeEnum_Pk_Columns_Input;
  activityFeedTypeEnum_select_column: ActivityFeedTypeEnum_Select_Column;
  activityFeedTypeEnum_set_input: ActivityFeedTypeEnum_Set_Input;
  activityFeedTypeEnum_update_column: ActivityFeedTypeEnum_Update_Column;
  activityFeed_aggregate: ResolverTypeWrapper<ActivityFeed_Aggregate>;
  activityFeed_aggregate_fields: ResolverTypeWrapper<ActivityFeed_Aggregate_Fields>;
  activityFeed_append_input: ActivityFeed_Append_Input;
  activityFeed_bool_exp: ActivityFeed_Bool_Exp;
  activityFeed_constraint: ActivityFeed_Constraint;
  activityFeed_delete_at_path_input: ActivityFeed_Delete_At_Path_Input;
  activityFeed_delete_elem_input: ActivityFeed_Delete_Elem_Input;
  activityFeed_delete_key_input: ActivityFeed_Delete_Key_Input;
  activityFeed_insert_input: ActivityFeed_Insert_Input;
  activityFeed_max_fields: ResolverTypeWrapper<ActivityFeed_Max_Fields>;
  activityFeed_min_fields: ResolverTypeWrapper<ActivityFeed_Min_Fields>;
  activityFeed_mutation_response: ResolverTypeWrapper<ActivityFeed_Mutation_Response>;
  activityFeed_obj_rel_insert_input: ActivityFeed_Obj_Rel_Insert_Input;
  activityFeed_on_conflict: ActivityFeed_On_Conflict;
  activityFeed_order_by: ActivityFeed_Order_By;
  activityFeed_pk_columns_input: ActivityFeed_Pk_Columns_Input;
  activityFeed_prepend_input: ActivityFeed_Prepend_Input;
  activityFeed_select_column: ActivityFeed_Select_Column;
  activityFeed_set_input: ActivityFeed_Set_Input;
  activityFeed_update_column: ActivityFeed_Update_Column;
  analytics_event: ResolverTypeWrapper<Analytics_Event>;
  analytics_event_aggregate: ResolverTypeWrapper<Analytics_Event_Aggregate>;
  analytics_event_aggregate_fields: ResolverTypeWrapper<Analytics_Event_Aggregate_Fields>;
  analytics_event_append_input: Analytics_Event_Append_Input;
  analytics_event_bool_exp: Analytics_Event_Bool_Exp;
  analytics_event_constraint: Analytics_Event_Constraint;
  analytics_event_delete_at_path_input: Analytics_Event_Delete_At_Path_Input;
  analytics_event_delete_elem_input: Analytics_Event_Delete_Elem_Input;
  analytics_event_delete_key_input: Analytics_Event_Delete_Key_Input;
  analytics_event_insert_input: Analytics_Event_Insert_Input;
  analytics_event_max_fields: ResolverTypeWrapper<Analytics_Event_Max_Fields>;
  analytics_event_min_fields: ResolverTypeWrapper<Analytics_Event_Min_Fields>;
  analytics_event_mutation_response: ResolverTypeWrapper<Analytics_Event_Mutation_Response>;
  analytics_event_on_conflict: Analytics_Event_On_Conflict;
  analytics_event_order_by: Analytics_Event_Order_By;
  analytics_event_pk_columns_input: Analytics_Event_Pk_Columns_Input;
  analytics_event_prepend_input: Analytics_Event_Prepend_Input;
  analytics_event_select_column: Analytics_Event_Select_Column;
  analytics_event_set_input: Analytics_Event_Set_Input;
  analytics_event_update_column: Analytics_Event_Update_Column;
  calendarHistory: ResolverTypeWrapper<CalendarHistory>;
  calendarHistory_aggregate: ResolverTypeWrapper<CalendarHistory_Aggregate>;
  calendarHistory_aggregate_fields: ResolverTypeWrapper<CalendarHistory_Aggregate_Fields>;
  calendarHistory_bool_exp: CalendarHistory_Bool_Exp;
  calendarHistory_constraint: CalendarHistory_Constraint;
  calendarHistory_insert_input: CalendarHistory_Insert_Input;
  calendarHistory_max_fields: ResolverTypeWrapper<CalendarHistory_Max_Fields>;
  calendarHistory_min_fields: ResolverTypeWrapper<CalendarHistory_Min_Fields>;
  calendarHistory_mutation_response: ResolverTypeWrapper<CalendarHistory_Mutation_Response>;
  calendarHistory_on_conflict: CalendarHistory_On_Conflict;
  calendarHistory_order_by: CalendarHistory_Order_By;
  calendarHistory_pk_columns_input: CalendarHistory_Pk_Columns_Input;
  calendarHistory_select_column: CalendarHistory_Select_Column;
  calendarHistory_set_input: CalendarHistory_Set_Input;
  calendarHistory_update_column: CalendarHistory_Update_Column;
  calendarPreference: ResolverTypeWrapper<CalendarPreference>;
  calendarPreference_aggregate: ResolverTypeWrapper<CalendarPreference_Aggregate>;
  calendarPreference_aggregate_fields: ResolverTypeWrapper<CalendarPreference_Aggregate_Fields>;
  calendarPreference_bool_exp: CalendarPreference_Bool_Exp;
  calendarPreference_constraint: CalendarPreference_Constraint;
  calendarPreference_insert_input: CalendarPreference_Insert_Input;
  calendarPreference_max_fields: ResolverTypeWrapper<CalendarPreference_Max_Fields>;
  calendarPreference_min_fields: ResolverTypeWrapper<CalendarPreference_Min_Fields>;
  calendarPreference_mutation_response: ResolverTypeWrapper<CalendarPreference_Mutation_Response>;
  calendarPreference_obj_rel_insert_input: CalendarPreference_Obj_Rel_Insert_Input;
  calendarPreference_on_conflict: CalendarPreference_On_Conflict;
  calendarPreference_order_by: CalendarPreference_Order_By;
  calendarPreference_pk_columns_input: CalendarPreference_Pk_Columns_Input;
  calendarPreference_select_column: CalendarPreference_Select_Column;
  calendarPreference_set_input: CalendarPreference_Set_Input;
  calendarPreference_update_column: CalendarPreference_Update_Column;
  calendarStartsOn: ResolverTypeWrapper<CalendarStartsOn>;
  calendarStartsOn_aggregate: ResolverTypeWrapper<CalendarStartsOn_Aggregate>;
  calendarStartsOn_aggregate_fields: ResolverTypeWrapper<CalendarStartsOn_Aggregate_Fields>;
  calendarStartsOn_bool_exp: CalendarStartsOn_Bool_Exp;
  calendarStartsOn_constraint: CalendarStartsOn_Constraint;
  calendarStartsOn_enum: CalendarStartsOn_Enum;
  calendarStartsOn_enum_comparison_exp: CalendarStartsOn_Enum_Comparison_Exp;
  calendarStartsOn_insert_input: CalendarStartsOn_Insert_Input;
  calendarStartsOn_max_fields: ResolverTypeWrapper<CalendarStartsOn_Max_Fields>;
  calendarStartsOn_min_fields: ResolverTypeWrapper<CalendarStartsOn_Min_Fields>;
  calendarStartsOn_mutation_response: ResolverTypeWrapper<CalendarStartsOn_Mutation_Response>;
  calendarStartsOn_on_conflict: CalendarStartsOn_On_Conflict;
  calendarStartsOn_order_by: CalendarStartsOn_Order_By;
  calendarStartsOn_pk_columns_input: CalendarStartsOn_Pk_Columns_Input;
  calendarStartsOn_select_column: CalendarStartsOn_Select_Column;
  calendarStartsOn_set_input: CalendarStartsOn_Set_Input;
  calendarStartsOn_update_column: CalendarStartsOn_Update_Column;
  category: ResolverTypeWrapper<Category>;
  category_aggregate: ResolverTypeWrapper<Category_Aggregate>;
  category_aggregate_fields: ResolverTypeWrapper<Category_Aggregate_Fields>;
  category_avg_fields: ResolverTypeWrapper<Category_Avg_Fields>;
  category_bool_exp: Category_Bool_Exp;
  category_constraint: Category_Constraint;
  category_inc_input: Category_Inc_Input;
  category_insert_input: Category_Insert_Input;
  category_max_fields: ResolverTypeWrapper<Category_Max_Fields>;
  category_min_fields: ResolverTypeWrapper<Category_Min_Fields>;
  category_mutation_response: ResolverTypeWrapper<Category_Mutation_Response>;
  category_obj_rel_insert_input: Category_Obj_Rel_Insert_Input;
  category_on_conflict: Category_On_Conflict;
  category_order_by: Category_Order_By;
  category_pk_columns_input: Category_Pk_Columns_Input;
  category_select_column: Category_Select_Column;
  category_set_input: Category_Set_Input;
  category_stddev_fields: ResolverTypeWrapper<Category_Stddev_Fields>;
  category_stddev_pop_fields: ResolverTypeWrapper<Category_Stddev_Pop_Fields>;
  category_stddev_samp_fields: ResolverTypeWrapper<Category_Stddev_Samp_Fields>;
  category_sum_fields: ResolverTypeWrapper<Category_Sum_Fields>;
  category_update_column: Category_Update_Column;
  category_var_pop_fields: ResolverTypeWrapper<Category_Var_Pop_Fields>;
  category_var_samp_fields: ResolverTypeWrapper<Category_Var_Samp_Fields>;
  category_variance_fields: ResolverTypeWrapper<Category_Variance_Fields>;
  colorFamilyEnum: ResolverTypeWrapper<ColorFamilyEnum>;
  colorFamilyEnum_aggregate: ResolverTypeWrapper<ColorFamilyEnum_Aggregate>;
  colorFamilyEnum_aggregate_fields: ResolverTypeWrapper<ColorFamilyEnum_Aggregate_Fields>;
  colorFamilyEnum_bool_exp: ColorFamilyEnum_Bool_Exp;
  colorFamilyEnum_constraint: ColorFamilyEnum_Constraint;
  colorFamilyEnum_enum: ColorFamilyEnum_Enum;
  colorFamilyEnum_enum_comparison_exp: ColorFamilyEnum_Enum_Comparison_Exp;
  colorFamilyEnum_insert_input: ColorFamilyEnum_Insert_Input;
  colorFamilyEnum_max_fields: ResolverTypeWrapper<ColorFamilyEnum_Max_Fields>;
  colorFamilyEnum_min_fields: ResolverTypeWrapper<ColorFamilyEnum_Min_Fields>;
  colorFamilyEnum_mutation_response: ResolverTypeWrapper<ColorFamilyEnum_Mutation_Response>;
  colorFamilyEnum_on_conflict: ColorFamilyEnum_On_Conflict;
  colorFamilyEnum_order_by: ColorFamilyEnum_Order_By;
  colorFamilyEnum_pk_columns_input: ColorFamilyEnum_Pk_Columns_Input;
  colorFamilyEnum_select_column: ColorFamilyEnum_Select_Column;
  colorFamilyEnum_set_input: ColorFamilyEnum_Set_Input;
  colorFamilyEnum_update_column: ColorFamilyEnum_Update_Column;
  contact_sync: ResolverTypeWrapper<Contact_Sync>;
  contact_sync_aggregate: ResolverTypeWrapper<Contact_Sync_Aggregate>;
  contact_sync_aggregate_fields: ResolverTypeWrapper<Contact_Sync_Aggregate_Fields>;
  contact_sync_bool_exp: Contact_Sync_Bool_Exp;
  contact_sync_constraint: Contact_Sync_Constraint;
  contact_sync_insert_input: Contact_Sync_Insert_Input;
  contact_sync_max_fields: ResolverTypeWrapper<Contact_Sync_Max_Fields>;
  contact_sync_min_fields: ResolverTypeWrapper<Contact_Sync_Min_Fields>;
  contact_sync_mutation_response: ResolverTypeWrapper<Contact_Sync_Mutation_Response>;
  contact_sync_on_conflict: Contact_Sync_On_Conflict;
  contact_sync_order_by: Contact_Sync_Order_By;
  contact_sync_pk_columns_input: Contact_Sync_Pk_Columns_Input;
  contact_sync_select_column: Contact_Sync_Select_Column;
  contact_sync_set_input: Contact_Sync_Set_Input;
  contact_sync_update_column: Contact_Sync_Update_Column;
  databasechangelog: ResolverTypeWrapper<Databasechangelog>;
  databasechangelog_aggregate: ResolverTypeWrapper<Databasechangelog_Aggregate>;
  databasechangelog_aggregate_fields: ResolverTypeWrapper<Databasechangelog_Aggregate_Fields>;
  databasechangelog_avg_fields: ResolverTypeWrapper<Databasechangelog_Avg_Fields>;
  databasechangelog_bool_exp: Databasechangelog_Bool_Exp;
  databasechangelog_inc_input: Databasechangelog_Inc_Input;
  databasechangelog_insert_input: Databasechangelog_Insert_Input;
  databasechangelog_max_fields: ResolverTypeWrapper<Databasechangelog_Max_Fields>;
  databasechangelog_min_fields: ResolverTypeWrapper<Databasechangelog_Min_Fields>;
  databasechangelog_mutation_response: ResolverTypeWrapper<Databasechangelog_Mutation_Response>;
  databasechangelog_order_by: Databasechangelog_Order_By;
  databasechangelog_select_column: Databasechangelog_Select_Column;
  databasechangelog_set_input: Databasechangelog_Set_Input;
  databasechangelog_stddev_fields: ResolverTypeWrapper<Databasechangelog_Stddev_Fields>;
  databasechangelog_stddev_pop_fields: ResolverTypeWrapper<Databasechangelog_Stddev_Pop_Fields>;
  databasechangelog_stddev_samp_fields: ResolverTypeWrapper<Databasechangelog_Stddev_Samp_Fields>;
  databasechangelog_sum_fields: ResolverTypeWrapper<Databasechangelog_Sum_Fields>;
  databasechangelog_var_pop_fields: ResolverTypeWrapper<Databasechangelog_Var_Pop_Fields>;
  databasechangelog_var_samp_fields: ResolverTypeWrapper<Databasechangelog_Var_Samp_Fields>;
  databasechangelog_variance_fields: ResolverTypeWrapper<Databasechangelog_Variance_Fields>;
  databasechangeloglock: ResolverTypeWrapper<Databasechangeloglock>;
  databasechangeloglock_aggregate: ResolverTypeWrapper<Databasechangeloglock_Aggregate>;
  databasechangeloglock_aggregate_fields: ResolverTypeWrapper<Databasechangeloglock_Aggregate_Fields>;
  databasechangeloglock_avg_fields: ResolverTypeWrapper<Databasechangeloglock_Avg_Fields>;
  databasechangeloglock_bool_exp: Databasechangeloglock_Bool_Exp;
  databasechangeloglock_constraint: Databasechangeloglock_Constraint;
  databasechangeloglock_inc_input: Databasechangeloglock_Inc_Input;
  databasechangeloglock_insert_input: Databasechangeloglock_Insert_Input;
  databasechangeloglock_max_fields: ResolverTypeWrapper<Databasechangeloglock_Max_Fields>;
  databasechangeloglock_min_fields: ResolverTypeWrapper<Databasechangeloglock_Min_Fields>;
  databasechangeloglock_mutation_response: ResolverTypeWrapper<Databasechangeloglock_Mutation_Response>;
  databasechangeloglock_on_conflict: Databasechangeloglock_On_Conflict;
  databasechangeloglock_order_by: Databasechangeloglock_Order_By;
  databasechangeloglock_pk_columns_input: Databasechangeloglock_Pk_Columns_Input;
  databasechangeloglock_select_column: Databasechangeloglock_Select_Column;
  databasechangeloglock_set_input: Databasechangeloglock_Set_Input;
  databasechangeloglock_stddev_fields: ResolverTypeWrapper<Databasechangeloglock_Stddev_Fields>;
  databasechangeloglock_stddev_pop_fields: ResolverTypeWrapper<Databasechangeloglock_Stddev_Pop_Fields>;
  databasechangeloglock_stddev_samp_fields: ResolverTypeWrapper<Databasechangeloglock_Stddev_Samp_Fields>;
  databasechangeloglock_sum_fields: ResolverTypeWrapper<Databasechangeloglock_Sum_Fields>;
  databasechangeloglock_update_column: Databasechangeloglock_Update_Column;
  databasechangeloglock_var_pop_fields: ResolverTypeWrapper<Databasechangeloglock_Var_Pop_Fields>;
  databasechangeloglock_var_samp_fields: ResolverTypeWrapper<Databasechangeloglock_Var_Samp_Fields>;
  databasechangeloglock_variance_fields: ResolverTypeWrapper<Databasechangeloglock_Variance_Fields>;
  date: ResolverTypeWrapper<Scalars['date']>;
  dateFormat: ResolverTypeWrapper<DateFormat>;
  dateFormat_aggregate: ResolverTypeWrapper<DateFormat_Aggregate>;
  dateFormat_aggregate_fields: ResolverTypeWrapper<DateFormat_Aggregate_Fields>;
  dateFormat_bool_exp: DateFormat_Bool_Exp;
  dateFormat_constraint: DateFormat_Constraint;
  dateFormat_enum: DateFormat_Enum;
  dateFormat_enum_comparison_exp: DateFormat_Enum_Comparison_Exp;
  dateFormat_insert_input: DateFormat_Insert_Input;
  dateFormat_max_fields: ResolverTypeWrapper<DateFormat_Max_Fields>;
  dateFormat_min_fields: ResolverTypeWrapper<DateFormat_Min_Fields>;
  dateFormat_mutation_response: ResolverTypeWrapper<DateFormat_Mutation_Response>;
  dateFormat_on_conflict: DateFormat_On_Conflict;
  dateFormat_order_by: DateFormat_Order_By;
  dateFormat_pk_columns_input: DateFormat_Pk_Columns_Input;
  dateFormat_select_column: DateFormat_Select_Column;
  dateFormat_set_input: DateFormat_Set_Input;
  dateFormat_update_column: DateFormat_Update_Column;
  date_comparison_exp: Date_Comparison_Exp;
  google_credential: ResolverTypeWrapper<Google_Credential>;
  google_credential_aggregate: ResolverTypeWrapper<Google_Credential_Aggregate>;
  google_credential_aggregate_fields: ResolverTypeWrapper<Google_Credential_Aggregate_Fields>;
  google_credential_bool_exp: Google_Credential_Bool_Exp;
  google_credential_constraint: Google_Credential_Constraint;
  google_credential_insert_input: Google_Credential_Insert_Input;
  google_credential_max_fields: ResolverTypeWrapper<Google_Credential_Max_Fields>;
  google_credential_min_fields: ResolverTypeWrapper<Google_Credential_Min_Fields>;
  google_credential_mutation_response: ResolverTypeWrapper<Google_Credential_Mutation_Response>;
  google_credential_obj_rel_insert_input: Google_Credential_Obj_Rel_Insert_Input;
  google_credential_on_conflict: Google_Credential_On_Conflict;
  google_credential_order_by: Google_Credential_Order_By;
  google_credential_pk_columns_input: Google_Credential_Pk_Columns_Input;
  google_credential_select_column: Google_Credential_Select_Column;
  google_credential_set_input: Google_Credential_Set_Input;
  google_credential_update_column: Google_Credential_Update_Column;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  linear_issue: ResolverTypeWrapper<Linear_Issue>;
  linear_issue_aggregate: ResolverTypeWrapper<Linear_Issue_Aggregate>;
  linear_issue_aggregate_fields: ResolverTypeWrapper<Linear_Issue_Aggregate_Fields>;
  linear_issue_bool_exp: Linear_Issue_Bool_Exp;
  linear_issue_constraint: Linear_Issue_Constraint;
  linear_issue_insert_input: Linear_Issue_Insert_Input;
  linear_issue_max_fields: ResolverTypeWrapper<Linear_Issue_Max_Fields>;
  linear_issue_min_fields: ResolverTypeWrapper<Linear_Issue_Min_Fields>;
  linear_issue_mutation_response: ResolverTypeWrapper<Linear_Issue_Mutation_Response>;
  linear_issue_on_conflict: Linear_Issue_On_Conflict;
  linear_issue_order_by: Linear_Issue_Order_By;
  linear_issue_pk_columns_input: Linear_Issue_Pk_Columns_Input;
  linear_issue_select_column: Linear_Issue_Select_Column;
  linear_issue_set_input: Linear_Issue_Set_Input;
  linear_issue_update_column: Linear_Issue_Update_Column;
  mutation_root: ResolverTypeWrapper<{}>;
  new_contact: ResolverTypeWrapper<New_Contact>;
  new_contact_aggregate: ResolverTypeWrapper<New_Contact_Aggregate>;
  new_contact_aggregate_fields: ResolverTypeWrapper<New_Contact_Aggregate_Fields>;
  new_contact_append_input: New_Contact_Append_Input;
  new_contact_bool_exp: New_Contact_Bool_Exp;
  new_contact_constraint: New_Contact_Constraint;
  new_contact_delete_at_path_input: New_Contact_Delete_At_Path_Input;
  new_contact_delete_elem_input: New_Contact_Delete_Elem_Input;
  new_contact_delete_key_input: New_Contact_Delete_Key_Input;
  new_contact_insert_input: New_Contact_Insert_Input;
  new_contact_max_fields: ResolverTypeWrapper<New_Contact_Max_Fields>;
  new_contact_min_fields: ResolverTypeWrapper<New_Contact_Min_Fields>;
  new_contact_mutation_response: ResolverTypeWrapper<New_Contact_Mutation_Response>;
  new_contact_on_conflict: New_Contact_On_Conflict;
  new_contact_order_by: New_Contact_Order_By;
  new_contact_pk_columns_input: New_Contact_Pk_Columns_Input;
  new_contact_prepend_input: New_Contact_Prepend_Input;
  new_contact_select_column: New_Contact_Select_Column;
  new_contact_set_input: New_Contact_Set_Input;
  new_contact_update_column: New_Contact_Update_Column;
  new_feedback: ResolverTypeWrapper<New_Feedback>;
  new_feedback_aggregate: ResolverTypeWrapper<New_Feedback_Aggregate>;
  new_feedback_aggregate_fields: ResolverTypeWrapper<New_Feedback_Aggregate_Fields>;
  new_feedback_bool_exp: New_Feedback_Bool_Exp;
  new_feedback_constraint: New_Feedback_Constraint;
  new_feedback_insert_input: New_Feedback_Insert_Input;
  new_feedback_max_fields: ResolverTypeWrapper<New_Feedback_Max_Fields>;
  new_feedback_min_fields: ResolverTypeWrapper<New_Feedback_Min_Fields>;
  new_feedback_mutation_response: ResolverTypeWrapper<New_Feedback_Mutation_Response>;
  new_feedback_on_conflict: New_Feedback_On_Conflict;
  new_feedback_order_by: New_Feedback_Order_By;
  new_feedback_pk_columns_input: New_Feedback_Pk_Columns_Input;
  new_feedback_select_column: New_Feedback_Select_Column;
  new_feedback_set_input: New_Feedback_Set_Input;
  new_feedback_update_column: New_Feedback_Update_Column;
  new_user: ResolverTypeWrapper<New_User>;
  new_user_aggregate: ResolverTypeWrapper<New_User_Aggregate>;
  new_user_aggregate_fields: ResolverTypeWrapper<New_User_Aggregate_Fields>;
  new_user_bool_exp: New_User_Bool_Exp;
  new_user_constraint: New_User_Constraint;
  new_user_insert_input: New_User_Insert_Input;
  new_user_max_fields: ResolverTypeWrapper<New_User_Max_Fields>;
  new_user_min_fields: ResolverTypeWrapper<New_User_Min_Fields>;
  new_user_mutation_response: ResolverTypeWrapper<New_User_Mutation_Response>;
  new_user_obj_rel_insert_input: New_User_Obj_Rel_Insert_Input;
  new_user_on_conflict: New_User_On_Conflict;
  new_user_order_by: New_User_Order_By;
  new_user_pk_columns_input: New_User_Pk_Columns_Input;
  new_user_select_column: New_User_Select_Column;
  new_user_set_input: New_User_Set_Input;
  new_user_update_column: New_User_Update_Column;
  order_by: Order_By;
  profile: ResolverTypeWrapper<Profile>;
  profile_aggregate: ResolverTypeWrapper<Profile_Aggregate>;
  profile_aggregate_fields: ResolverTypeWrapper<Profile_Aggregate_Fields>;
  profile_bool_exp: Profile_Bool_Exp;
  profile_constraint: Profile_Constraint;
  profile_insert_input: Profile_Insert_Input;
  profile_max_fields: ResolverTypeWrapper<Profile_Max_Fields>;
  profile_min_fields: ResolverTypeWrapper<Profile_Min_Fields>;
  profile_mutation_response: ResolverTypeWrapper<Profile_Mutation_Response>;
  profile_on_conflict: Profile_On_Conflict;
  profile_order_by: Profile_Order_By;
  profile_pk_columns_input: Profile_Pk_Columns_Input;
  profile_select_column: Profile_Select_Column;
  profile_set_input: Profile_Set_Input;
  profile_update_column: Profile_Update_Column;
  query_root: ResolverTypeWrapper<{}>;
  refreshUserStatusOutput: ResolverTypeWrapper<RefreshUserStatusOutput>;
  shareCalendarLink: ResolverTypeWrapper<ShareCalendarLink>;
  shareCalendarLinkTypeEnum: ResolverTypeWrapper<ShareCalendarLinkTypeEnum>;
  shareCalendarLinkTypeEnum_aggregate: ResolverTypeWrapper<ShareCalendarLinkTypeEnum_Aggregate>;
  shareCalendarLinkTypeEnum_aggregate_fields: ResolverTypeWrapper<ShareCalendarLinkTypeEnum_Aggregate_Fields>;
  shareCalendarLinkTypeEnum_bool_exp: ShareCalendarLinkTypeEnum_Bool_Exp;
  shareCalendarLinkTypeEnum_constraint: ShareCalendarLinkTypeEnum_Constraint;
  shareCalendarLinkTypeEnum_enum: ShareCalendarLinkTypeEnum_Enum;
  shareCalendarLinkTypeEnum_enum_comparison_exp: ShareCalendarLinkTypeEnum_Enum_Comparison_Exp;
  shareCalendarLinkTypeEnum_insert_input: ShareCalendarLinkTypeEnum_Insert_Input;
  shareCalendarLinkTypeEnum_max_fields: ResolverTypeWrapper<ShareCalendarLinkTypeEnum_Max_Fields>;
  shareCalendarLinkTypeEnum_min_fields: ResolverTypeWrapper<ShareCalendarLinkTypeEnum_Min_Fields>;
  shareCalendarLinkTypeEnum_mutation_response: ResolverTypeWrapper<ShareCalendarLinkTypeEnum_Mutation_Response>;
  shareCalendarLinkTypeEnum_on_conflict: ShareCalendarLinkTypeEnum_On_Conflict;
  shareCalendarLinkTypeEnum_order_by: ShareCalendarLinkTypeEnum_Order_By;
  shareCalendarLinkTypeEnum_pk_columns_input: ShareCalendarLinkTypeEnum_Pk_Columns_Input;
  shareCalendarLinkTypeEnum_select_column: ShareCalendarLinkTypeEnum_Select_Column;
  shareCalendarLinkTypeEnum_set_input: ShareCalendarLinkTypeEnum_Set_Input;
  shareCalendarLinkTypeEnum_update_column: ShareCalendarLinkTypeEnum_Update_Column;
  shareCalendarLink_aggregate: ResolverTypeWrapper<ShareCalendarLink_Aggregate>;
  shareCalendarLink_aggregate_fields: ResolverTypeWrapper<ShareCalendarLink_Aggregate_Fields>;
  shareCalendarLink_append_input: ShareCalendarLink_Append_Input;
  shareCalendarLink_avg_fields: ResolverTypeWrapper<ShareCalendarLink_Avg_Fields>;
  shareCalendarLink_bool_exp: ShareCalendarLink_Bool_Exp;
  shareCalendarLink_constraint: ShareCalendarLink_Constraint;
  shareCalendarLink_delete_at_path_input: ShareCalendarLink_Delete_At_Path_Input;
  shareCalendarLink_delete_elem_input: ShareCalendarLink_Delete_Elem_Input;
  shareCalendarLink_delete_key_input: ShareCalendarLink_Delete_Key_Input;
  shareCalendarLink_inc_input: ShareCalendarLink_Inc_Input;
  shareCalendarLink_insert_input: ShareCalendarLink_Insert_Input;
  shareCalendarLink_max_fields: ResolverTypeWrapper<ShareCalendarLink_Max_Fields>;
  shareCalendarLink_min_fields: ResolverTypeWrapper<ShareCalendarLink_Min_Fields>;
  shareCalendarLink_mutation_response: ResolverTypeWrapper<ShareCalendarLink_Mutation_Response>;
  shareCalendarLink_on_conflict: ShareCalendarLink_On_Conflict;
  shareCalendarLink_order_by: ShareCalendarLink_Order_By;
  shareCalendarLink_pk_columns_input: ShareCalendarLink_Pk_Columns_Input;
  shareCalendarLink_prepend_input: ShareCalendarLink_Prepend_Input;
  shareCalendarLink_select_column: ShareCalendarLink_Select_Column;
  shareCalendarLink_set_input: ShareCalendarLink_Set_Input;
  shareCalendarLink_stddev_fields: ResolverTypeWrapper<ShareCalendarLink_Stddev_Fields>;
  shareCalendarLink_stddev_pop_fields: ResolverTypeWrapper<ShareCalendarLink_Stddev_Pop_Fields>;
  shareCalendarLink_stddev_samp_fields: ResolverTypeWrapper<ShareCalendarLink_Stddev_Samp_Fields>;
  shareCalendarLink_sum_fields: ResolverTypeWrapper<ShareCalendarLink_Sum_Fields>;
  shareCalendarLink_update_column: ShareCalendarLink_Update_Column;
  shareCalendarLink_var_pop_fields: ResolverTypeWrapper<ShareCalendarLink_Var_Pop_Fields>;
  shareCalendarLink_var_samp_fields: ResolverTypeWrapper<ShareCalendarLink_Var_Samp_Fields>;
  shareCalendarLink_variance_fields: ResolverTypeWrapper<ShareCalendarLink_Variance_Fields>;
  subscription_root: ResolverTypeWrapper<{}>;
  theme: ResolverTypeWrapper<Theme>;
  theme_aggregate: ResolverTypeWrapper<Theme_Aggregate>;
  theme_aggregate_fields: ResolverTypeWrapper<Theme_Aggregate_Fields>;
  theme_bool_exp: Theme_Bool_Exp;
  theme_constraint: Theme_Constraint;
  theme_enum: Theme_Enum;
  theme_enum_comparison_exp: Theme_Enum_Comparison_Exp;
  theme_insert_input: Theme_Insert_Input;
  theme_max_fields: ResolverTypeWrapper<Theme_Max_Fields>;
  theme_min_fields: ResolverTypeWrapper<Theme_Min_Fields>;
  theme_mutation_response: ResolverTypeWrapper<Theme_Mutation_Response>;
  theme_on_conflict: Theme_On_Conflict;
  theme_order_by: Theme_Order_By;
  theme_pk_columns_input: Theme_Pk_Columns_Input;
  theme_select_column: Theme_Select_Column;
  theme_set_input: Theme_Set_Input;
  theme_update_column: Theme_Update_Column;
  timestamp: ResolverTypeWrapper<Scalars['timestamp']>;
  timestamp_comparison_exp: Timestamp_Comparison_Exp;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  todo: ResolverTypeWrapper<Todo>;
  todoTypeEnum: ResolverTypeWrapper<TodoTypeEnum>;
  todoTypeEnum_aggregate: ResolverTypeWrapper<TodoTypeEnum_Aggregate>;
  todoTypeEnum_aggregate_fields: ResolverTypeWrapper<TodoTypeEnum_Aggregate_Fields>;
  todoTypeEnum_bool_exp: TodoTypeEnum_Bool_Exp;
  todoTypeEnum_constraint: TodoTypeEnum_Constraint;
  todoTypeEnum_enum: TodoTypeEnum_Enum;
  todoTypeEnum_enum_comparison_exp: TodoTypeEnum_Enum_Comparison_Exp;
  todoTypeEnum_insert_input: TodoTypeEnum_Insert_Input;
  todoTypeEnum_max_fields: ResolverTypeWrapper<TodoTypeEnum_Max_Fields>;
  todoTypeEnum_min_fields: ResolverTypeWrapper<TodoTypeEnum_Min_Fields>;
  todoTypeEnum_mutation_response: ResolverTypeWrapper<TodoTypeEnum_Mutation_Response>;
  todoTypeEnum_on_conflict: TodoTypeEnum_On_Conflict;
  todoTypeEnum_order_by: TodoTypeEnum_Order_By;
  todoTypeEnum_pk_columns_input: TodoTypeEnum_Pk_Columns_Input;
  todoTypeEnum_select_column: TodoTypeEnum_Select_Column;
  todoTypeEnum_set_input: TodoTypeEnum_Set_Input;
  todoTypeEnum_update_column: TodoTypeEnum_Update_Column;
  todo_aggregate: ResolverTypeWrapper<Todo_Aggregate>;
  todo_aggregate_fields: ResolverTypeWrapper<Todo_Aggregate_Fields>;
  todo_aggregate_order_by: Todo_Aggregate_Order_By;
  todo_arr_rel_insert_input: Todo_Arr_Rel_Insert_Input;
  todo_avg_fields: ResolverTypeWrapper<Todo_Avg_Fields>;
  todo_avg_order_by: Todo_Avg_Order_By;
  todo_bool_exp: Todo_Bool_Exp;
  todo_constraint: Todo_Constraint;
  todo_inc_input: Todo_Inc_Input;
  todo_insert_input: Todo_Insert_Input;
  todo_max_fields: ResolverTypeWrapper<Todo_Max_Fields>;
  todo_max_order_by: Todo_Max_Order_By;
  todo_min_fields: ResolverTypeWrapper<Todo_Min_Fields>;
  todo_min_order_by: Todo_Min_Order_By;
  todo_mutation_response: ResolverTypeWrapper<Todo_Mutation_Response>;
  todo_on_conflict: Todo_On_Conflict;
  todo_order_by: Todo_Order_By;
  todo_pk_columns_input: Todo_Pk_Columns_Input;
  todo_select_column: Todo_Select_Column;
  todo_set_input: Todo_Set_Input;
  todo_stddev_fields: ResolverTypeWrapper<Todo_Stddev_Fields>;
  todo_stddev_order_by: Todo_Stddev_Order_By;
  todo_stddev_pop_fields: ResolverTypeWrapper<Todo_Stddev_Pop_Fields>;
  todo_stddev_pop_order_by: Todo_Stddev_Pop_Order_By;
  todo_stddev_samp_fields: ResolverTypeWrapper<Todo_Stddev_Samp_Fields>;
  todo_stddev_samp_order_by: Todo_Stddev_Samp_Order_By;
  todo_sum_fields: ResolverTypeWrapper<Todo_Sum_Fields>;
  todo_sum_order_by: Todo_Sum_Order_By;
  todo_update_column: Todo_Update_Column;
  todo_var_pop_fields: ResolverTypeWrapper<Todo_Var_Pop_Fields>;
  todo_var_pop_order_by: Todo_Var_Pop_Order_By;
  todo_var_samp_fields: ResolverTypeWrapper<Todo_Var_Samp_Fields>;
  todo_var_samp_order_by: Todo_Var_Samp_Order_By;
  todo_variance_fields: ResolverTypeWrapper<Todo_Variance_Fields>;
  todo_variance_order_by: Todo_Variance_Order_By;
  userActivityFeed: ResolverTypeWrapper<UserActivityFeed>;
  userActivityFeed_aggregate: ResolverTypeWrapper<UserActivityFeed_Aggregate>;
  userActivityFeed_aggregate_fields: ResolverTypeWrapper<UserActivityFeed_Aggregate_Fields>;
  userActivityFeed_aggregate_order_by: UserActivityFeed_Aggregate_Order_By;
  userActivityFeed_arr_rel_insert_input: UserActivityFeed_Arr_Rel_Insert_Input;
  userActivityFeed_bool_exp: UserActivityFeed_Bool_Exp;
  userActivityFeed_constraint: UserActivityFeed_Constraint;
  userActivityFeed_insert_input: UserActivityFeed_Insert_Input;
  userActivityFeed_max_fields: ResolverTypeWrapper<UserActivityFeed_Max_Fields>;
  userActivityFeed_max_order_by: UserActivityFeed_Max_Order_By;
  userActivityFeed_min_fields: ResolverTypeWrapper<UserActivityFeed_Min_Fields>;
  userActivityFeed_min_order_by: UserActivityFeed_Min_Order_By;
  userActivityFeed_mutation_response: ResolverTypeWrapper<UserActivityFeed_Mutation_Response>;
  userActivityFeed_on_conflict: UserActivityFeed_On_Conflict;
  userActivityFeed_order_by: UserActivityFeed_Order_By;
  userActivityFeed_pk_columns_input: UserActivityFeed_Pk_Columns_Input;
  userActivityFeed_select_column: UserActivityFeed_Select_Column;
  userActivityFeed_set_input: UserActivityFeed_Set_Input;
  userActivityFeed_update_column: UserActivityFeed_Update_Column;
  userPreferences: ResolverTypeWrapper<UserPreferences>;
  userPreferences_aggregate: ResolverTypeWrapper<UserPreferences_Aggregate>;
  userPreferences_aggregate_fields: ResolverTypeWrapper<UserPreferences_Aggregate_Fields>;
  userPreferences_avg_fields: ResolverTypeWrapper<UserPreferences_Avg_Fields>;
  userPreferences_bool_exp: UserPreferences_Bool_Exp;
  userPreferences_constraint: UserPreferences_Constraint;
  userPreferences_inc_input: UserPreferences_Inc_Input;
  userPreferences_insert_input: UserPreferences_Insert_Input;
  userPreferences_max_fields: ResolverTypeWrapper<UserPreferences_Max_Fields>;
  userPreferences_min_fields: ResolverTypeWrapper<UserPreferences_Min_Fields>;
  userPreferences_mutation_response: ResolverTypeWrapper<UserPreferences_Mutation_Response>;
  userPreferences_obj_rel_insert_input: UserPreferences_Obj_Rel_Insert_Input;
  userPreferences_on_conflict: UserPreferences_On_Conflict;
  userPreferences_order_by: UserPreferences_Order_By;
  userPreferences_pk_columns_input: UserPreferences_Pk_Columns_Input;
  userPreferences_select_column: UserPreferences_Select_Column;
  userPreferences_set_input: UserPreferences_Set_Input;
  userPreferences_stddev_fields: ResolverTypeWrapper<UserPreferences_Stddev_Fields>;
  userPreferences_stddev_pop_fields: ResolverTypeWrapper<UserPreferences_Stddev_Pop_Fields>;
  userPreferences_stddev_samp_fields: ResolverTypeWrapper<UserPreferences_Stddev_Samp_Fields>;
  userPreferences_sum_fields: ResolverTypeWrapper<UserPreferences_Sum_Fields>;
  userPreferences_update_column: UserPreferences_Update_Column;
  userPreferences_var_pop_fields: ResolverTypeWrapper<UserPreferences_Var_Pop_Fields>;
  userPreferences_var_samp_fields: ResolverTypeWrapper<UserPreferences_Var_Samp_Fields>;
  userPreferences_variance_fields: ResolverTypeWrapper<UserPreferences_Variance_Fields>;
  userStatus: ResolverTypeWrapper<UserStatus>;
  userStatus_aggregate: ResolverTypeWrapper<UserStatus_Aggregate>;
  userStatus_aggregate_fields: ResolverTypeWrapper<UserStatus_Aggregate_Fields>;
  userStatus_bool_exp: UserStatus_Bool_Exp;
  userStatus_constraint: UserStatus_Constraint;
  userStatus_insert_input: UserStatus_Insert_Input;
  userStatus_max_fields: ResolverTypeWrapper<UserStatus_Max_Fields>;
  userStatus_min_fields: ResolverTypeWrapper<UserStatus_Min_Fields>;
  userStatus_mutation_response: ResolverTypeWrapper<UserStatus_Mutation_Response>;
  userStatus_obj_rel_insert_input: UserStatus_Obj_Rel_Insert_Input;
  userStatus_on_conflict: UserStatus_On_Conflict;
  userStatus_order_by: UserStatus_Order_By;
  userStatus_pk_columns_input: UserStatus_Pk_Columns_Input;
  userStatus_select_column: UserStatus_Select_Column;
  userStatus_set_input: UserStatus_Set_Input;
  userStatus_update_column: UserStatus_Update_Column;
  user_allowlist: ResolverTypeWrapper<User_Allowlist>;
  user_allowlist_aggregate: ResolverTypeWrapper<User_Allowlist_Aggregate>;
  user_allowlist_aggregate_fields: ResolverTypeWrapper<User_Allowlist_Aggregate_Fields>;
  user_allowlist_bool_exp: User_Allowlist_Bool_Exp;
  user_allowlist_constraint: User_Allowlist_Constraint;
  user_allowlist_insert_input: User_Allowlist_Insert_Input;
  user_allowlist_max_fields: ResolverTypeWrapper<User_Allowlist_Max_Fields>;
  user_allowlist_min_fields: ResolverTypeWrapper<User_Allowlist_Min_Fields>;
  user_allowlist_mutation_response: ResolverTypeWrapper<User_Allowlist_Mutation_Response>;
  user_allowlist_on_conflict: User_Allowlist_On_Conflict;
  user_allowlist_order_by: User_Allowlist_Order_By;
  user_allowlist_pk_columns_input: User_Allowlist_Pk_Columns_Input;
  user_allowlist_select_column: User_Allowlist_Select_Column;
  user_allowlist_set_input: User_Allowlist_Set_Input;
  user_allowlist_update_column: User_Allowlist_Update_Column;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  uuid_comparison_exp: Uuid_Comparison_Exp;
  videoProvider: ResolverTypeWrapper<VideoProvider>;
  videoProvider_aggregate: ResolverTypeWrapper<VideoProvider_Aggregate>;
  videoProvider_aggregate_fields: ResolverTypeWrapper<VideoProvider_Aggregate_Fields>;
  videoProvider_bool_exp: VideoProvider_Bool_Exp;
  videoProvider_constraint: VideoProvider_Constraint;
  videoProvider_enum: VideoProvider_Enum;
  videoProvider_enum_comparison_exp: VideoProvider_Enum_Comparison_Exp;
  videoProvider_insert_input: VideoProvider_Insert_Input;
  videoProvider_max_fields: ResolverTypeWrapper<VideoProvider_Max_Fields>;
  videoProvider_min_fields: ResolverTypeWrapper<VideoProvider_Min_Fields>;
  videoProvider_mutation_response: ResolverTypeWrapper<VideoProvider_Mutation_Response>;
  videoProvider_on_conflict: VideoProvider_On_Conflict;
  videoProvider_order_by: VideoProvider_Order_By;
  videoProvider_pk_columns_input: VideoProvider_Pk_Columns_Input;
  videoProvider_select_column: VideoProvider_Select_Column;
  videoProvider_set_input: VideoProvider_Set_Input;
  videoProvider_update_column: VideoProvider_Update_Column;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AttendeeAvailability: AttendeeAvailability;
  Bestie: Bestie;
  Birthday: Birthday;
  BirthdaysViewInput: BirthdaysViewInput;
  BirthdaysViewOutput: BirthdaysViewOutput;
  BookCalendarSlotInput: BookCalendarSlotInput;
  BookCalendarSlotOutput: BookCalendarSlotOutput;
  Boolean: Scalars['Boolean'];
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  CalendarOwner: CalendarOwner;
  CalendarRole: CalendarRole;
  CalendarSlot: CalendarSlot;
  CalendarSlotInput: CalendarSlotInput;
  CategoryUpsertInput: CategoryUpsertInput;
  CheckAliasAvailabilityOutput: CheckAliasAvailabilityOutput;
  ClearEventsCacheInput: ClearEventsCacheInput;
  ClearEventsCacheOutput: ClearEventsCacheOutput;
  ContactReminderOutput: ContactReminderOutput;
  CreateCalendarLinkInput: CreateCalendarLinkInput;
  CreateCalendarLinkOutput: CreateCalendarLinkOutput;
  CreateContactsInput: CreateContactsInput;
  CreateContactsOutput: CreateContactsOutput;
  CreateEventInput: CreateEventInput;
  CreateEventOutput: CreateEventOutput;
  DateTime: Scalars['DateTime'];
  DateTimeRFC3339: Scalars['DateTimeRFC3339'];
  DeleteEventFilter: DeleteEventFilter;
  DeleteEventOutput: DeleteEventOutput;
  DisplayOptions: DisplayOptions;
  EventCreator: EventCreator;
  EventsResponse: EventsResponse;
  Feedback: Feedback;
  FindNextAvailableSlotArgs: FindNextAvailableSlotArgs;
  FindNextAvailableSlotResponse: FindNextAvailableSlotResponse;
  Float: Scalars['Float'];
  GMapsPlace: GMapsPlace;
  GetGMapsPlacesInput: GetGMapsPlacesInput;
  GetGMapsPlacesOutput: GetGMapsPlacesOutput;
  HideCalendarInput: HideCalendarInput;
  HideCalendarOutput: HideCalendarOutput;
  ID: Scalars['ID'];
  InCalendarsFilter: InCalendarsFilter;
  Int: Scalars['Int'];
  Int_comparison_exp: Int_Comparison_Exp;
  LightweightEventsFilter: LightweightEventsFilter;
  MinimalCalendarInfo: MinimalCalendarInfo;
  NewCalendar: NewCalendar;
  NewCalendarFilter: NewCalendarFilter;
  NewEvent: NewEvent;
  NewEventAttendee: NewEventAttendee;
  NewEventFilter: NewEventFilter;
  NewEventVideoConference: NewEventVideoConference;
  ProfileMeetInfoInput: ProfileMeetInfoInput;
  ProfileMeetInfoOutput: ProfileMeetInfoOutput;
  ProfileViewOutput: ProfileViewOutput;
  RecentlyPlayedTrack: RecentlyPlayedTrack;
  RefreshActivityFeedOutput: RefreshActivityFeedOutput;
  RetrieveSharedCalendarLinkInput: RetrieveSharedCalendarLinkInput;
  RetrieveSharedCalendarLinkOutput: RetrieveSharedCalendarLinkOutput;
  SendFeedbackInput: SendFeedbackInput;
  SendFeedbackOutput: SendFeedbackOutput;
  SetBirthdayReminderInput: SetBirthdayReminderInput;
  SetBirthdayReminderOutput: SetBirthdayReminderOutput;
  SetEventRecurrenceInput: SetEventRecurrenceInput;
  SetEventRecurrenceOutput: SetEventRecurrenceOutput;
  SetReminderInput: SetReminderInput;
  SetReminderOutput: SetReminderOutput;
  ShowCalendarInput: ShowCalendarInput;
  ShowCalendarOutput: ShowCalendarOutput;
  SignInUsingFirebaseAuthenticationInput: SignInUsingFirebaseAuthenticationInput;
  SignInUsingFirebaseAuthenticationOutput: SignInUsingFirebaseAuthenticationOutput;
  SignInUsingGoogleAuthorizationCodeInput: SignInUsingGoogleAuthorizationCodeInput;
  SignInUsingGoogleAuthorizationCodeOutput: SignInUsingGoogleAuthorizationCodeOutput;
  SingleEventInput: SingleEventInput;
  Slot: Slot;
  SpotifyPauseTrack: SpotifyPauseTrack;
  SpotifyPlayTrack: SpotifyPlayTrack;
  SpotifyQueue: SpotifyQueue;
  SpotifyTrack: SpotifyTrack;
  String: Scalars['String'];
  String_comparison_exp: String_Comparison_Exp;
  SyncContactsInput: SyncContactsInput;
  SyncContactsOutput: SyncContactsOutput;
  TodoCategory: TodoCategory;
  TodoItem: TodoItem;
  TodoUpsertInput: TodoUpsertInput;
  ToggleEventDoneFilter: ToggleEventDoneFilter;
  ToggleEventDoneOutput: ToggleEventDoneOutput;
  UpdateCalendarInput: UpdateCalendarInput;
  UpdateCalendarLinkInput: UpdateCalendarLinkInput;
  UpdateCalendarLinkOutput: UpdateCalendarLinkOutput;
  UpdateEventInput: UpdateEventInput;
  UpdateEventOutput: UpdateEventOutput;
  UpdateEventRSVPInput: UpdateEventRsvpInput;
  UpdateEventRSVPOutput: UpdateEventRsvpOutput;
  UpdateTodosInput: UpdateTodosInput;
  UpdateTodosOutput: UpdateTodosOutput;
  activityFeed: ActivityFeed;
  activityFeedTypeEnum: ActivityFeedTypeEnum;
  activityFeedTypeEnum_aggregate: ActivityFeedTypeEnum_Aggregate;
  activityFeedTypeEnum_aggregate_fields: ActivityFeedTypeEnum_Aggregate_Fields;
  activityFeedTypeEnum_bool_exp: ActivityFeedTypeEnum_Bool_Exp;
  activityFeedTypeEnum_enum_comparison_exp: ActivityFeedTypeEnum_Enum_Comparison_Exp;
  activityFeedTypeEnum_insert_input: ActivityFeedTypeEnum_Insert_Input;
  activityFeedTypeEnum_max_fields: ActivityFeedTypeEnum_Max_Fields;
  activityFeedTypeEnum_min_fields: ActivityFeedTypeEnum_Min_Fields;
  activityFeedTypeEnum_mutation_response: ActivityFeedTypeEnum_Mutation_Response;
  activityFeedTypeEnum_obj_rel_insert_input: ActivityFeedTypeEnum_Obj_Rel_Insert_Input;
  activityFeedTypeEnum_on_conflict: ActivityFeedTypeEnum_On_Conflict;
  activityFeedTypeEnum_order_by: ActivityFeedTypeEnum_Order_By;
  activityFeedTypeEnum_pk_columns_input: ActivityFeedTypeEnum_Pk_Columns_Input;
  activityFeedTypeEnum_set_input: ActivityFeedTypeEnum_Set_Input;
  activityFeed_aggregate: ActivityFeed_Aggregate;
  activityFeed_aggregate_fields: ActivityFeed_Aggregate_Fields;
  activityFeed_append_input: ActivityFeed_Append_Input;
  activityFeed_bool_exp: ActivityFeed_Bool_Exp;
  activityFeed_delete_at_path_input: ActivityFeed_Delete_At_Path_Input;
  activityFeed_delete_elem_input: ActivityFeed_Delete_Elem_Input;
  activityFeed_delete_key_input: ActivityFeed_Delete_Key_Input;
  activityFeed_insert_input: ActivityFeed_Insert_Input;
  activityFeed_max_fields: ActivityFeed_Max_Fields;
  activityFeed_min_fields: ActivityFeed_Min_Fields;
  activityFeed_mutation_response: ActivityFeed_Mutation_Response;
  activityFeed_obj_rel_insert_input: ActivityFeed_Obj_Rel_Insert_Input;
  activityFeed_on_conflict: ActivityFeed_On_Conflict;
  activityFeed_order_by: ActivityFeed_Order_By;
  activityFeed_pk_columns_input: ActivityFeed_Pk_Columns_Input;
  activityFeed_prepend_input: ActivityFeed_Prepend_Input;
  activityFeed_set_input: ActivityFeed_Set_Input;
  analytics_event: Analytics_Event;
  analytics_event_aggregate: Analytics_Event_Aggregate;
  analytics_event_aggregate_fields: Analytics_Event_Aggregate_Fields;
  analytics_event_append_input: Analytics_Event_Append_Input;
  analytics_event_bool_exp: Analytics_Event_Bool_Exp;
  analytics_event_delete_at_path_input: Analytics_Event_Delete_At_Path_Input;
  analytics_event_delete_elem_input: Analytics_Event_Delete_Elem_Input;
  analytics_event_delete_key_input: Analytics_Event_Delete_Key_Input;
  analytics_event_insert_input: Analytics_Event_Insert_Input;
  analytics_event_max_fields: Analytics_Event_Max_Fields;
  analytics_event_min_fields: Analytics_Event_Min_Fields;
  analytics_event_mutation_response: Analytics_Event_Mutation_Response;
  analytics_event_on_conflict: Analytics_Event_On_Conflict;
  analytics_event_order_by: Analytics_Event_Order_By;
  analytics_event_pk_columns_input: Analytics_Event_Pk_Columns_Input;
  analytics_event_prepend_input: Analytics_Event_Prepend_Input;
  analytics_event_set_input: Analytics_Event_Set_Input;
  calendarHistory: CalendarHistory;
  calendarHistory_aggregate: CalendarHistory_Aggregate;
  calendarHistory_aggregate_fields: CalendarHistory_Aggregate_Fields;
  calendarHistory_bool_exp: CalendarHistory_Bool_Exp;
  calendarHistory_insert_input: CalendarHistory_Insert_Input;
  calendarHistory_max_fields: CalendarHistory_Max_Fields;
  calendarHistory_min_fields: CalendarHistory_Min_Fields;
  calendarHistory_mutation_response: CalendarHistory_Mutation_Response;
  calendarHistory_on_conflict: CalendarHistory_On_Conflict;
  calendarHistory_order_by: CalendarHistory_Order_By;
  calendarHistory_pk_columns_input: CalendarHistory_Pk_Columns_Input;
  calendarHistory_set_input: CalendarHistory_Set_Input;
  calendarPreference: CalendarPreference;
  calendarPreference_aggregate: CalendarPreference_Aggregate;
  calendarPreference_aggregate_fields: CalendarPreference_Aggregate_Fields;
  calendarPreference_bool_exp: CalendarPreference_Bool_Exp;
  calendarPreference_insert_input: CalendarPreference_Insert_Input;
  calendarPreference_max_fields: CalendarPreference_Max_Fields;
  calendarPreference_min_fields: CalendarPreference_Min_Fields;
  calendarPreference_mutation_response: CalendarPreference_Mutation_Response;
  calendarPreference_obj_rel_insert_input: CalendarPreference_Obj_Rel_Insert_Input;
  calendarPreference_on_conflict: CalendarPreference_On_Conflict;
  calendarPreference_order_by: CalendarPreference_Order_By;
  calendarPreference_pk_columns_input: CalendarPreference_Pk_Columns_Input;
  calendarPreference_set_input: CalendarPreference_Set_Input;
  calendarStartsOn: CalendarStartsOn;
  calendarStartsOn_aggregate: CalendarStartsOn_Aggregate;
  calendarStartsOn_aggregate_fields: CalendarStartsOn_Aggregate_Fields;
  calendarStartsOn_bool_exp: CalendarStartsOn_Bool_Exp;
  calendarStartsOn_enum_comparison_exp: CalendarStartsOn_Enum_Comparison_Exp;
  calendarStartsOn_insert_input: CalendarStartsOn_Insert_Input;
  calendarStartsOn_max_fields: CalendarStartsOn_Max_Fields;
  calendarStartsOn_min_fields: CalendarStartsOn_Min_Fields;
  calendarStartsOn_mutation_response: CalendarStartsOn_Mutation_Response;
  calendarStartsOn_on_conflict: CalendarStartsOn_On_Conflict;
  calendarStartsOn_order_by: CalendarStartsOn_Order_By;
  calendarStartsOn_pk_columns_input: CalendarStartsOn_Pk_Columns_Input;
  calendarStartsOn_set_input: CalendarStartsOn_Set_Input;
  category: Category;
  category_aggregate: Category_Aggregate;
  category_aggregate_fields: Category_Aggregate_Fields;
  category_avg_fields: Category_Avg_Fields;
  category_bool_exp: Category_Bool_Exp;
  category_inc_input: Category_Inc_Input;
  category_insert_input: Category_Insert_Input;
  category_max_fields: Category_Max_Fields;
  category_min_fields: Category_Min_Fields;
  category_mutation_response: Category_Mutation_Response;
  category_obj_rel_insert_input: Category_Obj_Rel_Insert_Input;
  category_on_conflict: Category_On_Conflict;
  category_order_by: Category_Order_By;
  category_pk_columns_input: Category_Pk_Columns_Input;
  category_set_input: Category_Set_Input;
  category_stddev_fields: Category_Stddev_Fields;
  category_stddev_pop_fields: Category_Stddev_Pop_Fields;
  category_stddev_samp_fields: Category_Stddev_Samp_Fields;
  category_sum_fields: Category_Sum_Fields;
  category_var_pop_fields: Category_Var_Pop_Fields;
  category_var_samp_fields: Category_Var_Samp_Fields;
  category_variance_fields: Category_Variance_Fields;
  colorFamilyEnum: ColorFamilyEnum;
  colorFamilyEnum_aggregate: ColorFamilyEnum_Aggregate;
  colorFamilyEnum_aggregate_fields: ColorFamilyEnum_Aggregate_Fields;
  colorFamilyEnum_bool_exp: ColorFamilyEnum_Bool_Exp;
  colorFamilyEnum_enum_comparison_exp: ColorFamilyEnum_Enum_Comparison_Exp;
  colorFamilyEnum_insert_input: ColorFamilyEnum_Insert_Input;
  colorFamilyEnum_max_fields: ColorFamilyEnum_Max_Fields;
  colorFamilyEnum_min_fields: ColorFamilyEnum_Min_Fields;
  colorFamilyEnum_mutation_response: ColorFamilyEnum_Mutation_Response;
  colorFamilyEnum_on_conflict: ColorFamilyEnum_On_Conflict;
  colorFamilyEnum_order_by: ColorFamilyEnum_Order_By;
  colorFamilyEnum_pk_columns_input: ColorFamilyEnum_Pk_Columns_Input;
  colorFamilyEnum_set_input: ColorFamilyEnum_Set_Input;
  contact_sync: Contact_Sync;
  contact_sync_aggregate: Contact_Sync_Aggregate;
  contact_sync_aggregate_fields: Contact_Sync_Aggregate_Fields;
  contact_sync_bool_exp: Contact_Sync_Bool_Exp;
  contact_sync_insert_input: Contact_Sync_Insert_Input;
  contact_sync_max_fields: Contact_Sync_Max_Fields;
  contact_sync_min_fields: Contact_Sync_Min_Fields;
  contact_sync_mutation_response: Contact_Sync_Mutation_Response;
  contact_sync_on_conflict: Contact_Sync_On_Conflict;
  contact_sync_order_by: Contact_Sync_Order_By;
  contact_sync_pk_columns_input: Contact_Sync_Pk_Columns_Input;
  contact_sync_set_input: Contact_Sync_Set_Input;
  databasechangelog: Databasechangelog;
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  databasechangelog_aggregate_fields: Databasechangelog_Aggregate_Fields;
  databasechangelog_avg_fields: Databasechangelog_Avg_Fields;
  databasechangelog_bool_exp: Databasechangelog_Bool_Exp;
  databasechangelog_inc_input: Databasechangelog_Inc_Input;
  databasechangelog_insert_input: Databasechangelog_Insert_Input;
  databasechangelog_max_fields: Databasechangelog_Max_Fields;
  databasechangelog_min_fields: Databasechangelog_Min_Fields;
  databasechangelog_mutation_response: Databasechangelog_Mutation_Response;
  databasechangelog_order_by: Databasechangelog_Order_By;
  databasechangelog_set_input: Databasechangelog_Set_Input;
  databasechangelog_stddev_fields: Databasechangelog_Stddev_Fields;
  databasechangelog_stddev_pop_fields: Databasechangelog_Stddev_Pop_Fields;
  databasechangelog_stddev_samp_fields: Databasechangelog_Stddev_Samp_Fields;
  databasechangelog_sum_fields: Databasechangelog_Sum_Fields;
  databasechangelog_var_pop_fields: Databasechangelog_Var_Pop_Fields;
  databasechangelog_var_samp_fields: Databasechangelog_Var_Samp_Fields;
  databasechangelog_variance_fields: Databasechangelog_Variance_Fields;
  databasechangeloglock: Databasechangeloglock;
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  databasechangeloglock_aggregate_fields: Databasechangeloglock_Aggregate_Fields;
  databasechangeloglock_avg_fields: Databasechangeloglock_Avg_Fields;
  databasechangeloglock_bool_exp: Databasechangeloglock_Bool_Exp;
  databasechangeloglock_inc_input: Databasechangeloglock_Inc_Input;
  databasechangeloglock_insert_input: Databasechangeloglock_Insert_Input;
  databasechangeloglock_max_fields: Databasechangeloglock_Max_Fields;
  databasechangeloglock_min_fields: Databasechangeloglock_Min_Fields;
  databasechangeloglock_mutation_response: Databasechangeloglock_Mutation_Response;
  databasechangeloglock_on_conflict: Databasechangeloglock_On_Conflict;
  databasechangeloglock_order_by: Databasechangeloglock_Order_By;
  databasechangeloglock_pk_columns_input: Databasechangeloglock_Pk_Columns_Input;
  databasechangeloglock_set_input: Databasechangeloglock_Set_Input;
  databasechangeloglock_stddev_fields: Databasechangeloglock_Stddev_Fields;
  databasechangeloglock_stddev_pop_fields: Databasechangeloglock_Stddev_Pop_Fields;
  databasechangeloglock_stddev_samp_fields: Databasechangeloglock_Stddev_Samp_Fields;
  databasechangeloglock_sum_fields: Databasechangeloglock_Sum_Fields;
  databasechangeloglock_var_pop_fields: Databasechangeloglock_Var_Pop_Fields;
  databasechangeloglock_var_samp_fields: Databasechangeloglock_Var_Samp_Fields;
  databasechangeloglock_variance_fields: Databasechangeloglock_Variance_Fields;
  date: Scalars['date'];
  dateFormat: DateFormat;
  dateFormat_aggregate: DateFormat_Aggregate;
  dateFormat_aggregate_fields: DateFormat_Aggregate_Fields;
  dateFormat_bool_exp: DateFormat_Bool_Exp;
  dateFormat_enum_comparison_exp: DateFormat_Enum_Comparison_Exp;
  dateFormat_insert_input: DateFormat_Insert_Input;
  dateFormat_max_fields: DateFormat_Max_Fields;
  dateFormat_min_fields: DateFormat_Min_Fields;
  dateFormat_mutation_response: DateFormat_Mutation_Response;
  dateFormat_on_conflict: DateFormat_On_Conflict;
  dateFormat_order_by: DateFormat_Order_By;
  dateFormat_pk_columns_input: DateFormat_Pk_Columns_Input;
  dateFormat_set_input: DateFormat_Set_Input;
  date_comparison_exp: Date_Comparison_Exp;
  google_credential: Google_Credential;
  google_credential_aggregate: Google_Credential_Aggregate;
  google_credential_aggregate_fields: Google_Credential_Aggregate_Fields;
  google_credential_bool_exp: Google_Credential_Bool_Exp;
  google_credential_insert_input: Google_Credential_Insert_Input;
  google_credential_max_fields: Google_Credential_Max_Fields;
  google_credential_min_fields: Google_Credential_Min_Fields;
  google_credential_mutation_response: Google_Credential_Mutation_Response;
  google_credential_obj_rel_insert_input: Google_Credential_Obj_Rel_Insert_Input;
  google_credential_on_conflict: Google_Credential_On_Conflict;
  google_credential_order_by: Google_Credential_Order_By;
  google_credential_pk_columns_input: Google_Credential_Pk_Columns_Input;
  google_credential_set_input: Google_Credential_Set_Input;
  jsonb: Scalars['jsonb'];
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  linear_issue: Linear_Issue;
  linear_issue_aggregate: Linear_Issue_Aggregate;
  linear_issue_aggregate_fields: Linear_Issue_Aggregate_Fields;
  linear_issue_bool_exp: Linear_Issue_Bool_Exp;
  linear_issue_insert_input: Linear_Issue_Insert_Input;
  linear_issue_max_fields: Linear_Issue_Max_Fields;
  linear_issue_min_fields: Linear_Issue_Min_Fields;
  linear_issue_mutation_response: Linear_Issue_Mutation_Response;
  linear_issue_on_conflict: Linear_Issue_On_Conflict;
  linear_issue_order_by: Linear_Issue_Order_By;
  linear_issue_pk_columns_input: Linear_Issue_Pk_Columns_Input;
  linear_issue_set_input: Linear_Issue_Set_Input;
  mutation_root: {};
  new_contact: New_Contact;
  new_contact_aggregate: New_Contact_Aggregate;
  new_contact_aggregate_fields: New_Contact_Aggregate_Fields;
  new_contact_append_input: New_Contact_Append_Input;
  new_contact_bool_exp: New_Contact_Bool_Exp;
  new_contact_delete_at_path_input: New_Contact_Delete_At_Path_Input;
  new_contact_delete_elem_input: New_Contact_Delete_Elem_Input;
  new_contact_delete_key_input: New_Contact_Delete_Key_Input;
  new_contact_insert_input: New_Contact_Insert_Input;
  new_contact_max_fields: New_Contact_Max_Fields;
  new_contact_min_fields: New_Contact_Min_Fields;
  new_contact_mutation_response: New_Contact_Mutation_Response;
  new_contact_on_conflict: New_Contact_On_Conflict;
  new_contact_order_by: New_Contact_Order_By;
  new_contact_pk_columns_input: New_Contact_Pk_Columns_Input;
  new_contact_prepend_input: New_Contact_Prepend_Input;
  new_contact_set_input: New_Contact_Set_Input;
  new_feedback: New_Feedback;
  new_feedback_aggregate: New_Feedback_Aggregate;
  new_feedback_aggregate_fields: New_Feedback_Aggregate_Fields;
  new_feedback_bool_exp: New_Feedback_Bool_Exp;
  new_feedback_insert_input: New_Feedback_Insert_Input;
  new_feedback_max_fields: New_Feedback_Max_Fields;
  new_feedback_min_fields: New_Feedback_Min_Fields;
  new_feedback_mutation_response: New_Feedback_Mutation_Response;
  new_feedback_on_conflict: New_Feedback_On_Conflict;
  new_feedback_order_by: New_Feedback_Order_By;
  new_feedback_pk_columns_input: New_Feedback_Pk_Columns_Input;
  new_feedback_set_input: New_Feedback_Set_Input;
  new_user: New_User;
  new_user_aggregate: New_User_Aggregate;
  new_user_aggregate_fields: New_User_Aggregate_Fields;
  new_user_bool_exp: New_User_Bool_Exp;
  new_user_insert_input: New_User_Insert_Input;
  new_user_max_fields: New_User_Max_Fields;
  new_user_min_fields: New_User_Min_Fields;
  new_user_mutation_response: New_User_Mutation_Response;
  new_user_obj_rel_insert_input: New_User_Obj_Rel_Insert_Input;
  new_user_on_conflict: New_User_On_Conflict;
  new_user_order_by: New_User_Order_By;
  new_user_pk_columns_input: New_User_Pk_Columns_Input;
  new_user_set_input: New_User_Set_Input;
  profile: Profile;
  profile_aggregate: Profile_Aggregate;
  profile_aggregate_fields: Profile_Aggregate_Fields;
  profile_bool_exp: Profile_Bool_Exp;
  profile_insert_input: Profile_Insert_Input;
  profile_max_fields: Profile_Max_Fields;
  profile_min_fields: Profile_Min_Fields;
  profile_mutation_response: Profile_Mutation_Response;
  profile_on_conflict: Profile_On_Conflict;
  profile_order_by: Profile_Order_By;
  profile_pk_columns_input: Profile_Pk_Columns_Input;
  profile_set_input: Profile_Set_Input;
  query_root: {};
  refreshUserStatusOutput: RefreshUserStatusOutput;
  shareCalendarLink: ShareCalendarLink;
  shareCalendarLinkTypeEnum: ShareCalendarLinkTypeEnum;
  shareCalendarLinkTypeEnum_aggregate: ShareCalendarLinkTypeEnum_Aggregate;
  shareCalendarLinkTypeEnum_aggregate_fields: ShareCalendarLinkTypeEnum_Aggregate_Fields;
  shareCalendarLinkTypeEnum_bool_exp: ShareCalendarLinkTypeEnum_Bool_Exp;
  shareCalendarLinkTypeEnum_enum_comparison_exp: ShareCalendarLinkTypeEnum_Enum_Comparison_Exp;
  shareCalendarLinkTypeEnum_insert_input: ShareCalendarLinkTypeEnum_Insert_Input;
  shareCalendarLinkTypeEnum_max_fields: ShareCalendarLinkTypeEnum_Max_Fields;
  shareCalendarLinkTypeEnum_min_fields: ShareCalendarLinkTypeEnum_Min_Fields;
  shareCalendarLinkTypeEnum_mutation_response: ShareCalendarLinkTypeEnum_Mutation_Response;
  shareCalendarLinkTypeEnum_on_conflict: ShareCalendarLinkTypeEnum_On_Conflict;
  shareCalendarLinkTypeEnum_order_by: ShareCalendarLinkTypeEnum_Order_By;
  shareCalendarLinkTypeEnum_pk_columns_input: ShareCalendarLinkTypeEnum_Pk_Columns_Input;
  shareCalendarLinkTypeEnum_set_input: ShareCalendarLinkTypeEnum_Set_Input;
  shareCalendarLink_aggregate: ShareCalendarLink_Aggregate;
  shareCalendarLink_aggregate_fields: ShareCalendarLink_Aggregate_Fields;
  shareCalendarLink_append_input: ShareCalendarLink_Append_Input;
  shareCalendarLink_avg_fields: ShareCalendarLink_Avg_Fields;
  shareCalendarLink_bool_exp: ShareCalendarLink_Bool_Exp;
  shareCalendarLink_delete_at_path_input: ShareCalendarLink_Delete_At_Path_Input;
  shareCalendarLink_delete_elem_input: ShareCalendarLink_Delete_Elem_Input;
  shareCalendarLink_delete_key_input: ShareCalendarLink_Delete_Key_Input;
  shareCalendarLink_inc_input: ShareCalendarLink_Inc_Input;
  shareCalendarLink_insert_input: ShareCalendarLink_Insert_Input;
  shareCalendarLink_max_fields: ShareCalendarLink_Max_Fields;
  shareCalendarLink_min_fields: ShareCalendarLink_Min_Fields;
  shareCalendarLink_mutation_response: ShareCalendarLink_Mutation_Response;
  shareCalendarLink_on_conflict: ShareCalendarLink_On_Conflict;
  shareCalendarLink_order_by: ShareCalendarLink_Order_By;
  shareCalendarLink_pk_columns_input: ShareCalendarLink_Pk_Columns_Input;
  shareCalendarLink_prepend_input: ShareCalendarLink_Prepend_Input;
  shareCalendarLink_set_input: ShareCalendarLink_Set_Input;
  shareCalendarLink_stddev_fields: ShareCalendarLink_Stddev_Fields;
  shareCalendarLink_stddev_pop_fields: ShareCalendarLink_Stddev_Pop_Fields;
  shareCalendarLink_stddev_samp_fields: ShareCalendarLink_Stddev_Samp_Fields;
  shareCalendarLink_sum_fields: ShareCalendarLink_Sum_Fields;
  shareCalendarLink_var_pop_fields: ShareCalendarLink_Var_Pop_Fields;
  shareCalendarLink_var_samp_fields: ShareCalendarLink_Var_Samp_Fields;
  shareCalendarLink_variance_fields: ShareCalendarLink_Variance_Fields;
  subscription_root: {};
  theme: Theme;
  theme_aggregate: Theme_Aggregate;
  theme_aggregate_fields: Theme_Aggregate_Fields;
  theme_bool_exp: Theme_Bool_Exp;
  theme_enum_comparison_exp: Theme_Enum_Comparison_Exp;
  theme_insert_input: Theme_Insert_Input;
  theme_max_fields: Theme_Max_Fields;
  theme_min_fields: Theme_Min_Fields;
  theme_mutation_response: Theme_Mutation_Response;
  theme_on_conflict: Theme_On_Conflict;
  theme_order_by: Theme_Order_By;
  theme_pk_columns_input: Theme_Pk_Columns_Input;
  theme_set_input: Theme_Set_Input;
  timestamp: Scalars['timestamp'];
  timestamp_comparison_exp: Timestamp_Comparison_Exp;
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  todo: Todo;
  todoTypeEnum: TodoTypeEnum;
  todoTypeEnum_aggregate: TodoTypeEnum_Aggregate;
  todoTypeEnum_aggregate_fields: TodoTypeEnum_Aggregate_Fields;
  todoTypeEnum_bool_exp: TodoTypeEnum_Bool_Exp;
  todoTypeEnum_enum_comparison_exp: TodoTypeEnum_Enum_Comparison_Exp;
  todoTypeEnum_insert_input: TodoTypeEnum_Insert_Input;
  todoTypeEnum_max_fields: TodoTypeEnum_Max_Fields;
  todoTypeEnum_min_fields: TodoTypeEnum_Min_Fields;
  todoTypeEnum_mutation_response: TodoTypeEnum_Mutation_Response;
  todoTypeEnum_on_conflict: TodoTypeEnum_On_Conflict;
  todoTypeEnum_order_by: TodoTypeEnum_Order_By;
  todoTypeEnum_pk_columns_input: TodoTypeEnum_Pk_Columns_Input;
  todoTypeEnum_set_input: TodoTypeEnum_Set_Input;
  todo_aggregate: Todo_Aggregate;
  todo_aggregate_fields: Todo_Aggregate_Fields;
  todo_aggregate_order_by: Todo_Aggregate_Order_By;
  todo_arr_rel_insert_input: Todo_Arr_Rel_Insert_Input;
  todo_avg_fields: Todo_Avg_Fields;
  todo_avg_order_by: Todo_Avg_Order_By;
  todo_bool_exp: Todo_Bool_Exp;
  todo_inc_input: Todo_Inc_Input;
  todo_insert_input: Todo_Insert_Input;
  todo_max_fields: Todo_Max_Fields;
  todo_max_order_by: Todo_Max_Order_By;
  todo_min_fields: Todo_Min_Fields;
  todo_min_order_by: Todo_Min_Order_By;
  todo_mutation_response: Todo_Mutation_Response;
  todo_on_conflict: Todo_On_Conflict;
  todo_order_by: Todo_Order_By;
  todo_pk_columns_input: Todo_Pk_Columns_Input;
  todo_set_input: Todo_Set_Input;
  todo_stddev_fields: Todo_Stddev_Fields;
  todo_stddev_order_by: Todo_Stddev_Order_By;
  todo_stddev_pop_fields: Todo_Stddev_Pop_Fields;
  todo_stddev_pop_order_by: Todo_Stddev_Pop_Order_By;
  todo_stddev_samp_fields: Todo_Stddev_Samp_Fields;
  todo_stddev_samp_order_by: Todo_Stddev_Samp_Order_By;
  todo_sum_fields: Todo_Sum_Fields;
  todo_sum_order_by: Todo_Sum_Order_By;
  todo_var_pop_fields: Todo_Var_Pop_Fields;
  todo_var_pop_order_by: Todo_Var_Pop_Order_By;
  todo_var_samp_fields: Todo_Var_Samp_Fields;
  todo_var_samp_order_by: Todo_Var_Samp_Order_By;
  todo_variance_fields: Todo_Variance_Fields;
  todo_variance_order_by: Todo_Variance_Order_By;
  userActivityFeed: UserActivityFeed;
  userActivityFeed_aggregate: UserActivityFeed_Aggregate;
  userActivityFeed_aggregate_fields: UserActivityFeed_Aggregate_Fields;
  userActivityFeed_aggregate_order_by: UserActivityFeed_Aggregate_Order_By;
  userActivityFeed_arr_rel_insert_input: UserActivityFeed_Arr_Rel_Insert_Input;
  userActivityFeed_bool_exp: UserActivityFeed_Bool_Exp;
  userActivityFeed_insert_input: UserActivityFeed_Insert_Input;
  userActivityFeed_max_fields: UserActivityFeed_Max_Fields;
  userActivityFeed_max_order_by: UserActivityFeed_Max_Order_By;
  userActivityFeed_min_fields: UserActivityFeed_Min_Fields;
  userActivityFeed_min_order_by: UserActivityFeed_Min_Order_By;
  userActivityFeed_mutation_response: UserActivityFeed_Mutation_Response;
  userActivityFeed_on_conflict: UserActivityFeed_On_Conflict;
  userActivityFeed_order_by: UserActivityFeed_Order_By;
  userActivityFeed_pk_columns_input: UserActivityFeed_Pk_Columns_Input;
  userActivityFeed_set_input: UserActivityFeed_Set_Input;
  userPreferences: UserPreferences;
  userPreferences_aggregate: UserPreferences_Aggregate;
  userPreferences_aggregate_fields: UserPreferences_Aggregate_Fields;
  userPreferences_avg_fields: UserPreferences_Avg_Fields;
  userPreferences_bool_exp: UserPreferences_Bool_Exp;
  userPreferences_inc_input: UserPreferences_Inc_Input;
  userPreferences_insert_input: UserPreferences_Insert_Input;
  userPreferences_max_fields: UserPreferences_Max_Fields;
  userPreferences_min_fields: UserPreferences_Min_Fields;
  userPreferences_mutation_response: UserPreferences_Mutation_Response;
  userPreferences_obj_rel_insert_input: UserPreferences_Obj_Rel_Insert_Input;
  userPreferences_on_conflict: UserPreferences_On_Conflict;
  userPreferences_order_by: UserPreferences_Order_By;
  userPreferences_pk_columns_input: UserPreferences_Pk_Columns_Input;
  userPreferences_set_input: UserPreferences_Set_Input;
  userPreferences_stddev_fields: UserPreferences_Stddev_Fields;
  userPreferences_stddev_pop_fields: UserPreferences_Stddev_Pop_Fields;
  userPreferences_stddev_samp_fields: UserPreferences_Stddev_Samp_Fields;
  userPreferences_sum_fields: UserPreferences_Sum_Fields;
  userPreferences_var_pop_fields: UserPreferences_Var_Pop_Fields;
  userPreferences_var_samp_fields: UserPreferences_Var_Samp_Fields;
  userPreferences_variance_fields: UserPreferences_Variance_Fields;
  userStatus: UserStatus;
  userStatus_aggregate: UserStatus_Aggregate;
  userStatus_aggregate_fields: UserStatus_Aggregate_Fields;
  userStatus_bool_exp: UserStatus_Bool_Exp;
  userStatus_insert_input: UserStatus_Insert_Input;
  userStatus_max_fields: UserStatus_Max_Fields;
  userStatus_min_fields: UserStatus_Min_Fields;
  userStatus_mutation_response: UserStatus_Mutation_Response;
  userStatus_obj_rel_insert_input: UserStatus_Obj_Rel_Insert_Input;
  userStatus_on_conflict: UserStatus_On_Conflict;
  userStatus_order_by: UserStatus_Order_By;
  userStatus_pk_columns_input: UserStatus_Pk_Columns_Input;
  userStatus_set_input: UserStatus_Set_Input;
  user_allowlist: User_Allowlist;
  user_allowlist_aggregate: User_Allowlist_Aggregate;
  user_allowlist_aggregate_fields: User_Allowlist_Aggregate_Fields;
  user_allowlist_bool_exp: User_Allowlist_Bool_Exp;
  user_allowlist_insert_input: User_Allowlist_Insert_Input;
  user_allowlist_max_fields: User_Allowlist_Max_Fields;
  user_allowlist_min_fields: User_Allowlist_Min_Fields;
  user_allowlist_mutation_response: User_Allowlist_Mutation_Response;
  user_allowlist_on_conflict: User_Allowlist_On_Conflict;
  user_allowlist_order_by: User_Allowlist_Order_By;
  user_allowlist_pk_columns_input: User_Allowlist_Pk_Columns_Input;
  user_allowlist_set_input: User_Allowlist_Set_Input;
  uuid: Scalars['uuid'];
  uuid_comparison_exp: Uuid_Comparison_Exp;
  videoProvider: VideoProvider;
  videoProvider_aggregate: VideoProvider_Aggregate;
  videoProvider_aggregate_fields: VideoProvider_Aggregate_Fields;
  videoProvider_bool_exp: VideoProvider_Bool_Exp;
  videoProvider_enum_comparison_exp: VideoProvider_Enum_Comparison_Exp;
  videoProvider_insert_input: VideoProvider_Insert_Input;
  videoProvider_max_fields: VideoProvider_Max_Fields;
  videoProvider_min_fields: VideoProvider_Min_Fields;
  videoProvider_mutation_response: VideoProvider_Mutation_Response;
  videoProvider_on_conflict: VideoProvider_On_Conflict;
  videoProvider_order_by: VideoProvider_Order_By;
  videoProvider_pk_columns_input: VideoProvider_Pk_Columns_Input;
  videoProvider_set_input: VideoProvider_Set_Input;
};

export type CachedDirectiveArgs = {   refresh?: Scalars['Boolean'];
  ttl?: Scalars['Int']; };

export type CachedDirectiveResolver<Result, Parent, ContextType = any, Args = CachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AttendeeAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeAvailability'] = ResolversParentTypes['AttendeeAvailability']> = {
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  emailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BestieResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bestie'] = ResolversParentTypes['Bestie']> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventsAttended?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BirthdayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Birthday'] = ResolversParentTypes['Birthday']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthdayDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contactId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  daysLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BirthdaysViewOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['BirthdaysViewOutput'] = ResolversParentTypes['BirthdaysViewOutput']> = {
  birthdays?: Resolver<Array<ResolversTypes['Birthday']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookCalendarSlotOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookCalendarSlotOutput'] = ResolversParentTypes['BookCalendarSlotOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalendarOwner'] = ResolversParentTypes['CalendarOwner']> = {
  contactId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalendarRole'] = ResolversParentTypes['CalendarRole']> = {
  accessRole?: Resolver<ResolversTypes['AccessRole'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarSlotResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalendarSlot'] = ResolversParentTypes['CalendarSlot']> = {
  endAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recurrenceRules?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckAliasAvailabilityOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckAliasAvailabilityOutput'] = ResolversParentTypes['CheckAliasAvailabilityOutput']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClearEventsCacheOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClearEventsCacheOutput'] = ResolversParentTypes['ClearEventsCacheOutput']> = {
  calendarId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactReminderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactReminderOutput'] = ResolversParentTypes['ContactReminderOutput']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrenceRules?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  reminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCalendarLinkOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCalendarLinkOutput'] = ResolversParentTypes['CreateCalendarLinkOutput']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateContactsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateContactsOutput'] = ResolversParentTypes['CreateContactsOutput']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEventOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEventOutput'] = ResolversParentTypes['CreateEventOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  willNotify?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DateTimeRfc3339ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeRFC3339'], any> {
  name: 'DateTimeRFC3339';
}

export type DeleteEventOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteEventOutput'] = ResolversParentTypes['DeleteEventOutput']> = {
  eventId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  willNotify?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisplayOptions'] = ResolversParentTypes['DisplayOptions']> = {
  backgroundColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  foregroundColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventCreatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventCreator'] = ResolversParentTypes['EventCreator']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  self?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventsResponse'] = ResolversParentTypes['EventsResponse']> = {
  calendars?: Resolver<Array<ResolversTypes['CalendarRole']>, ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['Feedback'] = ResolversParentTypes['Feedback']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FindNextAvailableSlotResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FindNextAvailableSlotResponse'] = ResolversParentTypes['FindNextAvailableSlotResponse']> = {
  attendeeAvailabilities?: Resolver<Array<ResolversTypes['AttendeeAvailability']>, ParentType, ContextType>;
  slot?: Resolver<Maybe<ResolversTypes['Slot']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GMapsPlaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['GMapsPlace'] = ResolversParentTypes['GMapsPlace']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetGMapsPlacesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetGMapsPlacesOutput'] = ResolversParentTypes['GetGMapsPlacesOutput']> = {
  places?: Resolver<Array<ResolversTypes['GMapsPlace']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HideCalendarOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HideCalendarOutput'] = ResolversParentTypes['HideCalendarOutput']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MinimalCalendarInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['MinimalCalendarInfo'] = ResolversParentTypes['MinimalCalendarInfo']> = {
  colorFamily?: Resolver<ResolversTypes['ColorFamily'], ParentType, ContextType>;
  emoji?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewCalendarResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewCalendar'] = ResolversParentTypes['NewCalendar']> = {
  colorFamily?: Resolver<Maybe<ResolversTypes['ColorFamily']>, ParentType, ContextType>;
  displayOptions?: Resolver<ResolversTypes['DisplayOptions'], ParentType, ContextType>;
  events?: Resolver<Maybe<ResolversTypes['EventsResponse']>, ParentType, ContextType, RequireFields<NewCalendarEventsArgs, never>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['CalendarOwner']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewEvent'] = ResolversParentTypes['NewEvent']> = {
  attendees?: Resolver<Array<ResolversTypes['NewEventAttendee']>, ParentType, ContextType>;
  belongsToUserCalendar?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  calendar?: Resolver<Maybe<ResolversTypes['NewCalendar']>, ParentType, ContextType>;
  calendarId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  canEdit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  colorFamily?: Resolver<Maybe<ResolversTypes['ColorFamily']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['EventCreator']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doneAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doneBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  eventType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  guestsCanInviteOthers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  guestsCanModify?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  guestsCanSeeOtherGuests?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAllDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOwnEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSelfAsAttendee?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prevEndAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  prevStartAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  recurrenceRules?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  recurringEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rsvp?: Resolver<ResolversTypes['NewEventRSVPEnum'], ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  videoConferences?: Resolver<Array<ResolversTypes['NewEventVideoConference']>, ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['NewEventVisibilityEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewEventAttendeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewEventAttendee'] = ResolversParentTypes['NewEventAttendee']> = {
  RSVP?: Resolver<ResolversTypes['NewEventRSVPEnum'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  responseStatus?: Resolver<ResolversTypes['NewEventAttendeeResponseStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewEventVideoConferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewEventVideoConference'] = ResolversParentTypes['NewEventVideoConference']> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['NewVideoConferenceProvider'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileMeetInfoOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileMeetInfoOutput'] = ResolversParentTypes['ProfileMeetInfoOutput']> = {
  lastSeen?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  lastSeenSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfTimesMeet?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileViewOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileViewOutput'] = ResolversParentTypes['ProfileViewOutput']> = {
  birthday?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  contactId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  favoriteSince?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isOwnProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reminderEvent?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifiedAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecentlyPlayedTrackResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecentlyPlayedTrack'] = ResolversParentTypes['RecentlyPlayedTrack']> = {
  album?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  artist?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playedAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefreshActivityFeedOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefreshActivityFeedOutput'] = ResolversParentTypes['RefreshActivityFeedOutput']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RetrieveSharedCalendarLinkOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RetrieveSharedCalendarLinkOutput'] = ResolversParentTypes['RetrieveSharedCalendarLinkOutput']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bufferDurationMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  calendarId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coverImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventMaxDurationMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  eventMinDurationMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slots?: Resolver<Array<ResolversTypes['CalendarSlot']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendFeedbackOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendFeedbackOutput'] = ResolversParentTypes['SendFeedbackOutput']> = {
  feedback?: Resolver<Maybe<ResolversTypes['Feedback']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetBirthdayReminderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetBirthdayReminderOutput'] = ResolversParentTypes['SetBirthdayReminderOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetEventRecurrenceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetEventRecurrenceOutput'] = ResolversParentTypes['SetEventRecurrenceOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetReminderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetReminderOutput'] = ResolversParentTypes['SetReminderOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShowCalendarOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShowCalendarOutput'] = ResolversParentTypes['ShowCalendarOutput']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignInUsingFirebaseAuthenticationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignInUsingFirebaseAuthenticationOutput'] = ResolversParentTypes['SignInUsingFirebaseAuthenticationOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignInUsingGoogleAuthorizationCodeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignInUsingGoogleAuthorizationCodeOutput'] = ResolversParentTypes['SignInUsingGoogleAuthorizationCodeOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotResolvers<ContextType = any, ParentType extends ResolversParentTypes['Slot'] = ResolversParentTypes['Slot']> = {
  endAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyPauseTrackResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyPauseTrack'] = ResolversParentTypes['SpotifyPauseTrack']> = {
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyPlayTrackResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyPlayTrack'] = ResolversParentTypes['SpotifyPlayTrack']> = {
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyQueueResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyQueue'] = ResolversParentTypes['SpotifyQueue']> = {
  tracks?: Resolver<Array<ResolversTypes['RecentlyPlayedTrack']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyTrackResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyTrack'] = ResolversParentTypes['SpotifyTrack']> = {
  album?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  artist?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCurrentlyPlaying?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncContactsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SyncContactsOutput'] = ResolversParentTypes['SyncContactsOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TodoCategory'] = ResolversParentTypes['TodoCategory']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  colorFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  expanded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TodoItem'] = ResolversParentTypes['TodoItem']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  doneAt?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['DateTimeRFC3339']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTimeRFC3339'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleEventDoneOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToggleEventDoneOutput'] = ResolversParentTypes['ToggleEventDoneOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCalendarLinkOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCalendarLinkOutput'] = ResolversParentTypes['UpdateCalendarLinkOutput']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateEventOutput'] = ResolversParentTypes['UpdateEventOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  willNotify?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEventRsvpOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateEventRSVPOutput'] = ResolversParentTypes['UpdateEventRSVPOutput']> = {
  event?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  willNotify?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTodosOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTodosOutput'] = ResolversParentTypes['UpdateTodosOutput']> = {
  categories?: Resolver<Array<ResolversTypes['TodoCategory']>, ParentType, ContextType>;
  todos?: Resolver<Array<ResolversTypes['TodoItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeed'] = ResolversParentTypes['activityFeed']> = {
  activityFeedTypeEnum?: Resolver<ResolversTypes['activityFeedTypeEnum'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<ActivityFeedOwnerArgs, never>>;
  ownerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payload?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<ActivityFeedPayloadArgs, never>>;
  type?: Resolver<ResolversTypes['activityFeedTypeEnum_enum'], ParentType, ContextType>;
  uniqHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userActivityFeeds?: Resolver<Array<ResolversTypes['userActivityFeed']>, ParentType, ContextType, RequireFields<ActivityFeedUserActivityFeedsArgs, never>>;
  userActivityFeeds_aggregate?: Resolver<ResolversTypes['userActivityFeed_aggregate'], ParentType, ContextType, RequireFields<ActivityFeedUserActivityFeeds_AggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeedTypeEnum'] = ResolversParentTypes['activityFeedTypeEnum']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeedTypeEnum_aggregate'] = ResolversParentTypes['activityFeedTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeedTypeEnum_aggregate_fields'] = ResolversParentTypes['activityFeedTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<ActivityFeedTypeEnum_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeedTypeEnum_max_fields'] = ResolversParentTypes['activityFeedTypeEnum_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeedTypeEnum_min_fields'] = ResolversParentTypes['activityFeedTypeEnum_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeedTypeEnum_mutation_response'] = ResolversParentTypes['activityFeedTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeed_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeed_aggregate'] = ResolversParentTypes['activityFeed_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['activityFeed_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['activityFeed']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeed_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeed_aggregate_fields'] = ResolversParentTypes['activityFeed_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<ActivityFeed_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['activityFeed_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['activityFeed_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeed_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeed_max_fields'] = ResolversParentTypes['activityFeed_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeed_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeed_min_fields'] = ResolversParentTypes['activityFeed_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeed_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['activityFeed_mutation_response'] = ResolversParentTypes['activityFeed_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['activityFeed']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Analytics_EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['analytics_event'] = ResolversParentTypes['analytics_event']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<Analytics_EventLabelsArgs, never>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  received_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  sent_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Analytics_Event_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['analytics_event_aggregate'] = ResolversParentTypes['analytics_event_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['analytics_event_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['analytics_event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Analytics_Event_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['analytics_event_aggregate_fields'] = ResolversParentTypes['analytics_event_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Analytics_Event_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['analytics_event_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['analytics_event_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Analytics_Event_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['analytics_event_max_fields'] = ResolversParentTypes['analytics_event_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  received_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sent_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Analytics_Event_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['analytics_event_min_fields'] = ResolversParentTypes['analytics_event_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  received_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sent_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Analytics_Event_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['analytics_event_mutation_response'] = ResolversParentTypes['analytics_event_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['analytics_event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarHistory'] = ResolversParentTypes['calendarHistory']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarHistory_aggregate'] = ResolversParentTypes['calendarHistory_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['calendarHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['calendarHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarHistory_aggregate_fields'] = ResolversParentTypes['calendarHistory_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<CalendarHistory_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['calendarHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['calendarHistory_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarHistory_max_fields'] = ResolversParentTypes['calendarHistory_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarHistory_min_fields'] = ResolversParentTypes['calendarHistory_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarHistory_mutation_response'] = ResolversParentTypes['calendarHistory_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['calendarHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarPreferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarPreference'] = ResolversParentTypes['calendarPreference']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  googleCalendarId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  googleWatchChannelExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleWatchChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleWatchChannelResourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['new_user'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarPreference_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarPreference_aggregate'] = ResolversParentTypes['calendarPreference_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['calendarPreference_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['calendarPreference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarPreference_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarPreference_aggregate_fields'] = ResolversParentTypes['calendarPreference_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<CalendarPreference_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['calendarPreference_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['calendarPreference_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarPreference_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarPreference_max_fields'] = ResolversParentTypes['calendarPreference_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleCalendarId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleWatchChannelExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleWatchChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleWatchChannelResourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarPreference_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarPreference_min_fields'] = ResolversParentTypes['calendarPreference_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleCalendarId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleWatchChannelExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleWatchChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleWatchChannelResourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarPreference_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarPreference_mutation_response'] = ResolversParentTypes['calendarPreference_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['calendarPreference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarStartsOnResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarStartsOn'] = ResolversParentTypes['calendarStartsOn']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarStartsOn_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarStartsOn_aggregate'] = ResolversParentTypes['calendarStartsOn_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['calendarStartsOn_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['calendarStartsOn']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarStartsOn_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarStartsOn_aggregate_fields'] = ResolversParentTypes['calendarStartsOn_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<CalendarStartsOn_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['calendarStartsOn_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['calendarStartsOn_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarStartsOn_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarStartsOn_max_fields'] = ResolversParentTypes['calendarStartsOn_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarStartsOn_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarStartsOn_min_fields'] = ResolversParentTypes['calendarStartsOn_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarStartsOn_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['calendarStartsOn_mutation_response'] = ResolversParentTypes['calendarStartsOn_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['calendarStartsOn']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['category'] = ResolversParentTypes['category']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  colorFamily?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_enum']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expanded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  todos?: Resolver<Array<ResolversTypes['todo']>, ParentType, ContextType, RequireFields<CategoryTodosArgs, never>>;
  todos_aggregate?: Resolver<ResolversTypes['todo_aggregate'], ParentType, ContextType, RequireFields<CategoryTodos_AggregateArgs, never>>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_aggregate'] = ResolversParentTypes['category_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['category_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_aggregate_fields'] = ResolversParentTypes['category_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['category_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Category_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['category_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['category_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['category_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['category_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['category_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['category_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['category_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['category_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['category_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_avg_fields'] = ResolversParentTypes['category_avg_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_max_fields'] = ResolversParentTypes['category_max_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_min_fields'] = ResolversParentTypes['category_min_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_mutation_response'] = ResolversParentTypes['category_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_stddev_fields'] = ResolversParentTypes['category_stddev_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_stddev_pop_fields'] = ResolversParentTypes['category_stddev_pop_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_stddev_samp_fields'] = ResolversParentTypes['category_stddev_samp_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_sum_fields'] = ResolversParentTypes['category_sum_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_var_pop_fields'] = ResolversParentTypes['category_var_pop_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_var_samp_fields'] = ResolversParentTypes['category_var_samp_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_variance_fields'] = ResolversParentTypes['category_variance_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorFamilyEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['colorFamilyEnum'] = ResolversParentTypes['colorFamilyEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorFamilyEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['colorFamilyEnum_aggregate'] = ResolversParentTypes['colorFamilyEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorFamilyEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['colorFamilyEnum_aggregate_fields'] = ResolversParentTypes['colorFamilyEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<ColorFamilyEnum_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorFamilyEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['colorFamilyEnum_max_fields'] = ResolversParentTypes['colorFamilyEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorFamilyEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['colorFamilyEnum_min_fields'] = ResolversParentTypes['colorFamilyEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorFamilyEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['colorFamilyEnum_mutation_response'] = ResolversParentTypes['colorFamilyEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contact_SyncResolvers<ContextType = any, ParentType extends ResolversParentTypes['contact_sync'] = ResolversParentTypes['contact_sync']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  sync_started_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sync_token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contact_Sync_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['contact_sync_aggregate'] = ResolversParentTypes['contact_sync_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['contact_sync_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['contact_sync']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contact_Sync_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contact_sync_aggregate_fields'] = ResolversParentTypes['contact_sync_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Contact_Sync_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['contact_sync_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['contact_sync_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contact_Sync_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contact_sync_max_fields'] = ResolversParentTypes['contact_sync_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sync_started_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sync_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contact_Sync_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contact_sync_min_fields'] = ResolversParentTypes['contact_sync_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sync_started_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sync_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contact_Sync_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['contact_sync_mutation_response'] = ResolversParentTypes['contact_sync_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['contact_sync']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatabasechangelogResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog'] = ResolversParentTypes['databasechangelog']> = {
  author?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contexts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateexecuted?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  deployment_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exectype?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labels?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liquibase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  md5sum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderexecuted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_aggregate'] = ResolversParentTypes['databasechangelog_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['databasechangelog_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['databasechangelog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_aggregate_fields'] = ResolversParentTypes['databasechangelog_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['databasechangelog_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Databasechangelog_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['databasechangelog_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['databasechangelog_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['databasechangelog_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['databasechangelog_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['databasechangelog_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['databasechangelog_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['databasechangelog_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['databasechangelog_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['databasechangelog_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_avg_fields'] = ResolversParentTypes['databasechangelog_avg_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_max_fields'] = ResolversParentTypes['databasechangelog_max_fields']> = {
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contexts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateexecuted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deployment_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exectype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labels?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liquibase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  md5sum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderexecuted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_min_fields'] = ResolversParentTypes['databasechangelog_min_fields']> = {
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contexts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateexecuted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deployment_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exectype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labels?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liquibase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  md5sum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderexecuted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_mutation_response'] = ResolversParentTypes['databasechangelog_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['databasechangelog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_stddev_fields'] = ResolversParentTypes['databasechangelog_stddev_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_stddev_pop_fields'] = ResolversParentTypes['databasechangelog_stddev_pop_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_stddev_samp_fields'] = ResolversParentTypes['databasechangelog_stddev_samp_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_sum_fields'] = ResolversParentTypes['databasechangelog_sum_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_var_pop_fields'] = ResolversParentTypes['databasechangelog_var_pop_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_var_samp_fields'] = ResolversParentTypes['databasechangelog_var_samp_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangelog_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangelog_variance_fields'] = ResolversParentTypes['databasechangelog_variance_fields']> = {
  orderexecuted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatabasechangeloglockResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock'] = ResolversParentTypes['databasechangeloglock']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lockedby?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lockgranted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_aggregate'] = ResolversParentTypes['databasechangeloglock_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['databasechangeloglock_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['databasechangeloglock']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_aggregate_fields'] = ResolversParentTypes['databasechangeloglock_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['databasechangeloglock_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Databasechangeloglock_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['databasechangeloglock_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['databasechangeloglock_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['databasechangeloglock_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['databasechangeloglock_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['databasechangeloglock_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['databasechangeloglock_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['databasechangeloglock_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['databasechangeloglock_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['databasechangeloglock_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_avg_fields'] = ResolversParentTypes['databasechangeloglock_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_max_fields'] = ResolversParentTypes['databasechangeloglock_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lockedby?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lockgranted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_min_fields'] = ResolversParentTypes['databasechangeloglock_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lockedby?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lockgranted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_mutation_response'] = ResolversParentTypes['databasechangeloglock_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['databasechangeloglock']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_stddev_fields'] = ResolversParentTypes['databasechangeloglock_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_stddev_pop_fields'] = ResolversParentTypes['databasechangeloglock_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_stddev_samp_fields'] = ResolversParentTypes['databasechangeloglock_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_sum_fields'] = ResolversParentTypes['databasechangeloglock_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_var_pop_fields'] = ResolversParentTypes['databasechangeloglock_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_var_samp_fields'] = ResolversParentTypes['databasechangeloglock_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Databasechangeloglock_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['databasechangeloglock_variance_fields'] = ResolversParentTypes['databasechangeloglock_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export type DateFormatResolvers<ContextType = any, ParentType extends ResolversParentTypes['dateFormat'] = ResolversParentTypes['dateFormat']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateFormat_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['dateFormat_aggregate'] = ResolversParentTypes['dateFormat_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['dateFormat_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['dateFormat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateFormat_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['dateFormat_aggregate_fields'] = ResolversParentTypes['dateFormat_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<DateFormat_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['dateFormat_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['dateFormat_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateFormat_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['dateFormat_max_fields'] = ResolversParentTypes['dateFormat_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateFormat_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['dateFormat_min_fields'] = ResolversParentTypes['dateFormat_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateFormat_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dateFormat_mutation_response'] = ResolversParentTypes['dateFormat_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dateFormat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Google_CredentialResolvers<ContextType = any, ParentType extends ResolversParentTypes['google_credential'] = ResolversParentTypes['google_credential']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  refresh_token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['new_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Google_Credential_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['google_credential_aggregate'] = ResolversParentTypes['google_credential_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['google_credential_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['google_credential']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Google_Credential_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['google_credential_aggregate_fields'] = ResolversParentTypes['google_credential_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Google_Credential_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['google_credential_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['google_credential_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Google_Credential_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['google_credential_max_fields'] = ResolversParentTypes['google_credential_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  refresh_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Google_Credential_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['google_credential_min_fields'] = ResolversParentTypes['google_credential_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  refresh_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Google_Credential_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['google_credential_mutation_response'] = ResolversParentTypes['google_credential_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['google_credential']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type Linear_IssueResolvers<ContextType = any, ParentType extends ResolversParentTypes['linear_issue'] = ResolversParentTypes['linear_issue']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  linear_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Linear_Issue_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['linear_issue_aggregate'] = ResolversParentTypes['linear_issue_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['linear_issue_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['linear_issue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Linear_Issue_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['linear_issue_aggregate_fields'] = ResolversParentTypes['linear_issue_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Linear_Issue_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['linear_issue_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['linear_issue_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Linear_Issue_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['linear_issue_max_fields'] = ResolversParentTypes['linear_issue_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  linear_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Linear_Issue_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['linear_issue_min_fields'] = ResolversParentTypes['linear_issue_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  linear_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Linear_Issue_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['linear_issue_mutation_response'] = ResolversParentTypes['linear_issue_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['linear_issue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mutation_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = {
  bookCalendarSlot?: Resolver<ResolversTypes['BookCalendarSlotOutput'], ParentType, ContextType, RequireFields<Mutation_RootBookCalendarSlotArgs, 'objects'>>;
  clearEventsCache?: Resolver<ResolversTypes['ClearEventsCacheOutput'], ParentType, ContextType, RequireFields<Mutation_RootClearEventsCacheArgs, 'where'>>;
  createCalendarLink?: Resolver<ResolversTypes['CreateCalendarLinkOutput'], ParentType, ContextType, RequireFields<Mutation_RootCreateCalendarLinkArgs, 'objects'>>;
  createContacts?: Resolver<ResolversTypes['CreateContactsOutput'], ParentType, ContextType, RequireFields<Mutation_RootCreateContactsArgs, 'objects'>>;
  createEvent?: Resolver<ResolversTypes['CreateEventOutput'], ParentType, ContextType, RequireFields<Mutation_RootCreateEventArgs, 'objects'>>;
  deleteEvent?: Resolver<ResolversTypes['DeleteEventOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteEventArgs, 'where'>>;
  delete_activityFeed?: Resolver<Maybe<ResolversTypes['activityFeed_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ActivityFeedArgs, 'where'>>;
  delete_activityFeedTypeEnum?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ActivityFeedTypeEnumArgs, 'where'>>;
  delete_activityFeedTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ActivityFeedTypeEnum_By_PkArgs, 'value'>>;
  delete_activityFeed_by_pk?: Resolver<Maybe<ResolversTypes['activityFeed']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ActivityFeed_By_PkArgs, 'id'>>;
  delete_analytics_event?: Resolver<Maybe<ResolversTypes['analytics_event_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Analytics_EventArgs, 'where'>>;
  delete_analytics_event_by_pk?: Resolver<Maybe<ResolversTypes['analytics_event']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Analytics_Event_By_PkArgs, 'id'>>;
  delete_calendarHistory?: Resolver<Maybe<ResolversTypes['calendarHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CalendarHistoryArgs, 'where'>>;
  delete_calendarHistory_by_pk?: Resolver<Maybe<ResolversTypes['calendarHistory']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CalendarHistory_By_PkArgs, 'id'>>;
  delete_calendarPreference?: Resolver<Maybe<ResolversTypes['calendarPreference_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CalendarPreferenceArgs, 'where'>>;
  delete_calendarPreference_by_pk?: Resolver<Maybe<ResolversTypes['calendarPreference']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CalendarPreference_By_PkArgs, 'id'>>;
  delete_calendarStartsOn?: Resolver<Maybe<ResolversTypes['calendarStartsOn_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CalendarStartsOnArgs, 'where'>>;
  delete_calendarStartsOn_by_pk?: Resolver<Maybe<ResolversTypes['calendarStartsOn']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CalendarStartsOn_By_PkArgs, 'value'>>;
  delete_category?: Resolver<Maybe<ResolversTypes['category_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CategoryArgs, 'where'>>;
  delete_category_by_pk?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Category_By_PkArgs, 'id'>>;
  delete_colorFamilyEnum?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ColorFamilyEnumArgs, 'where'>>;
  delete_colorFamilyEnum_by_pk?: Resolver<Maybe<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ColorFamilyEnum_By_PkArgs, 'value'>>;
  delete_contact_sync?: Resolver<Maybe<ResolversTypes['contact_sync_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Contact_SyncArgs, 'where'>>;
  delete_contact_sync_by_pk?: Resolver<Maybe<ResolversTypes['contact_sync']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Contact_Sync_By_PkArgs, 'id'>>;
  delete_databasechangelog?: Resolver<Maybe<ResolversTypes['databasechangelog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DatabasechangelogArgs, 'where'>>;
  delete_databasechangeloglock?: Resolver<Maybe<ResolversTypes['databasechangeloglock_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DatabasechangeloglockArgs, 'where'>>;
  delete_databasechangeloglock_by_pk?: Resolver<Maybe<ResolversTypes['databasechangeloglock']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Databasechangeloglock_By_PkArgs, 'id'>>;
  delete_dateFormat?: Resolver<Maybe<ResolversTypes['dateFormat_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DateFormatArgs, 'where'>>;
  delete_dateFormat_by_pk?: Resolver<Maybe<ResolversTypes['dateFormat']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DateFormat_By_PkArgs, 'value'>>;
  delete_google_credential?: Resolver<Maybe<ResolversTypes['google_credential_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Google_CredentialArgs, 'where'>>;
  delete_google_credential_by_pk?: Resolver<Maybe<ResolversTypes['google_credential']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Google_Credential_By_PkArgs, 'id'>>;
  delete_linear_issue?: Resolver<Maybe<ResolversTypes['linear_issue_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Linear_IssueArgs, 'where'>>;
  delete_linear_issue_by_pk?: Resolver<Maybe<ResolversTypes['linear_issue']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Linear_Issue_By_PkArgs, 'id'>>;
  delete_new_contact?: Resolver<Maybe<ResolversTypes['new_contact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_New_ContactArgs, 'where'>>;
  delete_new_contact_by_pk?: Resolver<Maybe<ResolversTypes['new_contact']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_New_Contact_By_PkArgs, 'id'>>;
  delete_new_feedback?: Resolver<Maybe<ResolversTypes['new_feedback_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_New_FeedbackArgs, 'where'>>;
  delete_new_feedback_by_pk?: Resolver<Maybe<ResolversTypes['new_feedback']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_New_Feedback_By_PkArgs, 'id'>>;
  delete_new_user?: Resolver<Maybe<ResolversTypes['new_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_New_UserArgs, 'where'>>;
  delete_new_user_by_pk?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_New_User_By_PkArgs, 'id'>>;
  delete_profile?: Resolver<Maybe<ResolversTypes['profile_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProfileArgs, 'where'>>;
  delete_profile_by_pk?: Resolver<Maybe<ResolversTypes['profile']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Profile_By_PkArgs, 'id'>>;
  delete_shareCalendarLink?: Resolver<Maybe<ResolversTypes['shareCalendarLink_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ShareCalendarLinkArgs, 'where'>>;
  delete_shareCalendarLinkTypeEnum?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ShareCalendarLinkTypeEnumArgs, 'where'>>;
  delete_shareCalendarLinkTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ShareCalendarLinkTypeEnum_By_PkArgs, 'value'>>;
  delete_shareCalendarLink_by_pk?: Resolver<Maybe<ResolversTypes['shareCalendarLink']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ShareCalendarLink_By_PkArgs, 'id'>>;
  delete_theme?: Resolver<Maybe<ResolversTypes['theme_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ThemeArgs, 'where'>>;
  delete_theme_by_pk?: Resolver<Maybe<ResolversTypes['theme']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Theme_By_PkArgs, 'value'>>;
  delete_todo?: Resolver<Maybe<ResolversTypes['todo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TodoArgs, 'where'>>;
  delete_todoTypeEnum?: Resolver<Maybe<ResolversTypes['todoTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TodoTypeEnumArgs, 'where'>>;
  delete_todoTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['todoTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TodoTypeEnum_By_PkArgs, 'value'>>;
  delete_todo_by_pk?: Resolver<Maybe<ResolversTypes['todo']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Todo_By_PkArgs, 'id'>>;
  delete_userActivityFeed?: Resolver<Maybe<ResolversTypes['userActivityFeed_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserActivityFeedArgs, 'where'>>;
  delete_userActivityFeed_by_pk?: Resolver<Maybe<ResolversTypes['userActivityFeed']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserActivityFeed_By_PkArgs, 'id'>>;
  delete_userPreferences?: Resolver<Maybe<ResolversTypes['userPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserPreferencesArgs, 'where'>>;
  delete_userPreferences_by_pk?: Resolver<Maybe<ResolversTypes['userPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserPreferences_By_PkArgs, 'id'>>;
  delete_userStatus?: Resolver<Maybe<ResolversTypes['userStatus_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserStatusArgs, 'where'>>;
  delete_userStatus_by_pk?: Resolver<Maybe<ResolversTypes['userStatus']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserStatus_By_PkArgs, 'userEmail'>>;
  delete_user_allowlist?: Resolver<Maybe<ResolversTypes['user_allowlist_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_User_AllowlistArgs, 'where'>>;
  delete_user_allowlist_by_pk?: Resolver<Maybe<ResolversTypes['user_allowlist']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_User_Allowlist_By_PkArgs, 'id'>>;
  delete_videoProvider?: Resolver<Maybe<ResolversTypes['videoProvider_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_VideoProviderArgs, 'where'>>;
  delete_videoProvider_by_pk?: Resolver<Maybe<ResolversTypes['videoProvider']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_VideoProvider_By_PkArgs, 'value'>>;
  hideCalendar?: Resolver<ResolversTypes['HideCalendarOutput'], ParentType, ContextType, RequireFields<Mutation_RootHideCalendarArgs, 'input'>>;
  insert_activityFeed?: Resolver<Maybe<ResolversTypes['activityFeed_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ActivityFeedArgs, 'objects'>>;
  insert_activityFeedTypeEnum?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ActivityFeedTypeEnumArgs, 'objects'>>;
  insert_activityFeedTypeEnum_one?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ActivityFeedTypeEnum_OneArgs, 'object'>>;
  insert_activityFeed_one?: Resolver<Maybe<ResolversTypes['activityFeed']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ActivityFeed_OneArgs, 'object'>>;
  insert_analytics_event?: Resolver<Maybe<ResolversTypes['analytics_event_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Analytics_EventArgs, 'objects'>>;
  insert_analytics_event_one?: Resolver<Maybe<ResolversTypes['analytics_event']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Analytics_Event_OneArgs, 'object'>>;
  insert_calendarHistory?: Resolver<Maybe<ResolversTypes['calendarHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CalendarHistoryArgs, 'objects'>>;
  insert_calendarHistory_one?: Resolver<Maybe<ResolversTypes['calendarHistory']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CalendarHistory_OneArgs, 'object'>>;
  insert_calendarPreference?: Resolver<Maybe<ResolversTypes['calendarPreference_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CalendarPreferenceArgs, 'objects'>>;
  insert_calendarPreference_one?: Resolver<Maybe<ResolversTypes['calendarPreference']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CalendarPreference_OneArgs, 'object'>>;
  insert_calendarStartsOn?: Resolver<Maybe<ResolversTypes['calendarStartsOn_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CalendarStartsOnArgs, 'objects'>>;
  insert_calendarStartsOn_one?: Resolver<Maybe<ResolversTypes['calendarStartsOn']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CalendarStartsOn_OneArgs, 'object'>>;
  insert_category?: Resolver<Maybe<ResolversTypes['category_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CategoryArgs, 'objects'>>;
  insert_category_one?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Category_OneArgs, 'object'>>;
  insert_colorFamilyEnum?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ColorFamilyEnumArgs, 'objects'>>;
  insert_colorFamilyEnum_one?: Resolver<Maybe<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ColorFamilyEnum_OneArgs, 'object'>>;
  insert_contact_sync?: Resolver<Maybe<ResolversTypes['contact_sync_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Contact_SyncArgs, 'objects'>>;
  insert_contact_sync_one?: Resolver<Maybe<ResolversTypes['contact_sync']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Contact_Sync_OneArgs, 'object'>>;
  insert_databasechangelog?: Resolver<Maybe<ResolversTypes['databasechangelog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DatabasechangelogArgs, 'objects'>>;
  insert_databasechangelog_one?: Resolver<Maybe<ResolversTypes['databasechangelog']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Databasechangelog_OneArgs, 'object'>>;
  insert_databasechangeloglock?: Resolver<Maybe<ResolversTypes['databasechangeloglock_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DatabasechangeloglockArgs, 'objects'>>;
  insert_databasechangeloglock_one?: Resolver<Maybe<ResolversTypes['databasechangeloglock']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Databasechangeloglock_OneArgs, 'object'>>;
  insert_dateFormat?: Resolver<Maybe<ResolversTypes['dateFormat_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DateFormatArgs, 'objects'>>;
  insert_dateFormat_one?: Resolver<Maybe<ResolversTypes['dateFormat']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DateFormat_OneArgs, 'object'>>;
  insert_google_credential?: Resolver<Maybe<ResolversTypes['google_credential_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Google_CredentialArgs, 'objects'>>;
  insert_google_credential_one?: Resolver<Maybe<ResolversTypes['google_credential']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Google_Credential_OneArgs, 'object'>>;
  insert_linear_issue?: Resolver<Maybe<ResolversTypes['linear_issue_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Linear_IssueArgs, 'objects'>>;
  insert_linear_issue_one?: Resolver<Maybe<ResolversTypes['linear_issue']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Linear_Issue_OneArgs, 'object'>>;
  insert_new_contact?: Resolver<Maybe<ResolversTypes['new_contact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_New_ContactArgs, 'objects'>>;
  insert_new_contact_one?: Resolver<Maybe<ResolversTypes['new_contact']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_New_Contact_OneArgs, 'object'>>;
  insert_new_feedback?: Resolver<Maybe<ResolversTypes['new_feedback_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_New_FeedbackArgs, 'objects'>>;
  insert_new_feedback_one?: Resolver<Maybe<ResolversTypes['new_feedback']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_New_Feedback_OneArgs, 'object'>>;
  insert_new_user?: Resolver<Maybe<ResolversTypes['new_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_New_UserArgs, 'objects'>>;
  insert_new_user_one?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_New_User_OneArgs, 'object'>>;
  insert_profile?: Resolver<Maybe<ResolversTypes['profile_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProfileArgs, 'objects'>>;
  insert_profile_one?: Resolver<Maybe<ResolversTypes['profile']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Profile_OneArgs, 'object'>>;
  insert_shareCalendarLink?: Resolver<Maybe<ResolversTypes['shareCalendarLink_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ShareCalendarLinkArgs, 'objects'>>;
  insert_shareCalendarLinkTypeEnum?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ShareCalendarLinkTypeEnumArgs, 'objects'>>;
  insert_shareCalendarLinkTypeEnum_one?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ShareCalendarLinkTypeEnum_OneArgs, 'object'>>;
  insert_shareCalendarLink_one?: Resolver<Maybe<ResolversTypes['shareCalendarLink']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ShareCalendarLink_OneArgs, 'object'>>;
  insert_theme?: Resolver<Maybe<ResolversTypes['theme_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ThemeArgs, 'objects'>>;
  insert_theme_one?: Resolver<Maybe<ResolversTypes['theme']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Theme_OneArgs, 'object'>>;
  insert_todo?: Resolver<Maybe<ResolversTypes['todo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TodoArgs, 'objects'>>;
  insert_todoTypeEnum?: Resolver<Maybe<ResolversTypes['todoTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TodoTypeEnumArgs, 'objects'>>;
  insert_todoTypeEnum_one?: Resolver<Maybe<ResolversTypes['todoTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TodoTypeEnum_OneArgs, 'object'>>;
  insert_todo_one?: Resolver<Maybe<ResolversTypes['todo']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Todo_OneArgs, 'object'>>;
  insert_userActivityFeed?: Resolver<Maybe<ResolversTypes['userActivityFeed_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserActivityFeedArgs, 'objects'>>;
  insert_userActivityFeed_one?: Resolver<Maybe<ResolversTypes['userActivityFeed']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserActivityFeed_OneArgs, 'object'>>;
  insert_userPreferences?: Resolver<Maybe<ResolversTypes['userPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserPreferencesArgs, 'objects'>>;
  insert_userPreferences_one?: Resolver<Maybe<ResolversTypes['userPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserPreferences_OneArgs, 'object'>>;
  insert_userStatus?: Resolver<Maybe<ResolversTypes['userStatus_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserStatusArgs, 'objects'>>;
  insert_userStatus_one?: Resolver<Maybe<ResolversTypes['userStatus']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserStatus_OneArgs, 'object'>>;
  insert_user_allowlist?: Resolver<Maybe<ResolversTypes['user_allowlist_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_AllowlistArgs, 'objects'>>;
  insert_user_allowlist_one?: Resolver<Maybe<ResolversTypes['user_allowlist']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Allowlist_OneArgs, 'object'>>;
  insert_videoProvider?: Resolver<Maybe<ResolversTypes['videoProvider_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_VideoProviderArgs, 'objects'>>;
  insert_videoProvider_one?: Resolver<Maybe<ResolversTypes['videoProvider']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_VideoProvider_OneArgs, 'object'>>;
  refreshActivityFeed?: Resolver<ResolversTypes['RefreshActivityFeedOutput'], ParentType, ContextType>;
  refreshUserStatus?: Resolver<ResolversTypes['refreshUserStatusOutput'], ParentType, ContextType, RequireFields<Mutation_RootRefreshUserStatusArgs, 'userId'>>;
  sendFeedback?: Resolver<ResolversTypes['SendFeedbackOutput'], ParentType, ContextType, RequireFields<Mutation_RootSendFeedbackArgs, 'input'>>;
  setBirthdayReminder?: Resolver<ResolversTypes['SetBirthdayReminderOutput'], ParentType, ContextType, RequireFields<Mutation_RootSetBirthdayReminderArgs, 'objects'>>;
  setEventRecurrence?: Resolver<ResolversTypes['SetEventRecurrenceOutput'], ParentType, ContextType, RequireFields<Mutation_RootSetEventRecurrenceArgs, 'objects'>>;
  setReminder?: Resolver<ResolversTypes['SetReminderOutput'], ParentType, ContextType, RequireFields<Mutation_RootSetReminderArgs, 'objects'>>;
  showCalendar?: Resolver<ResolversTypes['ShowCalendarOutput'], ParentType, ContextType, RequireFields<Mutation_RootShowCalendarArgs, 'input'>>;
  signInUsingFirebaseAuthentication?: Resolver<Maybe<ResolversTypes['SignInUsingFirebaseAuthenticationOutput']>, ParentType, ContextType, RequireFields<Mutation_RootSignInUsingFirebaseAuthenticationArgs, 'input'>>;
  signInUsingGoogleAuthorizationCode?: Resolver<Maybe<ResolversTypes['SignInUsingGoogleAuthorizationCodeOutput']>, ParentType, ContextType, RequireFields<Mutation_RootSignInUsingGoogleAuthorizationCodeArgs, 'input'>>;
  spotifyPauseSong?: Resolver<Maybe<ResolversTypes['SpotifyPauseTrack']>, ParentType, ContextType, RequireFields<Mutation_RootSpotifyPauseSongArgs, 'source'>>;
  spotifyPlaySong?: Resolver<Maybe<ResolversTypes['SpotifyPlayTrack']>, ParentType, ContextType, RequireFields<Mutation_RootSpotifyPlaySongArgs, 'source' | 'uri'>>;
  syncContacts?: Resolver<ResolversTypes['SyncContactsOutput'], ParentType, ContextType, RequireFields<Mutation_RootSyncContactsArgs, never>>;
  toggleEventDone?: Resolver<ResolversTypes['ToggleEventDoneOutput'], ParentType, ContextType, RequireFields<Mutation_RootToggleEventDoneArgs, 'state' | 'where'>>;
  updateCalendar?: Resolver<ResolversTypes['MinimalCalendarInfo'], ParentType, ContextType, RequireFields<Mutation_RootUpdateCalendarArgs, 'input'>>;
  updateCalendarLink?: Resolver<ResolversTypes['UpdateCalendarLinkOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateCalendarLinkArgs, 'objects'>>;
  updateEvent?: Resolver<ResolversTypes['UpdateEventOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateEventArgs, 'objects'>>;
  updateEventRSVP?: Resolver<ResolversTypes['UpdateEventRSVPOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateEventRsvpArgs, 'objects'>>;
  updateTodos?: Resolver<ResolversTypes['UpdateTodosOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateTodosArgs, 'objects'>>;
  update_activityFeed?: Resolver<Maybe<ResolversTypes['activityFeed_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivityFeedArgs, 'where'>>;
  update_activityFeedTypeEnum?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivityFeedTypeEnumArgs, 'where'>>;
  update_activityFeedTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivityFeedTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_activityFeed_by_pk?: Resolver<Maybe<ResolversTypes['activityFeed']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivityFeed_By_PkArgs, 'pk_columns'>>;
  update_analytics_event?: Resolver<Maybe<ResolversTypes['analytics_event_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Analytics_EventArgs, 'where'>>;
  update_analytics_event_by_pk?: Resolver<Maybe<ResolversTypes['analytics_event']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Analytics_Event_By_PkArgs, 'pk_columns'>>;
  update_calendarHistory?: Resolver<Maybe<ResolversTypes['calendarHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CalendarHistoryArgs, 'where'>>;
  update_calendarHistory_by_pk?: Resolver<Maybe<ResolversTypes['calendarHistory']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CalendarHistory_By_PkArgs, 'pk_columns'>>;
  update_calendarPreference?: Resolver<Maybe<ResolversTypes['calendarPreference_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CalendarPreferenceArgs, 'where'>>;
  update_calendarPreference_by_pk?: Resolver<Maybe<ResolversTypes['calendarPreference']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CalendarPreference_By_PkArgs, 'pk_columns'>>;
  update_calendarStartsOn?: Resolver<Maybe<ResolversTypes['calendarStartsOn_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CalendarStartsOnArgs, 'where'>>;
  update_calendarStartsOn_by_pk?: Resolver<Maybe<ResolversTypes['calendarStartsOn']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CalendarStartsOn_By_PkArgs, 'pk_columns'>>;
  update_category?: Resolver<Maybe<ResolversTypes['category_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CategoryArgs, 'where'>>;
  update_category_by_pk?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Category_By_PkArgs, 'pk_columns'>>;
  update_colorFamilyEnum?: Resolver<Maybe<ResolversTypes['colorFamilyEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ColorFamilyEnumArgs, 'where'>>;
  update_colorFamilyEnum_by_pk?: Resolver<Maybe<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ColorFamilyEnum_By_PkArgs, 'pk_columns'>>;
  update_contact_sync?: Resolver<Maybe<ResolversTypes['contact_sync_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Contact_SyncArgs, 'where'>>;
  update_contact_sync_by_pk?: Resolver<Maybe<ResolversTypes['contact_sync']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Contact_Sync_By_PkArgs, 'pk_columns'>>;
  update_databasechangelog?: Resolver<Maybe<ResolversTypes['databasechangelog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DatabasechangelogArgs, 'where'>>;
  update_databasechangeloglock?: Resolver<Maybe<ResolversTypes['databasechangeloglock_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DatabasechangeloglockArgs, 'where'>>;
  update_databasechangeloglock_by_pk?: Resolver<Maybe<ResolversTypes['databasechangeloglock']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Databasechangeloglock_By_PkArgs, 'pk_columns'>>;
  update_dateFormat?: Resolver<Maybe<ResolversTypes['dateFormat_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DateFormatArgs, 'where'>>;
  update_dateFormat_by_pk?: Resolver<Maybe<ResolversTypes['dateFormat']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DateFormat_By_PkArgs, 'pk_columns'>>;
  update_google_credential?: Resolver<Maybe<ResolversTypes['google_credential_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Google_CredentialArgs, 'where'>>;
  update_google_credential_by_pk?: Resolver<Maybe<ResolversTypes['google_credential']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Google_Credential_By_PkArgs, 'pk_columns'>>;
  update_linear_issue?: Resolver<Maybe<ResolversTypes['linear_issue_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Linear_IssueArgs, 'where'>>;
  update_linear_issue_by_pk?: Resolver<Maybe<ResolversTypes['linear_issue']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Linear_Issue_By_PkArgs, 'pk_columns'>>;
  update_new_contact?: Resolver<Maybe<ResolversTypes['new_contact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_New_ContactArgs, 'where'>>;
  update_new_contact_by_pk?: Resolver<Maybe<ResolversTypes['new_contact']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_New_Contact_By_PkArgs, 'pk_columns'>>;
  update_new_feedback?: Resolver<Maybe<ResolversTypes['new_feedback_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_New_FeedbackArgs, 'where'>>;
  update_new_feedback_by_pk?: Resolver<Maybe<ResolversTypes['new_feedback']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_New_Feedback_By_PkArgs, 'pk_columns'>>;
  update_new_user?: Resolver<Maybe<ResolversTypes['new_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_New_UserArgs, 'where'>>;
  update_new_user_by_pk?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_New_User_By_PkArgs, 'pk_columns'>>;
  update_profile?: Resolver<Maybe<ResolversTypes['profile_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProfileArgs, 'where'>>;
  update_profile_by_pk?: Resolver<Maybe<ResolversTypes['profile']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Profile_By_PkArgs, 'pk_columns'>>;
  update_shareCalendarLink?: Resolver<Maybe<ResolversTypes['shareCalendarLink_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ShareCalendarLinkArgs, 'where'>>;
  update_shareCalendarLinkTypeEnum?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ShareCalendarLinkTypeEnumArgs, 'where'>>;
  update_shareCalendarLinkTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ShareCalendarLinkTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_shareCalendarLink_by_pk?: Resolver<Maybe<ResolversTypes['shareCalendarLink']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ShareCalendarLink_By_PkArgs, 'pk_columns'>>;
  update_theme?: Resolver<Maybe<ResolversTypes['theme_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ThemeArgs, 'where'>>;
  update_theme_by_pk?: Resolver<Maybe<ResolversTypes['theme']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Theme_By_PkArgs, 'pk_columns'>>;
  update_todo?: Resolver<Maybe<ResolversTypes['todo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TodoArgs, 'where'>>;
  update_todoTypeEnum?: Resolver<Maybe<ResolversTypes['todoTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TodoTypeEnumArgs, 'where'>>;
  update_todoTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['todoTypeEnum']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TodoTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_todo_by_pk?: Resolver<Maybe<ResolversTypes['todo']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Todo_By_PkArgs, 'pk_columns'>>;
  update_userActivityFeed?: Resolver<Maybe<ResolversTypes['userActivityFeed_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserActivityFeedArgs, 'where'>>;
  update_userActivityFeed_by_pk?: Resolver<Maybe<ResolversTypes['userActivityFeed']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserActivityFeed_By_PkArgs, 'pk_columns'>>;
  update_userPreferences?: Resolver<Maybe<ResolversTypes['userPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserPreferencesArgs, 'where'>>;
  update_userPreferences_by_pk?: Resolver<Maybe<ResolversTypes['userPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserPreferences_By_PkArgs, 'pk_columns'>>;
  update_userStatus?: Resolver<Maybe<ResolversTypes['userStatus_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserStatusArgs, 'where'>>;
  update_userStatus_by_pk?: Resolver<Maybe<ResolversTypes['userStatus']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserStatus_By_PkArgs, 'pk_columns'>>;
  update_user_allowlist?: Resolver<Maybe<ResolversTypes['user_allowlist_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_AllowlistArgs, 'where'>>;
  update_user_allowlist_by_pk?: Resolver<Maybe<ResolversTypes['user_allowlist']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Allowlist_By_PkArgs, 'pk_columns'>>;
  update_videoProvider?: Resolver<Maybe<ResolversTypes['videoProvider_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VideoProviderArgs, 'where'>>;
  update_videoProvider_by_pk?: Resolver<Maybe<ResolversTypes['videoProvider']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VideoProvider_By_PkArgs, 'pk_columns'>>;
};

export type New_ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_contact'] = ResolversParentTypes['new_contact']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  birthdayReminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  favoriteAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googlePersonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<New_ContactLabelsArgs, never>>;
  lastInteractedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['userStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['new_user'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Contact_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_contact_aggregate'] = ResolversParentTypes['new_contact_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['new_contact_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['new_contact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Contact_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_contact_aggregate_fields'] = ResolversParentTypes['new_contact_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<New_Contact_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['new_contact_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['new_contact_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Contact_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_contact_max_fields'] = ResolversParentTypes['new_contact_max_fields']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  birthdayReminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favoriteAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googlePersonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastInteractedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Contact_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_contact_min_fields'] = ResolversParentTypes['new_contact_min_fields']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  birthdayReminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favoriteAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googlePersonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastInteractedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reminderEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Contact_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_contact_mutation_response'] = ResolversParentTypes['new_contact_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['new_contact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_FeedbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_feedback'] = ResolversParentTypes['new_feedback']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['new_user'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Feedback_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_feedback_aggregate'] = ResolversParentTypes['new_feedback_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['new_feedback_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['new_feedback']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Feedback_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_feedback_aggregate_fields'] = ResolversParentTypes['new_feedback_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<New_Feedback_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['new_feedback_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['new_feedback_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Feedback_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_feedback_max_fields'] = ResolversParentTypes['new_feedback_max_fields']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Feedback_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_feedback_min_fields'] = ResolversParentTypes['new_feedback_min_fields']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_Feedback_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_feedback_mutation_response'] = ResolversParentTypes['new_feedback_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['new_feedback']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_user'] = ResolversParentTypes['new_user']> = {
  calendarPreference?: Resolver<Maybe<ResolversTypes['calendarPreference']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  googleContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleCredential?: Resolver<Maybe<ResolversTypes['google_credential']>, ParentType, ContextType>;
  googleDirectoryContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleDirectoryContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleOtherContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleOtherContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['userPreferences']>, ParentType, ContextType>;
  preferencesId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  spotifyRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedMinRefreshActivityFeed?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  zoomRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_user_aggregate'] = ResolversParentTypes['new_user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['new_user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['new_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_user_aggregate_fields'] = ResolversParentTypes['new_user_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<New_User_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['new_user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['new_user_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_user_max_fields'] = ResolversParentTypes['new_user_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleDirectoryContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleDirectoryContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleOtherContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleOtherContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferencesId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  spotifyRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedMinRefreshActivityFeed?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  zoomRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_user_min_fields'] = ResolversParentTypes['new_user_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleDirectoryContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleDirectoryContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  googleOtherContactsSyncToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleOtherContactsSyncTokenIat?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferencesId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  spotifyRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedMinRefreshActivityFeed?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  zoomRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type New_User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['new_user_mutation_response'] = ResolversParentTypes['new_user_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['new_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['profile'] = ResolversParentTypes['profile']> = {
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lastLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifiedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profile_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['profile_aggregate'] = ResolversParentTypes['profile_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['profile_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['profile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profile_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['profile_aggregate_fields'] = ResolversParentTypes['profile_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Profile_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['profile_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['profile_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profile_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['profile_max_fields'] = ResolversParentTypes['profile_max_fields']> = {
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifiedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profile_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['profile_min_fields'] = ResolversParentTypes['profile_min_fields']> = {
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifiedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Profile_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['profile_mutation_response'] = ResolversParentTypes['profile_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['profile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Query_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = {
  activityFeed?: Resolver<Array<ResolversTypes['activityFeed']>, ParentType, ContextType, RequireFields<Query_RootActivityFeedArgs, never>>;
  activityFeedTypeEnum?: Resolver<Array<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType, RequireFields<Query_RootActivityFeedTypeEnumArgs, never>>;
  activityFeedTypeEnum_aggregate?: Resolver<ResolversTypes['activityFeedTypeEnum_aggregate'], ParentType, ContextType, RequireFields<Query_RootActivityFeedTypeEnum_AggregateArgs, never>>;
  activityFeedTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['activityFeedTypeEnum']>, ParentType, ContextType, RequireFields<Query_RootActivityFeedTypeEnum_By_PkArgs, 'value'>>;
  activityFeed_aggregate?: Resolver<ResolversTypes['activityFeed_aggregate'], ParentType, ContextType, RequireFields<Query_RootActivityFeed_AggregateArgs, never>>;
  activityFeed_by_pk?: Resolver<Maybe<ResolversTypes['activityFeed']>, ParentType, ContextType, RequireFields<Query_RootActivityFeed_By_PkArgs, 'id'>>;
  analytics_event?: Resolver<Array<ResolversTypes['analytics_event']>, ParentType, ContextType, RequireFields<Query_RootAnalytics_EventArgs, never>>;
  analytics_event_aggregate?: Resolver<ResolversTypes['analytics_event_aggregate'], ParentType, ContextType, RequireFields<Query_RootAnalytics_Event_AggregateArgs, never>>;
  analytics_event_by_pk?: Resolver<Maybe<ResolversTypes['analytics_event']>, ParentType, ContextType, RequireFields<Query_RootAnalytics_Event_By_PkArgs, 'id'>>;
  bestie?: Resolver<Array<ResolversTypes['Bestie']>, ParentType, ContextType, RequireFields<Query_RootBestieArgs, never>>;
  birthdaysView?: Resolver<Maybe<ResolversTypes['BirthdaysViewOutput']>, ParentType, ContextType, RequireFields<Query_RootBirthdaysViewArgs, 'input'>>;
  calendar?: Resolver<Array<ResolversTypes['NewCalendar']>, ParentType, ContextType, RequireFields<Query_RootCalendarArgs, never>>;
  calendarHistory?: Resolver<Array<ResolversTypes['calendarHistory']>, ParentType, ContextType, RequireFields<Query_RootCalendarHistoryArgs, never>>;
  calendarHistory_aggregate?: Resolver<ResolversTypes['calendarHistory_aggregate'], ParentType, ContextType, RequireFields<Query_RootCalendarHistory_AggregateArgs, never>>;
  calendarHistory_by_pk?: Resolver<Maybe<ResolversTypes['calendarHistory']>, ParentType, ContextType, RequireFields<Query_RootCalendarHistory_By_PkArgs, 'id'>>;
  calendarPreference?: Resolver<Array<ResolversTypes['calendarPreference']>, ParentType, ContextType, RequireFields<Query_RootCalendarPreferenceArgs, never>>;
  calendarPreference_aggregate?: Resolver<ResolversTypes['calendarPreference_aggregate'], ParentType, ContextType, RequireFields<Query_RootCalendarPreference_AggregateArgs, never>>;
  calendarPreference_by_pk?: Resolver<Maybe<ResolversTypes['calendarPreference']>, ParentType, ContextType, RequireFields<Query_RootCalendarPreference_By_PkArgs, 'id'>>;
  calendarStartsOn?: Resolver<Array<ResolversTypes['calendarStartsOn']>, ParentType, ContextType, RequireFields<Query_RootCalendarStartsOnArgs, never>>;
  calendarStartsOn_aggregate?: Resolver<ResolversTypes['calendarStartsOn_aggregate'], ParentType, ContextType, RequireFields<Query_RootCalendarStartsOn_AggregateArgs, never>>;
  calendarStartsOn_by_pk?: Resolver<Maybe<ResolversTypes['calendarStartsOn']>, ParentType, ContextType, RequireFields<Query_RootCalendarStartsOn_By_PkArgs, 'value'>>;
  category?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType, RequireFields<Query_RootCategoryArgs, never>>;
  category_aggregate?: Resolver<ResolversTypes['category_aggregate'], ParentType, ContextType, RequireFields<Query_RootCategory_AggregateArgs, never>>;
  category_by_pk?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<Query_RootCategory_By_PkArgs, 'id'>>;
  checkAliasAvailability?: Resolver<ResolversTypes['CheckAliasAvailabilityOutput'], ParentType, ContextType, RequireFields<Query_RootCheckAliasAvailabilityArgs, 'alias'>>;
  colorFamilyEnum?: Resolver<Array<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType, RequireFields<Query_RootColorFamilyEnumArgs, never>>;
  colorFamilyEnum_aggregate?: Resolver<ResolversTypes['colorFamilyEnum_aggregate'], ParentType, ContextType, RequireFields<Query_RootColorFamilyEnum_AggregateArgs, never>>;
  colorFamilyEnum_by_pk?: Resolver<Maybe<ResolversTypes['colorFamilyEnum']>, ParentType, ContextType, RequireFields<Query_RootColorFamilyEnum_By_PkArgs, 'value'>>;
  contactReminder?: Resolver<Maybe<ResolversTypes['ContactReminderOutput']>, ParentType, ContextType, RequireFields<Query_RootContactReminderArgs, 'id'>>;
  contact_sync?: Resolver<Array<ResolversTypes['contact_sync']>, ParentType, ContextType, RequireFields<Query_RootContact_SyncArgs, never>>;
  contact_sync_aggregate?: Resolver<ResolversTypes['contact_sync_aggregate'], ParentType, ContextType, RequireFields<Query_RootContact_Sync_AggregateArgs, never>>;
  contact_sync_by_pk?: Resolver<Maybe<ResolversTypes['contact_sync']>, ParentType, ContextType, RequireFields<Query_RootContact_Sync_By_PkArgs, 'id'>>;
  databasechangelog?: Resolver<Array<ResolversTypes['databasechangelog']>, ParentType, ContextType, RequireFields<Query_RootDatabasechangelogArgs, never>>;
  databasechangelog_aggregate?: Resolver<ResolversTypes['databasechangelog_aggregate'], ParentType, ContextType, RequireFields<Query_RootDatabasechangelog_AggregateArgs, never>>;
  databasechangeloglock?: Resolver<Array<ResolversTypes['databasechangeloglock']>, ParentType, ContextType, RequireFields<Query_RootDatabasechangeloglockArgs, never>>;
  databasechangeloglock_aggregate?: Resolver<ResolversTypes['databasechangeloglock_aggregate'], ParentType, ContextType, RequireFields<Query_RootDatabasechangeloglock_AggregateArgs, never>>;
  databasechangeloglock_by_pk?: Resolver<Maybe<ResolversTypes['databasechangeloglock']>, ParentType, ContextType, RequireFields<Query_RootDatabasechangeloglock_By_PkArgs, 'id'>>;
  dateFormat?: Resolver<Array<ResolversTypes['dateFormat']>, ParentType, ContextType, RequireFields<Query_RootDateFormatArgs, never>>;
  dateFormat_aggregate?: Resolver<ResolversTypes['dateFormat_aggregate'], ParentType, ContextType, RequireFields<Query_RootDateFormat_AggregateArgs, never>>;
  dateFormat_by_pk?: Resolver<Maybe<ResolversTypes['dateFormat']>, ParentType, ContextType, RequireFields<Query_RootDateFormat_By_PkArgs, 'value'>>;
  event?: Resolver<ResolversTypes['EventsResponse'], ParentType, ContextType, RequireFields<Query_RootEventArgs, never>>;
  findNextAvailableSlot?: Resolver<ResolversTypes['FindNextAvailableSlotResponse'], ParentType, ContextType, RequireFields<Query_RootFindNextAvailableSlotArgs, 'input'>>;
  getGMapsPlaces?: Resolver<ResolversTypes['GetGMapsPlacesOutput'], ParentType, ContextType, RequireFields<Query_RootGetGMapsPlacesArgs, 'where'>>;
  google_credential?: Resolver<Array<ResolversTypes['google_credential']>, ParentType, ContextType, RequireFields<Query_RootGoogle_CredentialArgs, never>>;
  google_credential_aggregate?: Resolver<ResolversTypes['google_credential_aggregate'], ParentType, ContextType, RequireFields<Query_RootGoogle_Credential_AggregateArgs, never>>;
  google_credential_by_pk?: Resolver<Maybe<ResolversTypes['google_credential']>, ParentType, ContextType, RequireFields<Query_RootGoogle_Credential_By_PkArgs, 'id'>>;
  lightweightEvents?: Resolver<Array<Maybe<ResolversTypes['NewEvent']>>, ParentType, ContextType, RequireFields<Query_RootLightweightEventsArgs, 'where'>>;
  linear_issue?: Resolver<Array<ResolversTypes['linear_issue']>, ParentType, ContextType, RequireFields<Query_RootLinear_IssueArgs, never>>;
  linear_issue_aggregate?: Resolver<ResolversTypes['linear_issue_aggregate'], ParentType, ContextType, RequireFields<Query_RootLinear_Issue_AggregateArgs, never>>;
  linear_issue_by_pk?: Resolver<Maybe<ResolversTypes['linear_issue']>, ParentType, ContextType, RequireFields<Query_RootLinear_Issue_By_PkArgs, 'id'>>;
  new_contact?: Resolver<Array<ResolversTypes['new_contact']>, ParentType, ContextType, RequireFields<Query_RootNew_ContactArgs, never>>;
  new_contact_aggregate?: Resolver<ResolversTypes['new_contact_aggregate'], ParentType, ContextType, RequireFields<Query_RootNew_Contact_AggregateArgs, never>>;
  new_contact_by_pk?: Resolver<Maybe<ResolversTypes['new_contact']>, ParentType, ContextType, RequireFields<Query_RootNew_Contact_By_PkArgs, 'id'>>;
  new_feedback?: Resolver<Array<ResolversTypes['new_feedback']>, ParentType, ContextType, RequireFields<Query_RootNew_FeedbackArgs, never>>;
  new_feedback_aggregate?: Resolver<ResolversTypes['new_feedback_aggregate'], ParentType, ContextType, RequireFields<Query_RootNew_Feedback_AggregateArgs, never>>;
  new_feedback_by_pk?: Resolver<Maybe<ResolversTypes['new_feedback']>, ParentType, ContextType, RequireFields<Query_RootNew_Feedback_By_PkArgs, 'id'>>;
  new_user?: Resolver<Array<ResolversTypes['new_user']>, ParentType, ContextType, RequireFields<Query_RootNew_UserArgs, never>>;
  new_user_aggregate?: Resolver<ResolversTypes['new_user_aggregate'], ParentType, ContextType, RequireFields<Query_RootNew_User_AggregateArgs, never>>;
  new_user_by_pk?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType, RequireFields<Query_RootNew_User_By_PkArgs, 'id'>>;
  ownCalendarList?: Resolver<Array<ResolversTypes['MinimalCalendarInfo']>, ParentType, ContextType>;
  profile?: Resolver<Array<ResolversTypes['profile']>, ParentType, ContextType, RequireFields<Query_RootProfileArgs, never>>;
  profileMeetInfo?: Resolver<Maybe<ResolversTypes['ProfileMeetInfoOutput']>, ParentType, ContextType, RequireFields<Query_RootProfileMeetInfoArgs, 'objects'>>;
  profileView?: Resolver<Maybe<ResolversTypes['ProfileViewOutput']>, ParentType, ContextType, RequireFields<Query_RootProfileViewArgs, 'email'>>;
  profile_aggregate?: Resolver<ResolversTypes['profile_aggregate'], ParentType, ContextType, RequireFields<Query_RootProfile_AggregateArgs, never>>;
  profile_by_pk?: Resolver<Maybe<ResolversTypes['profile']>, ParentType, ContextType, RequireFields<Query_RootProfile_By_PkArgs, 'id'>>;
  retrieveSharedCalendarLink?: Resolver<ResolversTypes['RetrieveSharedCalendarLinkOutput'], ParentType, ContextType, RequireFields<Query_RootRetrieveSharedCalendarLinkArgs, 'objects'>>;
  shareCalendarLink?: Resolver<Array<ResolversTypes['shareCalendarLink']>, ParentType, ContextType, RequireFields<Query_RootShareCalendarLinkArgs, never>>;
  shareCalendarLinkTypeEnum?: Resolver<Array<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType, RequireFields<Query_RootShareCalendarLinkTypeEnumArgs, never>>;
  shareCalendarLinkTypeEnum_aggregate?: Resolver<ResolversTypes['shareCalendarLinkTypeEnum_aggregate'], ParentType, ContextType, RequireFields<Query_RootShareCalendarLinkTypeEnum_AggregateArgs, never>>;
  shareCalendarLinkTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType, RequireFields<Query_RootShareCalendarLinkTypeEnum_By_PkArgs, 'value'>>;
  shareCalendarLink_aggregate?: Resolver<ResolversTypes['shareCalendarLink_aggregate'], ParentType, ContextType, RequireFields<Query_RootShareCalendarLink_AggregateArgs, never>>;
  shareCalendarLink_by_pk?: Resolver<Maybe<ResolversTypes['shareCalendarLink']>, ParentType, ContextType, RequireFields<Query_RootShareCalendarLink_By_PkArgs, 'id'>>;
  singleEvent?: Resolver<Maybe<ResolversTypes['NewEvent']>, ParentType, ContextType, RequireFields<Query_RootSingleEventArgs, 'where'>>;
  spotifyCurrentlyPlaying?: Resolver<Maybe<ResolversTypes['SpotifyTrack']>, ParentType, ContextType, RequireFields<Query_RootSpotifyCurrentlyPlayingArgs, 'targetUser'>>;
  spotifyRecentlyPlayed?: Resolver<Maybe<ResolversTypes['SpotifyQueue']>, ParentType, ContextType, RequireFields<Query_RootSpotifyRecentlyPlayedArgs, 'before'>>;
  theme?: Resolver<Array<ResolversTypes['theme']>, ParentType, ContextType, RequireFields<Query_RootThemeArgs, never>>;
  theme_aggregate?: Resolver<ResolversTypes['theme_aggregate'], ParentType, ContextType, RequireFields<Query_RootTheme_AggregateArgs, never>>;
  theme_by_pk?: Resolver<Maybe<ResolversTypes['theme']>, ParentType, ContextType, RequireFields<Query_RootTheme_By_PkArgs, 'value'>>;
  todo?: Resolver<Array<ResolversTypes['todo']>, ParentType, ContextType, RequireFields<Query_RootTodoArgs, never>>;
  todoTypeEnum?: Resolver<Array<ResolversTypes['todoTypeEnum']>, ParentType, ContextType, RequireFields<Query_RootTodoTypeEnumArgs, never>>;
  todoTypeEnum_aggregate?: Resolver<ResolversTypes['todoTypeEnum_aggregate'], ParentType, ContextType, RequireFields<Query_RootTodoTypeEnum_AggregateArgs, never>>;
  todoTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['todoTypeEnum']>, ParentType, ContextType, RequireFields<Query_RootTodoTypeEnum_By_PkArgs, 'value'>>;
  todo_aggregate?: Resolver<ResolversTypes['todo_aggregate'], ParentType, ContextType, RequireFields<Query_RootTodo_AggregateArgs, never>>;
  todo_by_pk?: Resolver<Maybe<ResolversTypes['todo']>, ParentType, ContextType, RequireFields<Query_RootTodo_By_PkArgs, 'id'>>;
  userActivityFeed?: Resolver<Array<ResolversTypes['userActivityFeed']>, ParentType, ContextType, RequireFields<Query_RootUserActivityFeedArgs, never>>;
  userActivityFeed_aggregate?: Resolver<ResolversTypes['userActivityFeed_aggregate'], ParentType, ContextType, RequireFields<Query_RootUserActivityFeed_AggregateArgs, never>>;
  userActivityFeed_by_pk?: Resolver<Maybe<ResolversTypes['userActivityFeed']>, ParentType, ContextType, RequireFields<Query_RootUserActivityFeed_By_PkArgs, 'id'>>;
  userPreferences?: Resolver<Array<ResolversTypes['userPreferences']>, ParentType, ContextType, RequireFields<Query_RootUserPreferencesArgs, never>>;
  userPreferences_aggregate?: Resolver<ResolversTypes['userPreferences_aggregate'], ParentType, ContextType, RequireFields<Query_RootUserPreferences_AggregateArgs, never>>;
  userPreferences_by_pk?: Resolver<Maybe<ResolversTypes['userPreferences']>, ParentType, ContextType, RequireFields<Query_RootUserPreferences_By_PkArgs, 'id'>>;
  userStatus?: Resolver<Array<ResolversTypes['userStatus']>, ParentType, ContextType, RequireFields<Query_RootUserStatusArgs, never>>;
  userStatus_aggregate?: Resolver<ResolversTypes['userStatus_aggregate'], ParentType, ContextType, RequireFields<Query_RootUserStatus_AggregateArgs, never>>;
  userStatus_by_pk?: Resolver<Maybe<ResolversTypes['userStatus']>, ParentType, ContextType, RequireFields<Query_RootUserStatus_By_PkArgs, 'userEmail'>>;
  user_allowlist?: Resolver<Array<ResolversTypes['user_allowlist']>, ParentType, ContextType, RequireFields<Query_RootUser_AllowlistArgs, never>>;
  user_allowlist_aggregate?: Resolver<ResolversTypes['user_allowlist_aggregate'], ParentType, ContextType, RequireFields<Query_RootUser_Allowlist_AggregateArgs, never>>;
  user_allowlist_by_pk?: Resolver<Maybe<ResolversTypes['user_allowlist']>, ParentType, ContextType, RequireFields<Query_RootUser_Allowlist_By_PkArgs, 'id'>>;
  videoProvider?: Resolver<Array<ResolversTypes['videoProvider']>, ParentType, ContextType, RequireFields<Query_RootVideoProviderArgs, never>>;
  videoProvider_aggregate?: Resolver<ResolversTypes['videoProvider_aggregate'], ParentType, ContextType, RequireFields<Query_RootVideoProvider_AggregateArgs, never>>;
  videoProvider_by_pk?: Resolver<Maybe<ResolversTypes['videoProvider']>, ParentType, ContextType, RequireFields<Query_RootVideoProvider_By_PkArgs, 'value'>>;
};

export type RefreshUserStatusOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['refreshUserStatusOutput'] = ResolversParentTypes['refreshUserStatusOutput']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink'] = ResolversParentTypes['shareCalendarLink']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, RequireFields<ShareCalendarLinkPayloadArgs, never>>;
  type?: Resolver<ResolversTypes['shareCalendarLinkTypeEnum_enum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLinkTypeEnum'] = ResolversParentTypes['shareCalendarLinkTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLinkTypeEnum_aggregate'] = ResolversParentTypes['shareCalendarLinkTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLinkTypeEnum_aggregate_fields'] = ResolversParentTypes['shareCalendarLinkTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<ShareCalendarLinkTypeEnum_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLinkTypeEnum_max_fields'] = ResolversParentTypes['shareCalendarLinkTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLinkTypeEnum_min_fields'] = ResolversParentTypes['shareCalendarLinkTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLinkTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLinkTypeEnum_mutation_response'] = ResolversParentTypes['shareCalendarLinkTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shareCalendarLinkTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_aggregate'] = ResolversParentTypes['shareCalendarLink_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['shareCalendarLink_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['shareCalendarLink']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_aggregate_fields'] = ResolversParentTypes['shareCalendarLink_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['shareCalendarLink_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<ShareCalendarLink_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['shareCalendarLink_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['shareCalendarLink_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['shareCalendarLink_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['shareCalendarLink_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['shareCalendarLink_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['shareCalendarLink_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['shareCalendarLink_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['shareCalendarLink_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['shareCalendarLink_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_avg_fields'] = ResolversParentTypes['shareCalendarLink_avg_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_max_fields'] = ResolversParentTypes['shareCalendarLink_max_fields']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_min_fields'] = ResolversParentTypes['shareCalendarLink_min_fields']> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_mutation_response'] = ResolversParentTypes['shareCalendarLink_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shareCalendarLink']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_stddev_fields'] = ResolversParentTypes['shareCalendarLink_stddev_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_stddev_pop_fields'] = ResolversParentTypes['shareCalendarLink_stddev_pop_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_stddev_samp_fields'] = ResolversParentTypes['shareCalendarLink_stddev_samp_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_sum_fields'] = ResolversParentTypes['shareCalendarLink_sum_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_var_pop_fields'] = ResolversParentTypes['shareCalendarLink_var_pop_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_var_samp_fields'] = ResolversParentTypes['shareCalendarLink_var_samp_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareCalendarLink_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['shareCalendarLink_variance_fields'] = ResolversParentTypes['shareCalendarLink_variance_fields']> = {
  bookedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Subscription_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = {
  activityFeed?: SubscriptionResolver<Array<ResolversTypes['activityFeed']>, "activityFeed", ParentType, ContextType, RequireFields<Subscription_RootActivityFeedArgs, never>>;
  activityFeedTypeEnum?: SubscriptionResolver<Array<ResolversTypes['activityFeedTypeEnum']>, "activityFeedTypeEnum", ParentType, ContextType, RequireFields<Subscription_RootActivityFeedTypeEnumArgs, never>>;
  activityFeedTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['activityFeedTypeEnum_aggregate'], "activityFeedTypeEnum_aggregate", ParentType, ContextType, RequireFields<Subscription_RootActivityFeedTypeEnum_AggregateArgs, never>>;
  activityFeedTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['activityFeedTypeEnum']>, "activityFeedTypeEnum_by_pk", ParentType, ContextType, RequireFields<Subscription_RootActivityFeedTypeEnum_By_PkArgs, 'value'>>;
  activityFeed_aggregate?: SubscriptionResolver<ResolversTypes['activityFeed_aggregate'], "activityFeed_aggregate", ParentType, ContextType, RequireFields<Subscription_RootActivityFeed_AggregateArgs, never>>;
  activityFeed_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['activityFeed']>, "activityFeed_by_pk", ParentType, ContextType, RequireFields<Subscription_RootActivityFeed_By_PkArgs, 'id'>>;
  analytics_event?: SubscriptionResolver<Array<ResolversTypes['analytics_event']>, "analytics_event", ParentType, ContextType, RequireFields<Subscription_RootAnalytics_EventArgs, never>>;
  analytics_event_aggregate?: SubscriptionResolver<ResolversTypes['analytics_event_aggregate'], "analytics_event_aggregate", ParentType, ContextType, RequireFields<Subscription_RootAnalytics_Event_AggregateArgs, never>>;
  analytics_event_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['analytics_event']>, "analytics_event_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAnalytics_Event_By_PkArgs, 'id'>>;
  calendarHistory?: SubscriptionResolver<Array<ResolversTypes['calendarHistory']>, "calendarHistory", ParentType, ContextType, RequireFields<Subscription_RootCalendarHistoryArgs, never>>;
  calendarHistory_aggregate?: SubscriptionResolver<ResolversTypes['calendarHistory_aggregate'], "calendarHistory_aggregate", ParentType, ContextType, RequireFields<Subscription_RootCalendarHistory_AggregateArgs, never>>;
  calendarHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['calendarHistory']>, "calendarHistory_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCalendarHistory_By_PkArgs, 'id'>>;
  calendarPreference?: SubscriptionResolver<Array<ResolversTypes['calendarPreference']>, "calendarPreference", ParentType, ContextType, RequireFields<Subscription_RootCalendarPreferenceArgs, never>>;
  calendarPreference_aggregate?: SubscriptionResolver<ResolversTypes['calendarPreference_aggregate'], "calendarPreference_aggregate", ParentType, ContextType, RequireFields<Subscription_RootCalendarPreference_AggregateArgs, never>>;
  calendarPreference_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['calendarPreference']>, "calendarPreference_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCalendarPreference_By_PkArgs, 'id'>>;
  calendarStartsOn?: SubscriptionResolver<Array<ResolversTypes['calendarStartsOn']>, "calendarStartsOn", ParentType, ContextType, RequireFields<Subscription_RootCalendarStartsOnArgs, never>>;
  calendarStartsOn_aggregate?: SubscriptionResolver<ResolversTypes['calendarStartsOn_aggregate'], "calendarStartsOn_aggregate", ParentType, ContextType, RequireFields<Subscription_RootCalendarStartsOn_AggregateArgs, never>>;
  calendarStartsOn_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['calendarStartsOn']>, "calendarStartsOn_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCalendarStartsOn_By_PkArgs, 'value'>>;
  category?: SubscriptionResolver<Array<ResolversTypes['category']>, "category", ParentType, ContextType, RequireFields<Subscription_RootCategoryArgs, never>>;
  category_aggregate?: SubscriptionResolver<ResolversTypes['category_aggregate'], "category_aggregate", ParentType, ContextType, RequireFields<Subscription_RootCategory_AggregateArgs, never>>;
  category_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['category']>, "category_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCategory_By_PkArgs, 'id'>>;
  colorFamilyEnum?: SubscriptionResolver<Array<ResolversTypes['colorFamilyEnum']>, "colorFamilyEnum", ParentType, ContextType, RequireFields<Subscription_RootColorFamilyEnumArgs, never>>;
  colorFamilyEnum_aggregate?: SubscriptionResolver<ResolversTypes['colorFamilyEnum_aggregate'], "colorFamilyEnum_aggregate", ParentType, ContextType, RequireFields<Subscription_RootColorFamilyEnum_AggregateArgs, never>>;
  colorFamilyEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['colorFamilyEnum']>, "colorFamilyEnum_by_pk", ParentType, ContextType, RequireFields<Subscription_RootColorFamilyEnum_By_PkArgs, 'value'>>;
  contact_sync?: SubscriptionResolver<Array<ResolversTypes['contact_sync']>, "contact_sync", ParentType, ContextType, RequireFields<Subscription_RootContact_SyncArgs, never>>;
  contact_sync_aggregate?: SubscriptionResolver<ResolversTypes['contact_sync_aggregate'], "contact_sync_aggregate", ParentType, ContextType, RequireFields<Subscription_RootContact_Sync_AggregateArgs, never>>;
  contact_sync_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['contact_sync']>, "contact_sync_by_pk", ParentType, ContextType, RequireFields<Subscription_RootContact_Sync_By_PkArgs, 'id'>>;
  databasechangelog?: SubscriptionResolver<Array<ResolversTypes['databasechangelog']>, "databasechangelog", ParentType, ContextType, RequireFields<Subscription_RootDatabasechangelogArgs, never>>;
  databasechangelog_aggregate?: SubscriptionResolver<ResolversTypes['databasechangelog_aggregate'], "databasechangelog_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDatabasechangelog_AggregateArgs, never>>;
  databasechangeloglock?: SubscriptionResolver<Array<ResolversTypes['databasechangeloglock']>, "databasechangeloglock", ParentType, ContextType, RequireFields<Subscription_RootDatabasechangeloglockArgs, never>>;
  databasechangeloglock_aggregate?: SubscriptionResolver<ResolversTypes['databasechangeloglock_aggregate'], "databasechangeloglock_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDatabasechangeloglock_AggregateArgs, never>>;
  databasechangeloglock_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['databasechangeloglock']>, "databasechangeloglock_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDatabasechangeloglock_By_PkArgs, 'id'>>;
  dateFormat?: SubscriptionResolver<Array<ResolversTypes['dateFormat']>, "dateFormat", ParentType, ContextType, RequireFields<Subscription_RootDateFormatArgs, never>>;
  dateFormat_aggregate?: SubscriptionResolver<ResolversTypes['dateFormat_aggregate'], "dateFormat_aggregate", ParentType, ContextType, RequireFields<Subscription_RootDateFormat_AggregateArgs, never>>;
  dateFormat_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dateFormat']>, "dateFormat_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDateFormat_By_PkArgs, 'value'>>;
  google_credential?: SubscriptionResolver<Array<ResolversTypes['google_credential']>, "google_credential", ParentType, ContextType, RequireFields<Subscription_RootGoogle_CredentialArgs, never>>;
  google_credential_aggregate?: SubscriptionResolver<ResolversTypes['google_credential_aggregate'], "google_credential_aggregate", ParentType, ContextType, RequireFields<Subscription_RootGoogle_Credential_AggregateArgs, never>>;
  google_credential_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['google_credential']>, "google_credential_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGoogle_Credential_By_PkArgs, 'id'>>;
  linear_issue?: SubscriptionResolver<Array<ResolversTypes['linear_issue']>, "linear_issue", ParentType, ContextType, RequireFields<Subscription_RootLinear_IssueArgs, never>>;
  linear_issue_aggregate?: SubscriptionResolver<ResolversTypes['linear_issue_aggregate'], "linear_issue_aggregate", ParentType, ContextType, RequireFields<Subscription_RootLinear_Issue_AggregateArgs, never>>;
  linear_issue_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['linear_issue']>, "linear_issue_by_pk", ParentType, ContextType, RequireFields<Subscription_RootLinear_Issue_By_PkArgs, 'id'>>;
  new_contact?: SubscriptionResolver<Array<ResolversTypes['new_contact']>, "new_contact", ParentType, ContextType, RequireFields<Subscription_RootNew_ContactArgs, never>>;
  new_contact_aggregate?: SubscriptionResolver<ResolversTypes['new_contact_aggregate'], "new_contact_aggregate", ParentType, ContextType, RequireFields<Subscription_RootNew_Contact_AggregateArgs, never>>;
  new_contact_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['new_contact']>, "new_contact_by_pk", ParentType, ContextType, RequireFields<Subscription_RootNew_Contact_By_PkArgs, 'id'>>;
  new_feedback?: SubscriptionResolver<Array<ResolversTypes['new_feedback']>, "new_feedback", ParentType, ContextType, RequireFields<Subscription_RootNew_FeedbackArgs, never>>;
  new_feedback_aggregate?: SubscriptionResolver<ResolversTypes['new_feedback_aggregate'], "new_feedback_aggregate", ParentType, ContextType, RequireFields<Subscription_RootNew_Feedback_AggregateArgs, never>>;
  new_feedback_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['new_feedback']>, "new_feedback_by_pk", ParentType, ContextType, RequireFields<Subscription_RootNew_Feedback_By_PkArgs, 'id'>>;
  new_user?: SubscriptionResolver<Array<ResolversTypes['new_user']>, "new_user", ParentType, ContextType, RequireFields<Subscription_RootNew_UserArgs, never>>;
  new_user_aggregate?: SubscriptionResolver<ResolversTypes['new_user_aggregate'], "new_user_aggregate", ParentType, ContextType, RequireFields<Subscription_RootNew_User_AggregateArgs, never>>;
  new_user_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['new_user']>, "new_user_by_pk", ParentType, ContextType, RequireFields<Subscription_RootNew_User_By_PkArgs, 'id'>>;
  profile?: SubscriptionResolver<Array<ResolversTypes['profile']>, "profile", ParentType, ContextType, RequireFields<Subscription_RootProfileArgs, never>>;
  profile_aggregate?: SubscriptionResolver<ResolversTypes['profile_aggregate'], "profile_aggregate", ParentType, ContextType, RequireFields<Subscription_RootProfile_AggregateArgs, never>>;
  profile_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['profile']>, "profile_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProfile_By_PkArgs, 'id'>>;
  shareCalendarLink?: SubscriptionResolver<Array<ResolversTypes['shareCalendarLink']>, "shareCalendarLink", ParentType, ContextType, RequireFields<Subscription_RootShareCalendarLinkArgs, never>>;
  shareCalendarLinkTypeEnum?: SubscriptionResolver<Array<ResolversTypes['shareCalendarLinkTypeEnum']>, "shareCalendarLinkTypeEnum", ParentType, ContextType, RequireFields<Subscription_RootShareCalendarLinkTypeEnumArgs, never>>;
  shareCalendarLinkTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['shareCalendarLinkTypeEnum_aggregate'], "shareCalendarLinkTypeEnum_aggregate", ParentType, ContextType, RequireFields<Subscription_RootShareCalendarLinkTypeEnum_AggregateArgs, never>>;
  shareCalendarLinkTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shareCalendarLinkTypeEnum']>, "shareCalendarLinkTypeEnum_by_pk", ParentType, ContextType, RequireFields<Subscription_RootShareCalendarLinkTypeEnum_By_PkArgs, 'value'>>;
  shareCalendarLink_aggregate?: SubscriptionResolver<ResolversTypes['shareCalendarLink_aggregate'], "shareCalendarLink_aggregate", ParentType, ContextType, RequireFields<Subscription_RootShareCalendarLink_AggregateArgs, never>>;
  shareCalendarLink_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shareCalendarLink']>, "shareCalendarLink_by_pk", ParentType, ContextType, RequireFields<Subscription_RootShareCalendarLink_By_PkArgs, 'id'>>;
  theme?: SubscriptionResolver<Array<ResolversTypes['theme']>, "theme", ParentType, ContextType, RequireFields<Subscription_RootThemeArgs, never>>;
  theme_aggregate?: SubscriptionResolver<ResolversTypes['theme_aggregate'], "theme_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTheme_AggregateArgs, never>>;
  theme_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['theme']>, "theme_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTheme_By_PkArgs, 'value'>>;
  todo?: SubscriptionResolver<Array<ResolversTypes['todo']>, "todo", ParentType, ContextType, RequireFields<Subscription_RootTodoArgs, never>>;
  todoTypeEnum?: SubscriptionResolver<Array<ResolversTypes['todoTypeEnum']>, "todoTypeEnum", ParentType, ContextType, RequireFields<Subscription_RootTodoTypeEnumArgs, never>>;
  todoTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['todoTypeEnum_aggregate'], "todoTypeEnum_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTodoTypeEnum_AggregateArgs, never>>;
  todoTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['todoTypeEnum']>, "todoTypeEnum_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTodoTypeEnum_By_PkArgs, 'value'>>;
  todo_aggregate?: SubscriptionResolver<ResolversTypes['todo_aggregate'], "todo_aggregate", ParentType, ContextType, RequireFields<Subscription_RootTodo_AggregateArgs, never>>;
  todo_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['todo']>, "todo_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTodo_By_PkArgs, 'id'>>;
  userActivityFeed?: SubscriptionResolver<Array<ResolversTypes['userActivityFeed']>, "userActivityFeed", ParentType, ContextType, RequireFields<Subscription_RootUserActivityFeedArgs, never>>;
  userActivityFeed_aggregate?: SubscriptionResolver<ResolversTypes['userActivityFeed_aggregate'], "userActivityFeed_aggregate", ParentType, ContextType, RequireFields<Subscription_RootUserActivityFeed_AggregateArgs, never>>;
  userActivityFeed_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['userActivityFeed']>, "userActivityFeed_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserActivityFeed_By_PkArgs, 'id'>>;
  userPreferences?: SubscriptionResolver<Array<ResolversTypes['userPreferences']>, "userPreferences", ParentType, ContextType, RequireFields<Subscription_RootUserPreferencesArgs, never>>;
  userPreferences_aggregate?: SubscriptionResolver<ResolversTypes['userPreferences_aggregate'], "userPreferences_aggregate", ParentType, ContextType, RequireFields<Subscription_RootUserPreferences_AggregateArgs, never>>;
  userPreferences_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['userPreferences']>, "userPreferences_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserPreferences_By_PkArgs, 'id'>>;
  userStatus?: SubscriptionResolver<Array<ResolversTypes['userStatus']>, "userStatus", ParentType, ContextType, RequireFields<Subscription_RootUserStatusArgs, never>>;
  userStatus_aggregate?: SubscriptionResolver<ResolversTypes['userStatus_aggregate'], "userStatus_aggregate", ParentType, ContextType, RequireFields<Subscription_RootUserStatus_AggregateArgs, never>>;
  userStatus_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['userStatus']>, "userStatus_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserStatus_By_PkArgs, 'userEmail'>>;
  user_allowlist?: SubscriptionResolver<Array<ResolversTypes['user_allowlist']>, "user_allowlist", ParentType, ContextType, RequireFields<Subscription_RootUser_AllowlistArgs, never>>;
  user_allowlist_aggregate?: SubscriptionResolver<ResolversTypes['user_allowlist_aggregate'], "user_allowlist_aggregate", ParentType, ContextType, RequireFields<Subscription_RootUser_Allowlist_AggregateArgs, never>>;
  user_allowlist_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['user_allowlist']>, "user_allowlist_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUser_Allowlist_By_PkArgs, 'id'>>;
  videoProvider?: SubscriptionResolver<Array<ResolversTypes['videoProvider']>, "videoProvider", ParentType, ContextType, RequireFields<Subscription_RootVideoProviderArgs, never>>;
  videoProvider_aggregate?: SubscriptionResolver<ResolversTypes['videoProvider_aggregate'], "videoProvider_aggregate", ParentType, ContextType, RequireFields<Subscription_RootVideoProvider_AggregateArgs, never>>;
  videoProvider_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['videoProvider']>, "videoProvider_by_pk", ParentType, ContextType, RequireFields<Subscription_RootVideoProvider_By_PkArgs, 'value'>>;
};

export type ThemeResolvers<ContextType = any, ParentType extends ResolversParentTypes['theme'] = ResolversParentTypes['theme']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Theme_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['theme_aggregate'] = ResolversParentTypes['theme_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['theme_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['theme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Theme_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['theme_aggregate_fields'] = ResolversParentTypes['theme_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Theme_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['theme_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['theme_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Theme_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['theme_max_fields'] = ResolversParentTypes['theme_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Theme_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['theme_min_fields'] = ResolversParentTypes['theme_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Theme_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['theme_mutation_response'] = ResolversParentTypes['theme_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['theme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamp'], any> {
  name: 'timestamp';
}

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type TodoResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo'] = ResolversParentTypes['todo']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  doneAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['todoTypeEnum_enum']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['todoTypeEnum'] = ResolversParentTypes['todoTypeEnum']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['todoTypeEnum_aggregate'] = ResolversParentTypes['todoTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['todoTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['todoTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todoTypeEnum_aggregate_fields'] = ResolversParentTypes['todoTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<TodoTypeEnum_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['todoTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['todoTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todoTypeEnum_max_fields'] = ResolversParentTypes['todoTypeEnum_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todoTypeEnum_min_fields'] = ResolversParentTypes['todoTypeEnum_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodoTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['todoTypeEnum_mutation_response'] = ResolversParentTypes['todoTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['todoTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_aggregate'] = ResolversParentTypes['todo_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['todo_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['todo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_aggregate_fields'] = ResolversParentTypes['todo_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['todo_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<Todo_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['todo_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['todo_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['todo_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['todo_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['todo_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['todo_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['todo_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['todo_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['todo_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_avg_fields'] = ResolversParentTypes['todo_avg_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_max_fields'] = ResolversParentTypes['todo_max_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  doneAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_min_fields'] = ResolversParentTypes['todo_min_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  doneAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastClientUpdate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_mutation_response'] = ResolversParentTypes['todo_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['todo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_stddev_fields'] = ResolversParentTypes['todo_stddev_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_stddev_pop_fields'] = ResolversParentTypes['todo_stddev_pop_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_stddev_samp_fields'] = ResolversParentTypes['todo_stddev_samp_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_sum_fields'] = ResolversParentTypes['todo_sum_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_var_pop_fields'] = ResolversParentTypes['todo_var_pop_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_var_samp_fields'] = ResolversParentTypes['todo_var_samp_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Todo_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['todo_variance_fields'] = ResolversParentTypes['todo_variance_fields']> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityFeedResolvers<ContextType = any, ParentType extends ResolversParentTypes['userActivityFeed'] = ResolversParentTypes['userActivityFeed']> = {
  activityFeed?: Resolver<ResolversTypes['activityFeed'], ParentType, ContextType>;
  activityFeedId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['new_user'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityFeed_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['userActivityFeed_aggregate'] = ResolversParentTypes['userActivityFeed_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['userActivityFeed_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['userActivityFeed']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityFeed_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userActivityFeed_aggregate_fields'] = ResolversParentTypes['userActivityFeed_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<UserActivityFeed_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['userActivityFeed_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['userActivityFeed_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityFeed_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userActivityFeed_max_fields'] = ResolversParentTypes['userActivityFeed_max_fields']> = {
  activityFeedId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityFeed_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userActivityFeed_min_fields'] = ResolversParentTypes['userActivityFeed_min_fields']> = {
  activityFeedId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserActivityFeed_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['userActivityFeed_mutation_response'] = ResolversParentTypes['userActivityFeed_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['userActivityFeed']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences'] = ResolversParentTypes['userPreferences']> = {
  aroundLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendarStartsOn?: Resolver<Maybe<ResolversTypes['calendarStartsOn_enum']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dateFormat?: Resolver<Maybe<ResolversTypes['dateFormat_enum']>, ParentType, ContextType>;
  defaultDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  defaultEventColor?: Resolver<ResolversTypes['colorFamilyEnum_enum'], ParentType, ContextType>;
  hasOnboarded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hideDeclinedEvents?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  showArchivedLists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showDoneTodos?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['theme_enum']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  todoPrivacy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ui24HourClock?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uiCalendarTargetDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uiHideWeekend?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  uiVisibleCalendarsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['new_user']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoProvider?: Resolver<Maybe<ResolversTypes['videoProvider_enum']>, ParentType, ContextType>;
  workHoursEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  workHoursEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workHoursStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_aggregate'] = ResolversParentTypes['userPreferences_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['userPreferences_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['userPreferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_aggregate_fields'] = ResolversParentTypes['userPreferences_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['userPreferences_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<UserPreferences_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['userPreferences_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['userPreferences_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['userPreferences_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['userPreferences_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['userPreferences_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['userPreferences_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['userPreferences_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['userPreferences_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['userPreferences_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_avg_fields'] = ResolversParentTypes['userPreferences_avg_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_max_fields'] = ResolversParentTypes['userPreferences_max_fields']> = {
  aroundLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  defaultDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uiCalendarTargetDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uiVisibleCalendarsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workHoursEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workHoursStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_min_fields'] = ResolversParentTypes['userPreferences_min_fields']> = {
  aroundLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  defaultDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uiCalendarTargetDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uiVisibleCalendarsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workHoursEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workHoursStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_mutation_response'] = ResolversParentTypes['userPreferences_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['userPreferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_stddev_fields'] = ResolversParentTypes['userPreferences_stddev_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_stddev_pop_fields'] = ResolversParentTypes['userPreferences_stddev_pop_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_stddev_samp_fields'] = ResolversParentTypes['userPreferences_stddev_samp_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_sum_fields'] = ResolversParentTypes['userPreferences_sum_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_var_pop_fields'] = ResolversParentTypes['userPreferences_var_pop_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_var_samp_fields'] = ResolversParentTypes['userPreferences_var_samp_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferences_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPreferences_variance_fields'] = ResolversParentTypes['userPreferences_variance_fields']> = {
  defaultDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['userStatus'] = ResolversParentTypes['userStatus']> = {
  clearStatusCronJobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoji?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieEndAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  integrationAmieStartAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStatus_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['userStatus_aggregate'] = ResolversParentTypes['userStatus_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['userStatus_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['userStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStatus_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userStatus_aggregate_fields'] = ResolversParentTypes['userStatus_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<UserStatus_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['userStatus_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['userStatus_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStatus_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userStatus_max_fields'] = ResolversParentTypes['userStatus_max_fields']> = {
  clearStatusCronJobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoji?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieEndAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  integrationAmieStartAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStatus_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userStatus_min_fields'] = ResolversParentTypes['userStatus_min_fields']> = {
  clearStatusCronJobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoji?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieEndAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  integrationAmieStartAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  integrationAmieTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserStatus_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['userStatus_mutation_response'] = ResolversParentTypes['userStatus_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['userStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_AllowlistResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_allowlist'] = ResolversParentTypes['user_allowlist']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  email_address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Allowlist_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_allowlist_aggregate'] = ResolversParentTypes['user_allowlist_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['user_allowlist_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['user_allowlist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Allowlist_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_allowlist_aggregate_fields'] = ResolversParentTypes['user_allowlist_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<User_Allowlist_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['user_allowlist_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['user_allowlist_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Allowlist_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_allowlist_max_fields'] = ResolversParentTypes['user_allowlist_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Allowlist_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_allowlist_min_fields'] = ResolversParentTypes['user_allowlist_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Allowlist_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_allowlist_mutation_response'] = ResolversParentTypes['user_allowlist_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['user_allowlist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type VideoProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['videoProvider'] = ResolversParentTypes['videoProvider']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoProvider_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['videoProvider_aggregate'] = ResolversParentTypes['videoProvider_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['videoProvider_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['videoProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoProvider_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['videoProvider_aggregate_fields'] = ResolversParentTypes['videoProvider_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoProvider_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['videoProvider_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['videoProvider_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoProvider_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['videoProvider_max_fields'] = ResolversParentTypes['videoProvider_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoProvider_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['videoProvider_min_fields'] = ResolversParentTypes['videoProvider_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoProvider_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['videoProvider_mutation_response'] = ResolversParentTypes['videoProvider_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['videoProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AttendeeAvailability?: AttendeeAvailabilityResolvers<ContextType>;
  Bestie?: BestieResolvers<ContextType>;
  Birthday?: BirthdayResolvers<ContextType>;
  BirthdaysViewOutput?: BirthdaysViewOutputResolvers<ContextType>;
  BookCalendarSlotOutput?: BookCalendarSlotOutputResolvers<ContextType>;
  CalendarOwner?: CalendarOwnerResolvers<ContextType>;
  CalendarRole?: CalendarRoleResolvers<ContextType>;
  CalendarSlot?: CalendarSlotResolvers<ContextType>;
  CheckAliasAvailabilityOutput?: CheckAliasAvailabilityOutputResolvers<ContextType>;
  ClearEventsCacheOutput?: ClearEventsCacheOutputResolvers<ContextType>;
  ContactReminderOutput?: ContactReminderOutputResolvers<ContextType>;
  CreateCalendarLinkOutput?: CreateCalendarLinkOutputResolvers<ContextType>;
  CreateContactsOutput?: CreateContactsOutputResolvers<ContextType>;
  CreateEventOutput?: CreateEventOutputResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DateTimeRFC3339?: GraphQLScalarType;
  DeleteEventOutput?: DeleteEventOutputResolvers<ContextType>;
  DisplayOptions?: DisplayOptionsResolvers<ContextType>;
  EventCreator?: EventCreatorResolvers<ContextType>;
  EventsResponse?: EventsResponseResolvers<ContextType>;
  Feedback?: FeedbackResolvers<ContextType>;
  FindNextAvailableSlotResponse?: FindNextAvailableSlotResponseResolvers<ContextType>;
  GMapsPlace?: GMapsPlaceResolvers<ContextType>;
  GetGMapsPlacesOutput?: GetGMapsPlacesOutputResolvers<ContextType>;
  HideCalendarOutput?: HideCalendarOutputResolvers<ContextType>;
  MinimalCalendarInfo?: MinimalCalendarInfoResolvers<ContextType>;
  NewCalendar?: NewCalendarResolvers<ContextType>;
  NewEvent?: NewEventResolvers<ContextType>;
  NewEventAttendee?: NewEventAttendeeResolvers<ContextType>;
  NewEventVideoConference?: NewEventVideoConferenceResolvers<ContextType>;
  ProfileMeetInfoOutput?: ProfileMeetInfoOutputResolvers<ContextType>;
  ProfileViewOutput?: ProfileViewOutputResolvers<ContextType>;
  RecentlyPlayedTrack?: RecentlyPlayedTrackResolvers<ContextType>;
  RefreshActivityFeedOutput?: RefreshActivityFeedOutputResolvers<ContextType>;
  RetrieveSharedCalendarLinkOutput?: RetrieveSharedCalendarLinkOutputResolvers<ContextType>;
  SendFeedbackOutput?: SendFeedbackOutputResolvers<ContextType>;
  SetBirthdayReminderOutput?: SetBirthdayReminderOutputResolvers<ContextType>;
  SetEventRecurrenceOutput?: SetEventRecurrenceOutputResolvers<ContextType>;
  SetReminderOutput?: SetReminderOutputResolvers<ContextType>;
  ShowCalendarOutput?: ShowCalendarOutputResolvers<ContextType>;
  SignInUsingFirebaseAuthenticationOutput?: SignInUsingFirebaseAuthenticationOutputResolvers<ContextType>;
  SignInUsingGoogleAuthorizationCodeOutput?: SignInUsingGoogleAuthorizationCodeOutputResolvers<ContextType>;
  Slot?: SlotResolvers<ContextType>;
  SpotifyPauseTrack?: SpotifyPauseTrackResolvers<ContextType>;
  SpotifyPlayTrack?: SpotifyPlayTrackResolvers<ContextType>;
  SpotifyQueue?: SpotifyQueueResolvers<ContextType>;
  SpotifyTrack?: SpotifyTrackResolvers<ContextType>;
  SyncContactsOutput?: SyncContactsOutputResolvers<ContextType>;
  TodoCategory?: TodoCategoryResolvers<ContextType>;
  TodoItem?: TodoItemResolvers<ContextType>;
  ToggleEventDoneOutput?: ToggleEventDoneOutputResolvers<ContextType>;
  UpdateCalendarLinkOutput?: UpdateCalendarLinkOutputResolvers<ContextType>;
  UpdateEventOutput?: UpdateEventOutputResolvers<ContextType>;
  UpdateEventRSVPOutput?: UpdateEventRsvpOutputResolvers<ContextType>;
  UpdateTodosOutput?: UpdateTodosOutputResolvers<ContextType>;
  activityFeed?: ActivityFeedResolvers<ContextType>;
  activityFeedTypeEnum?: ActivityFeedTypeEnumResolvers<ContextType>;
  activityFeedTypeEnum_aggregate?: ActivityFeedTypeEnum_AggregateResolvers<ContextType>;
  activityFeedTypeEnum_aggregate_fields?: ActivityFeedTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  activityFeedTypeEnum_max_fields?: ActivityFeedTypeEnum_Max_FieldsResolvers<ContextType>;
  activityFeedTypeEnum_min_fields?: ActivityFeedTypeEnum_Min_FieldsResolvers<ContextType>;
  activityFeedTypeEnum_mutation_response?: ActivityFeedTypeEnum_Mutation_ResponseResolvers<ContextType>;
  activityFeed_aggregate?: ActivityFeed_AggregateResolvers<ContextType>;
  activityFeed_aggregate_fields?: ActivityFeed_Aggregate_FieldsResolvers<ContextType>;
  activityFeed_max_fields?: ActivityFeed_Max_FieldsResolvers<ContextType>;
  activityFeed_min_fields?: ActivityFeed_Min_FieldsResolvers<ContextType>;
  activityFeed_mutation_response?: ActivityFeed_Mutation_ResponseResolvers<ContextType>;
  analytics_event?: Analytics_EventResolvers<ContextType>;
  analytics_event_aggregate?: Analytics_Event_AggregateResolvers<ContextType>;
  analytics_event_aggregate_fields?: Analytics_Event_Aggregate_FieldsResolvers<ContextType>;
  analytics_event_max_fields?: Analytics_Event_Max_FieldsResolvers<ContextType>;
  analytics_event_min_fields?: Analytics_Event_Min_FieldsResolvers<ContextType>;
  analytics_event_mutation_response?: Analytics_Event_Mutation_ResponseResolvers<ContextType>;
  calendarHistory?: CalendarHistoryResolvers<ContextType>;
  calendarHistory_aggregate?: CalendarHistory_AggregateResolvers<ContextType>;
  calendarHistory_aggregate_fields?: CalendarHistory_Aggregate_FieldsResolvers<ContextType>;
  calendarHistory_max_fields?: CalendarHistory_Max_FieldsResolvers<ContextType>;
  calendarHistory_min_fields?: CalendarHistory_Min_FieldsResolvers<ContextType>;
  calendarHistory_mutation_response?: CalendarHistory_Mutation_ResponseResolvers<ContextType>;
  calendarPreference?: CalendarPreferenceResolvers<ContextType>;
  calendarPreference_aggregate?: CalendarPreference_AggregateResolvers<ContextType>;
  calendarPreference_aggregate_fields?: CalendarPreference_Aggregate_FieldsResolvers<ContextType>;
  calendarPreference_max_fields?: CalendarPreference_Max_FieldsResolvers<ContextType>;
  calendarPreference_min_fields?: CalendarPreference_Min_FieldsResolvers<ContextType>;
  calendarPreference_mutation_response?: CalendarPreference_Mutation_ResponseResolvers<ContextType>;
  calendarStartsOn?: CalendarStartsOnResolvers<ContextType>;
  calendarStartsOn_aggregate?: CalendarStartsOn_AggregateResolvers<ContextType>;
  calendarStartsOn_aggregate_fields?: CalendarStartsOn_Aggregate_FieldsResolvers<ContextType>;
  calendarStartsOn_max_fields?: CalendarStartsOn_Max_FieldsResolvers<ContextType>;
  calendarStartsOn_min_fields?: CalendarStartsOn_Min_FieldsResolvers<ContextType>;
  calendarStartsOn_mutation_response?: CalendarStartsOn_Mutation_ResponseResolvers<ContextType>;
  category?: CategoryResolvers<ContextType>;
  category_aggregate?: Category_AggregateResolvers<ContextType>;
  category_aggregate_fields?: Category_Aggregate_FieldsResolvers<ContextType>;
  category_avg_fields?: Category_Avg_FieldsResolvers<ContextType>;
  category_max_fields?: Category_Max_FieldsResolvers<ContextType>;
  category_min_fields?: Category_Min_FieldsResolvers<ContextType>;
  category_mutation_response?: Category_Mutation_ResponseResolvers<ContextType>;
  category_stddev_fields?: Category_Stddev_FieldsResolvers<ContextType>;
  category_stddev_pop_fields?: Category_Stddev_Pop_FieldsResolvers<ContextType>;
  category_stddev_samp_fields?: Category_Stddev_Samp_FieldsResolvers<ContextType>;
  category_sum_fields?: Category_Sum_FieldsResolvers<ContextType>;
  category_var_pop_fields?: Category_Var_Pop_FieldsResolvers<ContextType>;
  category_var_samp_fields?: Category_Var_Samp_FieldsResolvers<ContextType>;
  category_variance_fields?: Category_Variance_FieldsResolvers<ContextType>;
  colorFamilyEnum?: ColorFamilyEnumResolvers<ContextType>;
  colorFamilyEnum_aggregate?: ColorFamilyEnum_AggregateResolvers<ContextType>;
  colorFamilyEnum_aggregate_fields?: ColorFamilyEnum_Aggregate_FieldsResolvers<ContextType>;
  colorFamilyEnum_max_fields?: ColorFamilyEnum_Max_FieldsResolvers<ContextType>;
  colorFamilyEnum_min_fields?: ColorFamilyEnum_Min_FieldsResolvers<ContextType>;
  colorFamilyEnum_mutation_response?: ColorFamilyEnum_Mutation_ResponseResolvers<ContextType>;
  contact_sync?: Contact_SyncResolvers<ContextType>;
  contact_sync_aggregate?: Contact_Sync_AggregateResolvers<ContextType>;
  contact_sync_aggregate_fields?: Contact_Sync_Aggregate_FieldsResolvers<ContextType>;
  contact_sync_max_fields?: Contact_Sync_Max_FieldsResolvers<ContextType>;
  contact_sync_min_fields?: Contact_Sync_Min_FieldsResolvers<ContextType>;
  contact_sync_mutation_response?: Contact_Sync_Mutation_ResponseResolvers<ContextType>;
  databasechangelog?: DatabasechangelogResolvers<ContextType>;
  databasechangelog_aggregate?: Databasechangelog_AggregateResolvers<ContextType>;
  databasechangelog_aggregate_fields?: Databasechangelog_Aggregate_FieldsResolvers<ContextType>;
  databasechangelog_avg_fields?: Databasechangelog_Avg_FieldsResolvers<ContextType>;
  databasechangelog_max_fields?: Databasechangelog_Max_FieldsResolvers<ContextType>;
  databasechangelog_min_fields?: Databasechangelog_Min_FieldsResolvers<ContextType>;
  databasechangelog_mutation_response?: Databasechangelog_Mutation_ResponseResolvers<ContextType>;
  databasechangelog_stddev_fields?: Databasechangelog_Stddev_FieldsResolvers<ContextType>;
  databasechangelog_stddev_pop_fields?: Databasechangelog_Stddev_Pop_FieldsResolvers<ContextType>;
  databasechangelog_stddev_samp_fields?: Databasechangelog_Stddev_Samp_FieldsResolvers<ContextType>;
  databasechangelog_sum_fields?: Databasechangelog_Sum_FieldsResolvers<ContextType>;
  databasechangelog_var_pop_fields?: Databasechangelog_Var_Pop_FieldsResolvers<ContextType>;
  databasechangelog_var_samp_fields?: Databasechangelog_Var_Samp_FieldsResolvers<ContextType>;
  databasechangelog_variance_fields?: Databasechangelog_Variance_FieldsResolvers<ContextType>;
  databasechangeloglock?: DatabasechangeloglockResolvers<ContextType>;
  databasechangeloglock_aggregate?: Databasechangeloglock_AggregateResolvers<ContextType>;
  databasechangeloglock_aggregate_fields?: Databasechangeloglock_Aggregate_FieldsResolvers<ContextType>;
  databasechangeloglock_avg_fields?: Databasechangeloglock_Avg_FieldsResolvers<ContextType>;
  databasechangeloglock_max_fields?: Databasechangeloglock_Max_FieldsResolvers<ContextType>;
  databasechangeloglock_min_fields?: Databasechangeloglock_Min_FieldsResolvers<ContextType>;
  databasechangeloglock_mutation_response?: Databasechangeloglock_Mutation_ResponseResolvers<ContextType>;
  databasechangeloglock_stddev_fields?: Databasechangeloglock_Stddev_FieldsResolvers<ContextType>;
  databasechangeloglock_stddev_pop_fields?: Databasechangeloglock_Stddev_Pop_FieldsResolvers<ContextType>;
  databasechangeloglock_stddev_samp_fields?: Databasechangeloglock_Stddev_Samp_FieldsResolvers<ContextType>;
  databasechangeloglock_sum_fields?: Databasechangeloglock_Sum_FieldsResolvers<ContextType>;
  databasechangeloglock_var_pop_fields?: Databasechangeloglock_Var_Pop_FieldsResolvers<ContextType>;
  databasechangeloglock_var_samp_fields?: Databasechangeloglock_Var_Samp_FieldsResolvers<ContextType>;
  databasechangeloglock_variance_fields?: Databasechangeloglock_Variance_FieldsResolvers<ContextType>;
  date?: GraphQLScalarType;
  dateFormat?: DateFormatResolvers<ContextType>;
  dateFormat_aggregate?: DateFormat_AggregateResolvers<ContextType>;
  dateFormat_aggregate_fields?: DateFormat_Aggregate_FieldsResolvers<ContextType>;
  dateFormat_max_fields?: DateFormat_Max_FieldsResolvers<ContextType>;
  dateFormat_min_fields?: DateFormat_Min_FieldsResolvers<ContextType>;
  dateFormat_mutation_response?: DateFormat_Mutation_ResponseResolvers<ContextType>;
  google_credential?: Google_CredentialResolvers<ContextType>;
  google_credential_aggregate?: Google_Credential_AggregateResolvers<ContextType>;
  google_credential_aggregate_fields?: Google_Credential_Aggregate_FieldsResolvers<ContextType>;
  google_credential_max_fields?: Google_Credential_Max_FieldsResolvers<ContextType>;
  google_credential_min_fields?: Google_Credential_Min_FieldsResolvers<ContextType>;
  google_credential_mutation_response?: Google_Credential_Mutation_ResponseResolvers<ContextType>;
  jsonb?: GraphQLScalarType;
  linear_issue?: Linear_IssueResolvers<ContextType>;
  linear_issue_aggregate?: Linear_Issue_AggregateResolvers<ContextType>;
  linear_issue_aggregate_fields?: Linear_Issue_Aggregate_FieldsResolvers<ContextType>;
  linear_issue_max_fields?: Linear_Issue_Max_FieldsResolvers<ContextType>;
  linear_issue_min_fields?: Linear_Issue_Min_FieldsResolvers<ContextType>;
  linear_issue_mutation_response?: Linear_Issue_Mutation_ResponseResolvers<ContextType>;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  new_contact?: New_ContactResolvers<ContextType>;
  new_contact_aggregate?: New_Contact_AggregateResolvers<ContextType>;
  new_contact_aggregate_fields?: New_Contact_Aggregate_FieldsResolvers<ContextType>;
  new_contact_max_fields?: New_Contact_Max_FieldsResolvers<ContextType>;
  new_contact_min_fields?: New_Contact_Min_FieldsResolvers<ContextType>;
  new_contact_mutation_response?: New_Contact_Mutation_ResponseResolvers<ContextType>;
  new_feedback?: New_FeedbackResolvers<ContextType>;
  new_feedback_aggregate?: New_Feedback_AggregateResolvers<ContextType>;
  new_feedback_aggregate_fields?: New_Feedback_Aggregate_FieldsResolvers<ContextType>;
  new_feedback_max_fields?: New_Feedback_Max_FieldsResolvers<ContextType>;
  new_feedback_min_fields?: New_Feedback_Min_FieldsResolvers<ContextType>;
  new_feedback_mutation_response?: New_Feedback_Mutation_ResponseResolvers<ContextType>;
  new_user?: New_UserResolvers<ContextType>;
  new_user_aggregate?: New_User_AggregateResolvers<ContextType>;
  new_user_aggregate_fields?: New_User_Aggregate_FieldsResolvers<ContextType>;
  new_user_max_fields?: New_User_Max_FieldsResolvers<ContextType>;
  new_user_min_fields?: New_User_Min_FieldsResolvers<ContextType>;
  new_user_mutation_response?: New_User_Mutation_ResponseResolvers<ContextType>;
  profile?: ProfileResolvers<ContextType>;
  profile_aggregate?: Profile_AggregateResolvers<ContextType>;
  profile_aggregate_fields?: Profile_Aggregate_FieldsResolvers<ContextType>;
  profile_max_fields?: Profile_Max_FieldsResolvers<ContextType>;
  profile_min_fields?: Profile_Min_FieldsResolvers<ContextType>;
  profile_mutation_response?: Profile_Mutation_ResponseResolvers<ContextType>;
  query_root?: Query_RootResolvers<ContextType>;
  refreshUserStatusOutput?: RefreshUserStatusOutputResolvers<ContextType>;
  shareCalendarLink?: ShareCalendarLinkResolvers<ContextType>;
  shareCalendarLinkTypeEnum?: ShareCalendarLinkTypeEnumResolvers<ContextType>;
  shareCalendarLinkTypeEnum_aggregate?: ShareCalendarLinkTypeEnum_AggregateResolvers<ContextType>;
  shareCalendarLinkTypeEnum_aggregate_fields?: ShareCalendarLinkTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  shareCalendarLinkTypeEnum_max_fields?: ShareCalendarLinkTypeEnum_Max_FieldsResolvers<ContextType>;
  shareCalendarLinkTypeEnum_min_fields?: ShareCalendarLinkTypeEnum_Min_FieldsResolvers<ContextType>;
  shareCalendarLinkTypeEnum_mutation_response?: ShareCalendarLinkTypeEnum_Mutation_ResponseResolvers<ContextType>;
  shareCalendarLink_aggregate?: ShareCalendarLink_AggregateResolvers<ContextType>;
  shareCalendarLink_aggregate_fields?: ShareCalendarLink_Aggregate_FieldsResolvers<ContextType>;
  shareCalendarLink_avg_fields?: ShareCalendarLink_Avg_FieldsResolvers<ContextType>;
  shareCalendarLink_max_fields?: ShareCalendarLink_Max_FieldsResolvers<ContextType>;
  shareCalendarLink_min_fields?: ShareCalendarLink_Min_FieldsResolvers<ContextType>;
  shareCalendarLink_mutation_response?: ShareCalendarLink_Mutation_ResponseResolvers<ContextType>;
  shareCalendarLink_stddev_fields?: ShareCalendarLink_Stddev_FieldsResolvers<ContextType>;
  shareCalendarLink_stddev_pop_fields?: ShareCalendarLink_Stddev_Pop_FieldsResolvers<ContextType>;
  shareCalendarLink_stddev_samp_fields?: ShareCalendarLink_Stddev_Samp_FieldsResolvers<ContextType>;
  shareCalendarLink_sum_fields?: ShareCalendarLink_Sum_FieldsResolvers<ContextType>;
  shareCalendarLink_var_pop_fields?: ShareCalendarLink_Var_Pop_FieldsResolvers<ContextType>;
  shareCalendarLink_var_samp_fields?: ShareCalendarLink_Var_Samp_FieldsResolvers<ContextType>;
  shareCalendarLink_variance_fields?: ShareCalendarLink_Variance_FieldsResolvers<ContextType>;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  theme?: ThemeResolvers<ContextType>;
  theme_aggregate?: Theme_AggregateResolvers<ContextType>;
  theme_aggregate_fields?: Theme_Aggregate_FieldsResolvers<ContextType>;
  theme_max_fields?: Theme_Max_FieldsResolvers<ContextType>;
  theme_min_fields?: Theme_Min_FieldsResolvers<ContextType>;
  theme_mutation_response?: Theme_Mutation_ResponseResolvers<ContextType>;
  timestamp?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  todo?: TodoResolvers<ContextType>;
  todoTypeEnum?: TodoTypeEnumResolvers<ContextType>;
  todoTypeEnum_aggregate?: TodoTypeEnum_AggregateResolvers<ContextType>;
  todoTypeEnum_aggregate_fields?: TodoTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  todoTypeEnum_max_fields?: TodoTypeEnum_Max_FieldsResolvers<ContextType>;
  todoTypeEnum_min_fields?: TodoTypeEnum_Min_FieldsResolvers<ContextType>;
  todoTypeEnum_mutation_response?: TodoTypeEnum_Mutation_ResponseResolvers<ContextType>;
  todo_aggregate?: Todo_AggregateResolvers<ContextType>;
  todo_aggregate_fields?: Todo_Aggregate_FieldsResolvers<ContextType>;
  todo_avg_fields?: Todo_Avg_FieldsResolvers<ContextType>;
  todo_max_fields?: Todo_Max_FieldsResolvers<ContextType>;
  todo_min_fields?: Todo_Min_FieldsResolvers<ContextType>;
  todo_mutation_response?: Todo_Mutation_ResponseResolvers<ContextType>;
  todo_stddev_fields?: Todo_Stddev_FieldsResolvers<ContextType>;
  todo_stddev_pop_fields?: Todo_Stddev_Pop_FieldsResolvers<ContextType>;
  todo_stddev_samp_fields?: Todo_Stddev_Samp_FieldsResolvers<ContextType>;
  todo_sum_fields?: Todo_Sum_FieldsResolvers<ContextType>;
  todo_var_pop_fields?: Todo_Var_Pop_FieldsResolvers<ContextType>;
  todo_var_samp_fields?: Todo_Var_Samp_FieldsResolvers<ContextType>;
  todo_variance_fields?: Todo_Variance_FieldsResolvers<ContextType>;
  userActivityFeed?: UserActivityFeedResolvers<ContextType>;
  userActivityFeed_aggregate?: UserActivityFeed_AggregateResolvers<ContextType>;
  userActivityFeed_aggregate_fields?: UserActivityFeed_Aggregate_FieldsResolvers<ContextType>;
  userActivityFeed_max_fields?: UserActivityFeed_Max_FieldsResolvers<ContextType>;
  userActivityFeed_min_fields?: UserActivityFeed_Min_FieldsResolvers<ContextType>;
  userActivityFeed_mutation_response?: UserActivityFeed_Mutation_ResponseResolvers<ContextType>;
  userPreferences?: UserPreferencesResolvers<ContextType>;
  userPreferences_aggregate?: UserPreferences_AggregateResolvers<ContextType>;
  userPreferences_aggregate_fields?: UserPreferences_Aggregate_FieldsResolvers<ContextType>;
  userPreferences_avg_fields?: UserPreferences_Avg_FieldsResolvers<ContextType>;
  userPreferences_max_fields?: UserPreferences_Max_FieldsResolvers<ContextType>;
  userPreferences_min_fields?: UserPreferences_Min_FieldsResolvers<ContextType>;
  userPreferences_mutation_response?: UserPreferences_Mutation_ResponseResolvers<ContextType>;
  userPreferences_stddev_fields?: UserPreferences_Stddev_FieldsResolvers<ContextType>;
  userPreferences_stddev_pop_fields?: UserPreferences_Stddev_Pop_FieldsResolvers<ContextType>;
  userPreferences_stddev_samp_fields?: UserPreferences_Stddev_Samp_FieldsResolvers<ContextType>;
  userPreferences_sum_fields?: UserPreferences_Sum_FieldsResolvers<ContextType>;
  userPreferences_var_pop_fields?: UserPreferences_Var_Pop_FieldsResolvers<ContextType>;
  userPreferences_var_samp_fields?: UserPreferences_Var_Samp_FieldsResolvers<ContextType>;
  userPreferences_variance_fields?: UserPreferences_Variance_FieldsResolvers<ContextType>;
  userStatus?: UserStatusResolvers<ContextType>;
  userStatus_aggregate?: UserStatus_AggregateResolvers<ContextType>;
  userStatus_aggregate_fields?: UserStatus_Aggregate_FieldsResolvers<ContextType>;
  userStatus_max_fields?: UserStatus_Max_FieldsResolvers<ContextType>;
  userStatus_min_fields?: UserStatus_Min_FieldsResolvers<ContextType>;
  userStatus_mutation_response?: UserStatus_Mutation_ResponseResolvers<ContextType>;
  user_allowlist?: User_AllowlistResolvers<ContextType>;
  user_allowlist_aggregate?: User_Allowlist_AggregateResolvers<ContextType>;
  user_allowlist_aggregate_fields?: User_Allowlist_Aggregate_FieldsResolvers<ContextType>;
  user_allowlist_max_fields?: User_Allowlist_Max_FieldsResolvers<ContextType>;
  user_allowlist_min_fields?: User_Allowlist_Min_FieldsResolvers<ContextType>;
  user_allowlist_mutation_response?: User_Allowlist_Mutation_ResponseResolvers<ContextType>;
  uuid?: GraphQLScalarType;
  videoProvider?: VideoProviderResolvers<ContextType>;
  videoProvider_aggregate?: VideoProvider_AggregateResolvers<ContextType>;
  videoProvider_aggregate_fields?: VideoProvider_Aggregate_FieldsResolvers<ContextType>;
  videoProvider_max_fields?: VideoProvider_Max_FieldsResolvers<ContextType>;
  videoProvider_min_fields?: VideoProvider_Min_FieldsResolvers<ContextType>;
  videoProvider_mutation_response?: VideoProvider_Mutation_ResponseResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;